:root {
  --rs-blue-primary: #454db2;
  --primary-white: #ffffff;
  --primary-grey: #3c3838;
}

@font-face {
  font-family: 'SegoeUI';
  src:
    local('SegoeUI'),
    url(./fonts/segoe-ui-4-cufonfonts/SegoeUI.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src:
    local('OpenSans-Regular'),
    url(./fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src:
    local('OpenSans-Bold'),
    url(./fonts/Open_Sans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src:
    local('OpenSans-SemiBold'),
    url(./fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
}

.open-sans-bold {
  font-family: OpenSans-Bold;
  font-weight: 600;
}

.open-sans-regular {
  font-family: OpenSans-Regular;
}

.border_none_button_style {
  color: var(--lm-normal-525252);
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: OpenSans-Regular;
  font-size: 14px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  top: 226px;
  left: 1608px;
  width: 140px;
  height: 32px;
}

.screen_height_style {
  display: flex;
  height: 85vh;
}

.disable-style {
  pointer-events: none;
  color: rgb(168, 163, 163);
}
.popup_width_style {
  width: 600px;
}
.content_area_style1 {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  padding: 3px;
  height: 550px;
}

.popup_height_style {
  padding: 32px;
  max-height: fit-content;
  min-height: 48vh;
}

.slider_label_style {
  width: 272px;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
  border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #e5ecfc;
  border-radius: 5px;
  opacity: 1;
}
.slider_style {
  width: 310px;
}

.project-action {
  display: none;
}
.project-row:hover .project-action {
  display: flex;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

.color_grey {
  color: rgb(114, 113, 113);
  font-size: 12px;
  font-family: OpenSans-Regular;
}

.color_blue {
  color: #1648c6;
}

.content-wrapper {
  height: 100%;
}

.btn_style {
  background: var(--primary-1648c6) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-1648c6);
  background: #1648c6 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  margin-left: 15px;
  color: var(--lm-workspace-f6f7f9);
  text-align: center;
  letter-spacing: 0px;
  color: #f6f7f9;
  opacity: 1;
  padding: 4px;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 14px;
}
.btn_style1 {
  background: var(--primary-1648c6) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-1648c6);
  background: #bec0c2 0% 0% no-repeat padding-box;
  border: 1px solid #bec0c2;
  border-radius: 2px;
  margin-left: 15px;
  color: var(--lm-workspace-f6f7f9);
  text-align: center;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding: 4px;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 14px;
}

.btn_style_layout_project {
  top: 226px;
  left: 1772px;
  width: 84px;
  height: 32px;
}
.btn_style_layout_role {
  top: 208px;
  left: 1772px;
  width: 84px;
  height: 32px;
}

.btn_style_layout_user {
  top: 208px;
  left: 1772px;
  width: 84px;
  height: 32px;
}

.search_btn_style {
  width: 35px;
  height: 17px;
  opacity: 1;
  margin-top: 6px;
}

.border_style {
  border-top: 1px solid #e2e5ec;
}

.border_bottom_style {
  border-bottom: 1px solid #e2e5ec;
}

.input-field-color {
  border-color: #dad5d5;
}

.PhoneInputInput {
  border: none;
  border-bottom: 2px solid #dad5d5;
  padding-bottom: 0;
}

textarea:focus,
input:focus {
  outline: none;
  box-shadow: none;
}

.section_label_width {
  width: 291px;
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-weight: 400;
}

.role_permission_style {
  box-shadow: 0px 0px 6px #000000a1;
  opacity: 1;
  background-color: #fbfcfd;
  margin: 2px;
  top: 376px;
  margin-right: 12px;
  left: 489px;
  margin-top: 20px;
  padding: 32px;
  height: 321px;
  width: 943px;
}
.role_permission_style1 {
  box-shadow: 0px 0px 6px #000000a1;
  opacity: 1;
  background-color: #fbfcfd;
  margin: 2px;
  top: 376px;
  margin-right: 12px;
  left: 489px;
  margin-top: 20px;
}
.role_permission_style2 {
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 8px;
  padding: 3px;
}

.pagination_border_style {
  border: 1px solid grey;
}

.pagination_li_style {
  border: 1px solid grey;
  height: 24px;
  width: 20px;
  padding-left: 2px;
}

.pagination_input_style {
  height: 27px;
}

.pagination_icon_style {
  border: 1px solid grey;
  padding: 9px;
}

.selected_page_style {
  background-color: #5580ec;
}

.disable_icon {
  pointer-events: none;
  opacity: 0.5;
}

.delete-style {
  font-size: 18px;
  margin-right: 10px;
  opacity: 1;
}

.delete-style:hover {
  color: #b4031b;
  opacity: 100;
}
.more_icon {
  font-size: 18px;
  color: #000000;
  margin-right: 10px;
  opacity: 1;
}
.more_icon:hover {
  color: #434db8;
  opacity: 100;
}
.fileStyle {
  opacity: 0;
  inset: 0px;
  cursor: pointer;
  width: 80px;
  border: 1px solid red;
  height: 25px;
  font-size: 0;
  position: absolute;
}

.edit-style {
  font-size: 18px;
  margin-right: 10px;
  opacity: 1;
}

.edit-style:hover {
  color: #1648c6;
  opacity: 100;
}

.delete_btn_style {
  margin-top: 12px;
  float: right;
}

.delete_btn_style_role_details {
  text-align: right;
  padding: 12px 0;
}

.delete_btn_style_user_details {
  margin-top: 30px;
  float: right;
}

.user_delete_btn_style_userdetails {
  text-align: right;
  padding: 12px 0;
}

.marginupper {
  border-top: 3px solid #efbb07;
}

.line {
  width: 259% !important;
  border-top: 2px solid #f0f3f9;
  margin-left: -79px !important;
}

.modal.non-link-color-common {
  color: #3c3838;
}

.dashboard-font-style-common {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 13px;
  font-family: Poppins-Regular;
}

.dashed_border {
  border-bottom: 1px dashed grey;
}

.details_table_height {
  max-height: 35vh;
  overflow-y: auto;
  min-height: 15vh;
}

.table-height {
  flex: 1 1;
  flex-basis: 0;
  z-index: 0;
}
.userRoleMapping table thead tr {
  background: #f0f3f9 !important;
}
.userRoleMapping tbody tr {
  font-weight: normal !important;
  font-family: 'OpenSans-Regular';
  font-size: 12px;
}
.table_header_color {
  background-color: #f0f3f9 !important;
}

.thead_style {
  background-color: #e5ecfc !important;
}
table thead td {
  background-color: #f0f3f9;
}

table tr:hover {
  background-color: #f0f3f9 !important;
}

.ReactModal__Content {
  margin: auto;
  left: 0 !important;
  right: 0 !important;
  top: 0px !important;
  bottom: 0 !important;
  height: fit-content !important;
  width: fit-content;
  min-width: 620px;
  transform: inherit !important;
  border-radius: 8px;
}
.modal-dialog .modal-container {
  max-height: calc(100% - 100px) !important;
  border: unset;
}
.modal {
  z-index: 9999;
}
.MuiTypography-body1 {
  font-family: Poppins-Regular;
}
.Popup-header-layout-style-role {
  top: 230px;
  left: 656px;
  height: 22px;
  font-size: 16px;
  font-family: OpenSans-SemiBold;
  color: #3c3838;
}
.Popup-header-layout-style-user {
  top: 373px;
  left: 656px;
  height: 22px;
  color: var(--primary-grey);
}
.reschedule-header,
.Popup-header-layout-style-use {
  color: var(--primary-white);
  padding-left: 10px;
}

.create-button-style-common {
  border: 1px solid var(--primary-1648c6);
  background: #3a62c9 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
}

.create-button-layout-style-project {
  top: 858px;
  left: 1187px;
  width: 77px;
  height: 32px;
}

.create-button-layout-style-role {
  top: 820px;
  left: 1187px;
  width: 77px;
  height: 32px;
}
.create-button-layout-style-user {
  top: 676px;
  left: 1187px;
  width: 77px;
  height: 32px;
}

.create-label-common {
  color: var(--lm-workspace-light-fbfcfd);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fbfcfd;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-weight: 400;
  font-size: 14px;
}

.create-label-layout-style-project {
  top: 865px;
  left: 1203px;
  width: 45px;
  height: 19px;
}

.create-label-layout-style-role {
  top: 827px;
  left: 1203px;
  width: 45px;
  height: 19px;
}

.create-label-layout-style-role {
  top: 683px;
  left: 1203px;
  width: 45px;
  height: 19px;
}

.cancel-button-common {
  background: var(--dm-hover-dfdfdf) 0% 0% no-repeat padding-box;
  background: #eeeded 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  opacity: 1;
}
.cancel-button-common1 {
  background: var(--dm-hover-dfdfdf) 0% 0% no-repeat padding-box;
  background: #3a62c9 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
}

.cancel-button-layout-style-project {
  top: 900px;
  left: 1086px;
  width: 77px;
  height: 32px;
}

.cancel-button-layout-style-project1 {
  top: 900px;
  left: 1086px;
  width: 143px;
  height: 32px;
}

.cancel-button-layout-style-role {
  top: 820px;
  left: 1086px;
  width: 77px;
  height: 32px;
}

.cancel-button-layout-style-user {
  top: 676px;
  left: 1086px;
  width: 77px;
  height: 32px;
}

.cancel-label-common {
  color: var(--lm-hover-0a0a0a);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #0a0a0a;
  opacity: 1;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 14px;
}

.cancel-label-common:hover {
  color: rgb(48, 69, 187);
}
.cancel-label-layout-style-project {
  top: 907px;
  left: 1102px;
  width: 45px;
  height: 19px;
}

.cancel-label-layout-style-role {
  top: 827px;
  left: 1102px;
  width: 45px;
  height: 19px;
}

.cancel-label-layout-style-user {
  top: 683px;
  left: 1102px;
  width: 45px;
  height: 19px;
}

.cancel-button-layout-style-delete-project {
  top: 539px;
  left: 1199px;
  width: 77px;
  height: 32px;
}

.delete-button-layout-style-delete-project {
  top: 539px;
  left: 1099px;
  width: 76px;
  height: 32px;
}

.toggle-checkbox:checked {
  right: 0;
  border-color: blue;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: white;
  border: 1px solid blue;
}

.details-table-header {
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.pending-activation-label-stle {
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 14px;
}

.role-proj-permission-content {
  color: var(--dm-normal-989797);
  text-align: left;
  letter-spacing: 0px;
  color: #989797;
  opacity: 1;
  padding-left: 11px;
}

.line .linechild {
  position: relative;
}
.line .linechild:after {
  content: '';
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -100%);
  z-index: -1;
}
.line .linechild:last-child:after {
  display: none;
}

.required-fied-msg-style-common {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal 600 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 12px;
}

.radio-style-project {
  color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.dropdown-style {
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  font-size: 12px;
  font-family: OpenSans-Regular;
  padding-bottom: 0;
}

.btn-style-layout-edit-proj-config {
  top: 204px;
  left: 1780px;
  width: 84px;
  height: 32px;
  color: var(--lm-workspace-f6f7f9);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f6f7f9;
  opacity: 1;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
  font-size: 14px;
}

.individual-user-edit {
  font-size: 16px;
}

.addUser-dropdown-style {
  box-shadow: 0px 0px 2px var(--primary-1648c6);
  box-shadow: 0px 0px 2px #1648c6;
  border: 1px solid #1648c652;
  border-radius: 2px;
  opacity: 1;
}

.adduser-content-height {
  height: 45vh;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 65px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  position: absolute;
  z-index: 1000;
  bottom: 100%;
  left: 165%;
  margin-top: 10px;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext1 {
  visibility: hidden;
  width: 65px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 165%;
  margin-bottom: -55px;
  margin-left: -55px;
}

.tooltip:hover .tooltiptext1 {
  visibility: visible;
}

.popupheader {
  color: #3c3838 !important;
  text-align: left;
  font-size: 16px !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
}
.popupTreeHeight {
  height: 300px !important;
}

.description-width-project {
  width: 96%;
}
.mapping-table td,
.mapping-table th {
  padding: 11px;
}

.cancel-warning-popup-continue-btn-style-layout {
  top: 600px;
  left: 1120px;
  width: 144px;
  height: 32px;
}

.cancel-warning-popup-continue-btn-label-style-common {
  color: var(--lm-workspace-light-fbfcfd);
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fbfcfd;
  opacity: 1;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
}

.cancel-warning-popup-cancel-btn-style-common {
  background: var(--dm-hover-dfdfdf) 0% 0% no-repeat padding-box;
  background: #eeeded 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  opacity: 1;
}

.update-exit-layout {
  top: 600px;
  left: 972px;
  width: 124px;
  height: 33px;
}
.cancel-btn-layout-style-warning {
  top: 600px;
  left: 872px;
  width: 76px;
  height: 32px;
}

.individual-user-edit {
  font-size: 16px;
}

.addUser-dropdown-style {
  box-shadow: 0px 0px 2px var(--primary-1648c6);
  box-shadow: 0px 0px 2px #1648c6;
  border: 1px solid #1648c652;
  border-radius: 2px;
  opacity: 1;
}

.project_pop {
  top: 233px;
  left: 496px;
  width: 925px;
  height: 16px;
  background: #ccf1fe 0% 0% no-repeat padding-box;
  opacity: 1;
}
.button_user {
  margin-top: 14px;
}

.close-btn-style-for-details-layout {
  top: 873px;
  left: 1187px;
  width: 77px;
  height: 32px;
}

.user-create-body-style {
  height: fit-content;
}

.user-edit-body-style {
  height: fit-content;
}
.forgetpassword {
  font-size: 10px;
  float: right;
}

.overflow-hidden-common {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.backtologin {
  width: 450px;
  text-align: center;
  padding-top: 20px;
}
.backtologin a {
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  margin-top: 20px;
  font-weight: 700;
  color: var(--rs-blue-primary);
  width: 500px;
  margin-left: 50px;
}

.gridLayout {
  width: 80%;
  margin: 30px auto !important;
  text-align: center;
  position: relative;
  top: -90px;
}

.cards h2 {
  color: #2596be;
  font-size: 33px;
  font-family: sans-serif;
  line-height: normal;
  height: 100px;
}
.buyBtn {
  width: 70%;
  margin: 35px;
  background: #2596be;
  border: none;
  height: 63px;
  padding-top: 20px;
  font-size: 16px;
}
.cards p {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  font-family: sans-serif;
  height: 60px;
  line-height: 1.7;
}
.price {
  color: #2596be;
}
.cardList ul {
  padding-left: 55px;
}
.cardList li span {
  font-size: 18px;
}
.cardListdetails ul {
  padding-left: 10px;
}
.cardListdetails ul span {
  font-size: 12px;
}
.container-body {
  padding: 20px;
  height: 400px;
  background-color: #2596be;
}
.flex1 h2 {
  color: white;
  font-weight: 800;
  font-family: sans-serif;
}
.testTo {
  color: orange;
}
.loginbtn {
  float: right;
  background-color: #da8e04;
}
.flex-Top {
  padding-top: 0.1rem;
}
.allproject_line {
  border-width: 1px;
  border-style: solid;
  border-color: #f0f3f9;
}
/* .menu-item {
  @apply hover:bg-gray-200;
} */
.desc {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

select.MuiNativeSelect-select {
  font-family: Poppins-Regular;
  padding-top: 0 !important;
  font-size: 12px !important;
}
select.MuiNativeSelect-select:focus {
  padding-top: 0 !important;
  font-size: 12px !important;
  background-color: transparent;
}

.page-count-style {
  color: var(--dm-primary-88a6f2);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #5580ec;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.cursor-hand {
  cursor: pointer;
}

.pagination-style {
  border: 1px solid grey;
  width: 17px;
  height: 20px;
  text-align: center;
  background-color: #1648c6;
  color: white;
  font-size: 13px;
}

.pageCount {
  padding-left: 0.35rem;
  padding-right: 0.5rem;
}
.currentpagecount-1 {
  width: 21px;
  height: 23px;
  background-color: #5580ec;
  border-radius: 1px;
  color: white;
}

svg.MuiSvgIcon-root.close-icon-style-header {
  height: 15px;
  width: 15px;
  margin-left: 21px;
}

.arrow-style {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 15px solid #ffffff;
  margin-top: 34px;
  position: inherit;
  margin-left: 50px;
}

.project-name-label-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 14px/19px Segoe UI;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.project-search-input-filed-style {
  background-color: #f0f3f9;
  color: red;
}

.line-of-divider {
  border: 1px solid var(--lm-left-top-nav-f0f3f9);
  border-bottom: 1px solid #f0f3f9;
  opacity: 1;
}

.dropdown-style-header {
  min-height: 50px;
  background-color: white;
  max-height: 41vh;
  overflow-y: auto;
  position: absolute;
  width: 20%;
  overflow-x: hidden;
  padding: 12px;
}

.box {
  width: 150px;
  min-height: 75px;
  max-height: 50vh;
  min-width: 250px;
  max-width: 331px;
  width: 15%;
  background-color: #fff;
  position: absolute;
  left: 140px;
  margin: 10px;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #ffffff;
  opacity: 1;
}

.individual-proj-style {
  min-height: 6vh;
  max-height: 33vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  user-select: none;
}
.input-select::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-field-font-style-head::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.input-field-font-style-head {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Segoe UI;
  letter-spacing: 0px;
  color: #3c3838;
  margin-top: 2px !important;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin-top: 4px;
  width: 225px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.project-row-hover {
  width: 100%;
  padding: 0px 15px;
  height: 36px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.project-row-hover:hover {
  background-color: #f0f3f9 !important;
  color: #1648c6;
  width: 100%;
  padding: 0px 15px;
  height: 36px;
}

#hoverTest:hover {
  color: #1648c6;
}
#webHover:hover {
  fill: #1648c6;
}

.pagination-clickable-style {
  font-weight: 800;
  font-size: 20px;
  color: #1648c6;
  cursor: pointer;
}

.searchable-field-width {
  width: 261px;
}

.selected-repo-tab-style {
  padding: 0.7px;
}

.element-tab-style {
  width: 103px;
}

.repo-child-margin-style {
  margin-bottom: 13px;
}

.pre-post-bordr-style-common {
  border-bottom: 1px solid #e5ecfc;
  border-top: 1px solid #e5ecfc;
}

.text-overflow-style {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
}

.widthSet {
  max-width: 120px;
}

.alert-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: -104px;
}
.MuiMenu-list {
  margin-left: -20px;
  padding-left: 10px;
  position: relative;
}

.autocomplete-git {
  margin-top: -6px;
  width: 81%;
}

.alert-position-style-uploadFiles {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: -48px;
}
