.licencemanament {
  display: inline-flex;
  margin-top: 20px;
  margin-left: 20px;
}
.licencemanament h6 {
  color: blue;
  font-weight: 800;
  font-family: sans-serif;
}
.licencemanament button {
  float: right;
  background: blue;
  color: white;
  font-family: sans-serif;
  margin-left: 1250px;
  height: 40px;
  width: 150px;
  font-weight: 600;
}
.table-Container {
  background-color: #f6f7f9;
}
/* .table-Container TableHead{
    color:red;
} */
.licenceTableHead {
  background-color: #f0f3f9;
  font-size: 14px;
  font-weight: bold !important;
}
.selectButton {
  width: 140px;
}
.borderStyle {
  border-bottom: dotted;
  border-top: dotted;
  border-color: lightgrey;
}
.tableStyle {
  width: 80%;
}
.headerstyle {
  border: none !important;
}
