@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.legend-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -20px;
  right: 18px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.legend-value {
  @extend .fontPoppinsRegularXs;
  color: $grey_darkest;
  cursor: pointer;
}

.legend-label {
  @extend .fontPoppinsMediumSm;
  color: $grey_darkest;
  margin-right: 5px;
  cursor: pointer;
  color: $rs_primary;
  text-decoration: underline;
}
