@import './variable.scss';
@import '@styles/rs_fonts.scss';

.testoptimize-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .page-body {
    display: flex;
    min-height: calc(100vh - 40px);
    background-color: $skyblue_light;
    overflow-x: hidden;

    &.margin-top-px49 {
      margin-top: 49px;
    }

    .sidebar_menu_padding {
      flex: 1 1;
      background-color: $skyblue_light;
      flex: 1 1;
      padding-left: 10px;
      padding-top: 10px;
      padding-right: 10px;
      height: calc(100vh - 42px);

      @media (min-width: $xl) {
        // above 1280
      }

      .content_area_style {
        background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
        background: $white_light 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px $shadow_black;
        opacity: 1;
        top: 195px;
        left: 112px;
        height: calc(100vh - 107px);

        .content_area_header_style {
          height: 48px;
          padding-top: 8px;

          .main-header-label {
            @extend .fontPoppinsSemiboldXlg;
            text-align: left;
            letter-spacing: 0px;
            color: $content_area_header_font_color;
            opacity: 1;
            padding-left: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            margin-top: 3px;

            @media (min-width: $xl) {
              font-size: 16px;
            }
          }

          @media (min-width: $xl) {
            height: 64px;
            padding-top: 15px;
          }
        }

        .pagecountborder {
          height: 52px;
          border-top: 2px solid $white_dark;
          padding-top: 4px;
          bottom: 0px;
          position: fixed;
          left: 106px;
          right: 9px;

          // width: 1401px;
          .pagecount {
            color: $current_page_num_box_color;
          }

          .pageborderleft {
            border-left: 2px solid $current_page_num_box_color;

            .pageArrow {
              font-weight: 800;
              font-size: 20px;
              color: $grey_white;
              cursor: auto;
            }

            .currentpagecount {
              border-radius: 1px;
              opacity: 1;
              width: 20px;
              height: 20px;
              background-color: $current_page_num_box_color;
              border-radius: 1px;
              color: $current_page_count_color;
            }
          }

          @media (min-width: $xl) {
            // above 1280
            bottom: 3px;
          }
        }

        @media (min-width: $xl) {
          // above 1280
          // height: 853px;
          height: calc(100vh - 173px);
        }
      }

      .content_area_style_noTab_screen {
        @extend .content_area_style;
        background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
        background: $white_light 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px $shadow_black;
        opacity: 1;
        top: 195px;
        left: 112px;
        height: calc(106vh - 142px);
        margin-top: 20px;

        .dashboard_tree_height {
          height: 72vh;

          @media (min-width: $xl) {
            // above 1280
            // height: 853px;
          }
        }

        // @media (min-width:$xl) {
        //     // above 1280
        //     height: 853px;
        //     }
      }

      .content_area_style_execution {
        @extend .content_area_style;
        background: none;
        box-shadow: none;

        .content_area_header_style {
          background: $white_original 0% 0% no-repeat padding-box;
          box-shadow: 0px 1px 6px #0000001a;
          border-radius: 5px;
          opacity: 1;
        }
      }
    }
  }
}

.sidebar_menu_padding > div:first-child,
.sidebar_menu_padding .page-container {
  display: flex;
  flex-direction: column;

  .page-list-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
}

.pagination-clickable-style {
  font-weight: 800;
  font-size: 20px;
  color: $blue_dark;
  cursor: pointer;
}

.pageArrow {
  font-weight: 800;
  font-size: 20px;
  color: $grey_white;
}
