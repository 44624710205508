@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbox-support {
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background: $ff-light-silver;
  height: inherit;
  width: inherit;
  box-shadow: 0px 0px 15px $shodow_light_black;
  border-radius: 20px;
}

.send-button {
  @include center;
  padding: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: $ff-lavender-blue;
  margin-left: 5px;
}
.chatbox-messages {
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-height: calc(100vh - 146px);
  height: inherit;
  background-color: $white_light;
  padding: 0 10px;
}
.arrow-down-icon-style {
  @include center;
  position: absolute;
  bottom: 65px;
  right: 144px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: $ff-lavender-blue;

  .down-arrow-btn {
    transform: rotate(-90deg);
  }
}
.messages-item--operator {
  margin-left: auto;
  text-align: start;
  width: fit-content;
  word-wrap: break-word;
}

.chatbox-header {
  background: $ff-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  border-top-left-radius: 20px;
  box-shadow: 0px 10px 15px $shadow_black;
  height: 48px;
}
.chatbox-heading-header {
  @extend .fontPoppinsSemiboldLg;
  color: $white_original;
}
.chatbox-heading-subheader {
  @extend .fontPoppinsRegularSm;
  color: $white_light;
}
.chatbox-content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.exit-icon {
  @include center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: $white_original;
  }
}
.messages-item-visitor {
  @extend .fontPoppinsRegularSm;
  line-height: 16.5px;
  padding: 5px 5px 5px 18px;
  background: $ff-light-grayish-white-color;
  border-radius: 0px 10px 10px 10px;
  margin: 10px;
  margin-right: auto;
  max-width: 200px;
  word-wrap: break-word;
}
.messages-default {
  @extend .fontPoppinsRegularSm;
  padding: 5px;
  background: $ff-light-grayish-white-color;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 35px;
  line-height: 16.5px;
  margin-top: 16px;

  b {
    text-transform: capitalize;
  }
}
.messages-default > ul {
  padding-top: 12px;
  list-style-type: disc;
  margin-left: 30px;
}
.messages-item-visitor > ul {
  list-style-type: disc;
  margin-left: 30px;
}

.messages-item--operator {
  @extend .fontPoppinsRegularSm;
  border-radius: 10px 0px 10px 10px;
  padding: 5px;
  background: $ff-light-grayish-white-color;
  max-width: 200px;
  line-height: 16.5px;
}

.chatbox-footer {
  @include center;
  background: $white_original;
  border-radius: 0px 0px 10px 10px;
  height: 48px;
}
.chatbot-footer-innerdiv {
  width: 90%;
  display: flex;
  justify-content: space-between;
  background: $white_original;
}

.chatbot-footer-innerdiv input {
  border: none;
  padding: 10px 10px;
  border-radius: 30px;
  height: 32px;
  width: 225px;
  background: $ff-light-grayish-white-color;
  border: 1px solid $ff-medium-light-gray;
}

.chatbot-footer-innerdiv input[type='text']:focus {
  border: none;
  border-bottom: none;
  box-shadow: none;
  outline: none !important;
  text-align: left;
  background: $ff-light-grayish-white-color;
  padding-bottom: 10px;
  border: 1px solid $ff-light-grayish-white-color;
}

.chatbox-send-footer {
  color: $shodow_light_black;
  padding: 10px;
}

.container-data {
  width: 0px;
  position: fixed;
  top: 3rem;
  right: 0px;
  height: calc(100vh - 42px);
  z-index: 99;
  transition: 0.3s ease-in-out;
}
.container-data-hidden {
  @extend .container-data;
  right: -300px;
  visibility: hidden;
}

.chatbox {
  position: absolute;
  height: inherit;
}
.user-icon {
  @include center;
  height: 24px;
  width: 24px;
  margin-left: 8px;
  border-radius: 50%;
  background: $ff-soft-sky-blue;
}
.user-icon-text {
  @extend .fontPoppinsRegularSm;
  color: $ff-primary;
  text-transform: capitalize;
}
