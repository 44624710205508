@import '../../css_config/variable.scss';

.count-pill {
  background-color: $SkyLightblue;
  color: $grey_dark;
  border-radius: 14px;
  padding: 3px 11px;
  margin: 2px 5px;
  height: 28px;
}

.count-pill-numbers {
  background-color: $badge-blue;
  color: $white;
  border-radius: 14px;
  padding: 3px 11px;
  margin: 5px;
}

.email-name-trimmer {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-dropdown {
  width: 100%;
  margin-left: 3px;
  color: $grey_dark;

  div {
    &.react-dropdown-select-dropdown {
      &.react-dropdown-select-dropdown-position-bottom {
        width: 100%;
        border-radius: 8px;
        max-height: 210px;
      }
    }
  }
}
