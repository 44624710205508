/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */

/* Package Name Label Attribute*/

.console_output_container {
  border: 1px solid grey;
  height: calc(100% - 50px);
  overflow: auto;
}
.console-footer {
  margin-bottom: 24px;
}
.ace_gutter-layer,
.ace_print-margin-layer {
  overflow: hidden !important;
}

.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 80%;
}

.ace_scrollbar.ace_scrollbar-h {
  height: 7px !important;
  width: 60%;
}

.ace-tm .ace_gutter {
  background: #e2e2f7 !important;
}

/* Overall Ace Editor div */
.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}
.ace-console-wrp .project_label {
  padding-left: 0;
}
div#UNIQUE_ID {
  border: 1px solid grey;
  position: relative;
  width: 98% !important;
  height: 248px !important;
  /* margin-left: 22px; */
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */
.editor__console {
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  border: 1px solid grey !important;
  box-sizing: content-box !important;
  color: red;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}
.grid-full-height {
  height: 100%;
}
.editorRole {
  flex: 1 1;
}
