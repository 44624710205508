@import '@styles/_variables';

.text-info {
  color: $primary-light;
}

.info-svg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: none;
  :global {
    .MuiSvgIcon-root {
      width: 70%;
      height: auto;
    }
  }
}

.popup-title {
  color: $dark;
  margin-bottom: 16px;
}

.statement {
  font-size: 14px;
  color: $muted;
}

.sign-off-button {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  color: $dark;
  padding: 0.313rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 16px;

  &:hover {
    color: $primary;
    border-color: $primary;
  }
}
