@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

.left-image-section-wrapper {
  flex-grow: 1;
  font-family: OpenSans-Regular;

  .left-section {
    flex: 0 0 calc(4 * 100% / 12);
    width: calc(4 * 100% / 12);
    border-color: $border-muted;
    border-right-width: 1px;

    .page-for {
      color: $dark;
    }

    .page-note {
      color: $rs_blue_font;

      margin-top: 75px;
    }
  }

  .right-section {
    flex: 0 0 calc(8 * 100% / 12);
    width: calc(8 * 100% / 12);
  }
}

.common-background-wrapper {
  background: url('../../../assets/create-account-bg.jpg');
  background-size: cover;
  
  .SignUp_Container {
    height: auto;
    margin: 36px 80px 54px 110px;

    .signup_navbar {
      height: 37.07;
      width: 182.28px;
    }

    .signUp_text_container {
      color: #fff;

      .signup_header_text {
        width: 333px;
        height: 117px;
        text-align: left;
        letter-spacing: 0.54px;
        font-size: 30px;
        font-family: Poppins-Bold;
        text-transform: uppercase;
        margin-top: 110px;
        line-height: 37px;
      }

      .signup_text_message {
        width: 400px;
        text-align: left;
        margin-top: 30px;
        line-height: 26px;
        font-size: 17px;
        letter-spacing: 0.31px;
        font-family: 'Poppins-Regular';
      }
      .signup_ff_desc {
        text-align: left;
        letter-spacing: 0.31px;
        font-size: 19px;
        font-family: Poppins-Medium;
        margin-top: 20px;
        line-height: 26px;
      }
      .signup_copyright_text {
        font-size: 10px;
        font-family: Poppins-Regular;
        width: 244px;
        height: 15px;
        margin-top: 130px;
      }
    }

    .signup_form {
      width: 547px;
      height: 565px;
      margin-top: -20px;
      margin-left: 23px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 16px #00000029;
      border-radius: 13px;
      opacity: 1;
      padding: 16px;

      .signup_form_header {
        font-size: 18px;
        color: #434db8;
        font-family: Poppins-Bold;
        line-height: 24px;
      }

      .signup_form_border {
        border-bottom: 2px solid #db7221;
        width: 8%;
        margin: auto;
      }
      .signup_footer {
        @extend .fontPoppinsSemiboldSm;
        display: flex;
        height: 17px;
        padding: 14px 0px;
        .signup_footer_text_color {
          color: $black;
        }
        .signup_footer_signin_color {
          color: $orange;
        }
      }
    }
  }
}

.signup-wizard {
  padding: 30px 75px;

  .section-heading {
    color: $black;
  }

  .section-sub-heading {
    color: $black;

    a {
      color: $rs_blue_font;
    }
  }
}

.phone-input-wrapper {
  width: 242px;
  :global(.react-tel-input) {
    :global {
      .form-control {
        height: 25px;
        width: 99%;
        border: 1px solid $border-gray;
        border-radius: 5px;
        padding: 15px 0px 15px 50px;
        box-shadow: 0px 0px;
        font-size: 12px;
      }
      .flag-dropdown {
        left: 4%;
      }

      .form-control:focus {
        border: 2px solid $primary;
      }

      .selected-flag {
        padding-left: 0;
      }

      .country-list {
        width: 335px;
        height: 236px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px $border-muted;
        text-align: left;
        position: relative;
        background-color: $sky-blue-light;
      }

      .search-box {
        border: 1px solid $border-gray;
        border-radius: 5px;
        // border-top: 0;
        // border-left: 0;
        // border-right: 0;
        box-shadow: 0px 0px;
        width: 90%;
      }
    }
  }

  &.error {
    :global(.react-tel-input) {
      input {
        border: 1px solid $danger;
      }
    }
  }
}

.password-info-icon {
  :global {
    .MuiSvgIcon-root {
      width: 14px;
      height: 14px;
    }
  }
}

.phone-number-inputs-wrapper {
  min-height: 70px;
  width: 242px;
}
.signup_recaptch_container {
  height: 128px;
  .recaptch_container {
    width: 242px;
  }
}

.signup-input-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  gap: 34px;
}
