@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.base-h {
  height: 357px;
}

.empty_page_info {
  color: rgb(116, 115, 115);
}

.stepwidth {
  width: 335px;
}

.stepRequir {
  margin-top: 65px;
  margin-left: -360px;
}

.stepline {
  width: 160%;
  margin-left: -375px;
}

.elereq {
  margin-left: -460px;
}

.title-trim-folderFile {
  white-space: nowrap;
  max-width: 221px;
  text-overflow: ellipsis;
}

.elestepline {
  width: 158%;
  margin-left: -466px;
}

.active_tab_style {
  border-bottom: 3px solid blue;
}

.active_tab_color {
  color: #1648c6;
}

.repoFont {
  width: 500px;
  font-size: 14px;
  border: 1px solid #e5ecfc;
  padding-top: 9px;
  height: 39px;
  float: right !important;
}

.dropdownMargin {
  position: absolute;
  margin-top: 4px !important;
  margin-left: -65px !important;
}

.typedrop {
  font-size: 14px;
  padding: 0px 15px;
  top: 146px !important;
  left: 1154px;
  width: 64px;
  height: 31px;
  background: transparent;
  border: 1px solid #e5ecfc;
  border-radius: 2px;
  opacity: 1;
}

.count-style {
  background-color: lightgray;
  color: black;
  font-size: 10px;
}

.proj-count-style {
  background-color: #e5ecfc;
  color: #2554ca;
  font-size: 10px;
}

.bgColour {
  padding: 5px 6px;
  background-color: #e5ecfc;
}

.marginrig {
  border-right: 1px solid #2554ca;
  height: 35px;
  margin-right: 20px;
  margin-left: 20px;
}

.editmarginLeft {
  margin-left: auto;
}

.locatorHeight {
  height: 90px;
}

#treeSuggestion {
  display: none;
  font-size: 0.74rem;
  line-height: 1rem;
}

.search-tree:focus + #treeSuggestion {
  display: inline-block;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    background-color: #fbfcfd;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 8px #000000a1;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .modal-header {
      border-bottom: 1px solid #e5ecfc;
      padding: 12px 12px;
      position: relative;

      h2.title {
        @extend .fontPoppinsMediumLg;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal-body-folder {
      padding: 14px;
      height: 300px;
      overflow-y: auto;
    }

    .modal-body {
      padding: 7px;
      height: calc(100% - 110px);
      flex: 1 1;

      .mat-card {
        height: 100%;
      }

      overflow-y: auto;
    }

    .modal-footer {
      text-align: right;

      button {
        margin-left: 18px;
      }
    }
  }
}

.element-modal-size {
  width: 816px;
  height: 978px;
}

.secondary-button-layout-style {
  height: 32px;
  padding-left: 5px;
  padding-right: 5px;
}

.page-modal-size {
  width: 640px;
  height: auto;
}

.page-modal-size1 {
  width: 100%;
  height: 100%;
}

.loactor-details-modal-size {
  width: 960px;
  height: 669px;
}

.stepgroup-modal-size {
  width: 640px;
  height: 525px;
}

.stepgroup-modal-dropdown {
  .MuiSelect-select {
    padding: 0;
    padding-left: 2px;
    font-size: 12px;
  }
}

.input-filed-label-style-common-withError {
  @extend .fontPoppinsRegularMd;
  color: $red_darkest;
  text-align: left;
  letter-spacing: 0px;
}

.program-element-details-size {
  width: 960px;
  height: calc(100% - 50px);
}

.element-details-page {
  height: 852px !important;
  width: 1776px !important;
  overflow-y: auto;
  background-color: #fff !important;
}

.loactors-table {
  height: 335px;
}

.program-element-label {
  color: var(--primary-1648C6);
  text-align: left;
  height: 22px;
  text-align: left;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  padding-left: 10px;
}

.console-container {
  height: 140px;
  width: 100%;
  overflow: hidden;
}

.locatorsarray-size {
  width: 100%;
  height: 330px;
}

.locators-header {
  background-color: #f0f3f9;
}

.details-modal {
  width: 640px;
  height: 548px;
}

.element-details-modal-size {
  width: 960px;
  height: calc(100% - 137px);
}

.table-data:hover {
  background-color: #f0f3f9;
}

.helper-text {
  @extend .fontPoppinsRegularXs;
  color: $blue_dark;
}

.option-size {
  font-size: 2px;
}

.dropdown-project {
  position: absolute;
  right: 0px;
  top: 18px;
}

.action-element {
  display: none;
}

tr:hover .action-element {
  display: inline;
}

.locatorssyntax {
  @extend .fontPoppinsRegularXs;
  color: #727171;
  text-align: left;
}

.file-details-modal {
  width: 640px;
  height: 483px;
}

.userDetail-Style {
  height: 1300px;
}

.dropDownDynamic {
  position: relative;
  z-index: 1000;
}

.locatorHeader {
  @extend .fontPoppinsMediumLg;
  line-height: 25px;
  margin-left: 20px;
}

.divider {
  border-top: 2px dotted #ccf1fe;
  height: 1px;
}

.update-warning-modal {
  width: 640px;
  height: 254px;
}

.add-locator {
  @extend .fontPoppinsRegularMd;
  color: $blue_dark;
}

.searchIconRepo {
  color: #727171;
}

.programcount {
  font-size: 10px;
  margin-left: 215px;
}

.programcountWithError {
  font-size: 10px;
  margin-left: 215px;
  margin-top: -13px;
}

.elementDesc {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-trim {
  white-space: nowrap;
  max-width: 221px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.program-element-title-trim {
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-trimmer {
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-name-trimmer {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.path-trimmer {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trim-locator-value {
  max-width: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider-program-editor {
  border-top: 2px dotted #ccf1fe;
  height: 1px;
}

.dropDown-stepgroup {
  width: 241px;
  margin-top: 10px;
}

.project-type {
  @extend .fontPoppinsRegularMd;
  display: inline;
  color: $blue_dark;
}

.parameter-modal {
  width: 640px;
  height: 264px;
}

.parameter-details-modal {
  width: 639px;
  height: auto;
}

.step_group_variable_modal {
  width: 640px;
  height: 280px;
}

.locator-table-height {
  height: 238px;
}

.jar-table-height {
  height: 210px;
}

.import-jar-modal {
  height: 727px;
  width: 960px;
}

.UploadJarBox {
  height: 110px;
  width: 895px;
  border: dotted 1px black;
}

.UploadJarBoxHeader {
  font-size: 12px;
  font-family: OpenSans-Regular;
  color: #525252;
  margin-bottom: 8px;
}

.draganddrop {
  font-size: 12px;
  font-family: OpenSans-SemiBold;
  color: #525252;
  text-align: center;
  padding-top: 35px;
}

.browseText {
  color: #1648c6;
}

.jar-header {
  background-color: #f6f7f9;
}

.input-label-for-select {
  @extend .fontPoppinsRegularMd;
  color: $blue_dark;
  text-align: left;
  letter-spacing: 0px;
}

._input-label-for-select {
  @extend .fontPoppinsRegularMd;
  color: $grey_dark;
  text-align: left;
  letter-spacing: 0px;
}

.dynamic-Value {
  @extend .fontPoppinsRegularMd;
  color: $grey_dark;
  margin-top: 5px;
  padding-left: 10px;
}

.dynamic-Value:hover {
  color: $blue_dark;
  background-color: $skyblue_dark;
  cursor: pointer;
}

.link-color {
  color: #436bd1;
}

.warming-text {
  margin-left: -281px;
}

.toastr-msg-style {
  margin-top: -18px;
  padding-bottom: 18px;
}

.step-child-margin-style {
  margin-bottom: -4px;
}

.dynamicVal-suggestion {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 10;
}
.lengthyChar {
  display: inline-block;
  white-space: nowrap;
  width: 17em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_modal_size {
  min-width: 640px;
  height: 56vh !important;
}

#type {
  padding-bottom: 4px;
}
.no-locators-text {
  @extend .fontPoppinsMediumLg;
  margin-top: 10%;
  text-align: center;
}

.alert_mod_style {
  z-index: 1400;
}

.alert-position-style-element-modal {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
}
