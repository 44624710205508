@import '@src/css_config/variable.scss';

.settings-container {
  height: 350px;
  overflow: auto;
  padding: 0 10px 10px 10px;
  position: relative;
  margin-top: 8px;
}

.settings-content-data {
  min-height: 65px;
  padding-left: 16px;
  border-bottom: 1px solid $ff-border;

  .setting-font {
    height: 20px;
    padding: 8px 0;
    color: $ff-text;
  }

  .setting-title-desc {
    align-self: center;
  }
}

.toggle-button {
  display: flex;
  align-self: center;

  .settings-toggle-button {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
}

.settings-protocols {
  display: flex;
  margin-top: 8px;
}

.mouse-over {
  background-color: $skyblue_dark;
  box-shadow: none;
}

.setting-input-container {
  margin-right: 8px;
  .setting-input {
    width: 150px;
    color: $ff-text;
  }
}

.setting-select {
  width: 70px;
  height: 35px;
  margin-right: 8px;
  background-color: $white_original;
  color: $ff-text;
}

.version-text {
  color: $ff-text;
}