@import '@styles/rs_fonts.scss';

.notification {
  display: flex;
  // grid-template-columns: 30px auto 100px;
  padding: 4px 10px 4px 16px;
  border-radius: 0px;
  align-items: center;
  // margin-bottom: 12px;
  // width: 100% !important;
  // height: 38px;
  text-align: left !important;
  font-size: inherit !important;
  // margin-left: -20px;
  background-color: #fbfcfd;
  // margin-left: 30%;
  // margin-top: -32px;
  // right: 1%;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
  // width: fit-content;
  // margin-bottom:-38px;
  min-height: 38px;
  max-height: fit-content;
  // top: 50px;
  // position: absolute;
  animation-name: examples;
  animation-duration: 0.5s;
  z-index: 10000;
  // max-width: 40%;
  width: auto;
  margin: 0 auto;
}

@keyframes examples {
  // from  { right:1%; }
  // 25%  { left:30%; top:0px;}
  // to  { right:1%; }
  // 75%  { left:30%; top:50px;}
  // 100% { right: 0%;}
  0% {
    right: 0px;
  }
}
.notification .btn {
  padding: 6px 10px;
}
.notification .btn:hover {
  color: #b4031b;
  opacity: 100;
}
.notification {
  .message {
    @extend .fontPoppinsRegularMd;
    color: var(--lm-hover-0a0a0a);
    text-align: left;
    letter-spacing: 0px;
    color: #0a0a0a;
    opacity: 1;
    max-width: 804px;
    overflow-wrap: break-word;
    overflow: auto;
  }
}

.msg-type {
  @extend .fontPoppinsSemiboldLg;
  margin-right: 25px;
  color: var(--lm-hover-0a0a0a);
  text-align: left;
  letter-spacing: 0px;
  color: #0a0a0a;
  opacity: 1;
  margin-left: 25px;
}
.notification.success {
  background-color: #fbfcfd;
  border-left: 4px solid #79b62f;
  .icon {
    color: #79b62f;
  }
}

.notification.danger {
  background-color: #fbfcfd;
  border-left: 4px solid #c50303;
  .icon {
    color: #c50303;
  }
}
.notification.alertbanner {
  background-color: #fbfcfd;
  border-left: 4px solid #c50303;
  .icon {
    color: #c50303;
  }
}

.notification.warning {
  background-color: #fbfcfd;
  border-left: 4px solid #ff9800;
  .icon {
    color: #ff9800;
  }
}

.notification.info {
  background-color: #fbfcfd;
  border-left: 4px solid #2196f3;
  .icon {
    color: #2196f3;
  }
}

.notification .dismiss {
  text-align: right;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1px;

  font-size: 13px;
  text-transform: capitalize;
  padding: 4px 0;
  .btn {
    background: #eeeded;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    width: 84px;
    font-weight: 600;
    margin-left: 0;
    margin-top: 0;
    color: #0a0a0a !important;
    font-size: 14px;
  }
  .btn:hover {
    background-color: #aca3a3;
  }
}

svg.MuiSvgIcon-root.close-icon-style-alert {
  font-size: 16px;
  margin-top: 0;
}
