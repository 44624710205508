@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.excel-main-sheet {
  font-family: 'Poppins';

  .storybook-icon {
    flex-direction: row-reverse;
  }

  .excel-sheet {
    height: calc(100vh - 190px);

    &::-webkit-scrollbar {
      height: 8px;
    }
  }
}

.non-editable {
  pointer-events: none;

  .excel-sheet {
    pointer-events: auto;
  }

  .excel-tab {
    pointer-events: auto;
  }
}

.context-menu {
  position: absolute;
  z-index: 1;
  outline: none;
  background: $light_white no-repeat padding-box;
  box-shadow: 0px 0px 6px $ff-dropdown-shadow;
  border-radius: 5px;
  opacity: 1;
  padding: 16px 0;

  .menu-options {
    overflow: auto;
    min-height: 20px;
    max-height: 175px;
    min-width: 200px;
    opacity: 1;

    .options {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 8px;
      line-height: 35px;
      gap: 16px;

      .sheet-context-menu {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: $rs_primary;
        color: $ff-white;

        .options-label {
          color: $ff-white;
        }
      }
    }
  }
}

.excel-tab-container {
  background-color: $white-grey;
  color: $primary;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 4px;
  margin-bottom: 6px;
  height: 40px;

  .excel-tab {
    display: flex;
    overflow-x: scroll;
    padding: 4px;
    overflow-y: hidden;
    align-items: center;

    &::-webkit-scrollbar {
      height: 4px;
    }

    .excel-tab-list {
      padding: 4px 12px;
      margin: 4px;
      min-width: max-content;
      cursor: pointer;
      display: flex;
      font-weight: bold;
      text-decoration: none;

      &.active {
        font-weight: bolder;
        border-bottom: 2px solid $primary;
        margin-bottom: -1px;
      }

      &:focus {
        outline: none;
        text-decoration: none;
      }
    }

    .add-sheet-icon {
      position: sticky;
      right: 0;
      margin-left: 10px;
      z-index: 1;
    }
  }
}

.menu-list {
  display: flex;
  align-items: center;

  .menu-title {
    padding-left: 5px;
  }
}
