@import '@styles/_variables.scss';
@import '@src/styles/rs_fonts.scss';

.table-height {
  flex: 1 1;
  flex-basis: 0;
  z-index: 0;
}

.table-header {
  @extend .fontPoppinsSemiboldSm;
  position: sticky;
  top: 0px;
  z-index: 4;
  background-color: $ff-white;
  border-bottom: 1px solid $ff-border;
  color: $primary;
  tr {
    height: 35px;
    &:hover {
      background-color: $ff-white !important;
    }
  }
}

.table-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  color: $ff-text;
  background-color: $ff-white;
}

.table-row {
  @extend .fontPoppinsRegularSm;
  color: $ff-text;
  height: 35px;
  &:hover {
    background-color: $white-grey !important;
  }
}
