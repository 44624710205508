@import '@src/css_config/variable.scss';
.overflowxy-platform {
  overflow-y: auto;
  overflow-x: hidden;
}
.project-platform {
  max-height: 450px;
  @extend .overflowxy-platform;
}
.project-platform-edit {
  max-height: 450px;
  @extend .overflowxy-platform;
}
.disabled-line {
  width: 258px;
  background-color: $grey_light_white;
  margin-left: 325px;
  height: 1.3px;
  margin-top: -5px;
}
.project-details {
  max-height: 350px;
  width: 935px;
}
