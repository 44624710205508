@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.content_area_flow {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.toggle {
  transform: rotate(90deg);
}
.queryParamEnable {
  display: block;
  overflow: auto;
}
.activeQuery {
  border-bottom: 2px solid $blue_dark;
  color: $blue_dark;
  font-weight: 600;
}
.responseArrow {
  margin-left: 37%;
}
.requestArrow {
  margin-left: 20%;
}
.setIsArrowPositionClose {
  transform: rotate(270deg);
}

.responseBodyInternalServerError {
  margin-left: 27% !important;
  color: $red_dark !important;
}
.responseBodyInternal500ServerError {
  margin-left: 7% !important;
  color: $red_dark !important;
}
.saveAsStepButton {
  border: 1px solid $grey;
  border-radius: 2px;
  width: 110px;
  height: 34px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: $grey_dark;
  margin-left: 10px;
  &:hover {
    border-color: $blue_dark;
    color: $blue_dark;
  }
}
.restapiHeaderResponseSuccess {
  text-transform: capitalize;
  margin-left: 12px;
  color: $white_light;
  background: $green 0% 0% no-repeat padding-box;
  padding: 4px 18px;
  letter-spacing: 0px;
}
.restapiHeaderResponseFailure {
  text-transform: capitalize;
  margin-left: 12px;
  color: $white_light;
  border-radius: 30px;
  background: $red_darkest 0% 0% no-repeat padding-box;
  padding: 4px 18px;
  letter-spacing: 0px;
}
.restapiHeaderResponseSkipped {
  text-transform: capitalize;
  margin-left: 12px;
  color: $white_light;
  border-radius: 30px;
  background: $grey_dark 0% 0% no-repeat padding-box;
  padding: 4px 18px;
  letter-spacing: 0px;
}
.restApiMySelect {
  margin-top: -17.5px;
  margin-top: -8px;
}
.restApiInputLabelMargin {
  margin-top: -7px !important;
  margin-top: -7px !important;
}
.restApiTabLinksContainerElement {
  height: 400px;
  opacity: 1;
}
.restApiResponsePipe {
  margin-left: 10px;
  margin-top: -2px;
}
.responseArrowSign {
  margin-top: -2px;
}
.restApiText {
  margin-right: 20px;
  span {
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
  }
}
.send-btn {
  // margin-left: 5px;
  margin-top: 22px;
  // margin-right: 3px;
}
.send-btn-disable {
  @extend .send-btn;
  opacity: 0.5;
}
.request-name-textfield {
  margin-right: 2px;
  display: flex;
  align-items: flex-start;
  text-align: left;
}
.request-name-textfield .input {
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-right: 3px;
  background: $white_original 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 8px 9px;
  line-height: 20px;
  height: 35px;
  @extend .fontPoppinsRegularSm;
  &:focus {
    padding: 8px 9px;
    @extend .fontPoppinsRegularSm;
    border: 1px solid $light_gray_50;
  }
  &.custom-margin {
    width: 75%;
  }
  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}
.prerequisite-main-container .request-name-textfield .input.custom-margin {
  width: 85%;
}

.request-type-drop-down {
  margin-right: 2px;
  select {
    border: none;
    padding: 6.5px 16px;
    line-height: 20px;
    box-shadow: 0px 0px 6px $shadow_black;
    opacity: 1;
    letter-spacing: 0px;
    color: $grey_darkest;
    text-transform: uppercase;
    width: 120px;
    &:focus {
      padding: 6.5px 16px;
      border: none;
    }
  }
}
.border-top-1 {
  border-top-width: 1px;
}

.request-option-container {
  display: flex;
  width: 100%;
  border: 1px;
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
}
.response-option-container {
  display: flex;
  width: 100%;
  border: 1px;
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  justify-content: flex-end;
}
.request-option-list {
  width: 50%;
}
.response-option-list {
  width: 60%;
}
.request-option-collapse {
  margin-left: 158px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent 0% 0% no-repeat padding-box;
  position: absolute;
  right: 0px;
  top: 6px;
  img {
    opacity: 1;
    padding: 2px 4px;
  }
}
.response-option-collapse {
  width: 43%;

  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent 0% 0% no-repeat padding-box;
  img {
    opacity: 1;
    padding: 0px;
    margin: 5px;
    width: 24px;
    height: 24px;
  }
}
.request-expand-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $rs_primary 0% 0% no-repeat padding-box;
  border: 1px solid lightgray;
  opacity: 1;
  label {
    text-align: left;
    letter-spacing: 0px;
    color: $white_original;
    opacity: 1;
  }
}
.response-expand-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $rs_primary 0% 0% no-repeat padding-box;
  border: 1px solid lightgray;
  opacity: 1;
  label {
    text-align: left;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
  }
}
.response-tabs-container-list {
  border: 1px solid lightgray;
}
.request-option-expand {
  img {
    padding: 5px 5px;
    width: 38px;
    height: 38px;
    opacity: 1;
  }
}
.response-option-expand {
  img {
    padding: 0px;
    margin: 5px;
    width: 24px;
    height: 24px;
    opacity: 1;
  }
}
.failure-border {
  border-bottom: 2px solid $red_darkest;
}
.success-border {
  border-bottom: 2px solid $green;
}
.skipped-border {
  border-bottom: 2px solid $grey_dark;
}
.responseStatusLabel,
.responseTimeLabel {
  @extend .fontMontserratMediumXs;
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  margin-right: 10px;
  border: 2px solid $rs_primary;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: 500;
  height: 25px;
  display: flex;
  align-items: center;
}
.responseStatus {
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
  margin-left: 5px;
}
.responseTime {
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
  margin-left: 5px;
}
.vl {
  border-width: 0.5px;
  border-color: $white;
  margin-right: 30px;
  height: 40px;
}
.enable,
.show-request-tab {
  display: block;
}
.show-request-tab {
  border: 1px solid $grey_light;
}
.disable,
.hide-request-tab {
  display: none;
}
.rest-api-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.variable-dropdown {
  height: 240px;
  overflow: hidden;
  background: $light_white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  z-index: 99;
  position: absolute;
  text-align: left;
  width: 300px;
  border-radius: 7px;
}
.variable-inner-container{
  box-sizing: border-box; 
  height: calc(100% - 32px); 
  margin-top: 16px; 
  overflow-y: auto;
}
.variable-dropdown-extra-small {
  @extend .variable-dropdown;
  width: 160px !important;
}
.variable-dropdown-form-data {
  @extend .variable-dropdown;
  width: 24%;
}
.variable-dropdown-url-encoded {
  @extend .variable-dropdown;
  width: 300px;
}
.variable-dropdown-small {
  @extend .variable-dropdown;
  height: 200px;
  width: 185px;
}
.variable-dropdown-medium {
  @extend .variable-dropdown;
  height: 200px;
  width: 250px;
}
.assert-variable-dropdown {
  @extend .variable-dropdown;
  height: 200px;
  max-width: 200px;
}
.variable-dropdown-editor {
  @extend .variable-dropdown;
  top: 0px;
  width: 230px;
  height: 170px;
}
.variable-dropdown-bearer-token {
  @extend .variable-dropdown;
  width: 277px;
}

.variable-dropdown-jwt-bearer {
  @extend .variable-dropdown;
  width: 198px;
}

.variable-dropdown-api-key {
  @extend .variable-dropdown;
  width: 251px;
}

.default-assert-field-header-key > .variable-dropdown {
  height: 235px;
  overflow: auto;
  background: $light_white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  z-index: 5;
  position: absolute;
  text-align: left;
  width: 234px;
  overflow-x: hidden;
  border-radius: 7px;
  top: 35px;
}

.default-assert-header-key > .variable-dropdown {
  top: -20px;
}

.default-assert-field-json-path > .variable-dropdown {
  height: 235px;
  overflow: auto;
  background: $light_white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  z-index: 5;
  position: absolute;
  text-align: left;
  width: 183px;
  overflow-x: hidden;
  border-radius: 7px;
  top: 35px;
}
.request-name-textfield .input-text-error {
  border: 1px solid $rs_analytics_fail;
  width: 100%;
  padding: 4px 4px 4px 10px;
  &:focus {
    padding: 4px 4px 4px 10px;
  }
  &:before {
    content: '*';
  }
}

.request-name-textfield .input-text-error::-webkit-input-placeholder {
  color: $red_darkest;
  position: relative;
  font-size: 10px;
}
.input {
  width: 100%;
}
.proj-count-response-header,
.proj-count-assert-results {
  letter-spacing: 0px;
  color: $blue_dark;
  opacity: 1;
  background: var(--lightCyan) 0% 0% no-repeat padding-box;
  background: $white_dark 0% 0% no-repeat padding-box;
  border-radius: 30px;
  margin-right: 12px;
  margin-top: 3px;
  @extend .fontPoppinsSemiboldMd;
  &:hover {
    color: $transparent;
    cursor: pointer;
  }
  &:hover::before {
    font-size: 12px;
    content: attr(data-hover);
    color: $blue_dark;
  }
}
.btn-hover:hover {
  background: $grey_medium;
  color: $white_light;
}
.save-btn-hover:hover {
  background: $blue_dark;
  color: $white_light;
}
.cross-icon {
  width: 15px;
  height: 15px;
}
.svg-rocket-height {
  width: 10rem;
  height: 12rem;
}
.request_token_btn {
  padding: 6px 12px;
  border: 1px solid $grey;
  border-radius: 5px;
  color: $grey_dark;
  width: 111px;
  height: 29px;
  margin: 0 -5px -5px 0;
  letter-spacing: 0px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-color: $blue_dark;
    color: $blue_dark;
  }
}
.request_token_btn label {
  @extend .fontMontserratMediumXs;
  font-weight: 500;
}
.saveAsStepButtonDisable {
  border: 1px solid $grey_light;
  border-radius: 2px;
  width: 110px;
  height: 34px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: $grey_light;
  margin-left: 10px;
}
.oauth2-container {
  width: 99%;
}
.oauth1-consumer-field {
  width: 240px;
}
.oauth1-info {
  display: inline;
  margin-left: 8px;
  height: 15px;
  width: 15px;
}
.pre-enabled-btn {
  color: $rs_analytics_blue_header;
  &:hover {
    color: $white_original;
    background: $rs_analytics_blue_header 0% 0% no-repeat padding-box;
    border: 2px solid $rs_analytics_blue_header;
  }
}
.max-height-280 {
  max-height: 280px;
}

.custom-tabs {
  display: flex;
  flex-direction: column;
}

.tab-container {
  display: flex;
  position: relative;
  border-bottom: 1px solid $gray_light;
  left: 10px;
  margin-right: 25px;
  height: 29px;
  top: 4px;
}

.tab {
  @extend .fontPoppinsRegularSm;
  padding: 6px 12px;
  cursor: pointer;
  color: $rs_primary;
  border-bottom: 2px solid transparent;
  overflow: hidden;
}
.tab-request {
  @extend .fontPoppinsRegularSm;
  padding: 6px 12px;
  cursor: pointer;
  color: $rs_primary;
  border-bottom: 2px solid transparent;
}
.tab.active {
  color: $rs_primary;
  border-bottom: 2px solid $rs_primary;
}
.tab-request.active {
  color: $rs_primary;
  border-bottom: 2px solid $rs_primary;
  margin-bottom: -1px;
}

.indicator {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: left 0.3s ease-in-out;
}

.tab-contents {
  text-align: center;
}

.tab-content {
  display: block;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.tab-content.active {
  display: block;
}
.tab-content-in-prerequisite {
  background-color: $rs_bg_whitgrey;
  border-top: 5px solid $white_original;
  border-left: 15px solid $white_original;
  border-right: 10px solid $white_original;
  border-bottom: 10px solid $white_original;
}
.custom-left-container {
  width: 26px;
  border-radius: 12px 0px 0px 12px;
  opacity: 1;
  background: $light_blue_50 0% 0% no-repeat padding-box;
}
.custom-right-container {
  @extend .fontPoppinsRegularXs;
  border-left: none;
  border-radius: 0 8px 8px 0;
  width: 98%;
  padding: 16px 10px 0 16px;
  color: $grey_darkest;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: $scroll_bar_grey;
  }
}
.customContentContainer {
  white-space: pre-wrap;
  word-break: break-word;
  margin: 14px 10px 0 16px;
  overflow: hidden;
  display: flex;
  opacity: 1;
  border: 2px dashed $border_gray_200;
  border-radius: 12px;
  border-left: none;
}
.curl-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray_40;
}
.up-arrow-icon {
  padding: 3px;
  &:hover {
    background-color: $white_original;
    border-radius: 50%;
    box-shadow: 4px 20px 23px 0px $deep_black;
  }
}
.response-expand-div-height {
  height: 40px;
}
.width-of-response-tab-container {
  width: 55%;
}

.hover-effect-response-header {
  &:hover {
    background: $light_gray_100;
  }
}
.height-of-response-section {
  height: 310px;
}
.selected-method svg {
  height: fit-content;
}
.add-variable-btn {
  position: absolute;
  right: 54px;
  top: 4px;
}
.Local-view-variable {
  height: 148px;
}
.view-variable-modal {
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 0;
  background: $white_original 0% 0% no-repeat padding-box;
  padding: 0;
  opacity: 1;
}
.variable-option-collapse {
  margin-right: 6px;
  height: 26px;
  opacity: 1;
}
.variable-option-expand {
  height: 26px;
  width: 26px;
  opacity: 1;
  padding: 6px;
  margin-right: 6px;
}
input[type='text'].view-variable-input,
input[type='password'].view-variable-input {
  width: 95%;
  border: 1px solid transparent;
  background: transparent;
  line-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  @extend .fontPoppinsMediumSm;
  &:focus {
    padding: 0 0 0 12px;
  }
}
input[type='text'].view-variable-input-value,
input[type='password'].view-variable-input-value {
  width: 100%;
  border: 1px solid transparent;
  background: transparent;
  line-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  &:focus {
    padding: 0 0 0 12px;
  }
}
.variable-table-modal tr .modal-edit-icon {
  visibility: hidden;
}
.variable-table-modal tr:hover .modal-edit-icon {
  visibility: visible;
}
input[type='text'].editable,
input[type='password'].editable {
  border: 1px solid $Lightgray;
  background: $white_original;
  border-radius: 3px;
  height: 35px;
  width: 207px;
  &:focus {
    padding: 0 0 0 12px;
  }
}
.modal-edit-icon,
.variable-action-icons {
  &:hover {
    color: $primary;
    background-color: $white_original;
    border-radius: 50%;
    box-shadow: 4px 20px 23px 0px $deep_black;
  }
}

.response-option-container-height {
  height: 35px;
}
.response-tab-container-height {
  height: 29px;
  top: 4px;
  right: 7px;
}
.accrodion-body {
  overflow: auto;
  height: 226px;
  margin-top: 16px;
}

.accrodion-body::-webkit-scrollbar,
.variable-body::-webkit-scrollbar {
  width: 4px;
}

.accrodion-body::-webkit-scrollbar-track,
.variable-body::-webkit-scrollbar-track {
  background-color: $rs_light_gray;
}

.accrodion-body::-webkit-scrollbar-thumb,
.variable-body::-webkit-scrollbar-thumb {
  background-color: $rs_light_gray;
  border-radius: 6px;
}

.variable-table-key {
  width: 175px;
}
.variable-table-value {
  width: 250px;
}
.variable-table-modal tr .modal-edit-disable {
  visibility: hidden;
}
.variable-table-modal tr:hover .modal-edit-disable {
  visibility: visible;
}
.modal-edit-disable {
  color: $light_grey_white;
}
.accordion-table-name {
  width: 250px;
}
.accordion-table-value {
  width: 250px;
}
.variable-body {
  overflow: auto;
  height: 555px;
}
.request-section-errors {
  @extend .fontPoppinsRegularXs;
  color: $rs_analytics_fail;
  position: absolute;
  margin-left: 2px;
  margin-top: -2px;
}
.rest-api-select .css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 16px;
  padding-bottom: 16px;
}
.assert-headers {
  @extend .fontPoppinsRegularSm;
  padding: 14px 12px;
  cursor: pointer;
  color: $rs_primary;
  border-bottom: 2px solid transparent;
  overflow: hidden;
}
.request-dropdown .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 12px !important;
}
.assert-headers.active {
  color: $rs_primary;
  border-bottom: 2px solid $rs_primary;
}
.response-header-tabs {
  @extend .fontPoppinsRegularSm;
  padding: 6px 12px;
  cursor: pointer;
  color: $rs_primary;
  border-bottom: 2px solid transparent;
  overflow: hidden;
}
.response-header-tabs.active {
  color: $rs_primary;
  border-bottom: 2px solid $rs_primary;
}
.request-dropdown #mui-component-select-bodyType {
  padding: 6px 12px;
  text-align: left;
}
.request-dropdown svg {
  right: 6px;
}
.response-hovered-buttons {
  background-color: $grey;
  padding: 6px 12px;
}
.response-active-button {
  background-color: $rs_primary;
}
.Local-view-variable tr:hover {
  background-color: initial !important;
}
.variable-check-disable {
  color: $light_grey_white;
}
.variable-hover-effect {
  &:hover {
    color: $primary;
    background-color: $skyblue_dark;
    border-radius: 50%;
    box-shadow: 4px 20px 23px 0px $primary;
  }
}

.viewVariablecheckbox {
  border: 1px solid $shawdow_grey;
  border-radius: 3px;
  outline: none;
  padding: 0px;
  margin-top: 11px;
  margin-bottom: 10px;
  color: $ff-primary;
  margin-top: 4px;
  padding-left: 5px;
  background-color: $transparent;
  &.MuiButtonBase-root.Mui-disabled {
    color: $ff-primary;
    opacity: 0.5;
  }
}
.view-variable-checked {
  color: $rs_primary;
}
.viewVariablecheckbox[type='checkbox']:focus {
  --tw-ring-color: none;
}
.local-file-warning {
  @extend .fontPoppinsRegularXs;
  color: $rs_analytics_fail;
  text-align: center;
}
.curl-container-height {
  height: 512px;
}

// Upgraded below Dropdown CSS

.http-methods-dropdown,
.create-variable-dropdown,
.save-as-step-dropdown,
.content-type-dropdown {
  margin-top: 7px;
  padding: 8px 0;
  &::-webkit-scrollbar-thumb {
    background-color: $rs_light_gray;
  }
}
.http-methods-dropdown ul li:hover,
.http-methods-dropdown ul li.Mui-selected,
.create-variable-dropdown ul li:hover,
.create-variable-dropdown ul li.Mui-selected,
.save-as-step-dropdown ul li:hover,
.save-as-step-dropdown ul li.Mui-selected,
.content-type-dropdown ul li:hover,
.content-type-dropdown ul li.Mui-selected {
  background: $rs_primary 0% 0% no-repeat padding-box !important;
  color: $white_original;
}
.assert-lhs-variable-dropdown {
  @extend .assert-variable-dropdown;
  margin-top: 35px;
  width: 165px;
  margin-left: 5px;
}
.cdfr-lhs-variable-dropdown {
  @extend .assert-variable-dropdown;
  margin-top: 56px;
}
.import-curl-text {
  @extend .fontPoppinsRegularSm;
  color: $grey_darkest;
  margin: 8px 0px 4px 14px;
}
.ace-github.ace_focus .ace_marker-layer .ace_active-line,
.ace-github .ace_marker-layer .ace_active-line {
  background: $ff-hover-bg;
}
.ace-github .ace_gutter {
  background: $border_gray_200;
}
