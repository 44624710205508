@import '@styles/variables';

.card-wrapper {
  border: 1px solid $gray;
  border-radius: 10px;
  box-shadow: 0px 0px 6px $border-muted;
  width: 100%;
  .card-header {
    color: $primary;
    background-color: $sky-blue;
    padding: 8px 16px;
    border-bottom: 1px solid $gray;
    border-radius: 10px 10px 0px 0px;
  }

  .table-container {
    border-radius: 0px 0px 10px 10px;
    max-height: 302px;
    background-color: $white;
    font-size: 14px;
    overflow-y: scroll;
    position: relative;
    .table-head {
      border-bottom: 1px solid $sky-blue-dark;
      position: sticky;
      top: 0;
      z-index: 1;
      .table-head-cell {
        color: $primary;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
          padding-left: 16px;
        }

        &:last-of-type {
          padding-right: 16px;
          text-align: center;
        }
      }
    }

    .table-body {
      :first-child {
        .table-body-cell {
          padding-top: 10px;
        }
      }

      :nth-child(n + 2) {
        .table-body-cell {
          padding-top: 0px;
        }
      }

      .table-body-cell {
        border: 0;
        padding-bottom: 10px;
        background-color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
          padding-left: 16px;
        }

        &:last-of-type {
          text-align: center;
        }
      }
    }
    .cost-per-quantity-text {
      color: $dark;
      margin-left: 4px;
    }
  }

  .purchase-summary {
    .table-head {
      .table-row {
        .table-head-cell {
          flex: 0 0 25%;
          width: 25%;

          &:first-of-type {
            flex: 0 0 33%;
            width: 33%;
          }

          &:nth-of-type(3) {
            flex: 0 0 17%;
            width: 17%;
          }

          &:nth-of-type(4) {
            flex: 0 0 25%;
            width: 25%;
          }
        }
      }
    }
    .table-body {
      .table-row {
        .table-body-cell {
          flex: 0 0 25%;
          width: 25%;
          color: $dark;
          &:first-of-type {
            flex: 0 0 35%;
            width: 35%;
          }

          &:nth-of-type(3) {
            flex: 0 0 15%;
            width: 15%;
          }

          &:nth-of-type(4) {
            flex: 0 0 25%;
            width: 25%;
          }
        }
      }
    }
  }
}

.w-1\.5 {
  width: calc(1.5 * 100% / 5);
}
.total-amount {
  width: 100%;
  height: 35px;
  background-color: $sky-blue;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  border-radius: 0 0 10px 10px;
}
