@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';

@keyframes slide-from-bottom {
  0% {
    margin-top: 10%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes placeholder-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 100%;
  }
}

.wrapper {
  background-color: $white_original;
  width: 100%;
  padding: 17px 0px;
  margin: 12px 0px;
  color: $davy-gray;
  animation: slide-from-bottom 0.5s ease-in;
  border-radius: 10px;
  border: 1px solid $license_card_border;
  height: 66px;

  &.selected {
    background-color: $license_row_card_bg;
    box-shadow: 0 0 6px $rs_black;
  }

  .shield-icon {
    width: 38.55px;
    height: 38.55px;
  }

  &.placeholder {
    .shield-icon,
    .title {
      color: $black-110;
      animation: placeholder-animation 1.5s ease-in-out 0.5s infinite;
    }
  }

  .success {
    color: $active_license;
    fill: $active_license;
  }
}

.warning {
  color: $license_about_to_expire;
  fill: $license_about_to_expire;
}

.danger {
  color: $red;
  fill: $red;
}

.title {
  color: $dark;
  margin-bottom: 4px;
  width: max-content;
}

.value {
  color: $rs_primary;
}

.col-0\.5 {
  flex: 0 0 calc(0.5 * 100% / 12);
  width: calc(0.5 * 100% / 12);
}

.col-2 {
  flex: 0 0 calc(2 * 100% / 12);
  width: calc(2 * 100% / 12);
}

.col-11\.5 {
  flex: 0 0 calc(11.5 * 100% / 12);
  width: calc(11.5 * 100% / 12);
}

.plan-button {
  border: 1px solid $davy-gray;
  border-radius: 2px;
  opacity: 0.8;
  padding: 4px;
  flex-shrink: 0;
  margin-left: 8px;
}

.edit-button {
  color: $primary;

  &:disabled {
    color: $gray;
  }
}

.radio-control-wrapper {
  border-color: $gray;
  .radio-control {
    cursor: pointer;
    font-weight: 500;
    min-width: 17px;
    min-height: 17px;
    position: relative;
    display: flex;
    align-items: center;

    input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;

      &:checked ~ .control-indicator {
        box-shadow:
          inset 0 0 0 3px $white,
          inset 0 0 0 10px $primary,
          0 0 0 2px $primary;
        height: 14px;
        width: 14px;
      }
    }
    .control-indicator {
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto 0;
      height: 17px;
      width: 17px;
      background: transparent;
      box-shadow: inset 0 0 0 2px $rs_primary;
      transition: all 0.35s ease;

      &.disable {
        cursor: no-drop;
        opacity: 0.3;
      }
    }
  }
}

.custom-tooltip {
  :global {
    .MuiTooltip-tooltip {
      background-color: $license_tooltip_bg;

      color: $white_original;
      box-shadow: 0px 0px 10px $border-muted;
    }
    .MuiTooltip-arrow {
      color: $license_tooltip_bg;
    }
  }

  &.success {
    :global {
      .MuiTooltip-tooltip {
        border: 1px solid $license_tooltip_bg;
        .MuiTooltip-arrow {
          &:before {
            border: 1px solid $license_tooltip_bg;
          }
        }
      }
    }
  }

  &.warning {
    :global {
      .MuiTooltip-tooltip {
        border: 1px solid $license_tooltip_bg;
        .MuiTooltip-arrow {
          &:before {
            border: 1px solid $license_tooltip_bg;
          }
        }
      }
    }
  }

  &.danger {
    :global {
      .MuiTooltip-tooltip {
        border: 1px solid $license_tooltip_bg;
        .MuiTooltip-arrow {
          &:before {
            border: 1px solid $license_tooltip_bg;
          }
        }
      }
    }
  }
}

.w-1\.5\/12 {
  width: calc(1.5 * 100% / 12);
}

.w-2\.5\/12 {
  width: calc(2.5 * 100% / 12);
}
.btnColor {
  background-color: $rs_primary;
}
.ToolTipRenderer {
  :global {
    .MuiTooltip-tooltip {
      background-color: $license_tooltip_bg;

      color: $white_original;
      box-shadow: 0px 0px 10px $rs_primary;
    }
    .MuiTooltip-arrow {
      color: $license_tooltip_bg;
    }
  }
}
.status_Success {
  color: $rs_analytics_pass;
}
.status_Warning {
  color: $rs_analytics_warning;
}
.status_Failed {
  color: $rs_analytics_fail;
}
