@import '@src/css_config/variable.scss';
@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';

.max-width-text {
  display: inline-block;
  width: 150px;
}

.project-create-header {
  border-top-width: 1px;
  width: 107%;
  margin-left: -18px;
}

.drag-file-error-text {
  @extend .fontPoppinsMediumSm;
  background: $error-bg;
  color: $ff-error;
  border-radius: 12px;
}

.drag-and-drop-bg {
  border: 1px dotted $ff-placeholder;
  border-radius: 12px;
  background: $ff-drag-drop-bg;
  color: $ff-text;
  outline: none;
  transition: background-color 0.2s ease;
}

@mixin icon-bg {
  border-radius: 5px;
  background-color: $ff-white;
  box-shadow: 0px 0px 6px 0px $ff-box-shadow-grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zip-icon-bg {
  @include icon-bg;
  height: 48px;
  width: 48px;
  position: absolute;
  top: 33%;
}

.ff-icon-bg {
  @include icon-bg;
  height: 76px;
  width: 76px;
  position: absolute;
  top: 25%;
  right: 0;
}
.icon-wrapper {
  margin-top: 6%;
  height: 145px;
  width: 207px;
  position: relative;
}

.arrow-icon-container {
  position: relative;
  top: 10px;
  left: 22%;
  z-index: 1;
}

.dropped-filename {
  @extend .fontPoppinsMediumSm;
  color: $ff-primary;
  font-weight: 500;
  line-height: 18px;
}

.drag-file-btn {
  @extend .fontMontserratMediumXs;
  color: $ff-primary;
  font-weight: 500;
  border: 1px solid $ff-primary;
  cursor: pointer;
  background: $white_original 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
  left: 12px;
  padding: 6px 12px;
  border-radius: 5px;
  height: 25px;
  display: flex;
  align-items: center;
}

.import-progress-bg {
  border: 1px solid $ff-placeholder;
  border-radius: 5px;
  background-color: $ff-drag-drop-bg;
}
