@import './variable.scss';
@import '@styles/rs_fonts.scss';

p.MuiTypography-root.radio-btn-style.MuiTypography-body1 {
  @extend .fontPoppinsRegularMd;
  color: $radio_btn_label_color;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}
