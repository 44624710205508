@import '@src/css_config/variable.scss';
.color-bar {
  width: 100%;
  height: 4px;
  border: 0px 1px 1px 0px solid $grey_darkest;
  .colorPicker {
    width: 100%;
    height: 0;
    padding: 0;
    visibility: hidden;
  }
}
