:root {
  --rs-gray: #727171;
  --rs-blue: #434db8;
  --rs-border-color: #e5ecfc;
  --rs-text-color: #3c3838;
  --header-background: #e4e6f4;
}

.category {
  margin-left: 20px;
}
.item {
  margin-left: 20px;
}
.filter-section {
  background-color: transparent;
}
.search-section {
  margin: 10px;
}

.search-btnBlock > input {
  background: var(--header-background);
  height: 40px;
  border-radius: 5px;
  padding: 5px 30px 5px 10px;
  width: 100%;
  border: none;
}

.search-btnBlock > input:focus {
  outline: 1px solid var(--rs-blue);
  outline-offset: 0px;
}

.time-period-input {
  background: var(--header-background);
  height: 40px;
}

.react-datepicker__input-container > input[type='text'] {
  border: none !important;
  border-radius: 5px 0 0 5px;
}

.filterData-trimmer {
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radioGroupButtons {
  margin-left: 15px;
}
.radioButtonLabel {
  color: var(--rs-gray);
}
.suiteLabel {
  color: var(--rs-text-color);
}
.executionID {
  color: var(--rs-gray);
}
.suiteCount {
  color: var(--rs-text-color);
}
.IDCount {
  font-size: 10px;
  color: var(--rs-gray);
}
.ChevronIcon {
  color: var(--rs-gray);
  height: 21px;
  width: 20px;
  margin-left: 15px;
}
.datepicker {
  border: 1px solid #989797;
  width: 85px;
  border-radius: 2px;
}
.FromTODate {
  font-size: 10px;
  color: var(--rs-gray);
}
div.react-datepicker-popper {
  z-index: 999 !important;
}
.timeperiodheader {
  background: var(--header-background);
  width: 100%;
}
.search-icon .MuiSvgIcon-root {
  font-size: 1rem;
}
.suite-filter {
  color: var(--rs-gray);
  overflow-y: auto;
  overflow-x: hidden;
}
.filtersHeader {
  display: inline-block;
  background: var(--header-background);
  width: 100%;
}

.Analytics_ChevronIcon {
  margin-top: 7px;
}

.radioGroupButtons .MuiButtonBase-root {
  padding: 9px;
}

.filterType {
  margin-left: -3px;
}

.filterType .MuiButtonBase-root {
  padding: 9px;
}

.item .MuiButtonBase-root {
  padding: 9px;
}

.filterType .css-i4bv87-MuiSvgIcon-root {
  height: 20px;
  width: 16px;
}

.filterType .css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  color: var(--rs-gray);
  white-space: nowrap;
  max-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item .css-i4bv87-MuiSvgIcon-root {
  height: 16px;
  width: 16px;
}
.item .css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  color: var(--rs-gray);
}
.filters_labels {
  height: 28px;
}
.filter_category {
  padding-bottom: 5px;
}
.filters_heading {
  border-radius: 0;
}

.category_accordian:hover {
  border-radius: 7px;
  background: var(--header-background);
}

.datePickerBlock .react-datepicker {
  font-family: 'Poppins-Regular';
}

.scrollable {
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar{
    display: none;
  }
}