@import '@styles/variables';

.card-wrapper {
  border: 1px solid $gray;
  border-radius: 10px;
  box-shadow: 0px 0px 6px $border-muted;

  .card-header {
    background-color: $sky-blue;
    padding: 14px 24px;
    border-bottom: 1px solid $gray;
    border-radius: 10px 10px 0px 0px;
  }

  :global(.MuiFormControl-root) {
    :global {
      box-shadow: none;
      border: none;
      border-radius: 0;

      .MuiInputBase-input {
        border-bottom: 2px solid $gray !important;
      }

      fieldset {
        box-shadow: none;
        border: 0;
        border-radius: 0;
        outline: 0;
      }

      .MuiInputBase-input {
        border-color: $gray;
        padding: 15px 0px 15px 15px;
        font-size: 14px;
        background-color: $sky-blue-medium;
        &::placeholder {
          color: $muted;
        }
      }

      .MuiFormHelperText-root {
        white-space: nowrap;
      }
    }
  }

  .card-icon-wrapper {
    color: $muted;
    background-color: transparent;
    padding: 13px;
    border-bottom: 2px solid $gray;
  }

  .card-number-input-wrapper {
    :global(.MuiFormControl-root) {
      :global {
        .MuiInputBase-adornedEnd {
          background-color: $sky-blue-medium;
          padding-right: 0;

          .MuiInputAdornment-root {
            margin-left: 0;
          }
        }
      }
    }
  }

  .expiry-input-wrapper {
    :global {
      .MuiOutlinedInput-root {
        border-right: 2px solid $gray;
        padding-right: 0;
        .MuiInputBase-input {
          border-radius: 0px 0px 0px 6px;
        }
      }

      .MuiInputBase-adornedEnd {
        background-color: $sky-blue-medium;
        padding-right: 0;

        .MuiInputAdornment-root {
          margin-left: 0;
          height: 100%;
        }
      }
    }
    .card-icon-wrapper {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &.error {
      .card-icon-wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  .cvv-input-wrapper {
    :global {
      .MuiOutlinedInput-root {
        padding-right: 0;
      }
      .MuiInputBase-adornedEnd {
        background-color: $sky-blue-medium;
        padding-right: 0;

        .MuiInputAdornment-root {
          margin-left: 0;
          height: 100%;
        }
      }
    }

    .card-icon-wrapper {
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 0px 0px 6px 0px;
    }

    &.error {
      .card-icon-wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  .error {
    .card-icon-wrapper {
      background-color: $danger-light;
    }
    :global {
      .MuiInputBase-input {
        background-color: $danger-light;
      }
    }
  }
}
