@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

@keyframes progress-animation {
  from {
    width: 0%;
  }
}

.progress-line-wrapper {
  background-color: $Lightwhite;
  border-radius: 5px;
  width: 100%;

  .filled {
    border-radius: 5px;
    animation: progress-animation 2s linear;
    .filled-value-circle {
      @extend .fontPoppinsMediumXs8px;
      background-color: $white_original;
      color: $grey_darkest;
      height: 20px;
      width: 20px;
      background: white;
      border: 2px solid $rs_primary;
      padding: 3px 0px 0px 1px;
      margin: 0px -10px -6px 0px;
      border-radius: 50%;
      text-align: center;
    }
  }
}
