/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */

:root {
  --bg-white: #ffffff;
  --bg-blue: #434db8;
  --bg-slate: #e0e3f6;
  --color-gray: #969696;
}

.console_output_container {
  border: 1px solid grey;
  height: calc(100% - 50px);
  overflow: auto;
}
.console-footer {
  margin-bottom: 24px;
}
.ace_gutter-layer,
.ace_print-margin-layer {
  overflow: hidden !important;
}

.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 80%;
}

.ace_scrollbar.ace_scrollbar-h {
  height: 7px !important;
  width: 60%;
}

.ace-tm .ace_gutter {
  background: #e2e2f7 !important;
}

/* Overall Ace Editor div */
.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}
.ace-console-wrp .project_label {
  padding-left: 0;
}
div#UNIQUE_ID {
  border: 1px solid grey;
  position: absolute;
  width: 80% !important;
  height: 248px !important;
  /* margin-left: 22px; */
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */
.editor__console {
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  border: 1px solid grey !important;
  box-sizing: content-box !important;
  color: red;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}
.grid-full-height {
  height: 100%;
}
.editorRole {
  flex: 1 1;
}
.ace_Gutter {
  background-color: var(--white_original);
  background-repeat: no-repeat;
  background-origin: padding-box;

  color: var(--color-gray);
}
#demo .ace_gutter {
  background-color: var(--bg-slate);
}
.ace_gutter-cell:last-child {
  background-color: var(--bg-blue);
  color: var(--bg-white);
  max-height: 20px;
}
.ace_editor {
  line-height: 20px;
  letter-spacing: 1px;
}
.ace-common-styles {
  font-size: 12px;
}
.ace_autocomplete {
  display: none;
}
.dropdown-wrapper {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.4s ease, max-height 1s ease-in-out;
}
.dropdown-wrapper.open {
  opacity: 1;
  max-height: 500px;
}