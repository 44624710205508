.mytable thead {
  /* background-color: #3c3838;#f0f3f9 */
  background-color: #f0f3f9;
}
.mytable tr {
  height: 50px;
}
.mytable tr:hover {
  background-color: #f0f3f9;
}
.mytable th,
.mytable td {
  text-align: left;
  padding: 0 16px;
}
.mytable td:last-child {
  opacity: 0;
}
.mytable tr:hover td:last-child {
  opacity: 1;
}
.mytable td input,
.mytable td select {
  border: none !important;
}
.mytable td input[type='checkbox'] {
  border: 1px solid gray !important;
}
.mytable td input:focus {
  border-bottom: 2px solid darkblue !important;
}
