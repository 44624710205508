@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';

.quick-start {
  height: 30vh;
  width: 30vw;
}
.addDefectIcon {
  height: 32px;
  width: 32px;
}
.addTempBtn {
  background: $tr-bottom-color;
  font-family: 'Poppins-Medium';
  color: $primary;
  border-radius: 21px;
  font-size: 12px;
  text-align: center;
  height: 34px;
  border: 1px solid $rs_scroll_color_grey;
  position: absolute;
  width: 130px;
  right: 7px;
  transition: border-color 0.3s ease;
}

.addTempBtn:hover {
  border-color: $primary;
}

.QuickStartAddBtn {
  background: $tr-bottom-color;
  font-family: 'Poppins-Medium';
  color: $primary;
  border-radius: 21px;
  font-size: 12px;
  text-align: center;
  height: 34px;
  border: 1px solid $rs_scroll_color_grey;
  position: relative;
  width: 130px;
  right: 7px;
  transition: border-color 0.3s ease;
}

.QuickStartAddBtn:hover {
  border-color: $primary;
}

.header-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $rs_analytics_blue_header;
  color: $white_original;
  padding: 5px 10px;
  height: 33px;

  .closeIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .closeIcon:hover {
    border: 1px solid;
    cursor: pointer;
  }
}
.createInstance {
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  border: 1px solid $rs_primary;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  margin: 5px 12px 0 0;
  line-height: 1;
}
.buttonStyleInstance {
  border: 1px solid $gray_40;
  opacity: 1;
  color: $gray_40;
  padding: 6px 12px;
  height: 25px;
  border-radius: 5px;
  margin: 5px;
  line-height: 1;
}
.button-cancel {
  border: 1px solid $gray_40;
  opacity: 1;
  color: $gray_40;
  padding: 6px 12px;
  height: 25px;
  border-radius: 5px;
  margin: 5px;
  line-height: 1;
  margin-left: 390px;
}
.deleteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: 220px;
  background-color: $white_original;
  box-shadow: 24px;
  padding: 4px;
}
.headerdelete {
  width: 540px;
  height: auto;
  display: flex;
  border-top: 8px solid $rs_analytics_warning;
  margin-top: -4px;
  margin-left: -4px;
}
.successImg {
  top: 318px;
  left: 600px;
  width: 96px;
  height: 96px;
  opacity: 1;
}
.instance-input-field {
  input[type='text'],
  input[type='password'] {
    border: none;
    box-shadow: none;
    outline: none !important;
    text-align: left;
    letter-spacing: 0px;
    padding-bottom: 6px;
    padding-top: 6px;
    width: 358px !important;
    height: 22px;
  }
}
.delete-button {
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  border: 1px solid $rs_primary;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  margin: 5px 12px 0 0;
  line-height: 1;
  margin-left: 15px;
}
.success-instance {
  border-color: $grey_Nevada;
  color: $grey_Nevada;
  border-radius: 4px;
}
.success-instance:hover {
  background: $grey_Nevada;
  color: white;
}

.cancel-instance {
  border-color: $rs_analytics_blue_header;
  color: $rs_analytics_blue_header;
  border-radius: 4px;
}
.cancel-instance:hover {
  background: $rs_analytics_blue_header;
  color: white;
}

.footerBlueDelete {
  width: 100%;
  height: 67px;
  background-color: AliceBlue;
  margin-top: 23px;
}
.error-message {
  color: $rs_analytics_fail;
}
.error-exist {
  color: $Light_red;
}
.instance-label {
  color: $rs_blue;
}
.table-line {
  border-bottom: 1px solid;
}
.info-text {
  color: $rs_tabs_border_color;
}
.centered-message {
  text-align: center;
  margin-top: 12rem;
}
.quick-start {
  height: 150px;
  width: 300px;
  margin-bottom: 10px;
}
.verification-dialog-container {
  .MuiDrawer-paperAnchorRight {
    border-radius: 10px 0px 0px 10px;
  }
}

.svgImgWarning {
  height: 63.8px;
  width: 68.98px;
  margin-left: 20px;
  margin-top: 20px;
}
.text-field-Style {
  width: 420px;
}
.select-field-Style {
  width: 420px;
  height: 35px;
  margin-top: 2px;
}
.disabled-field {
  background-color: $rs_Disabled;
}
.action-button:hover {
  color: $rs_primary;
  background-color: $white_original;
  border-radius: 100%;
}
.invalid-message {
  margin-right: 320px;
}

.info-icon-span {
  color: $dark-grey;
}
.info-icon-span:hover {
  color: $rs_primary;
  cursor: pointer;
}
.table-content {
  color: $grey_border;
}
.field-wrap {
  white-space: nowrap;
}
.table-header-text {
  color: $rs_analytics_blue_header;
}
.verification-footer {
  height: 35px;
  background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 10px;
  opacity: 1;
  width: inherit;
  z-index: 999;
  text-align: right;
}
.delete-footer {
  width: auto;
  height: 35px;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  margin-top: 28px;
}
.delete-content {
  color: $license_tooltip_bg;
}
.verification-close-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 45%;
}
.verification-close-icon:hover {
  border: 1px solid;
  cursor: pointer;
}

.instance-details-style {
  color: $payne_Gray;
}
.disabled-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: $shade_red !important;
  }
}
.plugin-visibilityIcon {
  color: $grey_dark;
}
.instance-width {
  width: 420px;
}
.integration-label {
  color: $rs_label;
}
.disable-verify {
  border-color: $rs_analytics_blue_header;
  width: 70px;
  height: 28px;
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: $rs_analytics_blue_header;
  color: $white_original;
  cursor: default;
}
.enable-verify {
  border-color: $rs_analytics_blue_header;
  width: 70px;
  height: 28px;
  border: 1px solid $primary;
  border-radius: 4px;
  color: $rs_analytics_blue_header;
  cursor: default;
  &:hover {
    background-color: $rs_analytics_blue_header;
    color: $white_original;
  }
}
.create-instance-text{
  margin-left: 53px;
  color: $rs_primary !important;
}
