@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';

@mixin margin-top-left($top, $left) {
  margin-top: $top;
  margin-left: $left;
}

@keyframes slide-from-right {
  0% {
    right: 5%;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

.fireflink_rights,
.docked-drawer {
  @extend .fontPoppinsRegularSm;
  letter-spacing: 0px;
  background: $primary-bg-dark;
  bottom: 0;
  position: fixed;
  width: 200px;
  line-height: 40px;
  text-align: center;
}

.docked-drawer {
  width: 64px;
}

/*Active Side Menu Classes Start when close side nav*/
@keyframes slide-from-left {
  0% {
    margin-left: 10%;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.active-drawer {
  filter: drop-shadow(0px 0px 1px $white);
  animation: all 0.1s ease-in-out;
}

.active-drawer-close {
  @extend .active-drawer;
  animation: slide-from-left 0.1s ease-in;
}

.active-drawer-close:before,
.active-drawer-close:after {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 0;
  background: radial-gradient(circle at top left, $black-white 72%, $white 75%);
}

.active-drawer-close:after {
  right: 0;
  top: 100%;
  background: radial-gradient(circle at bottom left, $black-white 70%, $white 75%);
}

.active-drawer-close:before {
  bottom: 100%;
  right: 0;
}

.active-drawer-open {
  @extend .active-drawer;
  width: 190px;
}

.active-drawer-open:before,
.active-drawer-open:after {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 0;
  background: radial-gradient(circle at top left, $black-white 72%, $white 75%);
}

.active-drawer-open:after {
  right: 0;
  top: 100%;
  background: radial-gradient(circle at bottom left, $black-white 70%, $white 75%);
}

.active-drawer-open:before {
  bottom: 100%;
  right: 0;
}

.css-h4y409-MuiList-root {
  padding-bottom: 0px;
  padding-top: 0px;
}

.side-menu-list-animation :hover {
  animation: slide-from-right 0.3s ease-in-out;
}
.side-menu-list-disable {
  pointer-events: none;
  opacity: 0.1;
}
.side-menu-list-enable {
  pointer-events: unset;
  opacity: unset;
}
.active-drawer-width-close {
  width: 55px !important;
}
