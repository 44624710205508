@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.custom-date-selector {
  label {
    @extend .fontPoppinsRegularSm;
    margin-top: -7px;
    margin-left: -4px;
    color: $black_original;
  }

  div {
    input {
      padding: 9.5px 8px;

      &:focus {
        --tw-ring-shadow: none;
      }
    }
    div {
      margin-right: -6px;
    }
  }
}

.custom-date-selector-label-disable {
  label {
    display: none;
  }

  div {
    input {
      padding: 9.5px 8px;

      &:focus {
        --tw-ring-shadow: none;
      }
    }
    div {
      margin-right: -6px;
    }
  }
}
