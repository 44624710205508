@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.parallel_run_status_label_style {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  width: 181px;
}

.details_header_box_style {
  width: 836px;
  height: 35px;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 10px;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 18px;
  padding-top: 5px;
  .details_header_text_style {
    @extend .fontPoppinsMediumMd;
    color: $rs_primary;
  }
}

// User details classes

.user_details_main_style {
  padding: 15px;
}

.user_key_text_style {
  @extend .fontPoppinsMediumSm;
  color: $rs_blue;
  opacity: 1;
  display: block;
}

.user_data_text_style {
  @extend .fontPoppinsRegularSm;
  color: $grey_darkest;
  opacity: 1;
}

.border-style {
  border: 1px dashed $rs_border;
  opacity: 1;
}

.button_group_btn_style {
  border: 1px solid $rs_primary;
  opacity: 1;
  color: $rs_primary;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.button_group_btn_style_above_modal {
  border: 1px solid $white_original;
  opacity: 1;
  color: $white_original;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.badge-font-style {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $white_original;
  text-transform: capitalize;
  opacity: 1;
}

.suite_badge_width {
  width: 6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.exe-table-height {
  height: 72vh;
  overflow-y: auto;
}

.exe-table-height-device-details {
  height: 52vh;
  overflow-y: auto;
}

.exe-table-height-user-details {
  height: 40vh;
  overflow-y: auto;
}
