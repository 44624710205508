@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css_config/variable.scss';
@import './css_config/fonts.scss';
@import '@styles/rs_fonts.scss';

.testoptimize-app {
  .primary-blue-color {
    color: $blue_dark;
  }

  .hide-navigation {
    display: none;
  }

  input.input:focus {
    outline: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white_original inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: $black_original !important;
  }

  .ReactModal__Overlay .ReactModal__Overlay--after-open {
    z-index: 99 !important;
    background-color: #0000004d;
  }

  .selected_tab_style {
    color: $ff-primary;
  }

  .hyper_link_color-common {
    color: $blue;
  }

  .left_navigation_color {
    color: $left_nav_color;
    border-radius: 0px;
    margin-right: -10px;

    &:hover {
      background-color: #e2e3e6;
    }
  }

  .left_navigation_color_dark:hover {
    background-color: $left_nav_color_dark;
  }

  .sidebar_main_style {
    padding: 10px;
    background: $skyblue_dark;
    z-index: 9;
    position: relative;
    bottom: 0;
    width: 95px;

    .toogle-menu {
      cursor: pointer;
      height: 35px;
    }

    .left_navigation_color {
      width: 98px;
      height: 55px;
      margin-left: -14px;
      position: relative;

      .nav-link-label {
        margin-left: 5px;
        padding-left: 15px;
      }

      .sidebar-nav-selected-icon {
        display: none;
      }

      .sidebar-nav-icon-margin {
        margin-left: 30px;
      }
    }

    .selected_sidemenuitem_style_dark {
      border-left: 4px solid $blue_dark;
      margin-left: 1px;
      border-radius: 0px;
      background-color: $left_nav_color_dark;
      margin-right: -10px;
      color: $blue_dark;
    }

    .selected_sidemenuitem_style {
      margin-left: -10px;
      border-radius: 0px;
      background: url(./assets/rectangle.png) no-repeat top left #fbfcfd;
      background-position: 10px 2px, right 0px bottom 1px;
      background-size: 3px 51px;
      margin-right: -10px;
      color: $blue_dark;
      height: 55px;

      &.left_navigation_color {
        width: 100px;
        height: 55px;
        margin-left: -15px;
        position: relative;
        padding-left: 10px;

        .sidebar-nav-selected-icon {
          display: inline;
          margin-left: 30px;
          width: 20px;
          margin-top: -18px;
        }

        .sidebar-nav-icon {
          display: none;
          margin-left: 38px;
          width: 20px;
          margin-top: -18px;
          color: gray !important;
        }
      }

      .sidebar-menuitem-title {
        display: 'flex';
        margin-top: '26px';
        width: '97px';
        height: '20px';
        display: 'block';
        font-size: '12px';
        position: 'absolute';
        text-align: 'center';
        margin-left: '-8px';
      }

      img {
        margin-left: 4px;
      }
    }

    .side-menu-item-style {
      margin-left: -8px;
      margin-top: 26px;
      width: 97px;
    }

    @media (min-width: $xl) {
      width: 116px;
      transition-duration: 450ms;

      .left_navigation_color {
        width: 120px;
        height: 80px;

        .sidebar-nav-icon-margin {
          margin-left: 38px;
        }
      }

      .selected_sidemenuitem_style {
        background-size: 3px 75px;
        margin-left: -15px;

        &.left_navigation_color {
          width: 120px;
          height: 80px;

          .sidebar-nav-icon {
            display: none;
            width: 20px;
            margin-top: -18px;
            color: gray !important;
          }

          .sidebar-nav-icon-margin {
            margin-left: 38px;
          }

          .sidebar-nav-selected-icon {
            display: inline;
            margin-left: 38px;
            width: 20px;
            margin-top: -18px;
          }
        }
      }

      .side-menu-item-style {
        margin-left: 0px;
        margin-top: 26px;
        width: 97px;
      }
    }
  }

  .header {
    $fontSize: 14px;
    $inputWidth: 225px;
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0b0b0b30;
    opacity: 1;
    height: 48px;

    .logo {
      width: auto;
      margin-left: 48px;
      margin-right: 0;
    }

    .leftIcons {
      display: inline-flex;
    }

    .header_line {
      width: 0px;
      height: 30px;
      border-left: 3px solid $blue_dark;
      opacity: 1;
    }

    .header_line_right {
      margin-right: auto;
      position: relative;
      left: 14px;
    }

    .profile {
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .settings_icon {
      @extend .profile;
      padding: 24px;
      color: black;
    }

    .MuiIconButton-root {
      padding: 10px;
    }

    .project-search-input-filed-style {
      background-color: $skyblue_dark;
      color: red;
    }

    .input-field-font-style-head::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .input-field-font-style-head {
      @extend .fontPoppinsSemiboldMd;
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      margin-top: 2px !important;
      opacity: 1;
      margin-top: 4px;
      width: 225px;
      position: relative;
      user-select: none;
      cursor: pointer;
    }

    .project-row-hover {
      width: 100%;
      padding: 0px 15px;
      height: 36px;
      position: relative;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: $skyblue_dark !important;
        color: $blue_dark;
      }
    }

    .box {
      min-width: 280px;
      max-width: 331px;
      width: 15%;
      background-color: $white_original;
      position: absolute;
      left: 215px;
      margin: 10px;
      float: left;
      box-shadow: 0px 0px 6px #00000029;
      border: 1px solid $white_original;
    }

    .line-of-divider {
      border-bottom: 1px solid $skyblue_dark;
    }

    .individual-proj-style {
      min-height: 6vh;
      max-height: 33vh;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .arrow-style {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 15px solid $white_original;
      margin-top: 34px;
      position: inherit;
      margin-left: 50px;
    }

    .project-name-label-style {
      text-align: left;
      font: normal normal normal 14px/19px Segoe UI;
      letter-spacing: 0px;
      color: $grey_dark;
    }

    .dropdown-style-header {
      min-height: 50px;
      background-color: white;
      max-height: 41vh;
      overflow-y: auto;
      position: absolute;
      width: 20%;
      overflow-x: hidden;
      padding: 12px;
    }

    @media (min-width: $xl) {
      transition-duration: 450ms;

      .MuiIconButton-root {
        padding: 10px;
      }

      .header_line {
        width: 0px;
        height: 45px;
      }
    }
  }

  .page-table-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 83vh;
    overflow: hidden;
  }

  .page-tab-with-actions {
    display: flex;
  }

  .repo-testdev-tab-style {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;

    :hover {
      text-align: left;
      letter-spacing: 0px;
      color: $blue_dark;
      opacity: 1;
      border-bottom: 1px solid $blue_dark;
    }
  }

  .selected-repo-testdev-tab-style {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;
  }

  .pre_post_style_common {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;

    &:hover {
      border-bottom: 1px solid $blue_dark;
    }
  }

  .selected_pre_post_common {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    border-bottom: 2px solid $blue_dark;
  }

  .content_area_style {
    background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .project_area {
    top: 194px;
    left: 112px;
    width: 1780px;
    height: 63px;
    background: #b5e8fd 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .project_label {
    // @extend .fontPoppinsSemiboldMd; For v2.1
    @extend .fontPoppinsSemiboldXlg;
    text-align: left;
    letter-spacing: 0px;
    // color: $ff-text; For v2.1
    color: $black_original;
    opacity: 1;
    // padding-left: 16px; For v2.1
    padding-left: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  .colum_header {
    color: $grey_darkest;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: rgb(12, 131, 230);
        margin: -4px 0 0 -4px;
      }

      &:nth-child(1) {
        animation-delay: -0.036s;

        &:after {
          top: 63px;
          left: 63px;
        }
      }

      &:nth-child(2) {
        animation-delay: -0.072s;

        &:after {
          top: 68px;
          left: 56px;
        }
      }

      &:nth-child(3) {
        animation-delay: -0.108s;

        &:after {
          top: 71px;
          left: 48px;
        }
      }

      &:nth-child(4) {
        animation-delay: -0.144s;

        &:after {
          top: 72px;
          left: 40px;
        }
      }

      &:nth-child(5) {
        animation-delay: -0.18s;

        &:after {
          top: 71px;
          left: 32px;
        }
      }

      &:nth-child(6) {
        animation-delay: -0.216s;

        &:after {
          top: 68px;
          left: 24px;
        }
      }

      &:nth-child(7) {
        animation-delay: -0.252s;

        &:after {
          top: 63px;
          left: 17px;
        }
      }

      &:nth-child(8) {
        animation-delay: -0.288s;

        &:after {
          top: 56px;
          left: 12px;
        }
      }
    }
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .sidebar_main_width {
    width: 220px;
  }

  .header {
    $fontSize: 14px;
    $inputWidth: 225px;
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0b0b0b30;
    opacity: 1;
    height: 48px;

    .logo {
      width: auto;
      margin-left: 48px;
      margin-right: 0;
    }

    .leftIcons {
      display: inline-flex;
    }

    .header_line {
      width: 0px;
      height: 30px;
      border-left: 3px solid $blue_dark;
      opacity: 1;
    }

    .header_line_right {
      margin-right: auto;
      position: relative;
      left: 14px;
    }

    .profile {
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .settings_icon {
      @extend .profile;
      padding: 24px;
      color: black;
    }

    .MuiIconButton-root {
      padding: 10px;
      margin-left: 8px;
    }

    .project-search-input-filed-style {
      background-color: $skyblue_dark;
      color: red;
    }

    .input-field-font-style-head::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .input-field-font-style-head {
      @extend .fontPoppinsSemiboldMd;
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      margin-top: 2px !important;
      opacity: 1;
      margin-top: 4px;
      width: 225px;
      position: relative;
      user-select: none;
      cursor: pointer;
    }

    .project-row-hover {
      width: 100%;
      padding: 0px 15px;
      height: 36px;
      position: relative;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: $skyblue_dark !important;
        color: $blue_dark;
      }
    }

    .box {
      min-width: 280px;
      max-width: 331px;
      width: 15%;
      background-color: $white_original;
      position: absolute;
      left: 215px;
      margin: 10px;
      float: left;
      box-shadow: 0px 0px 6px #00000029;
      border: 1px solid $white_original;
    }

    .line-of-divider {
      border-bottom: 1px solid $skyblue_dark;
    }

    .individual-proj-style {
      min-height: 6vh;
      max-height: 33vh;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .arrow-style {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 15px solid $white_original;
      margin-top: 34px;
      position: inherit;
      margin-left: 50px;
    }

    .project-name-label-style {
      text-align: left;
      font: normal normal normal 14px/19px Segoe UI;
      letter-spacing: 0px;
      color: $grey_dark;
    }

    .dropdown-style-header {
      min-height: 50px;
      background-color: white;
      max-height: 41vh;
      overflow-y: auto;
      position: absolute;
      width: 20%;
      overflow-x: hidden;
      padding: 12px;
    }

    @media (min-width: $xl) {
      transition-duration: 450ms;

      .MuiIconButton-root {
        padding: 10px;
      }

      .header_line {
        width: 0px;
        height: 45px;
      }
    }
  }

  .page-table-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 83vh;
    overflow: hidden;
  }

  .page-tab-with-actions {
    display: flex;
  }

  .repo-testdev-tab-style {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;

    :hover {
      text-align: left;
      letter-spacing: 0px;
      color: $blue_dark;
      opacity: 1;
      border-bottom: 1px solid $blue_dark;
    }
  }

  .selected-repo-testdev-tab-style {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;
  }

  .pre_post_style_common {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;

    &:hover {
      border-bottom: 1px solid $blue_dark;
    }
  }

  .selected_pre_post_common {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    border-bottom: 2px solid $blue_dark;
  }

  .content_area_style {
    background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .project_area {
    top: 194px;
    left: 112px;
    width: 1780px;
    height: 63px;
    background: #b5e8fd 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .colum_header {
    color: $grey_darkest;
  }

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: rgb(12, 131, 230);
        margin: -4px 0 0 -4px;
      }

      &:nth-child(1) {
        animation-delay: -0.036s;

        &:after {
          top: 63px;
          left: 63px;
        }
      }

      &:nth-child(2) {
        animation-delay: -0.072s;

        &:after {
          top: 68px;
          left: 56px;
        }
      }

      &:nth-child(3) {
        animation-delay: -0.108s;

        &:after {
          top: 71px;
          left: 48px;
        }
      }

      &:nth-child(4) {
        animation-delay: -0.144s;

        &:after {
          top: 72px;
          left: 40px;
        }
      }

      &:nth-child(5) {
        animation-delay: -0.18s;

        &:after {
          top: 71px;
          left: 32px;
        }
      }

      &:nth-child(6) {
        animation-delay: -0.216s;

        &:after {
          top: 68px;
          left: 24px;
        }
      }

      &:nth-child(7) {
        animation-delay: -0.252s;

        &:after {
          top: 63px;
          left: 17px;
        }
      }

      &:nth-child(8) {
        animation-delay: -0.288s;

        &:after {
          top: 56px;
          left: 12px;
        }
      }
    }
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.bggreencolor {
  background-color: $green;
}

.execution-modal-color {
  background-color: $color_blue;
}

#journal-scroll::-webkit-scrollbar,
.journal-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  height: 4px;
}

.desc-modal {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#journal-scroll::-webkit-scrollbar-track,
.journal-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}

#journal-scroll::-webkit-scrollbar-thumb,
.journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgb(133, 132, 132);
  border-radius: 10px;
}

.alert-div-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 10000;
  width: 100%;
  margin-left: -104px;
}

.MuiPopover-root {
  &.MuiMenu-root {
    .MuiMenu-list {
      .MuiMenuItem-root {
        &.MuiButtonBase-root {
          display: flex;
          padding: 6px 16px;
          justify-content: flex-start;
        }
      }
    }
  }
}

.w-94per {
  width: calc(100% - 65px);
}

.w-84per {
  width: calc(100% - 200px);
}

.left-65p {
  left: 65px;
}

.left-200p {
  left: 200px;
}

.zIndexDialog {
  z-index: 10000;
}

.w-98per {
  width: 98%;
}

.zIndex-1 {
  z-index: 1;
}

.data-provider-validation-font-style {
  font-size: 10px;
}

.Popup-header-common {
  @extend .fontPoppinsMediumLg;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.account-name-trimmer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 560px;
  margin-left: 17px;
}

.Popup-header-layout-style-project {
  @extend .fontPoppinsMediumLg;
  top: 240px;
  left: 656px;
  height: 22px;
  color: $grey_darkest;
}

.details-key-style-common {
  // @extend .fontPoppinsMediumSm; For v2.1
  @extend .fontPoppinsMediumLg;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-text;
}

.details-value-style-common {
  // @extend .fontPoppinsRegularSm; For v2.1
  text-align: left;
  letter-spacing: 0px;
  // color: $ff-text; For v2.1
  color: $grey;
}

.variable-details-key-style-common {
  @extend .details-key-style-common;
  @extend .fontPoppinsMediumSm;
}

.details-data-style-common {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.config-details-style-common {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $payne_Gray;
  opacity: 1;
}

.variable-details-data-style-common {
  @extend .details-data-style-common;
  @extend .fontPoppinsRegularSm;
}

.rs-user-input-container input[type='text'] {
  @extend .fontPoppinsRegularSm;
  border: none;
  border-bottom: 2px solid $gray_white !important;
  box-shadow: none;
  outline: none !important;
  text-align: left;
  height: 20px;
  padding-left: 0px;
  letter-spacing: 0px;
  color: $grey_dark;
  padding-bottom: 0;

  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}

.rs-user-input-container {
  .MuiFormControl-root {
    .MuiFormLabel-root {
      @extend .fontPoppinsRegularXLg;
      text-align: left;
      letter-spacing: 0px;
    }

    .MuiInput-underline::before {
      border-bottom: 1px solid $grey_dark;

      .Mui-disabled::before {
        border-bottom-style: none !important;
      }
    }
  }
}

.rs-input-style-textarea {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  padding-bottom: 0;
  overflow: hidden;
  min-height: 32px !important;

  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}

.rs-input-style-textarea:focus {
  @extend .fontPoppinsRegularMd;
  border: none;
  border-bottom: 2px solid $blue_dark !important;
  box-shadow: none;
  outline: none !important;
  color: $grey_dark;
  text-align: left;
  letter-spacing: 0px;
  padding-bottom: 0;
}

.rs-input-style-textareaSm {
  @extend .fontPoppinsRegularSm;
  @extend .rs-input-style-textarea;
}

.rs-input-style-textareaSm:focus {
  @extend .fontPoppinsRegularSm;
  @extend .rs-input-style-textareaSm, :focus;
}

.input-style-textarea {
  @extend .fontPoppinsRegularMd;
  // color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  // font-family: OpenSans-Regular;
  // font-size: 12px;
  padding-bottom: 0;
  overflow: hidden;
  resize: none;
  min-height: 32px !important;
}

.input-style-textarea:focus {
  @extend .fontPoppinsRegularMd;
  border: none;
  border-bottom: 2px solid $blue_dark !important;
  box-shadow: none;
  outline: none !important;
  // color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  // font-family: OpenSans-Regular;
  // font-size: 12px;
  padding-bottom: 0;
}

.warning-popup-header {
  @extend .fontPoppinsSemiboldLg;
  text-align: left;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
}

.warning-popup-content {
  @extend .fontPoppinsMediumMd;
  text-align: left;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
}

.details-table-body {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
}

.table-non-link-color-common {
  color: $grey_dark;
}

.cursor > * {
  cursor: default !important;
}

.label-success {
  color: $success;
}

.label-error {
  color: $error;
}

.label-archive {
  color: $orange_light;
}

.file-download-label-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $blue;
  margin-left: 12px;
  opacity: 1;
  cursor: pointer;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-250 {
  max-width: 250px;
}

.errorMessage {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  color: $red;
}

.ff-input-err-msg {
  @extend .fontPoppinsRegularXs8px;
  text-align: left;
  color: $red_darkest;
}

.ff-text-area {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-text;
  padding-bottom: 0;
  overflow: hidden;
  resize: none;
  min-height: 32px !important;
  height: 70px !important;
  border-radius: 5px !important;
  padding: 2px 8px 2px 8px !important;

  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}

.modal-body-testdevheight {
  height: 525px;
}

.modal-body-height {
  height: 200px;
}

#hoverable {
  visibility: hidden;
}

#hovered:hover #hoverable {
  visibility: visible;
}

input[type='text'],
input[type='password'] {
  @extend .fontPoppinsRegularMd;
  border: none;
  box-shadow: none;
  outline: none !important;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
}

input[type='text']:focus,
input[type='password']:focus {
  @extend .fontPoppinsRegularMd;
  border: none;
  border-bottom: 2px solid $light_grey_white;
  box-shadow: none;
  outline: none !important;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  padding-bottom: 0;
}

input[type='text'] :hover {
  border-bottom: 2px solid $grey_dark;
}

input[type='text'] :active {
  border-bottom: 2px solid $grey_dark;
}

input[type='text']:focus + label {
  color: $blue_dark;
}

input,
input::-webkit-input-placeholder {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
}

.fancytree-folder .fancytree-title {
  @extend .fontPoppinsRegularLg;
}

.rs-icon-alignment-font-style {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.font-style-common {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.test-dev-exe-dashboard-action {
  width: 120px;
}

.stick-table-parent-header {
  position: sticky;
  top: 0;
  background: $white_original;
  z-index: 1;
}

.stick-table-thead {
  position: sticky;
  top: 55px;
  z-index: 4;
}

.suggestion-dropdowns {
  position: absolute;
  height: 175px;
  width: 85%;
  overflow: scroll;
  z-index: 1;
}

.highlightTheText {
  background-color: $highlight_bg_yellow;
  color: $black_original;
}

.locator-list-table table tr:hover {
  background-color: transparent !important;
}

.dropdown-child-option-style-stepGroup {
  margin-top: -42px;
  right: 115px;
}

.ff-icon-btn {
  height: 24px;
  width: 24px;
}

.action-btn {
  svg {
    fill: $ff-icon-default;
  }

  padding: 5px;
}

.action-btn svg {
  height: 14px;
  width: 14px;
}

.action-btn:hover {
  svg {
    fill: $ff-primary !important;
  }

  background-color: $ff-white;
  border-radius: 50px;
}

.tree-head-btn {
  svg {
    fill: $ff-primary;
  }

  height: 30px;
  width: 30px;
  padding-left: 7px;
}

.expand-show-btn {
  svg {
    height: 14px;
    width: 14px;
  }
}

.collapse-hide-btn {
  svg {
    height: 20px;
    width: 20px;
  }
}

.tree-head-btn:hover {
  svg {
    fill: $ff-white !important;
  }

  background-color: $ff-primary;
  border-radius: 50px;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .responsive-parameter-div {
    left: 43.5%;
    right: 20%;
    top: 33%;
    width: 53.9%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 240px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .responsive-parameter-div {
    left: 40%;
    right: 20%;
    top: 38% !important;
    width: 57%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 240px;
      height: calc(61vh - 123px);
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .responsive-parameter-div {
    left: 40%;
    right: 20%;
    top: 32% !important;
    width: 57%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 240px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (min-width: 1401px) and (max-width: 1599px) {
  .responsive-parameter-div {
    left: 44.5%;
    right: 20%;
    top: 30%;
    width: 53.5%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 280px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .responsive-parameter-div {
    left: 43.5%;
    right: 20%;
    top: 27%;
    width: 54.5%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 240px;
      // height: 395px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (max-width: 1900px) and (min-width: 1701px) {
  .responsive-parameter-div {
    left: 43.5%;
    right: 20%;
    top: 25.5%;
    width: 54.5%;
    height: fit-content;

    .responsive-parameter-div-height {
      min-height: 320px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (max-width: 2100px) and (min-width: 1901px) {
  .responsive-parameter-div {
    left: 43.5%;
    right: 20%;
    top: 22%;
    width: 55%;
    height: 67%;

    .responsive-parameter-div-height {
      min-height: 440px;
      height: calc(67vh - 124px);
    }
  }
}

@media screen and (min-width: 2101px) {
  .responsive-parameter-div {
    left: 44%;
    right: 20%;
    top: 17%;
    width: 55%;
    height: 68%;

    .responsive-parameter-div-height {
      min-height: 520px;
      height: calc(67vh - 124px);
    }
  }
}

.terminate_zIndex {
  z-index: 1400;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.ff-text-wrap {
  text-wrap: wrap;
}
.ff-ai-btn {
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    transform: scale(1.02);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
}
