@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.table_header_style {
  color: var(--rs-blue);
  border-bottom: 1px solid #ecedf8;
}

.table_header_text_style {
  @extend .fontPoppinsMediumSm;
  text-align: left;
  letter-spacing: 0px;
  color: $rs_primary;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 100;
}

.table_content_text_style {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  text-transform: capitalize;
  opacity: 1;
}

.table_content_text_style_hyperlink {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
  color: $rs_primary;
  text-transform: capitalize;
  opacity: 1;
}

.sub_header_style {
  @extend .fontPoppinsMediumSm;
  text-align: left;
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  margin-top: 21px;
  height: 30px;
}

.no_data_msg_style {
  @extend .fontPoppinsMediumXXlg;
  text-align: left;
  letter-spacing: 0px;
  color: $rs_primary;
  text-transform: capitalize;
  opacity: 1;
}

.show_action {
  display: none;
}

.show-action-style:hover .show_action {
  display: inline-block;
}

.sticky-table-header {
  position: sticky;
  top: 0px;
  z-index: 4;
  background-color: $white_original;
}
