@import '@src/styles/_variables.scss';
@import '@src/styles/rs_fonts.scss';

.label {
  color: $license_label_gray;
  &.required {
    &:before {
      content: '*';
      color: $red;
      margin-right: 4px;
    }
  }

  &.error {
    color: $red;
  }
}

.select-box-wrapper {
  min-height: 77px;
  &.signup_select_box {
    width: 242px;
  }
  :global {
    .MuiInputBase-root {
      &.Mui-disabled {
        &::before {
          border-style: solid;
        }
      }
    }
  }
}

.select-box-menu-list-wrapper {
  :global {
    max-height: 300px;
    .MuiMenuItem-root {
      display: flex;
      justify-content: flex-start;
      padding: 6px 16px;

      &.MuiMenu-list {
        background-color: $select_dropdown_bg;
      }
      &.MuiButtonBase-root:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
.textWrap {
  text-overflow: ellipsis;
  display: block !important;
  overflow: hidden;
  white-space: nowrap;
  width: 98%;
}

.labeled-input {
  // min-height: 56px;
  &.signup-input-field {
    width: 242px;
    min-height: 70px;
  }
  .form-control {
    :global {
      .MuiInputBase-root {
        .MuiInputBase-input {
          min-height: 28px;
          border-radius: 5px;
          padding: 2px 2px 2px 10px;
        }

        &.Mui-disabled {
          &::before {
            border-style: solid;
          }
        }
      }
    }
  }
}

.ff-labeled-input {
  min-height: 56px;
  @extend .fontPoppinsRegularSm;
  .form-control {
    :global {
      .MuiInputBase-root {
        .MuiInputBase-input {
          min-height: 28px;
          height: 35px;
          border-radius: 5px;
          padding: 2px 8px 2px 8px;
          color: $ff-text;
          font-size: 12px !important;
        }

        &.Mui-disabled {
          &::before {
            border-style: solid;
          }
        }
      }
    }
  }
}

.number-input-control {
  :global {
    .MuiInputBase-root {
      .MuiInputBase-input {
        &:focus {
          box-shadow: none;
        }

        &::-webkit-inner-spin-button {
          opacity: 0;
          -webkit-appearance: none;
          background: url('../../../assets/input-inner-spin-button.png') no-repeat 50% 50% !important;
          padding: 15px;
          transition: opacity 0.3s;
        }

        &:hover::-webkit-inner-spin-button {
          opacity: 1;
        }

        &:focus::-webkit-inner-spin-button {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.8;
        }
      }
    }
  }
}
