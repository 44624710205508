@import './variable.scss';

@mixin breadcrumb_common_style {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 12px;
  height: 16px;

  @media (min-width: $xl) {
    // above 1280
    font-size: 12px;
    height: 16px;
  }
}

@keyframes slide-from-right {
  0% {
    margin-right: 10%;
    opacity: 0;
  }

  100% {
    margin-right: 0;
    opacity: 1;
  }
}

.breadcrumb-wrapper {
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    --tw-bg-opacity: 1;
    align-content: center;

    .breadcrumb_link_color {
      @include breadcrumb_common_style;
      color: $primary;
      .breadcrumb-link:hover {
        text-decoration: solid;
        text-decoration: underline $primary;
      }
    }

    .non-clickable-field {
      @include breadcrumb_common_style;
      color: $grey_dark;
    }
  }

  @media (min-width: $xl) {
    margin-bottom: 20px;
  }
}

.header-open-breadcrumb {
  position: fixed;
  left: 90px;
  top: 17px;
  max-width: 900px;
}

.header-close-breadcrumb {
  position: fixed;
  left: 220px;
  animation: slide-from-right 0.1s ease-in;
  top: 17px;
  max-width: 807px;
}
