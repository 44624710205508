@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';
.content_area_flow_snippet {
  background: $white_light 0% 0% no-repeat padding-box;
  opacity: 1;
}

.sub_div_2 {
  margin-top: 1% !important;
}

.css-1cakejq-MuiTableContainer-root {
  width: 100%;
  width: 226px !important;
  height: 360px !important;
  overflow-x: auto;
}
.toggle_Data {
  transform: rotate(90deg);
}

.toggle_Console {
  transform: rotate(-6deg);
}

.error {
  color: $red_dark;
  font-size: 10px !important;
}

#rowInputFieldSearch {
  border-bottom: 2px solid $blue_dark !important;
}

#color_input {
  color: $gray_dark_50 !important;
}

#color_normal_input {
  color: $blue_dark !important;
}

#color_error {
  color: $valencia !important;
}
.create-snippet-header {
  color: $grey_darkest;
  font-size: 18px;
  margin-left: 13px;
}
.create-snippet-full-section {
  height: 40px;
  position: relative;
  z-index: 5;
  background: $gray_dark_70 0% 0% no-repeat padding-box;
  display: flex;
  margin-left: -7px;
}
.update-snippet-full-section {
  height: 40px;
  position: relative;
  z-index: 5;
  background: $gray_dark_70 0% 0% no-repeat padding-box;
  display: flex;
  margin-left: -7px;
}
.width-of-left-side-panel-at-isOpen {
  width: 20%;
}
.margin-left-for-toggle-icon {
  margin-left: 55%;
}
.margin-left-for-toggle-icon-two {
  width: 5.5%;
  padding: 0px 0px 0px 10px;
  background-color: $skyblue_light 0% 0% no-repeat padding-box;
  margin-right: 170px;
}

.percent-width-78 {
  width: 78%;
}
.width-42-in-snippet-editor {
  width: 42vw;
  justify-content: flex-end;
}
.group-icon-width-isOpen {
  width: 2%;
  display: flex;
  z-index: 5;
}
.group-icon-width-isNotOpen {
  width: 2%;
  display: flex;
  z-index: 5;
}
.left-side-panel-width {
  width: 20%;
  float: left;
  min-width: 230px;
  max-height: 410px;
  background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0px;
  overflow: auto;
  z-index: 10;
}
.notIsOpen-textBox-margin {
  margin-left: -170px;
}
.isOpen-textBox-margin {
  border-left: 2px solid $grey_light;
}
.height-of-editor-and-console {
  max-height: 467px;
}
.snippet-main-div-height {
  position: relative;
  max-height: 517px;
  overflow: hidden;
}
.snippet-main-page-height {
  height: 490px;
  background-color: $ff-white;
}
.snippet_div_height {
  height: 75vh;
}
.container-height-480 {
  height: 480px;
}
.console-border-in-snippet {
  color: $white_original;
  background: $rs_analytics_blue_header 0 0 no-repeat padding-box;
  border: 1px solid $rs_analytics_blue_header;
  align-items: center;
  border-radius: 10px 10px 0 0;
}
.console-border-in-snippet-new {
  @extend .console-border-in-snippet;
  border-radius: 10px 10px 10px 10px;
}
.maxHeight-display-console-300 {
  max-height: 300px;
}
.maxHeight-display-console-250 {
  max-height: 250px;
}
.maxHeight-display-console-433 {
  max-height: 330px;
}
.max-height-40 {
  max-height: 40px;
}
.table-color-and-fonts {
  @extend .fontPoppinsRegularMd;
  color: $blue_dark;
}
.hover-active-details {
  &:hover {
    color: $info;
  }
}

.min-length-of-snippet {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  float: left;
}

.snippet-dialog .snippet-container {
  background-color: $white_light;
  box-shadow: 0px 0px 11px $shadow_black;
  padding: 0;
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  opacity: 1;
  width: 35%;
  border: none;
  overflow: hidden;
  border-radius: 10px 0px 0px 10px;
}

.snippet-header {
  background-color: $rs_primary;
  color: $ff-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding-left: 12px;
}

.editor-height-in-snippet-details {
  height: 200px;
  width: 97%;
  border-radius: 10px;
  overflow-y: auto;
  border: 2px dashed $border_gray_200;
  margin: auto;
}

.snippet-footer {
  padding: 4px 12px;
}

.snippet-page-state {
  border: 1px solid $tr-bottom-color;
  height: 27px;
  padding: 4px;
  display: inline-block;
  border-radius: 20px;
  background: linear-gradient(to right, $tr-bottom-color 25%, $white_original 30% 100%);
  position: relative;
}
.snippet-page-state::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(66.66% - 2px);
  height: 100%;
  border-right: 1px solid $tr-bottom-color;
}
.snippet-page-state::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(30% - 2px);
  height: 100%;
  border-right: 4px solid $tr-bottom-color;
}
.snippet-search-svg-icon {
  transition: fill 0.3s;
  background-color: $light_white_50;
  padding: 5px;
  border-radius: 50px;
}

.snippet-search-svg-icon:hover {
  fill: $red;
}
.DeleteIcon-in-snippet-list path {
  fill: $gray_40;
  transition: fill 0.3s;
}

.DeleteIcon-in-snippet-list:hover path {
  fill: $rs_analytics_blue_header;
}
.DeleteIcon-in-snippet-list:hover {
  background-color: $white_original;
  border-radius: 50%;
}
.ViewIcon-in-snippet-list path {
  fill: $gray_40;
  transition: fill 0.3s;
}

//   .ViewIcon-in-snippet-list:hover path {
//     fill: $primary;
//   }
//   .ViewIcon-in-snippet-list:hover {
//     background-color: $white_original;
//     border-radius: 50%;
//   }
.viewIcon-in-snippet-list-header {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $white_original;
    border-radius: 50%;
    .ViewIcon-in-snippet-list path {
      fill: $primary;
    }
    .ViewIcon-in-snippet-list {
      background-color: $white_original;
    }
  }
}
.EditIcon-in-snippet-list path {
  fill: $gray_40;
  transition: fill 0.3s;
}

.EditIcon-in-snippet-list:hover path {
  fill: $primary;
}
.EditIcon-in-snippet-list-span {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $white_original;
    border-radius: 50%;
    .EditIcon-in-snippet-list path {
      fill: $primary;
    }
  }
}
.ExpandIcon-in-snippet-list path {
  fill: $black_original_100;
  transition: fill 0.3s;
}

.ExpandIcon-in-snippet-list:hover path {
  fill: $primary;
}
.ExpandIcon-in-snippet-list:hover {
  background-color: $white_original;
  border-radius: 50%;
}
.arrow-expand-icon,
.arrow-collapse-icon {
  border-radius: 50px;
  &:hover {
    background-color: $white_original;
  }
}
.snippet-details-close-icon {
  &:hover {
    border: 2px solid $white_original;
    border-radius: 50px;
  }
}
.snippet-page-search-img {
  background-color: $light_white_50;
  padding: 5px;
  border-radius: 50px;
}
.snippet-input-field-alignment {
  width: calc(85%);
  position: absolute;
}
.snippet-alignment-for-row {
  width: 65.5vw;
  justify-content: flex-end;
}
.snippet-shaded-background {
  background-color: $rs_bg_whitgrey;
}
.customization-of-compile-save-btn {
  @extend .fontMontserratMediumXs;
  border: 1px solid $rs_analytics_blue_header;
  color: $rs_analytics_blue_header;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.customization-disable-compile-save-btn {
  @extend .customization-of-compile-save-btn;
  color: $slateBlack;
  border: 1px solid $grey_dark;
}
.hover-of-compile-save-btn {
  &:hover {
    background-color: $rs_analytics_blue_header;
    color: $white_original;
  }
}
.snippet-editor-div-customization {
  border-right: 10px solid $rs_bg_whitgrey;
  border-bottom: 10px solid $rs_bg_whitgrey;
  max-height: 410px;
  background-color: $white_original;
  border: 2px solid $white_dark_50;
}
.snippet-editor-console-customization {
  border-top: 2px dashed $border_gray_200;
  border-right: 2px dashed $border_gray_200;
  border-bottom: 2px dashed $border_gray_200;
  border-radius: 15px;
}
.snippet-expand-btn-customization {
  top: 23.5%;
  left: 95%;
  position: absolute;
}
.snippet-btn {
  height: 30px;
  width: fit-content;
  border: 2px solid $rs_primary;
}
.snippet-list-header {
  background-color: $light_white_50;
  color: $rs_primary;
}
.snippet-details-cancel-btn {
  border: 1px solid $gray_40;
  height: 25px;
  padding: 6px 12px;
  line-height: 1;
}
.w-150 {
  width: 150px;
}
.expand-editor-alignment-snippet-editor {
  width: 100%;
  position: absolute;
  top: 8%;
  left: 0;
  z-index: 10;
  overflow: hidden;
  max-height: 72vh;
  max-width: 93vw;
  opacity: 1;
  animation: snippet-expand-animation 0.4s ease;
}
.expand-editor-alignment-custom-editor {
  @extend .expand-editor-alignment-snippet-editor;
  top: 11%;
}
.expand-editor-alignment-prerequisite-editor {
  @extend .expand-editor-alignment-snippet-editor;
  top: 0%;
}
@keyframes snippet-expand-animation {
  0% {
    height: 232px;
    transform: scale(0.5);
  }
  100% {
    height: 72vh;
    transform: scale(1);
  }
}
.collapse-editor-alignment-snippet-editor {
  z-index: 1000;
  overflow: hidden;
  max-height: 72vh;
  max-width: 93vw;
  opacity: 1;
  animation: snippet-collapse-animation 1s ease;
}
@keyframes snippet-collapse-animation {
  0% {
    z-index: 1000;
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.snippet-expand-btn-customization-in-expand-click {
  top: 2%;
  left: 97%;
  position: absolute;
  background-color: $white_original;
  border-radius: 50%;
}
.snippet-text-div-alignments {
  width: 100%;
  background-color: $gray_dark_70;
  align-items: center;
}
.snippet-my-input-width {
  width: 97%;
}
.snippet-width-20 {
  width: 22%;
}
.active-tab-in-snippet {
  background-color: $rs_analytics_blue_header;
  color: $white_original;
}
.active-tab-in-snippet::after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  transform: rotate(45deg);
  height: 78.4%;
  width: 23.9px;
  left: 100%;
  transform-origin: top left;
  aspect-ratio: 1/1;
  border-right: 1px solid $rs_analytics_blue_header;
  border-top: 1px solid $rs_analytics_blue_header;
  border-top-right-radius: 2px;
  z-index: 1;
  background: $rs_analytics_blue_header 0% 0% no-repeat padding-box;
  color: $white_original;
}
.snippet-update-tab-alignments,
.snippet-create-tab-alignments,
.snippet-update-toggle-alignments {
  background-color: $rs_primary;
  color: $white_original;
}
.snippet-update-tab-alignments {
  padding: 8px 0 8px 16px;
  width: 26%;
}
.snippet-create-tab-alignments {
  padding: 8px 0 8px 16px;
  width: 26%;
}
.background-od-snippet-create {
  background-color: $rs_bg_whitgrey;
}
.snippet_div_height::-webkit-scrollbar,
.table-height::-webkit-scrollbar {
  width: 6px;
}
.snippet_div_height::-webkit-scrollbar-thumb,
.table-height::-webkit-scrollbar-thumb {
  background-color: $rs_light_gray !important;
  border-radius: 6px;
}
.snippet-delete-icon > svg,
.snippet-edit-icon > svg {
  padding: 1px;
}
