:root {
  --primary-white: #ffffff;
}
.no_data_msg_layout {
  text-align: center;
  margin-top: 20px;
}

.FaStop-style {
  color: #ea0322;
}

.icon-blue-style {
  color: #1648c6;
}

.edit-style {
  margin-left: 13px;
}

.selected-state-style {
  background-color: #e5ecfc;
  background: var(--lm-left-top-nav-border-e5ecfc) 0% 0% no-repeat padding-box;
  background: #e5ecfc 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  color: #1648c6;
  pointer-events: none;
}

/* .create-suite-header-style {
  border-bottom: 1px solid #f0f3f9;
  height: 45px;
 
 
} */

.generate-height-width {
  height: 14px;
  width: 14px;
}

.create-suite-label-style {
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  /* font: normal normal bold 18px/24px Open Sans; */
  padding-left: 10px;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.clear-btn-style {
  top: 220px;
  left: 1719px;
  width: 66px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
}

.clear-btn-label-style {
  width: 34px;
  height: 19px;
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.save-btn-style {
  top: 220px;
  left: 1809px;
  width: 63px;
  height: 31px;
  border: 1px solid var(--primary-1648c6);
  background: #3a62c9 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
}

.save-btn-label-style {
  top: 226px;
  left: 1825px;
  width: 31px;
  height: 19px;
  color: var(--lm-workspace-light-fbfcfd);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fbfcfd;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.step-trigger {
  display: grid;
}

.circle-style {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  margin: 0.25rem;
  margin-left: 0.1rem;
  line-height: 1em;
  color: #727171;
  background-color: #f0f3f9;
  border-radius: 1em;
}

/* #Suite,
#Variable,
#TestData,
#RunSuiteIn,
#Email {
  height: auto;
}

.stepper-height {
  height: 60vh;
  overflow-y: auto;
} */

.line-stepper {
  width: 18% !important;
  border-top: 2px solid #e1dfdf;
  margin-left: -4px !important;
  margin-top: 21px;
}

.selected-line-stepper {
  width: 18% !important;
  border-top: 2px solid #1648c6;
  margin-left: -4px !important;
  margin-top: 21px;
}

.stepper-two-line {
  display: grid;
}

.stepper-variable-width {
  width: 18% !important;
}

.stepper-testData-width {
  width: 16% !important;
}

.stepper-runin-width {
  width: 15% !important;
}

.stepper-email-width {
  width: 13.5% !important;
}

.selected-stepper-color {
  color: #1648c6;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.stepper-style {
  color: #727171;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.refresh-icon-style {
  /* float: right; */
  margin-top: -25px;
  margin-left: 52%;
}

.stopper-label-layout {
  margin-left: 10%;
  margin-top: 6px;
}

.stopper-number-layout {
  margin-left: 10%;
}

.input-field-style {
  background-color: #fbfcfd;
}

/* .input-field-style-textarea {
  background-color: #fbfcfd;
  padding-top: 0;
  border-color: #dad5d5;
  width: 97%;
  max-height: 40px;
  height: 30px;
  line-height: 17px;
} */

/* Module Script */

/* .module-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 8px;
  margin-top: 12px;
  padding: 3px;
  height: 404px;
} */

.module-script-label-style {
  top: 532px;
  left: 176px;
  /* width: 133px; */
  height: 22px;
  color: var(--text-3c3838);
  text-align: left;
  /* font: normal normal 600 16px/22px Open Sans; */
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
}

.selet-module-style {
  top: 528px;
  left: 1589px;
  width: 187px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
}

.selet-module-style:hover {
  border: 1px solid #2b71f7;
}

.select-module-label-style {
  top: 534px;
  left: 1605px;
  width: 155px;
  height: 19px;
  color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: Poppins-Regular;
  font-size: 14px;
}

.select-module-label-style:hover {
  color: #2b71f7;
}

/* .module-popup-layout {
  top: 192px;
  left: 682px;
  width: 1145px;
  height: 66vh;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
} */
.select-mod-script-buttton-style {
  top: 781px;
  left: 908px;
  width: 187px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  color: #727171;
}

.select-mod-script-buttton-style:hover {
  border: 1px solid #2b71f7;
  color: #2b71f7;
}

.filters-header {
  top: 192px;
  left: 357px;
  width: 305px;
  height: 48px;
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.filter-box-style {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #fefeff 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 0px 0px 6px #00000029;
  margin-right: 8px;
}

.filter-label-style {
  top: 208px;
  left: 373px;
  width: 36px;
  height: 17px;
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin-left: 12px;
}

.empty-div-height {
  height: 6px;
}

.label-clearall-style {
  color: var(--information-2b71f7);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #2b71f7;
  text-transform: uppercase;
  opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin-right: 12px;
  margin-top: 6px;
}

.auto-search-field-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.max-popup-style-1 {
  width: 86%;
  height: 85vh;
  /* width: 1696px;
  height: 884px */
}

.module-search-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 12px;
}

.filter-header-style {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -12px;
  margin-right: -12px;
  height: 40px;
}

.module-search-name-style {
  /* color: var(--text-3c3838); */
  text-align: left;
  /* font: normal normal 600 12px/17px Open Sans; */
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 12px;
}

/* .max-popup-style {
  width: 90%;
  height: 902px;
} */

.variable-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 11px;
  /* padding: 21px; */
  /* height: 404px; */
}

.variable-type-header-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
  height: 63px;
  padding: 13px;
}

.variable-type-header-style-width {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
  height: 52px;
  padding: 18px;
}

/* .create-set-layout {
 
  margin-left: 35%;
  margin-top: 30px;
  margin-bottom: 30px;
} */

/* .quick-start-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* .quick-start-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.set-table-header {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.set-table-header-label {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

/* .variable-set-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 100%;
  flex-direction: column;
  margin-top: 11px;
 
} */
/* .variable-set-content-area-stylee {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  
  flex-direction: column;
  margin-top: 11px;
  
  height: 56vh;
} */
.variable-set-content-area-global-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  /* display: flex; */
  flex-direction: column;
  margin-top: 11px;
  /* padding: 21px; */
  height: 45vh;
}

.test-data-set-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 54vh;
  /* display: flex; */
  flex-direction: column;
  margin-top: 11px;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* padding: 21px; */
}

/* .create-set-header-label {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  margin-top: 3px;
} */

/* .create-set-header {
 
  padding: 12px;
} */

.disabled-btn {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #727171;
  padding: 4px 14px;
  /* border: 1px solid #525252; */
  border-radius: 3px;

  border: 1px solid var(--disable-e1dfdf);
  border: 1px solid #e1dfdf;
  border-radius: 2px;
  opacity: 1;
}

.disabled-action {
  pointer-events: none;
}

.disabled-update-btn {
  background: #1648c6;
  border: 1px solid #1648c6;
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  color: #f6f7f9;
  padding: 4px 14px;
  font-size: 14px;
  opacity: 0.5;
  pointer-events: none;
}

.search-icon-maximize-style {
  font-size: 16px;
  margin-right: 9px;
  margin-top: 3px;
}

.search-icon-maximize-style:hover {
  color: #1648c6;
}

.input-field-display {
  display: grid;
}

/* .maximize-tree-size {
  height: 75vh;
} */

.test-data-parent-tree-height {
  min-height: 30vh;
  max-height: 60vh;
}

.tast-data-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 11px;
  /* padding: 21px; */
  /* height: 404px; */
}

.edit-tes-data-style {
  margin-top: 10px;
  /* margin-left: 144px; */
  width: 1632px;

  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.edit-tes-data-style-1 {
  margin-top: 80px;
  /* margin-left: 144px; */
  width: 86%;
  /* height: 85vh; */

  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.test-data-top-line-style {
  /* border: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc); */
  border-top: 2px dashed #e5ecfc;
  opacity: 1;
  margin-top: 18px;
  margin-bottom: 18px;
}

.test-data-checkbox-style {
  margin-left: -17px;
}

/* .recipient-email-id-style {
 
  width: 170%;
} */

.auto-complete-style {
  width: 50%;
  margin-top: -19px;
}

.email-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 404px; */
  margin-right: 6px;
}

.email-page-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 404px; */
  margin-right: 6px;
  height: 56vh;
  display: flex;
  flex-direction: column;
}

/* .email-notification-header {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* .email-checkbox-value-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
} */

/* .email-footer-search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border: 0.5px solid #e5ecfc;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 0 0 5 0px;
  opacity: 1;
  width: 250px;
  margin-top: -1px;
  position: absolute;
  top: 245px;
  left: 3px;
  z-index: 15;
}

.search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #e5ecfc;
  border-radius: 5px;
  opacity: 1;
  width: 250px;
  overflow-y: auto;
  height: 27vh;
  flex: 1 1;
  flex-basis: 0;            
  position: absolute;
  top:60px;
  left: 3px;
  z-index: 15;
        
} */

.emailGroup-class {
  position: relative;
}

.email-search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0.5px solid #e5ecfc;
  opacity: 1;
  width: 0px;
}

.border-botton-style {
  border-bottom: 1px solid #e2e5ec;
}

.email-search-dropdown-btn-style {
  text-align: right;
  padding: 3px;
}

.multi-sel-btn-style {
  background: var(--lm-left-top-nav-border-e5ecfc) 0% 0% no-repeat padding-box;
  background: #e5ecfc 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}

.recipient-email-id-style .MuiPaper-elevation1 {
  box-shadow: none;
}

.recipient-email-id-style .MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
}

.recipient-email-id-style .MuiPaper-rounded {
  border-radius: 0px;
}

.recipient-email-id-style .MuiChip-root {
  height: 24px;
  background-color: #e5ecfc;
}

.recipient-email-id-style .MuiChip-root:hover {
  color: #1648c6;
  height: 24px;
  background-color: #e5ecfc;
}

.recipient-email-id-style .makeStyles-root-43 {
  padding: 0px;
}

.recipient-email-id-style .WAMuiChipInput-inputRoot-6 {
  margin-top: 12px;
}

.test-envi-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.run-settings-content-area {
  /* background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  opacity: 1; */

  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 56vh;
  display: flex;
  flex-direction: column;
}

.css-1ojkyje-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  background-clip: padding-box;
  outline: 0px !important;
}

.device-autocomplete .MuiChip-label {
  overflow: hidden;
  white-space: nowrap;
  /* padding-left: 12px; */
  padding-right: 12px;
  text-overflow: ellipsis;
  background-color: white;
}

.device-autocomplete .MuiChip-label {
  background-color: white;
}

.visibility-select-tag {
  opacity: 0;
  position: absolute;
}

/* .select-exe-type-label-style {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.radio-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.single-machine-table-height {
  /* height: fit-content; */
  flex: 1 1;
  flex-basis: 0;
  overflow: auto;
  z-index: 0;
  min-height: 15vh;
  height: 370px;
}

.selected-single-machine-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  overflow-x: auto;
  min-height: 62vh;
}

/* .implicit-wait-style .MuiFormControl-root {
  margin-left: 8px;
  width: 45%;
} */

.millisec-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

/* .implicit-explicit-label-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  font-size: 12px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.dotted-border-style {
  border-top: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc);
  border-top: 2px dashed #e5ecfc;
  opacity: 1;
}

.time-dropdown .MuiSelect-select.MuiSelect-select {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.schedule-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: -8px;
  width: 90%;
}

.expire-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: 14px;
  width: 75%;
}

.maximize-tree-height-style {
  height: 70vh;
}

.maximize-tree-height-testdata-style {
  height: 63vh;
}

/* .set-details-value-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.set-details-value-style-description {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  overflow-wrap: anywhere;
}

.details-content-area {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}

.test-details-content-area {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  height: 49vh;
}

/* .test-data-table-height{
  overflow: hidden;
  height: 45vh;
} */
.details-content-area-height {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  height: 380px;
}

.priv-pub-font-style {
  font-size: 10px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

/* .select-tag-style select{
  margin-left: 11px;
  font-size: 12px;
  padding-bottom: 0px;
  background-color: #fbfcfd;
  margin-top:-2px

}

.select-tag-style select:hover{
border-bottom: 2px solid black; 
} */
/* border-bottom: 2px solid black;  */
#footer-notfixed {
  /* height: 25px; */
  bottom: 0px;
  left: 5%;
  right: 2%;

  /* position: fixed;
    height: 52px;
    bottom: 185px;
    left: 4.5%;
    right: 4.5%;
    margin-bottom: 7px; */
}

/* .pagecountborder-set{
  border-top: 2px solid #e5ecfc;
    padding-top: 4px;
    margin-top: auto!important;
 
    
    
    
} */
.email-pagecountborder-set {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  height: 18px;
}

/* .pagecountborder-set-single-machines{
  border-top: 2px solid #e5ecfc;
    padding-top: 4px;
    margin-top: auto!important;
    position: fixed;
    height: 7vh;
    bottom: 152px;
    left: 4.8%;
    right: 3.4%;
    margin-bottom: 7px;
   
} */
.pagecountborder-set-global {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 23vh;
  bottom: 145px;

  margin-bottom: 7px;
}

/* .pagecountborder-set-global-set-details { */
/* border-top: 2px solid #e5ecfc;
    padding-top: 4px;
    margin-top: auto!important;
    position: fixed;
    height: 16vh;
    bottom: 25px;
    left: 19.2%;
    right: 19.2%;
    
    
    margin-bottom: 7px; */

/* border-top: 2px solid #e5ecfc;
    padding-top: 4px;
    margin-top: auto!important;
    position: fixed;
    height: 0vh;
    bottom: 135px;
    left: 19.2%;
    right: 19.2%;
    margin-bottom: 7px; */

/* } */

.pagecountborder-set-project-set-details {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 16vh;
  bottom: 25px;
  left: 19%;
  right: 19%;

  margin-bottom: 7px;
}

.pagecountborder-set-Mult-machines {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 7vh;
  bottom: 152px;
  left: 5%;
  right: 5%;
  margin-bottom: 7px;
}

.pagecountborder-set-maximize {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
}

/*.pagecountborder-maximize{
  margin-bottom: 65px;
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
 
  margin-right: 31px;
} */

/* #footer-projVar-set-max{
  position: fixed;
    height: 50px;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 51px;
} */
#footer-testData-set-max {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 7%;
  right: 7%;
  margin-bottom: 51px;
}

#footer-singleMachine-set-max {
  position: fixed;
  height: 50px;
  bottom: 50px;
  left: 6%;
  right: 6%;
  margin-bottom: 51px;
}

/* #footer-MultipleMachine-set-max{
  position: fixed;
    height: 50px;
    bottom: 55px;
    left: 6%;
    right: 6%;
    margin-bottom:51px;
} */

/* suite of suite *********************************************************************************************/
.suite-table-td-height {
  height: 48px;
}

.crete-pagination-style {
  bottom: 151px;
  position: fixed;
  right: 75px;
}

.suite-of-suite-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 8px;
  /* padding: 3px; */
  height: 60vh;
  /* max-height: fit-content; */
}

#footer-suite-of-suite {
  position: fixed;
  height: 32px;
  bottom: 108px;
  left: 53px;
  right: 53px;
}

#footer-suite-of-suite-main-tbl {
  position: fixed;
  height: 32px;
  bottom: 0px;
  left: 108px;
  right: 10px;
  margin-bottom: 20px;
}

.pagination-padding {
  border-top: 1px solid #dee0e6;
  opacity: 1;
  padding: 9px;
  margin-top: 13px;
  bottom: 14px;
  position: fixed;
  width: 87%;
}

.pagination-padding-suiteofsuite {
  border-top: 1px solid #dee0e6;
  opacity: 1;
  padding: 9px;
  margin-top: 13px;
  bottom: 14px;
  position: fixed;
  width: 90%;
}

.details-header-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.suite-of-suite-deails-area-style-popup {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 60vh;
}

#schedule-footer-sos-details-popup {
  margin-top: auto !important;
  position: fixed;
  height: 7vh;
  bottom: 121px;
  left: 30.4%;
  right: 30.4%;
}

.suite-of-suite-deails-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 68vh;
  margin: 12px;
}

.suite-of-suite-deails-area-style-instance {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #00000029; */
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 77vh;
}

#schedule-footer-suiteofsuite {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 100px;
  right: 11px;
}

.suite-of-suite-deails-area-style-child {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 58.2vh;
  margin: 18px;
}

#footer-details-suiteofsuite {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 101px;
  right: 13px;
}

.suite-of-suite-header-style {
  border-bottom: 1px solid #d7dae1;
  height: 42px;
}

.suite-header-style {
  align-self: center;
}

.suite-header-main-style {
  height: 52px;
}

.delete-icon-style .MuiSvgIcon-root {
  font-size: 18px;
}

.disabled-delete-style {
  color: #e1dfdf;
}

.delete-font-style {
  color: var(--lm-hover-0a0a0a);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #0a0a0a;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

/* .schedule-font-style{
  color: var(--lm-normal-525252);
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #727171;
font-size: 12px;
font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.schedule-time-font-style{
  color: var(--text-3c3838);
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #3C3838;
font-size: 12px;
font-family: OpenSans-Semibold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* DETAILS STARTS HERE */
/* .details-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 76vh;
} */

/* .details-main-header{
  height: 50px;
  border-bottom: 1px solid #d7dae1;
} */

.details-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.module-details-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin-top: 20px;
  /* padding: 4px; */
  /* height: 404px; */
}

.dotted-border {
  border: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc);
  border: 2px dashed #e5ecfc;
  opacity: 1;
  margin-top: 30px;
}

/* .section-header-style{
  color: var(--text-3c3838);
text-align: left;
font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0px;
color: #3C3838;
opacity: 1;
font-size: 16px;
font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.preview-page-height {
  height: 69vh;
}

.execute-icon-style {
  font-size: 20px;
}

/* .module-auto-complete-style .MuiAutocomplete-endAdornment {} */

.no-step-exe {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.visibility-hiiden-style {
  visibility: hidden;
}

#footer-details {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 101px;
  right: 46px;
  margin-bottom: 27px;
}

#footer-suite {
  position: fixed;
  height: 32px;
  bottom: 0px;
  left: 122px;
  right: 33px;
  margin-bottom: 20px;
}

.footer-class {
  margin-top: 22vh;
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
}

.schedule-style-suiteofsuite .css-1lwbda4-MuiStack-root {
  margin-top: 16px;
  width: 151px;
  margin-left: 10px;
  margin-right: 47px;
}

.expire-style-suiteofsuite .css-1lwbda4-MuiStack-root {
  margin-top: 16px;
  width: 151px;
  margin-left: 10px;
  margin-right: 47px;
}

.display-flex-class {
  display: none;
}

.project-row:hover .display-flex-class {
  display: flex;
}
.suite-project-row {
  height: 61px;
}

.suite-project-row:hover {
  height: 61px;
}
.suite-project-row:hover .project-action {
  display: flex;
}

.dashboard-table-height {
  height: 62vh;
}
.suite-action {
  display: none;
}

.maximize-btn-style:hover {
  color: blue;
}

.modified {
  cursor: default;
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modifiedBy {
  cursor: default;
}

.radioBtn {
  margin-left: 12px;
}

.text-data-head-style {
  color: #1648c6;
  padding-top: 10px;
  border-bottom: 2px;
  border-bottom: 1px solid #1648c6;
}

.searchBtn {
  color: grey;
  height: 17.49px;
}

.quickStart {
  margin-bottom: 2%;
}

/* .qucBtn{
  margin-bottom: 3%;
} */
.actionBtn {
  float: left;
  margin-left: 12px;
}

.typeHead {
  float: left;
}

.dashboard-font-style {
  font-size: 0.75rem;
  line-height: 1rem;
}

.modal-dialog .modal-container .modal-body-1 {
  height: calc(100% - 110px);
  flex: 1 1;
  overflow-y: auto;
}

.modal-dialog .modal-container .modal-body-setup {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
}

.modal-dialog .modal-container .modal-body-setup-project {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
  height: 40vh;
}

.modal-dialog .modal-container .modal-body-setup-global {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
}

.modal-dialog .modal-container .modal-body-setupp {
  flex: 1 1;
  padding: 24px;
}

.modal-dialog .modal-container .schedule-popup-height {
  height: 65vh;
  padding: 12px;
  flex: 1 1;
}

.border-top-style {
  border-top: 2px solid #e5ecfc;
}

.input-filed-background-color {
  background-color: #fbfcfd;
}

.single-dotted-border {
  opacity: 1;
  margin-top: 30px;
  border-top: 2px dashed #e5ecfc;
}

.searchableDevicee {
  width: 110px !important;
  border: none !important;
}

.searchable-env {
  width: 50px !important;
  border: none !important;
}

.css-1izoxys-DropDown.searchable-env {
  width: 50px !important;
  border: none !important;
}

.modal-body-mult-machine {
  padding: 24px;
  flex: 1 1;
  height: calc(70% - 80px);
  height: 30vh;

  /* overflow-y: auto; */
}

.selected-single-machine-content-area-style-height {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  /* overflow-x: auto; */
  min-height: 15vh;
}

/* #footer-single-machine{
  position: fixed;
    height: 50px;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 153px;
} */
/* #footer-multiple-machine{
  position: fixed;
    height: 50px;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 153px;
} */

/* #footer-multiple-machine-max{
  max-height: fit-content;
  min-height: 130vh;

} */
.Access-table-font-style-common {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  width: 130px;
  height: 10px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.suite-table-font-style-common {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.machine-table-font-style-common {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 13px;

  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.suite-header-font {
  font-size: 12px;
}

.project-page-modal-size {
  width: 640px;
  height: 745px;
}

.max-popup-common-style {
  width: 1696px;
  height: 884px;
}

.var-set-max-popup-style {
  width: 135vh;
  height: 90vh;
}

.global-var-max-popup-style {
  width: 135vh;
}

.project-var-max-popup-style {
  width: 135vh;
  height: 85vh;
}

.border_style-project-pagination {
  border-top: 1px solid #e2e5ec;
  margin-top: 2vh !important;
  /* height: 60px; */
  /* position: absolute;
    bottom: 159px;
    width: 60.2vw;
    height: 10px; */
  position: fixed;
  height: 52px;
  bottom: 140px;
  left: 19.1%;
  right: 19.1%;
  margin-bottom: 7px;
}

.border_style-global-pagination {
  border-top: 1px solid #e2e5ec;
  margin-top: 5vh !important;
  position: absolute;
  bottom: 200px;
  width: 60.2vw;
  height: 29px;

  /* flex: 1 1;
  flex-basis: 0;
  overflow: auto;
  z-index: 0; */
  /* min-height: 45px; */
}

.table-font-style-common-table {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  margin-right: 100px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.machine-table-height {
  max-height: fit-content;
  min-height: 36vh;
}

/* .table-height {
  max-height: fit-content;
  min-height: 80vh;

} */

/* create-edit suit stepper css start */
/* t
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  transition: background-color 0.2s ease;
  }
  
  .arrow-steps12 .step12:after,
  .arrow-steps12 .step12:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 17px solid #EEEDED;	
  z-index: 2;
  transition: border-color 0.2s ease;
  }
  
  .arrow-steps12 .step12:before {
  right: auto;
  left: 0;
  border-left: 17px solid #FBFCFD;	
  z-index: 0;
  }

  .arrow-steps12 .step12.current {
      color: #1648C6;
      background-color: #E5ECFC;
  }
  
  .arrow-steps12 .step12.current:after {
      border-left: 17px solid #E5ECFC;	
  }

  .arrow-steps12 .step12:hover {
      color: #1648C6;
      background-color: #E5ECFC;
  }
  .arrow-steps12 .step12:hover::after {
      border-left: 17px solid #E5ECFC;	
  } */
/* create-edit suit stepper css end */

#footer-suite-height {
  position: fixed;
  height: 48px;
  bottom: 0px;
  left: 106px;
  right: 9px;
  /* margin-bottom: 10px;  */
}

.Popup-header-layout-style-userr {
  top: 373px;
  left: 656px;
  /* width: 114px; */
  height: 22px;
  font-size: 16px;
  font-family: OpenSans-SemiBold;
  color: #3c3838;
  padding-bottom: 40px;
}

.details-page-popup-height {
  height: 70vh;
}

.searchable-env .react-dropdown-select-input {
  /* width: 100px;  */
  margin-right: 3px;
  margin-right: 5px;
  /* margin-top: -5px; */
}

.searchableDevicee .react-dropdown-select-input {
  width: 90px;
  margin-top: -5px;
}

.searchable-env .react-dropdown-select-dropdown-handle {
  margin-bottom: -1px;
  margin-left: -69px;
}

.Pub-Pvt-Button-width {
  width: 109px;
}

.warning-status-exe {
  background: #d9a903;
  color: white;
}

.secondary-btn-disable {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #727171;
  padding: 4px 14px;
  border: 1px solid #525252;
  border-radius: 3px;
  font-family: OpenSans-Regular;
}

.disableColor {
  opacity: 0.5;
}

.alert-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  /* width: 99%; */
  margin-left: -104px;
}

.sos-details-modal-style {
  width: 716px;
  height: auto;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root .tab-font-style{
  font-size: 13px !important;
} */

/* .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style{
  font-size: 12px !important;
  font-family: 'OpenSans-SemiBold', "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: #1648C6;
    padding: 10px;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style{
  font-size: 12px !important;
  font-family: 'OpenSans-Regular', "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    padding: 10px;
} */

.css-1aquho2-MuiTabs-indicator {
  visibility: hidden;
}

.margin-top-10 {
  margin-top: 10px;
}

select.execution-specific-selectTag {
  border: 1px solid #cecaca;
  padding-top: 1px;
  padding-bottom: 2px;
  background-color: #fbfcfd;
}

.react-dropdown-select {
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  width: 100%;
  border-radius: 2px;
  padding: 2px 5px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  direction: ltr;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
}

.disabled_opacity {
  opacity: 0.5;
}

.execute-warning {
  border-top: 4px solid #fbbf24 !important;
}

.action-suite {
  width: 100px;
}
.no-machine-info-margin {
  margin-top: 145px;
}
.no-user-info {
  position: relative;
  top: 140px;
}
.qrs-details-overflow {
  overflow-y: auto;
  height: 360px;
}

.parallel_run_status_style_execution {
  margin-top: -8px;
  margin-right: 16px;
}
.col-min-max-width-32-per {
  min-width: 32%;
  max-width: 32%;
}

.testEnv-header-section {
  background: #434db7;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 45px;
}
.closeIconStyle {
  color: var(--primary-white);
  border-radius: 50%;
}
.closeIconStyle:hover {
  border: 2px solid var(--primary-white);
  background-color: unset;
}
.close:hover {
  background-color: unset !important;
}
.no_data_msg_layout {
  text-align: center;
  margin-top: 20px;
}

.FaStop-style {
  color: #ea0322;
}

.icon-blue-style {
  color: #1648c6;
}

.edit-style {
  margin-left: 13px;
}

.selected-state-style {
  background-color: #e5ecfc;
  background: var(--lm-left-top-nav-border-e5ecfc) 0% 0% no-repeat padding-box;
  background: #e5ecfc 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  color: #1648c6;
  pointer-events: none;
}

/* .create-suite-header-style {
  border-bottom: 1px solid #f0f3f9;
  height: 45px;
 
 
} */

.generate-height-width {
  height: 14px;
  width: 14px;
}

.create-suite-label-style {
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  /* font: normal normal bold 18px/24px Open Sans; */
  padding-left: 10px;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.clear-btn-style {
  top: 220px;
  left: 1719px;
  width: 66px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
}

.clear-btn-label-style {
  width: 34px;
  height: 19px;
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.save-btn-style {
  top: 220px;
  left: 1809px;
  width: 63px;
  height: 31px;
  border: 1px solid var(--primary-1648c6);
  background: #3a62c9 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
}

.save-btn-label-style {
  top: 226px;
  left: 1825px;
  width: 31px;
  height: 19px;
  color: var(--lm-workspace-light-fbfcfd);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fbfcfd;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.step-trigger {
  display: grid;
}

.circle-style {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  margin: 0.25rem;
  margin-left: 0.1rem;
  line-height: 1em;
  color: #727171;
  background-color: #f0f3f9;
  border-radius: 1em;
}

/* #Suite,
#Variable,
#TestData,
#RunSuiteIn,
#Email {
  height: auto;
}

.stepper-height {
  height: 60vh;
  overflow-y: auto;
} */

.line-stepper {
  width: 18% !important;
  border-top: 2px solid #e1dfdf;
  margin-left: -4px !important;
  margin-top: 21px;
}

.selected-line-stepper {
  width: 18% !important;
  border-top: 2px solid #1648c6;
  margin-left: -4px !important;
  margin-top: 21px;
}

.stepper-two-line {
  display: grid;
}

.stepper-variable-width {
  width: 18% !important;
}

.stepper-testData-width {
  width: 16% !important;
}

.stepper-runin-width {
  width: 15% !important;
}

.stepper-email-width {
  width: 13.5% !important;
}

.selected-stepper-color {
  color: #1648c6;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.stepper-style {
  color: #727171;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.refresh-icon-style {
  /* float: right; */
  margin-top: -25px;
  margin-left: 52%;
}

.stopper-label-layout {
  margin-left: 10%;
  margin-top: 6px;
}

.stopper-number-layout {
  margin-left: 10%;
}

.input-field-style {
  background-color: #fbfcfd;
}

/* .input-field-style-textarea {
  background-color: #fbfcfd;
  padding-top: 0;
  border-color: #dad5d5;
  width: 97%;
  max-height: 40px;
  height: 30px;
  line-height: 17px;
} */

/* Module Script */

/* .module-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 8px;
  margin-top: 12px;
  padding: 3px;
  height: 404px;
} */

.module-script-label-style {
  top: 532px;
  left: 176px;
  /* width: 133px; */
  height: 22px;
  color: var(--text-3c3838);
  text-align: left;
  /* font: normal normal 600 16px/22px Open Sans; */
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
}

.selet-module-style {
  top: 528px;
  left: 1589px;
  width: 187px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
}

.selet-module-style:hover {
  border: 1px solid #2b71f7;
}

.select-module-label-style {
  top: 534px;
  left: 1605px;
  width: 155px;
  height: 19px;
  color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: Poppins-Regular;
  font-size: 14px;
}

.select-module-label-style:hover {
  color: #2b71f7;
}

/* .module-popup-layout {
  top: 192px;
  left: 682px;
  width: 1145px;
  height: 66vh;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
} */
.select-mod-script-buttton-style {
  top: 781px;
  left: 908px;
  width: 187px;
  height: 31px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  color: #727171;
}

.select-mod-script-buttton-style:hover {
  border: 1px solid #2b71f7;
  color: #2b71f7;
}

.filters-header {
  top: 192px;
  left: 357px;
  width: 305px;
  height: 48px;
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.filter-box-style {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #fefeff 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 0px 0px 6px #00000029;
  margin-right: 8px;
}

.filter-label-style {
  top: 208px;
  left: 373px;
  width: 36px;
  height: 17px;
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin-left: 12px;
}

.empty-div-height {
  height: 6px;
}

.label-clearall-style {
  color: var(--information-2b71f7);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #2b71f7;
  text-transform: uppercase;
  opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin-right: 12px;
  margin-top: 6px;
}

.auto-search-field-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.max-popup-style-1 {
  width: 86%;
  height: 85vh;
  /* width: 1696px;
  height: 884px */
}

.module-search-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 12px;
}

.filter-header-style {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -12px;
  margin-right: -12px;
  height: 40px;
}

.module-search-name-style {
  /* color: var(--text-3c3838); */
  text-align: left;
  /* font: normal normal 600 12px/17px Open Sans; */
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 12px;
}

/* .max-popup-style {
  width: 90%;
  height: 902px;
} */

.variable-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 11px;
  /* padding: 21px; */
  /* height: 404px; */
}

.variable-type-header-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
  height: 63px;
  padding: 13px;
}

.variable-type-header-style-width {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-size: 16px;
  height: 52px;
  padding: 18px;
}

/* .create-set-layout {
 
  margin-left: 35%;
  margin-top: 30px;
  margin-bottom: 30px;
} */

/* .quick-start-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* .quick-start-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.set-table-header {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.set-table-header-label {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

/* .variable-set-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 100%;
  flex-direction: column;
  margin-top: 11px;
 
} */
/* .variable-set-content-area-stylee {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  
  flex-direction: column;
  margin-top: 11px;
  
  height: 56vh;
} */
.variable-set-content-area-global-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  /* display: flex; */
  flex-direction: column;
  margin-top: 11px;
  /* padding: 21px; */
  height: 45vh;
}

.test-data-set-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 54vh;
  /* display: flex; */
  flex-direction: column;
  margin-top: 11px;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* padding: 21px; */
}

/* .create-set-header-label {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  margin-top: 3px;
} */

/* .create-set-header {
 
  padding: 12px;
} */

.disabled-btn {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #727171;
  padding: 4px 14px;
  /* border: 1px solid #525252; */
  border-radius: 3px;

  border: 1px solid var(--disable-e1dfdf);
  border: 1px solid #e1dfdf;
  border-radius: 2px;
  opacity: 1;
}

.disabled-action {
  pointer-events: none;
}

.disabled-update-btn {
  background: #1648c6;
  border: 1px solid #1648c6;
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  color: #f6f7f9;
  padding: 4px 14px;
  font-size: 14px;
  opacity: 0.5;
  pointer-events: none;
}

.search-icon-maximize-style {
  font-size: 16px;
  margin-right: 9px;
  margin-top: 3px;
}

.search-icon-maximize-style:hover {
  color: #1648c6;
}

.input-field-display {
  display: grid;
}

/* .maximize-tree-size {
  height: 75vh;
} */

.test-data-parent-tree-height {
  min-height: 30vh;
  max-height: 60vh;
}

.tast-data-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 11px;
  /* padding: 21px; */
  /* height: 404px; */
}

.edit-tes-data-style {
  margin-top: 10px;
  /* margin-left: 144px; */
  width: 1632px;

  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.edit-tes-data-style-1 {
  margin-top: 80px;
  /* margin-left: 144px; */
  width: 86%;
  /* height: 85vh; */

  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.test-data-top-line-style {
  /* border: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc); */
  border-top: 2px dashed #e5ecfc;
  opacity: 1;
  margin-top: 18px;
  margin-bottom: 18px;
}

.test-data-checkbox-style {
  margin-left: -17px;
}

/* .recipient-email-id-style {
 
  width: 170%;
} */

.auto-complete-style {
  width: 50%;
  margin-top: -19px;
}

.email-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 404px; */
  margin-right: 6px;
}

.email-page-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* height: 404px; */
  margin-right: 6px;
  height: 56vh;
  display: flex;
  flex-direction: column;
}

/* .email-notification-header {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* .email-checkbox-value-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
} */

/* .email-footer-search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border: 0.5px solid #e5ecfc;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 0 0 5 0px;
  opacity: 1;
  width: 250px;
  margin-top: -1px;
  position: absolute;
  top: 245px;
  left: 3px;
  z-index: 15;
}

.search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.5px solid #e5ecfc;
  border-radius: 5px;
  opacity: 1;
  width: 250px;
  overflow-y: auto;
  height: 27vh;
  flex: 1 1;
  flex-basis: 0;            
  position: absolute;
  top:60px;
  left: 3px;
  z-index: 15;
        
} */

.emailGroup-class {
  position: relative;
}

.email-search-box-div-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
  background: #fbfcfd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0.5px solid #e5ecfc;
  opacity: 1;
  width: 0px;
}

.border-botton-style {
  border-bottom: 1px solid #e2e5ec;
}

.email-search-dropdown-btn-style {
  text-align: right;
  padding: 3px;
}

.multi-sel-btn-style {
  background: var(--lm-left-top-nav-border-e5ecfc) 0% 0% no-repeat padding-box;
  background: #e5ecfc 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}

.recipient-email-id-style .MuiPaper-elevation1 {
  box-shadow: none;
}

.recipient-email-id-style .MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
}

.recipient-email-id-style .MuiPaper-rounded {
  border-radius: 0px;
}

.recipient-email-id-style .MuiChip-root {
  height: 24px;
  background-color: #e5ecfc;
}

.recipient-email-id-style .MuiChip-root:hover {
  color: #1648c6;
  height: 24px;
  background-color: #e5ecfc;
}

.recipient-email-id-style .makeStyles-root-43 {
  padding: 0px;
}

.recipient-email-id-style .WAMuiChipInput-inputRoot-6 {
  margin-top: 12px;
}

.test-envi-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.run-settings-content-area {
  /* background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  opacity: 1; */

  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 56vh;
  display: flex;
  flex-direction: column;
}

.css-1ojkyje-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  background-clip: padding-box;
  outline: 0px !important;
}

.device-autocomplete .MuiChip-label {
  overflow: hidden;
  white-space: nowrap;
  /* padding-left: 12px; */
  padding-right: 12px;
  text-overflow: ellipsis;
  background-color: white;
}

.device-autocomplete .MuiChip-label {
  background-color: white;
}

.visibility-select-tag {
  opacity: 0;
  position: absolute;
}

/* .select-exe-type-label-style {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.radio-label-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.single-machine-table-height {
  /* height: fit-content; */
  flex: 1 1;
  flex-basis: 0;
  overflow: auto;
  z-index: 0;
  min-height: 15vh;
  height: 370px;
}

.selected-single-machine-content-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  overflow-x: auto;
  min-height: 62vh;
}

/* .implicit-wait-style .MuiFormControl-root {
  margin-left: 8px;
  width: 45%;
} */

.millisec-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

/* .implicit-explicit-label-style {
  color: var(--lm-normal-525252);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #727171;
  font-size: 12px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.dotted-border-style {
  border-top: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc);
  border-top: 2px dashed #e5ecfc;
  opacity: 1;
}

.time-dropdown .MuiSelect-select.MuiSelect-select {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.schedule-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: -8px;
  width: 90%;
}

.expire-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: 14px;
  width: 75%;
}

.maximize-tree-height-style {
  height: 70vh;
}

.maximize-tree-height-testdata-style {
  height: 63vh;
}

/* .set-details-value-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.set-details-value-style-description {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  overflow-wrap: anywhere;
}

.details-content-area {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}

.test-details-content-area {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  height: 49vh;
}

/* .test-data-table-height{
  overflow: hidden;
  height: 45vh;
} */
.details-content-area-height {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  height: 380px;
}

.priv-pub-font-style {
  font-size: 10px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

#footer-notfixed {
  /* height: 25px; */
  bottom: 0px;
  left: 5%;
  right: 2%;
}

.email-pagecountborder-set {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  height: 18px;
}

.pagecountborder-set-global {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 23vh;
  bottom: 145px;

  margin-bottom: 7px;
}

.pagecountborder-set-project-set-details {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 16vh;
  bottom: 25px;
  left: 19%;
  right: 19%;

  margin-bottom: 7px;
}

.pagecountborder-set-Mult-machines {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
  position: fixed;
  height: 7vh;
  bottom: 152px;
  left: 5%;
  right: 5%;
  margin-bottom: 7px;
}

.pagecountborder-set-maximize {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
}

#footer-testData-set-max {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 7%;
  right: 7%;
  margin-bottom: 51px;
}

#footer-singleMachine-set-max {
  position: fixed;
  height: 50px;
  bottom: 50px;
  left: 6%;
  right: 6%;
  margin-bottom: 51px;
}

/* suite of suite */
.suite-table-td-height {
  height: 48px;
}

.crete-pagination-style {
  bottom: 151px;
  position: fixed;
  right: 75px;
}

.suite-of-suite-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 8px;
  height: 60vh;
}

#footer-suite-of-suite {
  position: fixed;
  height: 32px;
  bottom: 108px;
  left: 53px;
  right: 53px;
}

#footer-suite-of-suite-main-tbl {
  position: fixed;
  height: 32px;
  bottom: 0px;
  left: 108px;
  right: 10px;
  margin-bottom: 20px;
}

.pagination-padding {
  border-top: 1px solid #dee0e6;
  opacity: 1;
  padding: 9px;
  margin-top: 13px;
  bottom: 14px;
  position: fixed;
  width: 87%;
}

.pagination-padding-suiteofsuite {
  border-top: 1px solid #dee0e6;
  opacity: 1;
  padding: 9px;
  margin-top: 13px;
  bottom: 14px;
  position: fixed;
  width: 90%;
}

.details-header-style {
  color: var(--text-3c3838);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 16px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.suite-of-suite-deails-area-style-popup {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 60vh;
}

#schedule-footer-sos-details-popup {
  margin-top: auto !important;
  position: fixed;
  height: 7vh;
  bottom: 121px;
  left: 30.4%;
  right: 30.4%;
}

.suite-of-suite-deails-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 68vh;
  margin: 12px;
}

.suite-of-suite-deails-area-style-instance {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #00000029; */
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 77vh;
}

#schedule-footer-suiteofsuite {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 100px;
  right: 11px;
}

.suite-of-suite-deails-area-style-child {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  /* margin: 8px;
  padding: 3px; */
  height: 58.2vh;
  margin: 18px;
}

#footer-details-suiteofsuite {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 101px;
  right: 13px;
}

.suite-of-suite-header-style {
  border-bottom: 1px solid #d7dae1;
  height: 42px;
}

.suite-header-style {
  align-self: center;
}

.suite-header-main-style {
  height: 52px;
}

.delete-icon-style .MuiSvgIcon-root {
  font-size: 18px;
}

.disabled-delete-style {
  color: #e1dfdf;
}

.delete-font-style {
  color: var(--lm-hover-0a0a0a);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #0a0a0a;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

/* .schedule-font-style{
  color: var(--lm-normal-525252);
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #727171;
font-size: 12px;
font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.schedule-time-font-style{
  color: var(--text-3c3838);
text-align: left;
font: normal normal normal 12px/17px Open Sans;
letter-spacing: 0px;
color: #3C3838;
font-size: 12px;
font-family: OpenSans-Semibold, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

/* DETAILS STARTS HERE */
/* .details-content-area-style {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 76vh;
} */

/* .details-main-header{
  height: 50px;
  border-bottom: 1px solid #d7dae1;
} */

.details-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.module-details-area-style {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin-top: 20px;
  /* padding: 4px; */
  /* height: 404px; */
}

.dotted-border {
  border: 1.5px dashed var(--lm-left-top-nav-border-e5ecfc);
  border: 2px dashed #e5ecfc;
  opacity: 1;
  margin-top: 30px;
}

/* .section-header-style{
  color: var(--text-3c3838);
text-align: left;
font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0px;
color: #3C3838;
opacity: 1;
font-size: 16px;
font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.preview-page-height {
  height: 69vh;
}

.execute-icon-style {
  font-size: 20px;
}

.module-auto-complete-style .MuiAutocomplete-endAdornment {
}

.no-step-exe {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.visibility-hiiden-style {
  visibility: hidden;
}

#footer-details {
  position: fixed;
  height: 50px;
  bottom: 0px;
  left: 101px;
  right: 46px;
  margin-bottom: 27px;
}

#footer-suite {
  position: fixed;
  height: 32px;
  bottom: 0px;
  left: 122px;
  right: 33px;
  margin-bottom: 20px;
}

.footer-class {
  margin-top: 22vh;
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
}

.schedule-style-suiteofsuite .css-1lwbda4-MuiStack-root {
  margin-top: 16px;
  width: 151px;
  margin-left: 10px;
  margin-right: 47px;
}

.expire-style-suiteofsuite .css-1lwbda4-MuiStack-root {
  margin-top: 16px;
  width: 151px;
  margin-left: 10px;
  margin-right: 47px;
}

.display-flex-class {
  display: none;
}

.project-row:hover .display-flex-class {
  display: flex;
}
.suite-project-row {
  height: 61px;
}

.suite-project-row:hover {
  height: 61px;
}
.suite-project-row:hover .project-action {
  display: flex;
}

.dashboard-table-height {
  height: 62vh;
}
.suite-action {
  display: none;
}

.maximize-btn-style:hover {
  color: blue;
}

.modifiedBy {
  cursor: default;
}

.radioBtn {
  margin-left: 12px;
}

.text-data-head-style {
  color: #1648c6;
  padding-top: 10px;
  border-bottom: 2px;
  border-bottom: 1px solid #1648c6;
}

.searchBtn {
  color: grey;
  height: 17.49px;
}

.quickStart {
  margin-bottom: 2%;
}

/* .qucBtn{
  margin-bottom: 3%;
} */
.actionBtn {
  float: left;
  margin-left: 12px;
}

.typeHead {
  float: left;
}

.dashboard-font-style {
  font-size: 0.75rem;
  line-height: 1rem;
}

.modal-dialog .modal-container .modal-body-1 {
  height: calc(100% - 110px);
  flex: 1 1;
  overflow-y: auto;
}

.modal-dialog .modal-container .modal-body-setup {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
}

.modal-dialog .modal-container .modal-body-setup-project {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
  height: 40vh;
}

.modal-dialog .modal-container .modal-body-setup-global {
  height: calc(100% - 110px);
  flex: 1 1;
  /* overflow-y: auto;  */
  padding: 24px;
}

.modal-dialog .modal-container .modal-body-setupp {
  flex: 1 1;
  padding: 24px;
}

.modal-dialog .modal-container .schedule-popup-height {
  height: 5vh;
  flex: 1 1;
}

.border-top-style {
  border-top: 2px solid #e5ecfc;
}

.input-filed-background-color {
  background-color: #fbfcfd;
}

.single-dotted-border {
  opacity: 1;
  margin-top: 30px;
  border-top: 2px dashed #e5ecfc;
}

.searchableDevicee {
  width: 110px !important;
  border: none !important;
}

.searchable-env {
  width: 50px !important;
  border: none !important;
}

.css-1izoxys-DropDown.searchable-env {
  width: 50px !important;
  border: none !important;
}

.modal-body-mult-machine {
  padding: 24px;
  flex: 1 1;
  height: calc(70% - 80px);
  height: 30vh;

  /* overflow-y: auto; */
}

.selected-single-machine-content-area-style-height {
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  /* overflow-x: auto; */
  min-height: 15vh;
}

/* #footer-single-machine{
  position: fixed;
    height: 50px;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 153px;
} */
/* #footer-multiple-machine{
  position: fixed;
    height: 50px;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 153px;
} */

/* #footer-multiple-machine-max{
  max-height: fit-content;
  min-height: 130vh;

} */
.Access-table-font-style-common {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  width: 130px;
  height: 10px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.suite-table-font-style-common {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.machine-table-font-style-common {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 13px;

  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.suite-header-font {
  font-size: 12px;
}

.project-page-modal-size {
  width: 640px;
  height: 745px;
}

.max-popup-common-style {
  width: 1696px;
  height: 884px;
}

.var-set-max-popup-style {
  width: 135vh;
  height: 90vh;
}

.global-var-max-popup-style {
  width: 135vh;
}

.project-var-max-popup-style {
  width: 135vh;
  height: 85vh;
}

.border_style-project-pagination {
  border-top: 1px solid #e2e5ec;
  margin-top: 2vh !important;
  /* height: 60px; */
  /* position: absolute;
    bottom: 159px;
    width: 60.2vw;
    height: 10px; */
  position: fixed;
  height: 52px;
  bottom: 140px;
  left: 19.1%;
  right: 19.1%;
  margin-bottom: 7px;
}

.border_style-global-pagination {
  border-top: 1px solid #e2e5ec;
  margin-top: 5vh !important;
  position: absolute;
  bottom: 200px;
  width: 60.2vw;
  height: 29px;

  /* flex: 1 1;
  flex-basis: 0;
  overflow: auto;
  z-index: 0; */
  /* min-height: 45px; */
}

.table-font-style-common-table {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
  margin-right: 100px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.machine-table-height {
  max-height: fit-content;
  min-height: 36vh;
}

/* .table-height {
  max-height: fit-content;
  min-height: 80vh;

} */

/* create-edit suit stepper css start */
/* t
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  transition: background-color 0.2s ease;
  }
  
  .arrow-steps12 .step12:after,
  .arrow-steps12 .step12:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 17px solid #EEEDED;	
  z-index: 2;
  transition: border-color 0.2s ease;
  }
  
  .arrow-steps12 .step12:before {
  right: auto;
  left: 0;
  border-left: 17px solid #FBFCFD;	
  z-index: 0;
  }

  .arrow-steps12 .step12.current {
      color: #1648C6;
      background-color: #E5ECFC;
  }
  
  .arrow-steps12 .step12.current:after {
      border-left: 17px solid #E5ECFC;	
  }

  .arrow-steps12 .step12:hover {
      color: #1648C6;
      background-color: #E5ECFC;
  }
  .arrow-steps12 .step12:hover::after {
      border-left: 17px solid #E5ECFC;	
  } */
/* create-edit suit stepper css end */

#footer-suite-height {
  position: fixed;
  height: 48px;
  bottom: 0px;
  left: 106px;
  right: 9px;
  /* margin-bottom: 10px;  */
}

.Popup-header-layout-style-userr {
  top: 373px;
  left: 656px;
  /* width: 114px; */
  height: 22px;
  font-size: 16px;
  font-family: OpenSans-SemiBold;
  color: #3c3838;
  padding-bottom: 40px;
}

.details-page-popup-height {
  height: 70vh;
}

.searchable-env .react-dropdown-select-input {
  /* width: 100px;  */
  margin-right: 3px;
  margin-right: 5px;
  /* margin-top: -5px; */
}

.searchableDevicee .react-dropdown-select-input {
  width: 90px;
  margin-top: -5px;
}

.searchable-env .react-dropdown-select-dropdown-handle {
  margin-bottom: -1px;
  margin-left: -69px;
}

.Pub-Pvt-Button-width {
  width: 109px;
}

.warning-status-exe {
  background: #d9a903;
  color: white;
}

.secondary-btn-disable {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #727171;
  padding: 4px 14px;
  border: 1px solid #525252;
  border-radius: 3px;
  font-family: OpenSans-Regular;
}

.disableColor {
  opacity: 0.5;
}

.alert-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  /* width: 99%; */
  margin-left: -104px;
}

.sos-details-modal-style {
  width: 716px;
  height: auto;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root .tab-font-style{
  font-size: 13px !important;
} */

/* .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style{
  font-size: 12px !important;
  font-family: 'OpenSans-SemiBold', "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: #1648C6;
    padding: 10px;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style{
  font-size: 12px !important;
  font-family: 'OpenSans-Regular', "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    padding: 10px;
} */

.css-1aquho2-MuiTabs-indicator {
  visibility: hidden;
}

.margin-top-10 {
  margin-top: 10px;
}

select.execution-specific-selectTag {
  border: 1px solid #cecaca;
  padding-top: 1px;
  padding-bottom: 2px;
  background-color: #fbfcfd;
}

.react-dropdown-select {
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  width: 100%;
  border-radius: 2px;
  padding: 2px 5px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  direction: ltr;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
}

.disabled_opacity {
  opacity: 0.5;
}

.execute-warning {
  border-top: 4px solid #fbbf24 !important;
}

.action-suite {
  width: 100px;
}
.no-machine-info-margin {
  margin-top: 145px;
}
.no-user-info {
  position: relative;
  top: 140px;
}
.qrs-details-overflow {
  overflow-y: auto;
  height: 360px;
}

.parallel_run_status_style_execution {
  margin-top: -8px;
  margin-right: 16px;
}
.col-min-max-width-32-per {
  min-width: 32%;
  max-width: 32%;
}

.testEnv-header-section {
  background: #434db7;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#date-picker-inline,
#date-picker-inline-expiredate{
  width:126px;
}