@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.stepStatus-result {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
}

.step-columns {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
}

.column-value {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.tab-border {
  border: 0.5px solid $white_dark;
  opacity: 1;
}

.close-button {
  /* font-size: 14px;*/
  font-weight: 600 !important;
  width: 15px !important;
  height: 15px !important;
  letter-spacing: 0px;
  color: $grey_dark;
}

.table-header {
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  opacity: 1;
  color: $grey_darkest;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  line-height: 2rem;
}

.step-result-modal .table-body {
  font-size: 14px;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
  text-align: left;
  line-height: 2rem;
}

.info-tab {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $blue_dark;
}

.selected-tab {
  @extend .fontPoppinsSemiboldMd;
  // font-size: 14px;
  // font-weight: 600;
  /* text-decoration: underline; */
}

.unselected-tab {
  @extend .fontPoppinsRegularMd;
  // font-size: 14px;
}

.table-scroll-height {
  max-height: 19vh;
}

.not-used-data {
  height: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-style {
  height: 13rem;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* width: 18rem; */
}

.download-icon {
  color: $blue_dark;
  height: 16px;
  width: 16px;
}

.variables-count {
  background: $white_dark 0% 0% no-repeat padding-box;
}

.passed-elements {
  background: $green 0% 0% no-repeat padding-box;
}

.failed-elements {
  background: $red 0% 0% no-repeat padding-box;
}

.skipped-elements {
  background: $grey_darkest 0% 0% no-repeat padding-box;
}

.warned-elements {
  background: $yellow 0% 0% no-repeat padding-box;
}

.highlight-border {
  border-bottom: 2px solid $blue_dark;
  outline: none;
}

.elements-stat {
  color: $white_light;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.collapse {
  display: none;
  font-size: 22px;
  color: $grey_darkest;
}

.expand {
  display: inline-block;
  color: $grey_darkest;
  font-size: 22px;
}

.wrap-text {
  /* width: 20rem; */
  word-break: break-word;
  cursor: pointer;
}
.step-modal-size {
  width: 1598px;
  /* height: 631px; */
  width: calc(100vw - 200px);
  top: 196px;
  left: 161px;
  height: 679px;
}
.wrapToolTip {
  max-width: 300 !important;
}

#wrapId {
  display: block;
  white-space: nowrap !important;
  /* height: 2.7em; Fallback for non-webkit, line-height * 2 */
  /* line-height: 1.3em; */
  -webkit-line-clamp: 3; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#errorWrapId {
  display: block;
  display: -webkit-box;
  /* height: 2.7em; Fallback for non-webkit, line-height * 2 */
  /* line-height: 1.3em; */
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-value-errors {
  /* width: 298%; */
  margin-bottom: 10px;
}
.content-error {
  border-top: $skyblue_dark;
  border-left: $skyblue_dark;
  border-right: $skyblue_dark;
}
.myElements {
  margin-top: 140px;
}

.ReactModal__Content {
  height: 100px;
}
.subtab-template {
  border: solid $white_dark 1px;
  background-color: $white_original;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.ace_gutter-cell {
  color: $blue_dark;
}

.search_text {
  display: flex;
  justify-content: space-between;
}

.has-text-left {
  text-align: left;
}

.table-Value-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  float: left;
}

.column-value-err {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.mrleft {
  margin-left: -20%;
}

.flex-wrapper {
  display: flex;
}
.step-alert-position-style {
  width: fit-content;
  align-self: center;
  z-index: 10000;
  position: absolute;
  left:0;
  right: 0;
  margin: auto;
}
.step-results-download-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px 40px 2px 0;
}
