.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}
/* ------------------- */
.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 14px);
}
.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  /* padding-left: 0; */
  margin-top: 16px;
}
/* ---------------------------- */
.tree ul li {
  border-left: 2px solid #6e6e6e;
}
.tree ul li:last-child {
  border-color: transparent;
}
/* -------------------------------- */
.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + -11px);
  height: calc(var(--spacing) + -1px);
  border: solid #6e6e6e;
  border-width: 0 0 2px 2px;
}
/* ------------------ */
.tree summary {
  cursor: pointer;
  margin-left: -35px;
}
.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: block;
}
.tree summary:focus {
  outline: none;
}
.tree summary:focus-visible {
  outline: 1px dotted #6e6e6e;
}
.tree details > summary {
  list-style-type: disc;
}
.tree details[open] > summary {
  list-style-type: disc;
}
.disabled[disabled] summary,
details.disabled summary {
  pointer-events: none;
}
