.breadcrumb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
}
.breadcrumb-active {
  font-size: 12px;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
}
.configPageHeading {
  color: black;
  font-weight: 600;
  margin-left: 30px;
}
.icon {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}
.icon .search {
  margin-right: 1rem;
  color: grey;
}
.tableSection {
  display: flex;
}
.tableSection .childTable {
  font-weight: 600;
  padding: 0.7rem;
  margin-left: 2rem;
}
.tableHead {
  margin-left: 50px;
}
.editIcon {
  visibility: hidden;
}
.visibleData:hover .editIcon {
  visibility: visible;
}
.boxHead {
  background: rgb(240, 243, 249);
  text-align: left;
  height: 40px;
}
.dragInd {
  color: blue;
  height: 14px;
}
.Tdata {
  font-size: 14px;
  color: grey;
}
.verticalBar {
  border-left: 2px dashed #f0f3f9;
  margin-left: 11.5rem;
  height: 18.5rem;
  position: absolute;
}
.searchIcon {
  margin-left: 40rem;
  color: grey;
  margin-top: -2.5rem;
}
.grayText {
  color: gray;
}
.ml11 {
  margin-left: 11.3rem;
}
.ml13 {
  margin-left: 13rem;
}
.ml9 {
  margin-left: 9.5rem;
}

.headData {
  cursor: pointer;
  width: 100px;
  height: 19px;
  margin-left: -1rem;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  font-weight: 600;
  font-style: normal;
  opacity: 1;
  border-bottom: 1px solid #1648c6;
  font-family: 'Poppins-SemiBold';
  padding-right: 2px;
}
.headDataHistory {
  color: blue;
  cursor: pointer;
  width: 95px;
  height: 19px;
  margin-left: -1rem;
  font-size: 15px;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  font-weight: 500;
  font-style: normal;
  opacity: 1;
  /* font-family: "OpenSans" !important;    */
  padding-right: 2px;
  margin-top: -3px;
}
.historyCon {
  left: 252px;
  width: 49px;
  height: 19px;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  font-family: 'OpenSans-SemiBold' !important;
  border-bottom: 1px solid #1648c6;
  font-size: 15px;
  padding-bottom: 23px;
}
.createdByData {
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;

  font-style: normal;
  font-weight: 600;
}
