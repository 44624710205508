@import './css_config/variable.scss';
@import '@styles/rs_fonts.scss';

$primary-color: #1648c6;

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.not-found-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: fit-content;
  margin: auto;
  .not-found-msg {
    text-align: center;
    font-size: 12px;
    color: #3c3838;
    .primary_btn {
      margin-top: 10px;
    }
  }
}

.confirm-dialog {
  .MuiDialogTitle-root {
    border-bottom: 2px solid #f0f3f9;
    padding-bottom: 5px;
    margin-bottom: 12px;
    border-top: 4px solid #efbb07;
    .MuiTypography-h6 {
      font-size: inherit;
      .MuiSvgIcon-root {
        float: left;
        color: #efbb07;
        font-size: 30px;
        margin-right: 12px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        color: #0a0a0a;
        margin: 0;
      }
      .sub-title {
        font-size: 14px;
        color: #727171;
      }
    }
  }
  .MuiDialogContent-root {
    p {
      font-size: 12px;
      color: #0a0a0a;
      margin-bottom: 14px;
    }
  }
  .MuiDialogActions-root {
    border-top: 2px solid #f0f3f9;
  }
}

.toast-wrapper {
  display: flex;
  flex-direction: row;
  background-color: #9897970d;
  height: 48px;
  margin-bottom: 10px;
  align-items: center;
  gap: 15px;
  border-left: 4px solid #79b62f;
  padding: 0 14px;
  .icon {
    color: #79b62f;
  }
  .toast-type {
    font-weight: 600;
    color: #0a0a0a;
  }
  .toast-msg {
    font-size: 14px;
    color: #0a0a0a;
    margin: 0;
  }
  .gray_btn {
    margin-left: auto;
  }
  &.warning {
    border-left-color: #efbb07;
    .icon {
      color: #efbb07;
    }
  }
  &.error {
    border-left-color: #b62f2f;
    .icon {
      color: #b62f2f;
    }
  }
  &.info {
    border-left-color: #2b71f7;
    .icon {
      color: #2b71f7;
    }
  }
}

.header-project-selection-dropdown {
  .project-selection-menu {
    width: 180px;
    max-width: 180px;
    .menu-btn {
      position: relative;
      padding-right: 30px;
      .menu-btn-title {
        overflow-x: hidden;
        width: 173px;
        text-align: left;
        color: $blue_dark;
      }
      .down-arrow-icon {
        position: absolute;
        right: 0;
        top: 9px;
        color: rgb(77, 75, 75);
      }
    }
  }
}
.project-dropdown-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

.tab-style-common {
  color: var(--primary-1648c6);
  text-align: left;
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
  @extend .fontPoppinsRegularMd;
}

.no_overflow {
  overflow: hidden;
}

.main-table-height {
  height: 70vh;
  @media (max-width: $lg) {
    height: 65vh;
  }
}

.primary-table-height {
  @extend .main-table-height;
  table thead tr th {
    min-width: 140px;
  }
}

.modal-table-height {
  max-height: 250px;
}

.input-filed-label-style-common {
  // @extend .fontPoppinsMediumSm; For v2.1
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-label-header;
}
.ff-add-btn {
  border-radius: 5px 20px 20px 5px;
  background-color: $tr-bottom-color;
  height: 24px;
  padding: 0 16px 0 0;
  color: $ff-primary;
}

.project-details-content-area {
  height: calc(100vh - 89px);
}

.table-content-area {
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
