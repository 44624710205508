@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatbotopener-container {
  @include center;
  position: fixed;
  right: -75px;
  bottom: 50px;
  cursor: pointer;
  transition: right 0.5s;
  z-index: 2;
  &:hover:not(.prevent-hover) {
    right: 0;
  }
}
.chatbot-main-div {
  display: none;
  transition: all 0.5s ease-in-out;
}

.chatbot-text {
  @extend .fontPoppinsSemiboldMd;
  cursor: pointer;
  color: $grey_darkest;
  width: 72px;
  height: 22px;
  margin-top: 5px;
  margin-left: 10px;
}

.chatbot-opener-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 122px;
  height: 42px;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
}
.chatbot-opener-div {
  display: flex;
  background-color: $white_original;
  width: 122px;
  height: 42px;
  box-shadow: 0 0 24px -8px $shodow_light_black;
  border-radius: 8px 0px 0px 8px;
  z-index: 1;
}

.right-arrow-icon {
  @include center;
  position: absolute;
  height: 16px;
  width: 16px;
  top: -6px;
  right: 113px;
  outline: 7px solid transparent;
  border-radius: 50%;
  background: $ff-light-slate-blue;
  cursor: pointer;
  z-index: 3;
  &:hover {
    .chatbotopener-container {
      pointer-events: none;
    }
  }
}

.hide-chatbotopener {
  @include center;
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 2;
  right: 0px;
  width: 14px;
  height: 42px;
  background: $ff-primary;
  border-radius: 8px 0px 0px 8px;
  transition-duration: 0.5s;
}
