@import './variable.scss';
@import '@styles/rs_fonts.scss';
.no-steps-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 78px;

  .qucBtn {
    margin-bottom: 15px;

    @media (min-width: $xl) {
      margin-bottom: 20px;
    }
  }

  .quick-start-style {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;

    @media (min-width: $xl) {
      font-size: 12px;
    }
  }

  .quick-start-label-style {
    @extend .fontPoppinsMediumMd;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;

    @media (min-width: $xl) {
      font-size: 12px;
    }
  }
}

.search_btn_style {
  width: 35px;
  height: 17px;
  opacity: 1;
  margin-top: 8px;
}

// suite table
.table-height-suite {
  width: 100%;
  height: 74vh;
  overflow-y: auto;
}

label.hidetest {
  @extend .fontPoppinsRegularMd;
  position: absolute;
  right: 8%;
  margin-top: 3px;
}

.suite_specific {
  .container12 {
    font-family: 'Lato', sans-serif;
    width: 89%;
    margin: 10px 5%;
    background-color: transparent;
    height: fit-content;

    .arrow-steps12 .step12 {
      font-size: 14px;
      text-align: start;
      color: $grey_dark;
      cursor: default;
      margin: 0 12px;
      padding: 10px 10px 10px 30px;
      min-width: 18%;
      float: left;
      position: relative;
      background-color: $white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: background-color 0.2s ease;

      @media (min-width: $xl) {
        min-width: 18%;
      }
    }

    .arrow-steps12 .step12:after,
    .arrow-steps12 .step12:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 22px solid transparent;
      border-left: 17px solid $white;
      z-index: 2;
      transition: border-color 0.2s ease;
    }

    .arrow-steps12 .step12:before {
      right: auto;
      left: 0;
      border-left: 17px solid $white_light;
      z-index: 0;
    }

    .arrow-steps12 .step12.current {
      color: $blue_dark;
      background-color: $white_dark;
    }

    .arrow-steps12 .step12.current:after {
      border-left: 17px solid $white_dark;
    }

    .arrow-steps12 .step12:hover {
      color: $blue_dark;
      background-color: $white_dark;
    }

    .arrow-steps12 .step12:hover::after {
      border-left: 17px solid $white_dark;
    }
  }

  #Suite,
  #Variable,
  #TestData,
  #RunSuiteIn,
  #Email {
    height: auto;
    margin-top: 25px;
  }

  .suite-name-input-background {
    background-color: $white_original;
    padding-left: 0px;
  }

  input[type='text']:focus {
    border-bottom: 2px solid var(--lightGray);
  }

  .input-field-style-textarea {
    background-color: $white_original;
    padding-top: 0;
    padding-left: 0;
    border-color: $light_grey_white;
    width: 97%;
    max-height: 40px;
    height: 30px;
    line-height: 17px;
    border-bottom: 1px solid;
  }

  .input-style-textarea {
    @extend .fontPoppinsRegularSm;
    color: var(--lm-normal-525252);
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    padding-bottom: 0;
    overflow: hidden;
    min-height: 30px !important;
    padding-top: 8px;
  }

  .input-style-textarea:focus {
    @extend .fontPoppinsRegularSm;
    border: none;
    border-bottom: 2px solid $blue_dark !important;
    box-shadow: none;
    outline: none !important;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    padding-bottom: 0;
  }
  input[type='text'] {
    @extend .fontPoppinsRegularMd;
    border: none;
    border-bottom: 1px solid $black_original !important;
    box-shadow: none;
    outline: none !important;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    padding-bottom: 0;
    &:focus {
      border-bottom: 2px solid $blue_dark !important ;
    }
  }

  .create-suite-header-style {
    border-bottom: 1px solid $skyblue_dark;
  }

  .module-area-style {
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px $shadow_black;
    opacity: 1;
    margin-top: 12px;
    max-height: 56vh;

    .table_height {
      max-height: 305px;

      @media (min-width: $xl) {
        max-height: 29vh;
      }
    }

    .table_tree_height {
      height: 49vh;

      @media (min-width: $xl) {
        height: 31vh;
      }
    }

    .pagecountborder-set {
      height: 52px;
      border-top: 2px solid $white_dark;
      padding-top: 4px;
      bottom: 0px;
      // position: fixed;
      left: 106px;
      right: 9px;

      // width: 1401px;
      .pagecount {
        color: $current_page_num_box_color;
      }

      .pageborderleft {
        border-left: 2px solid $current_page_num_box_color;

        .pageArrow {
          font-weight: 800;
          font-size: 20px;
          color: $grey_white;
          cursor: auto;
        }

        .currentpagecount {
          border-radius: 1px;
          opacity: 1;
          width: 20px;
          height: 20px;
          background-color: $current_page_num_box_color;
          border-radius: 1px;
          color: $current_page_count_color;
        }
      }

      @media (min-width: $xl) {
        // above 1280
        bottom: 3px;
      }
    }

    @media (min-width: $xl) {
      height: 404px;
    }
  }

  //   project and variable section

  // email section

  .div.WAMuiChipInput-standard-21.WAMuiChipInput-chipContainer-19.WAMuiChipInput-underline-29 {
    margin-bottom: -8px;
  }

  div.WAMuiChipInput-standard-21.WAMuiChipInput-chipContainer-19.WAMuiChipInput-underline-29.WAMuiChipInput-disabled-28 {
    margin-bottom: -8px;
  }

  div.WAMuiChipInput-standard-22.WAMuiChipInput-chipContainer-20.WAMuiChipInput-underline-30.WAMuiChipInput-disabled-29 {
    margin-bottom: -8px;
  }

  .WAMuiChipInput-chipContainer-20 {
    margin-bottom: -8px;
  }

  .WAMuiChipInput-chipContainer-19 {
    margin-bottom: -8px;
  }

  .recipient-email-id-style {
    width: 180%;
  }

  .WAMuiChipInput-chipContainer-50 {
    min-height: 0px;
    margin-bottom: 0px;
    margin-top: 7px;
  }

  .emailGroupStyle {
    width: 250px;
  }

  input.MuiInputBase-input.MuiInput-input {
    border-bottom: none;
  }

  //   run settings section
  .device-section {
    .radio-button-label-color {
      text-align: left;
      letter-spacing: 0px;
      color: $rs_analytics_blue_header;
      opacity: 1;
    }

    .select-exe-type-label-style {
      text-align: left;
      letter-spacing: 0px;
      color: $grey;
      opacity: 1;
      font-size: 14px;
      font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji';
    }
  }
}

//project variable modal class

.variable-set-content-area-style {
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  flex-direction: column;
  height: 49vh;
  overflow-y: auto;

  .create-set-header {
    padding: 8px 13px;
    height: 48px;

    .create-set-header-label {
      @extend .fontPoppinsSemiboldMd;
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      opacity: 1;
      margin-top: 8px;

      @media (min-width: $xl) {
        font-size: 14px;
      }
    }

    .searchBtn {
      margin-top: 6px;
    }

    @media (min-width: $xl) {
      padding: 14px;
      height: 64px;
    }
  }

  .scroll-height {
    height: 47vh;

    @media (min-width: $xl) {
      height: 36vh;
    }
  }

  .scroll-height-setModal {
    height: 41vh;
    @media (min-width: $xl) {
      height: 36vh;
    }
  }

  .test-data-tree-height {
    height: 49vh;

    @media (min-width: $xl) {
      height: 36vh;
    }
  }

  .details-table-header {
    @extend .fontPoppinsSemiboldSm;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;

    @media (min-width: $xl) {
      font-size: 12px;
    }
  }

  .details-table-body {
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    opacity: 1;
    font-size: 14px;
    font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji';

    input.input-filed-background-color {
      background-color: $white_light;
      width: 100%;
    }

    @media (min-width: $xl) {
      font-size: 14px;
    }
  }

  @media (min-width: $xl) {
    height: 457px;
  }
}
.variable-set-create-content-area-style {
  @extend .variable-set-content-area-style;
  overflow: hidden;
}
.variable-set-edit-content-area-style {
  @extend .variable-set-content-area-style;
  height: 100%;
  overflow: hidden;
  .edit-table-height {
    height: 52vh;
    overflow-y: auto;
  }

  .pagecountborder-modal-set {
    border-top: 2px solid $white_dark;
    padding-top: 9px;
  }

  @media (min-width: $xl) {
    height: 43vh;
  }
}

.variable-set-details-content-area-style {
  @extend .variable-set-content-area-style;
  height: 40vh;

  .table_height {
    height: 32vh;
  }

  .pagecountborder-set-details {
    border-top: 2px solid $white_dark;
    padding-top: 4px;
    position: fixed;
    height: 52px;
    bottom: 98px;
    left: 19.2%;
    right: 19.2%;
    margin-bottom: 7px;

    @media (min-width: $xl) {
      bottom: 198px;
      left: 13.2%;
      right: 13%;
    }
  }
}

.max-popup-style {
  height: 884px;
  width: 90%;

  .maximize-tree-size {
    height: 75vh;
  }

  .pagecountborder-maximize {
    border-top: 2px solid $white_dark;
    padding-top: 4px;
    height: 52px;
    position: fixed;
    bottom: 0px;
    left: 5%;
    right: 5%;
    margin-bottom: 47px;

    @media (min-width: $xl) {
      bottom: 0px;
      left: 6%;
      right: 6%;
      margin-bottom: 93px;
    }
  }

  @media (min-width: $xl) {
    width: 1696px;
    height: 884px;
  }
}

// Test data modal class

.testData-modal-style {
  // height:884px;
  width: 90%;
  height: 85vh;
  .test-data-set-content-area-style {
    @extend .variable-set-content-area-style;
    height: 51vh;

    .test-data-tree-height {
      // overflow: hidden;
      height: 43vh;

      @media (min-width: $xl) {
        height: 51vh;
      }
    }

    @media (min-width: $xl) {
      width: 1696px;
      height: 400px;
    }
  }

  .alert-testData-modal-position-style {
    width: fit-content;
    align-self: center;
    position: absolute;
    z-index: 10000;
    right: 0;
    left: 0;
    margin: 0 auto;
    margin-top: -22px;
  }

  .edit-test-data-content-area-style {
    @extend .variable-set-content-area-style;
    height: 63vh;

    .test-data-tree-height {
      height: 55vh;
    }

    @media (min-width: $xl) {
      width: 1663px;
      height: 733px;
    }
  }

  .test-data-details-content-area-style {
    @extend .variable-set-content-area-style;
    height: 44vh;
    overflow: hidden;

    .test-data-tree-height {
      // overflow: hidden;
      height: 39vh;

      @media (min-width: $xl) {
        height: 40vh;
      }
    }

    @media (min-width: $xl) {
      width: 1663px;
      height: 590px;
    }
  }
}

.device-modal-style {
  min-width: 90% !important;

  .run-settings-content-area {
    @extend .variable-set-content-area-style;
    height: 60vh;

    .create-set-header {
      padding: 10px 13px;
      height: 48px;
    }

    .pagecountborder-machines {
      border-top: 2px solid $white_dark;
      padding-top: 2px;
      position: fixed;
      height: 52px;
      bottom: 136px;
      left: 6.8%;
      right: 6.4%;

      @media (min-width: $xl) {
        bottom: 219px;
        left: 6.3%;
        right: 6.5%;
      }
    }

    @media (min-width: $xl) {
    }
  }

  .user-machine-content-area {
    @extend .variable-set-content-area-style;
    height: 61vh;

    @media (min-width: $xl) {
      height: 61vh;
    }
  }

  @media (min-width: $xl) {
    width: 1723px;
    height: 829px;
  }
}

.email-style {
  .email-checkbox-value-style {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    opacity: 1;
  }

  .email-notification-header {
    @extend .fontPoppinsSemiboldMd;
    text-align: left;
    letter-spacing: 0px;
    color: $grey;
    opacity: 1;
  }

  .email-footer-search-box-div-style {
    background: $white_light 0% 0% no-repeat padding-box;
    border: 0.5px solid $white_dark;
    box-shadow: 0px 0px 4px $shadow_black;
    border-radius: 0 0 5 0px;
    opacity: 1;
    width: 250px;
    margin-top: -1px;
    position: absolute;
    top: 245px;
    left: 3px;
    z-index: 15;
  }

  .search-box-div-style {
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px $shadow_black;
    border: 0.5px solid $white_dark;
    border-radius: 5px;
    opacity: 1;
    width: 250px;
    overflow-y: auto;
    height: 27vh;
    flex: 1 1;
    flex-basis: 0;
    position: absolute;
    top: 60px;
    left: 3px;
    z-index: 15;

    @media (min-width: $xl) {
      width: 253px;
      height: 223px;
    }
  }
}

.content_area_style {
  .details-main-header {
    height: 52px;
    padding: 14px;
    border-bottom: 1px solid $whitish_blue;

    .page-header-common {
      @extend .fontPoppinsSemiboldXlg;
      text-align: left;
      letter-spacing: 0px;
      color: $black;
      opacity: 1;
    }

    @media (min-width: $xl) {
      height: 64px;
      padding: 18px;
    }
  }

  .details-body {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 74vh;
    overflow-y: auto;

    .details-label-style {
      @extend .fontPoppinsMediumLg;
      text-align: left;
      letter-spacing: 0px;
      color: $grey;
      opacity: 1;

      @media (min-width: $xl) {
        font-size: 12px;
      }
    }

    .details-value-style {
      @extend .fontPoppinsRegularMd;
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      opacity: 1;

      @media (min-width: $xl) {
        font-size: 12px;
      }
    }

    .details-content-area-style {
      background: $white_light 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px $shadow_black;
      opacity: 1;
      margin-top: 20px;

      // min-height: 400px;
      .table_height {
        max-height: 309px;
      }

      .details_content_area_header_style {
        height: 48px;
        padding: 12px;

        .details-header-style {
          @extend .fontPoppinsSemiboldLg;
          text-align: left;
          letter-spacing: 0px;
          color: $grey_darkest;
          opacity: 1;

          @media (min-width: $xl) {
            font-size: 16px;
          }
        }

        .searchBtn {
          height: 22.49px;
        }

        @media (min-width: $xl) {
          height: 63px;
          padding: 18px;
        }
      }

      .section-header-style {
        @extend .fontPoppinsSemiboldLg;
        text-align: left;
        letter-spacing: 0px;
        color: $grey_darkest;
        opacity: 1;

        @media (min-width: $xl) {
          font-size: 16px;
        }
      }

      .tree_height {
        min-height: 39vh;
      }
    }

    .single-machine-details-content-area-style {
      @extend .details-content-area-style;
      min-height: 260px;

      .tree_height {
        min-height: 10vh;
      }
    }

    @media (min-width: $xl) {
      height: 76vh;
    }
  }

  .table-height-executionDashboard {
    width: 100%;
    height: 75vh;
  }
}

// common

.select-tag-style select {
  margin-left: 11px;
  font-size: 12px;
  padding-bottom: 0px;
  background-color: $white_light;
  color: #525252;
}

.implicit-explicit-label-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
}
.implicit-explicit-dropdown-style {
  display: flex;
  align-items: center;
}

.capture-screenshot-label-style {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
}

.set-details-value-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.implicit-wait-style .MuiFormControl-root {
  margin-left: 8px;

  input[type='number'] {
    width: 240px;
    border-bottom: 1px solid $white_light;
  }
}

.schedule-font-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
}

.schedule-time-font-style {
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  font-size: 12px;
  font-family: OpenSans-Semibold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.expire-style {
  width: 82%;
}

.expire-style .MuiFormControl-root.MuiTextField-root {
  margin-top: 15px;
}
.expire-style .MuiFormHelperText-root.Mui-error {
  @extend .fontPoppinsRegularXs;
}

.schedule-style {
  width: 86%;
}

.schedule-style .MuiFormControl-root.MuiTextField-root {
  margin-top: 15px;
}

.re-schedule-style .MuiFormControl-root.MuiTextField-root {
  display: flex;
}

.schedule-style .MuiFormHelperText-root.Mui-error {
  @extend .fontPoppinsRegularXs;
}

.schedule-name-style {
  width: 75%;
}

.schedule-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: -8px;
  width: 92%;
}

.expire-style .MuiFormControl-marginNormal {
  margin-left: -15px;
  margin-right: 14px;
  width: 92%;
}

.repeat-field-width {
  width: 78%;
}

.timezone-height {
  max-height: 153px;
}

.MuiFormControl-root.MuiTextField-root.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style {
  @extend .fontPoppinsSemiboldSm;
  color: $blue_dark;
  padding: 10px;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 2px solid $blue_dark;
}

[class*='-MuiButtonBase-root-MuiTab-root.Mui-selected'] {
  @extend .fontPoppinsSemiboldSm;
  color: $blue_dark;
  padding: 10px;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 2px solid $blue_dark;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected[class*='-MuiButtonBase-root-MuiTab-root'] {
  @extend .fontPoppinsSemiboldSm;
  color: $blue_dark;
  padding: 10px;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 2px solid $blue_dark;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.tab-font-style {
  @extend .fontPoppinsRegularSm;
  padding: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
}

.common-var-max-popup-style {
  width: 640px;

  input[type='text'] :hover {
    border-bottom: 5px solid var(--lightGray);
  }

  @media (min-width: $xl) {
    width: 640px;
  }
}

// .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
//     margin: 12px 0;
// }
// .css-15v22id-MuiAccordionDetails-root {
//     padding: 10px 0px;
// }

// [class*="-MuiAccordionSummary-content"].Mui-expanded{
//   margin: 12px 0;
// }

// [class*="-MuiAccordionDetails-root"]{
//   padding: 10px 0px;
// }

.accordian-style {
  height: 76vh;
  overflow-y: auto;
  margin: 10px 0;

  .MuiAccordionSummary-gutters.MuiAccordionSummary-root.MuiButtonBase-root {
    width: 100%;
    box-shadow: 0px 0px 6px #00000029;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
    margin-bottom: 10px;
  }

  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    margin-left: 10px;
  }

  [class*='-MuiButtonBase-root-MuiAccordionSummary-root'] {
    width: 100%;
  }

  [class*='-MuiButtonBase-root-MuiAccordionSummary-root'].Mui-expanded {
    border-bottom: 1px solid $white_dark;
  }

  .MuiAccordionDetails-root[class*='-MuiAccordionDetails-root'] {
    padding: 0px 0px;
  }

  [class*='-MuiPaper-root-MuiAccordion-root'].Mui-expanded:first-of-type {
    margin: 0px 0;
  }

  [class*='-MuiPaper-root-MuiAccordion-root']:first-of-type {
    margin: 0px 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: $white_original 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px $shodow_light_black;
    border-radius: 5px;
    opacity: 1;
  }

  [class*='-MuiPaper-root-MuiAccordion-root'].Mui-expanded {
    margin: 10px 0px;
  }

  [class*='-MuiPaper-root-MuiAccordion-root'] {
    margin: 10px 0;
    background: $white_original 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px $shodow_light_black;
    border-radius: 5px;
    opacity: 1;
  }

  [class*='-MuiPaper-root-MuiAccordion-root']:before {
    opacity: 0;
  }

  [class*='-MuiTypography-root'] {
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;
    font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji';
    font-size: 16px;
  }

  .module-area-style {
    @extend .module-area-style;
    background: $white_original 0% 0% no-repeat padding-box;
  }

  .left-border-style {
    margin-left: 10px;
  }

  .top-border-style {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .icon-style {
    margin-top: 1px;
    margin-right: 8px;
  }

  .variable-icon-style {
    margin-right: 6px;
    color: $blue_dark;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(12, 131, 230);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.schedule-no-data-style {
  @extend .fontPoppinsRegularXs;
  float: right;
  margin-right: 81px;
  margin-top: -3px;
  color: $error_red;
}

#demo-simple-select-label-timezone-reschedule {
  top: 386px !important;
}

.reschedule-timezone-height {
  max-height: 153px !important;
}

.re-schedule-no-data-style {
  @extend .fontPoppinsRegularXs;
  float: right;
  margin-right: 25px;
  color: $error_red;
}

.expiry-no-data-style {
  @extend .fontPoppinsRegularXs;
  float: right;
  margin-right: 85px;
  color: $error_red;
}

.re-expiry-no-data-style {
  @extend .fontPoppinsRegularXs;
  float: right;
  margin-right: 25px;
  color: $error_red;
}

.antSwitch {
  position: absolute;
  right: 47px;
  top: 15px;
}

.user-machine-details {
  background-color: $white_light;
  box-shadow: 0px 0px 6px $shadow_black;
  border-radius: 4px;
  height: 22.9rem;
  width: 43.5rem;

  @media (min-width: $xl) {
    width: 38.5rem;
  }
}

.user-machine-details-div {
  height: 194px;
  overflow-y: scroll;
}

.type-badge {
  @apply px-2 py-0.5 font-bold;
  @apply rounded-lg;

  &.type-label {
    @extend .fontPoppinsRegularXs;
    background-color: $white_dark;
    color: $badge-blue;
    font-size: 10px;
    min-width: 50px;
    height: 19px;
  }
}

// machine related class
.white-bg-td {
  background-color: $white_light;
}

.qrs-system-grid-container {
  height: 360px;
  grid-template-rows: 360px;

  .qrs-machine-table {
    height: calc(100% - 20px);
    overflow-y: scroll;
  }

  .qrs-details-div {
    background-color: $white_light;
    box-shadow: 0px 0px 6px $shadow_black;
    border-radius: 4px;
    height: 100%;
  }
}

.dashboad-action-font-style {
  height: 17px;
  width: 17px;
}

.line-style {
  border-top: 2px solid $skyblue_dark;
}

.info-child-modal-z-index {
  z-index: 1301;
}

.react-modal-style {
  height: 76vh;
  overflow-y: scroll;
}

.react-modal-main-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: fit-content;
  max-height: 100%;
  background-color: $skyblue_light;
  box-shadow: 0px 0px 11px $shodow_light_black;
  border-radius: 4px;
  opacity: 1;
}

.modal-overlay {
  height: fit-content;
  max-height: fit-content;
}

.user-react-modal-style {
  @extend .react-modal-main-style;
  width: 40%;
  background-color: $white_original;
}

.maximize-react-modal-style {
  @extend .react-modal-main-style;
  width: 90%;
  background-color: $white_original;
}

.alert-variable-modal-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 10000;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: -22px;
}
.alert-modal-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, -40px);
}
.machine_specific {
  max-height: 526px;

  .device-section {
    .select-exe-type-label-style {
      @extend .fontPoppinsMediumMd;
      text-align: left;
      letter-spacing: 0px;
      color: $grey;
      opacity: 1;
    }
  }

  .left-border-style {
    margin-left: 13px;
  }

  .content_area_header_style {
    height: 48px;
    padding-top: 8px;

    .main-header-label {
      @extend .fontPoppinsMediumLg;
      text-align: left;
      letter-spacing: 0px;
      color: $content_area_header_font_color;
      opacity: 1;
      padding-left: 24px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
      margin-top: 3px;

      @media (min-width: $xl) {
        font-size: 16px;
      }
    }

    @media (min-width: $xl) {
      height: 64px;
      padding-top: 15px;
    }
  }

  .module-area-style {
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px $shadow_black;
    opacity: 1;
    margin-top: 12px;
    max-height: 56vh;
    margin-left: 10px;
    margin-right: 10px;

    .table_height {
      max-height: 305px;
      overflow: scroll;

      @media (min-width: $xl) {
        max-height: 29vh;
      }
    }

    .table_tree_height {
      height: 49vh;

      @media (min-width: $xl) {
        height: 31vh;
      }
    }

    @media (min-width: $xl) {
      height: 404px;
    }
  }
}

.suite-name-overflow-style {
  max-width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-data-msg {
  padding: 26px;
  font-size: 14px;
}

.exe-start-time-width {
  min-width: 150px;
}

.ReactModal__Content.ReactModal__Content--after-open.popup-height-runsettings.react-modal-main-style.modal-overlay {
  width: 90%;
}

.user-machine-max-width-style {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 128px;
}

.table-display-common {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-font-style-common {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
}

.exetable-font-style-common {
  @extend .fontPoppinsRegularSm;
  text-align: left;
  letter-spacing: 0px;
}

.priv-pub-font-style-button {
  @extend .fontPoppinsRegularXs;
  margin-right: 15px;
}

.variable-details-area-style {
  .section-header-style {
    @extend .fontPoppinsSemiboldLg;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;

    @media (min-width: $xl) {
      font-size: 16px;
    }
  }
}

.run-sttings-modal-sub-header-style {
  @extend .fontPoppinsSemiboldSm;
  color: $color_blue;
}

.MuiTypography-root.MuiTypography-body1 {
  @extend .fontPoppinsRegularMd;
}

.table-header_font-style-exe-dashboard {
  @extend .fontPoppinsSemiboldSm;
  color: $grey_darkest;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  z-index: 1;
}

.module-modal-size {
  min-width: 1145px;
  height: 85vh;
}

.result-aborted {
  color: $red;
}

.result-scheduled {
  color: $result_warning_color;
}

.dashboard-overflow-style {
  position: relative;
  width: 88px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.over-view-overflow-style {
  position: relative;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suite-execution-dropdown {
  @extend .fontPoppinsRegularMd;
  width: 30vh !important;
  margin-left: 3px;
  border: none !important;
  color: $grey_dark;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 210px;
  }
  .react-dropdown-select-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.suite-execution-os-dropdown {
  width: 30vh !important;
  margin-left: 3px;
  border: none;
  color: $grey_dark;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 210px;
    width: 194px;
  }
}
/*custom width classes*/
.w-20px {
  width: 20px !important;
}
.w-40px {
  width: 40px !important;
}
.w-60px {
  width: 60px !important;
}
.w-80px {
  width: 80px !important;
}
.w-100px {
  width: 100px !important;
}
.w-120px {
  width: 120px !important;
}
.w-140px {
  width: 140px !important;
}
.w-160px {
  width: 160px !important;
}
.w-180px {
  width: 180px !important;
}
.w-185px {
  width: 185px !important;
}
.w-200px {
  width: 200px !important;
}
.w-220px {
  width: 220px !important;
}
.w-240px {
  width: 240px !important;
}
.w-300px {
  width: 300px !important;
}
.w-360px {
  width: 360px !important;
}
.w-400px {
  width: 400px !important;
}

.manual_tree_height {
  height: 49vh;

  @media (min-width: $xl) {
    height: 41vh;
  }
}

.hide-scripts-switch {
  position: absolute;
  right: 4%;
  top: 19px;
}
