@import '@styles/variables';

.auto-renew-license-card {
  margin-top: 16px;
  color: $muted;
  background-color: $white;
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  padding: 16px 24px;

  .success {
    color: $success;
  }

  .swtich-wrapper {
    display: flex;
    align-items: center;
    :global {
      .MuiSwitch-root {
        width: 42px;
        height: 20px;
        padding: 0px;
        display: flex;
        &:active {
          & .MuiSwitch-thumb {
            width: 14px;
          }
          & .MuiSwitch-switchBase.Mui-checked {
            transform: translateX(18px);
          }
        }
        & .MuiSwitch-switchBase {
          padding: 2px;
          color: $muted;
          position: absolute;
          & + .MuiSwitch-track {
            background-color: $white;
            border-color: $black;
            border: 1px solid $muted;
          }
          &.Mui-checked {
            transform: translateX(22px);
            color: $primary;
            & + .MuiSwitch-track {
              opacity: 1;
              background-color: $white;
              border-color: $black;
              border: 1px solid $primary;
            }
          }
        }
        & .MuiSwitch-thumb {
          box-shadow: 0 2px 4px 0 $black;
          width: 14px;
          height: 14px;
          border-radius: 50px;
          transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        & .MuiSwitch-track {
          border-radius: 10px;
          opacity: 1;
          background-color: $white;
          box-sizing: border-box;
        }
      }
    }

    .label {
      margin-left: 12px;
      color: $muted;
      opacity: 0.5;

      &.checked {
        opacity: 1;
        color: $success;
      }
    }
  }
}

.link {
  color: $primary;
}
