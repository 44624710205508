@import '/src/css_config/variable.scss';

@mixin borderTop($color) {
  border-top: 4px solid $color;
}
@mixin textColor($color) {
  color: $color;
}

.infoBorder {
  @include borderTop($info);
}
.warningBorder {
  @include borderTop($rs_analytics_warning);
}
.successBorder {
  @include borderTop($success);
}
.errorBorder {
  @include borderTop($rs_analytics_fail);
}
.titleInfoColor {
  @include textColor($info);
}
.titleWarningColor {
  @include textColor($rs_analytics_warning);
}
.titleSuccessColor {
  @include textColor($success);
}
.titleErrorColor {
  @include textColor($rs_analytics_fail);
}
.popUpFooter {
  background-color: $SkyLightblue;
  height: 57px;
}
.popUpSize {
  width: 605px;
  height: 246px;
}
