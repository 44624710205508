@import './variable.scss';
@import '@styles/rs_fonts.scss';

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    background-color: $white_light;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 11px $shadow_black;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    opacity: 1;

    .module-popup-layout {
      width: 1145px;
      height: 63vh;
      background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
      background: $white_light 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px $shadow_black;
      opacity: 1;
    }

    .modal-header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      .Popup-header-common {
        text-align: left;
        letter-spacing: 0px;
        color: $grey_darkest;
        opacity: 1;
      }

      h2.title {
        @extend .fontPoppinsMediumLg;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal-body {
      padding: 24px;
      height: calc(100% - 110px);
      flex: 1 1;

      .mat-card {
        height: 100%;
      }

      overflow-y: auto;
    }

    .modal-body-1 {
      height: calc(100% - 110px);
      flex: 1 1;
      overflow-y: auto;

      .table_height {
        height: 79vh;

        @media (min-width: $xl) {
          height: 76vh;
        }
      }
    }
  }
}

.modal-footer {
  border-top: 1px solid $white_dark;
  padding: 12px 12px;
  text-align: right;

  button {
    margin-left: 18px;
  }
}

.modal-position {
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}
