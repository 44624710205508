@import '../../css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.files-drag-drop {
  background: $white_original 0% 0% no-repeat padding-box;
  border: 2px dashed $blue-dashed-border;
  opacity: 1;
  height: 137px;
  margin: 0 auto;
  width: 98%;
  .file-upload-msg-style {
    @extend .fontPoppinsRegularSm;
    letter-spacing: 0px;
    opacity: 1;
    display: block;
    text-align: center;
    width: 100%;
  }
  .selected-file-name-style {
    @extend .fontPoppinsRegularSm;
    letter-spacing: 0px;
    color: $rs_primary;
    opacity: 1;
    margin: 0 auto;
    text-align: center;
  }

  .error-message-color {
    color: $rs_analytics_fail;
    text-align: center;
  }
  .warning-icon {
    color: $rs_analytics_fail;
    height: 12px;
    width: 14px;
  }

  .thead-bg-color {
    background-color: $rs_gray_bg;
  }
}

.MTC-table-wrapper {
  .table-section {
    max-height: 100px;
    overflow: auto;
  }

  table {
    border-collapse: collapse;
    position: sticky;
    top: 0;
  }

  thead {
    position: sticky;
    top: -1;
    height: 30px;
  }

  tbody {
    border: 2px solid $rs_light_gray;
    border-collapse: collapse;
    overflow-y: 'auto';
    overflow-x: 'hidden';
    height: 30px;
  }

  .td-border-style {
    border: 2px solid $rs_light_gray;
    border-collapse: collapse;
    height: 30px;
  }
  .td-border-top-style {
    border-top: 2px solid $rs_light_gray;
    border-collapse: collapse;
    height: 30px;
  }
  .hide-border-top {
    border-collapse: collapse;
    height: 30px;
  }

  .error-text {
    color: $rs_analytics_fail;
  }

  .table-header-text-color {
    color: $rs_analytics_blue_header;
  }
}

.import-progress-section {
  border: 2px solid $blue-dashed-border;
  border-style: dashed;
  border-radius: 12px;

  .rs-primary {
    color: $rs_primary;
  }
}

.close-btn {
  @extend .fontPoppinsMediumMd;
  background: $rs_gray_btn;
  color: $white_original;
  border-radius: 4px;
  letter-spacing: 0.5px;
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
}

.import-progress-minimze-block {
  background-color: $white_original;
  height: 40px;
  box-shadow: 0px 2px 12px $shadow_black;
  border-radius: 4px;
}

.cancel-button {
  background: $rs_gray_btn;
  color: $white_original;
  height: 30px;
  width: 73px;
  border-radius: 4px;
  letter-spacing: 0.5px;
}
