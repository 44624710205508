@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.file-upload-btn-text {
  @extend .fontPoppinsRegularXs;
  max-width: 100px;
  position: relative;
  margin-left: 20px;
}

.file-upload-container {
  @extend .fontPoppinsRegularMd;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  position: relative;
  display: flex;
  align-items: center;
  // margin-top: 5px; For v2.1
  margin-top: 10px;

  .file-upload-btn {
    @extend .fontMontserratMediumXs;
    cursor: pointer;
    background: $white_original 0% 0% no-repeat padding-box;
    border: 1px solid $gray_40;
    opacity: 1;
    position: relative;
    left: 12px;
    padding: 6px 12px;
    font-weight: 500;
    border-radius: 5px;
    height: 25px;
    display: flex;
    align-items: center;
  }

  .file-upload-hidden-input {
    position: absolute;
    z-index: 2;
    opacity: 0;
    inset: 0px;
    cursor: pointer;
  }
  .file-upload-hidden-input::file-selector-button {
    cursor: pointer;
  }
  .file-upload-hidden-input-r-width {
    width: 80px;
  }

  .file-upload-hidden-input-c-width {
    width: 78px;
  }

  .drag-file-btn {
    @extend .file-upload-btn;
    color: $ff-primary;
    font-weight: 500;
    border: 1px solid $ff-primary;
    height: 24px;
    width: 83px;
  }

  .upload-file {
    @extend .file-upload-btn-text;
    color: $blue_dark;
  }

  .choose-file {
    @extend .fontPoppinsRegularXs8px;
    // margin-left: 10px; For v2.1
    margin-left: 20px;
    color: $grey_darkest;
  }
}
.file-upload-container-for-website {
  @extend .file-upload-container;
  margin: 10px 0 0 12px;
}
