@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.boxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 315px;
  background-color: $white_original;
}
.boxModal-user {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 405px;
  background-color: $white_original;
}
.boxModal-select-user {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: $white_original;
}
.select-Validation {
  margin-left: 145px;
}
.select-field-user-admin {
  width: 250px;
}
.select-field-super-admin {
  width: 230px;
}
.colorHeader {
  width: 576px;
  height: 150px;
  display: flex;
  border-top: 8px solid $green;
  margin-top: -4px;
  margin-left: -4px;
}

.colorHeaderRed {
  display: flex;
  border-top: 4px solid $red;
}

.colorHeaderYellow {
  height: 150px;
  display: flex;
  border-top: 8px solid $warning;
  margin-top: -4px;
}

.colorHeaderBlue {
  height: 120px;
  display: flex;
  border-top: 4px solid $rs_primary;
}

.successImg {
  top: 318px;
  left: 600px;
  width: 96px;
  height: 96px;
  opacity: 1;
}

.svgImgStyle {
  height: 60px;

  margin-left: 20px;

  margin-top: 34px;
}

.svgContent {
  margin-left: 23px;

  font-size: 25px;
  color: $green;

  font-weight: bold;
}

.svgContentRed {
  color: $red;
  margin-left: 16px;
}

.svgContentYellow {
  margin-left: 23px;
  color: $warning;
}

.svgContentBlue {
  margin-left: 23px;
  color: $rs_primary;
}

.svgContentTwo {
  margin-top: -22px;
  font-size: 14px;
  margin-left: 24px;
  color: gray;
}

.svgContentTwoRed {
  margin-top: -16px;
  margin-left: 16px;
  color: $dark;
}

.signOffBtnStyle {
  margin-left: 460px;
  margin-top: -59px;
  width: 76px;
  height: 32px;
  color: $black;
  padding: 2px;
  text-align: center;
}

.signOffBtnStyle:hover {
  color: $rs_primary;
  border: 1px solid $rs_primary;
  border-radius: 16px;
  width: 76px;
  height: 32px;
  padding: 2px;
  cursor: pointer;
}

.hrStyle {
  margin: 5px 0;
  border: 1px dashed;
}
.hrExpired-width {
  width: 470px;
  margin-left: 160px;
}
.hrExpiring-width {
  width: 460px;
  margin-left: 113px;
}
.hrSelect-width {
  width: 446px;
  margin-left: 33px;
}
.expired-text {
  color: $red;
}
.expiring-text {
  color: $warning;
}
.footerNewLic {
  width: 100%;
  height: 35px;
  background-color: $alert_footer_bg;
  margin-top: 57px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  justify-content: flex-end;
  padding-right: 10px;
}

.footerBlue {
  width: 100%;
  height: 67px;
  background-color: AliceBlue;
  margin-top: 23px;
}
.retain-content {
  max-width: 580px;
}
.primaryBtnStyle {
  width: 217px;
  height: 39px;
  border: 1px solid $rs_primary;
  border-radius: 2px;
  background-color: $rs_primary;
  color: $white_original;
  margin-top: 15px;
  font-size: 13px;
  margin-left: 28px;
}

.primaryBtnStyleForRed {
  width: 59px;
  height: 25px;
  border: 1px solid $rs_primary;
  border-radius: 4px;
  background-color: transparent;
  color: $rs_primary;
  margin-top: 6px;
  margin-left: 29px;
}
.primaryBtnStyleForRed:hover {
  background-color: $rs_primary;
  color: $white_original;
}

.primaryBtnStyleForBlue {
  width: 90px;
  height: 39px;
  border: 1px solid $rs_primary;
  border-radius: 2px;
  background-color: $rs_primary;
  color: $white_original;
  margin-top: 15px;
  font-size: 13px;
  margin-left: 29px;
}

.word-wrap-class-for-alert {
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.secondaryBtnStyle {
  width: 196px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white_original;
  color: $black;
  margin-top: 15px;
  margin-left: 104px;
}

.secondaryBtnStyleForRed {
  width: 150px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white;
  color: $black;
  margin-top: 15px;
  margin-left: 34%;
}

.secondaryBtnStyleForRed:hover {
  background-color: $grey_medium;
  color: $white_original;
}

.secondaryBtnUser {
  width: 112px;
  height: 25px;
  border: 1px solid $cancel_btn_border;
  border-radius: 4px;
  background-color: transparent;
  color: $cancel_btn_border;
  margin-top: 6px;
}

.secondaryBtnUser:hover {
  background-color: $cancel_btn_hover_color;
  color: $white_original;
}

.secondaryBtnStyleForBlue {
  width: 90px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white_original;
  color: $black;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 327px;
  font-size: 13px;
}

.changeLicenseBoxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 721px;
  height: 240px;
  background-color: $white_original;
  box-shadow: 24px;
  padding: 4px;
}

.headerLicenseChange {
  width: 721px;
  height: 150px;
  display: flex;
  border-top: 8px solid $warning;
  margin-top: -4px;
  margin-left: -4px;
}
.retain-container {
  border-top: 8px solid $warning;
  margin-top: -4px;
  margin-left: -4px;
}
.retainBoxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 721px;
  height: 300px;
  background-color: $white_original;
  box-shadow: 24px;
  padding: 4px;
}
.buttonStyleForChangeLicense {
  width: 90px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white_original;
  color: $black;
  margin-top: 15px;
  margin-left: 477px;
}

#sideLeft {
  :global {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding-right: 87px;
      width: auto;
    }
  }
}

.footerForExpUser {
  width: 100%;
  height: 67px;
  background-color: AliceBlue;
  margin-top: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.primaryExpUser {
  width: 157px;
  height: 39px;
  border: 1px solid $rs_primary;
  border-radius: 2px;
  background-color: $rs_primary;
  color: $white_original;
  margin-top: 15px;
  font-size: 13px;
  margin-left: -29px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 39px;
  margin-top: -12px;
}

.footerForSelectLicense {
  width: 100%;
  height: 67px;
  background-color: $alice_blue;
  margin-top: 53px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.renewLicense {
  width: 88px;
  height: 32px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  opacity: 0.8;
  margin-left: 32px;
  border: 1px solid $rs_primary;
  background-color: $rs_primary;
  color: $white_original;
}

.primaryStyleForExp {
  width: 162px;
  height: 43px;
  border: 1px solid $rs_primary;
  border-radius: 2px;
  background-color: $rs_primary;
  color: $white_original;
  margin-top: 15px;
  font-size: 13px;
  margin-left: -42px;
}

.secondaryStyleSelectLicense {
  width: 90px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white_original;
  color: $black;
  margin-top: 15px;
  margin-left: 64%;
}

.secondaryStyleSelectLicense:hover {
  background-color: $grey_medium;
  color: $white_dark;
  font-weight: 300;
}

.changeLic {
  width: 136px;
  height: 39px;
  border: 1px solid $gainsboro;
  border-radius: 2px;
  background-color: $white_original;
  color: $black;
  font-weight: bold;
  margin-top: 15px;
  margin-left: -145px;
  font-size: 13px;
}

.changecircle:hover {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.changecircle {
  border-radius: 50px;
}

.changecircle span {
  color: $blue;
  font-size: 0;
}

.changecircle:hover {
  border: 2px solid $blue;
}

.changecircle:hover span {
  padding: 2px;
  @include fontPoppins(Poppins-Regular, 12px);
}

.changecircle:hover .span {
  visibility: visible;
}

.changecircle:hover .ico::before {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  content: '\f10b';
  margin-left: -5px;
}

.infoBox {
  margin-left: 31px;

  height: 39px;
}

.infoBox p {
  font-size: 0;
}

.infoBox:hover .collapseText {
  @include fontPoppins(Poppins-Regular, 12px);
  background-color: $alice_blue;
  visibility: visible;
  width: 100%;
}

.infoText {
  margin-top: 8px;
  margin-left: 5px;
}

.selectLeft MenuItem {
  margin-right: 120px;
}

.moreDetailsBox {
  margin: 18px;
  height: 105px;
  width: 335px;
  border: 1px solid $Lightwhite;
  border-radius: 10px;
  opacity: 1;
  background-color: $SkyLightblue;
}

.shieldIconDet {
  width: 30px;
  height: 30px;
  margin: 11px;
  color: $Lightgray;
}

.moreDetailsBox:hover {
  box-shadow: $slateBlack 0px 3px 8px;
}

.eastIcon {
  color: black;

  .MuiSvgIcon-root {
    width: 14px;
  }
}

.changeLicenseIcon {
  display: inline-block;
}

.changeLicenseIcon-user {
  position: absolute;
  top: 24%;
  right: 24%;
}

.email {
  color: $rs_analytics_blue_header;
  @extend .fontPoppinsSemiboldMd;
}
.renew-text-style {
  color: $black;
  @include fontPoppins(Poppins-SemiBold, 14px);
}
.box-content {
  display: flex;
  padding: 10px 20px;
  gap: 15px;
}
.expired-svg {
  width: 60px;
  height: 60px;
}
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: $black_original;
}
.header-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-title {
  color: $red;
}
.signout-btn {
  background-color: $white_original;
  border: 1px solid $rs_primary;
  color: $rs_primary;
  border-radius: 16px;
  @include fontPoppins(Poppins-Medium, 10px);
  padding: 5px;
}
.signout-btn:hover {
  background-color: $rs_primary;
  color: $white;
}
.license-details-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.switch-license-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: $white_original;
  color: $rs_primary;
  border-radius: 20px;
  border: 1px solid $rs_primary;
  @include fontPoppins(Poppins-Regular, 12px);
  padding: 5px;
}
.switch-license-btn:hover {
  background-color: $rs_primary;
  color: $white_original;
}
.box-footer {
  width: 100%;
  height: 35px;
  background-color: $alert_footer_bg;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px;
  gap: 10px;
  position: absolute;
  bottom: 0;
}
.renew-btn {
  background-color: transparent;
  color: $rs_primary;
  border: 1px solid $rs_primary;
  padding: 5px 10px;
  border-radius: 5px;
}
.renew-btn:hover {
  background-color: $rs_primary;
  color: $white_original;
}
.buy-new-btn {
  background-color: transparent;
  color: $cancel_btn_border;
  border: 1px solid $cancel_btn_border;
  border-radius: 5px;
  padding: 5px;
}
.buy-new-btn:hover {
  background-color: $cancel_btn_hover_color;
  color: $white_original;
}
.default-license-modal {
  width: 540px;
  height: 328px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white_original;
  display: flex;
  flex-direction: column;
}
.header-default-license-modal {
  width: 100%;
  display: flex;
  border-top: 8px solid $warning;
  margin-top: -4px;
}
.default-license-text {
  color: $license_tooltip_bg;
}
.label-text {
  color: $rs_label;
}
.login-content {
  width: 50%;
  margin: 0 118px;
}
.cancel-button-license {
  background: transparent;
  color: $cancel_btn_border;
  border: 1px solid $cancel_btn_border;
  border-radius: 4px;
  padding: 5px;
}
.cancel-button-license:hover {
  background-color: $cancel_btn_hover_color;
  color: $white_original;
}
.renew-dashed-border-admin-user {
  border-top: 2px dashed $rs_tabs_border_color;
  border-bottom: 2px dashed $rs_tabs_border_color;
  padding: 0 118px;
}
.renew-dashed-border-super-admin {
  border-top: 2px dashed $rs_tabs_border_color;
  padding: 0 118px;
}
.single-border {
  border-top: 2px dashed $rs_tabs_border_color;
  padding: 0 118px;
  padding-top: 30px;
}
.license-warning-color {
  color: $license_about_to_expire;
}
.primary-color {
  color: $rs_primary;
}
.warning-user-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 10px 118px;
}
.expired-content-user-admin {
  width: 100%;
  border-top: 2px dashed $cancel_btn_hover_color;
  border-bottom: 2px dashed $cancel_btn_hover_color;
  padding: 20px 20px 5px 80px;
}
.expired-content-super-admin {
  width: 100%;
  border-top: 2px dashed $cancel_btn_hover_color;
  padding: 20px 20px 5px 80px;
}
.expired-color {
  color: $rs_analytics_fail;
}
.contact-super-admin {
  margin: 10px 86px;
}
