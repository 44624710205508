@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';
@mixin common_button_Style {
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0px;
  padding: 6px 12px;
  height: 24.5px;
  text-transform: none;
  font-weight: 500;
  @extend .fontMontserratMediumXs;
  @media (min-width: $xl) {
    // above 1280
    font-size: 10px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 5px;
    height: 32px;
  }
}
.rs_primary_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $rs_primary;
    color: $rs_primary;
    width: fit-content;
    &:hover {
      background: $rs_primary;
      color: $white_original;
    }
    &:disabled {
      border-color: $grey_dark;
    }
    &.icon-btn {
      svg {
        position: relative;
        top: 3px;
      }
    }
  }
}
.rs_secondary_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $grey_dark;
    color: $grey_dark;
    width: fit-content;
    &:hover {
      background: $grey_dark;
      color: $submit_btn_font_color;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.rs_destructive_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $rs_analytics_fail;
    color: $rs_analytics_fail;
    width: fit-content;
    &:hover {
      background: $rs_analytics_fail;
      color: $submit_btn_font_color;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.rs_success_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $success;
    color: $success;
    width: fit-content;
    &:hover {
      background: $success;
      color: $white_original;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.rs_whitelist_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $white_original;
    color: $white_original;
    width: fit-content;
    &:hover {
      background: $white_original;
      color: $rs_primary;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.rs_disable_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $grey_light;
    color: $grey_light;
    width: fit-content;
  }
}
