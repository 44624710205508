@import '@styles/_variables.scss';

.search-wrapper {
  width: 100%;
  height: 100%;
  padding: 5px;

  .search-element {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    border: 1px solid $rs_tabs_border_color;
    border-radius: 15px;
    cursor: pointer;
    background-color: $white;

    .searchable-title-element {
      width: 80%;
      color: $primary;
    }
  }

  .drop-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $ff-white;
    box-shadow: 0px 0px 6px $border-muted;
    margin-top: 5px;
    transition: all 0.3s linear;
    border-radius: 3px;

    .search-block {
      width: 100%;
      padding: 8px 12px;
      position: relative;

      .search-input {
        width: 100%;
        height: 35px;
        background-color: $white;
        border: 1px solid $rs_tabs_border_color;
        border-radius: 5px;

        &::placeholder {
          margin-bottom: 10px;
          color: $rs_tabs_border_color;
        }

        &:focus,
        &:focus[type='text'] {
          padding-bottom: 8px;
          background: $ff-white;
        }
      }
      .search-icon {
        position: absolute;
        top: 18px;
        right: 26px;
      }
    }

    .drop-box-scroll {
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;

      .drop-item {
        color: $primary;
        width: 100%;
        padding: 8.5px 16px;
        transition: all 0.3s linear;
        cursor: pointer;

        &.selected-item,
        &:hover {
          color: $white;
          background-color: $primary;
        }
      }

      .no-data-found {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
      }
    }
  }
}
