@import './variable.scss';
@import '@styles/rs_fonts.scss';

@mixin table_common_style {
  text-align: left;
  letter-spacing: 0px;
  // opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}
/* if font size is 13px use this class */
.dashboard-font-style-common {
  @include table_common_style;
  font-size: 13px;
  @media (min-width: $xl) {
    // above 1280
    font-size: 14px;
  }
}

.table-header_font-style {
  @extend .fontPoppinsSemiboldMd;
  color: $grey_darkest;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  z-index: 1;
  @media (min-width: $xl) {
    // above 1280
    font-size: 12px;
  }
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.table-font-style-common {
  @include table_common_style;
  font-size: 14px;
  @media (min-width: $xl) {
    // above 1280
    font-size: 14px;
  }
}

.font-style-common {
  @include table_common_style;
  font-size: 14px;
  @media (min-width: $xl) {
    // above 1280
    font-size: 14px;
  }
}

.hyper_link_color-common {
  color: $clickable_feild_color;
}

.table-non-link-color-common {
  color: $non_clickable_feild_color;
}

.modal.non-link-color-common {
  color: $modal_non_clickable_feild_color;
}
