
@import '@src/css_config/variable.scss';

@mixin center {
  display: flex;
  align-items: center;
}

.mobilemodal-container {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include center();
  flex-direction: column;
  justify-content: flex-start; 
}

.mobilevideo-container {
  position: relative;
  width: 220px;
  height: 448px;
  @include center();
  justify-content: center;
  background-color: $rs_bg_whitgrey;
  cursor: pointer;


 .mobilevideo-stream {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .mobileerror-message {
    color:$black ;
    text-align: center;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobileclose_icon {
    position: absolute;
    top: 50%; 
    right: -50px; 
    transform: translateY(-50%); 
    cursor: pointer;
  }
  .video-loading {
    @include center;
    flex-direction: column;
    justify-content: center;
  
  }
}
