.w-250 {
  width: 250px;
}

.mls-9 {
  margin-left: 9.6rem;
}

.Icons {
  margin-left: 25rem;
}

.detailName {
  width: 127px;
  height: 24px;

  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-weight: 600;

  font-size: 14px;
  font-style: normal;
}

.ricipName {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  height: 19px;
  color: #525252;
  margin-top: 8px;
  margin-left: 32px;
}

.ricipEmail {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  height: 19px;
  color: #525252;
  margin-top: 8px;
  margin-left: 50px;
}

.ricipTableName {
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.alertPositionStyle {
  width: 34rem;
  position: absolute;
  z-index: 9999;
  top: 1.5rem;
  left: 30rem;
  align-self: center;
}
