@import '@styles/_variables.scss';

.rowHover:hover {
  background-color: $sky-blue;
  :global {
    .bgColor {
      background-color: $sky-blue;
    }
    .actionIcons {
      visibility: visible;
    }
    .project-action {
      display: inline-block;
    }
  }
}
