@import '../../../../../../../css_config/variable.scss';

.template-dropdown {
  width: 95% !important;
  margin-left: 3px;
  color: $grey_dark;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 160px;
  }
}

.opacity-70-imp {
  opacity: 0.7 !important;
}

.template-options-dropdown {
  border-radius: 1px;
  background-color: $white_light;

  div {
    padding-bottom: 3px;

    div {
      margin: 0px;
      padding-top: 5px;
      padding-left: 4px;
    }

    &:hover {
      background-color: $skyblue_dark;
      color: $blue_dark;
    }
  }
}

.test-case-type {
  margin: 0 10px 0 0;
  font-weight: 300;
  font-size: 12px;
  color: $blue_dark;
  background-color: $white_dark;
  display: inline-block;
  vertical-align: top;
  padding: 4px 6px;
  border-radius: 3px;
}

.automation-button-tct {
  width: 78.17px;
  margin: 0 10px 0 0;
}
.manual-button-tct {
  width: 53.95px;
  margin: 0 10px 0 0;
}

.autocomplete-wrapper-jira-mapping {
  input {
    border-bottom: none;
    &:focus {
      border-bottom: none;
    }
    &:focus-within {
      border-bottom: none;
    }
  }
  .MuiAutocomplete-endAdornment {
    top: -4px;
  }

  .MuiInput-underline.Mui-disabled {
    &::before {
      border-bottom-style: solid;
    }
  }
}
