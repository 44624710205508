/*Developer -:  Rahul Agrawal*/

.noFileChosen {
  margin-top: 10px;
  margin-left: -482px;
  font-size: 12px !important;
}
.noFileChosen1 {
  margin-top: 4px;
  margin-left: 6px;
  font-size: 12px !important;
}
