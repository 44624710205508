@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.analyticsPage {
  height: 77vh;
  width: 100%;
  display: flex;
  gap: 20px;
  overflow: hidden;
  margin: 10px 0;

  .flex-ana-container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .flex-ana-item {
      color: $label-gray-text;
      text-align: center;
    }
  }

  .analyticsBody {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: space-between;

    .scriptsBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 10px 5px 10px;
      gap: 20px;
      height: inherit;
      overflow: hidden;
      overflow-y: scroll;
      margin-bottom: 20px;

      .statusBlock {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;

        .PASSED {
          box-shadow: 0px 0px 12px $rs_analytics_pass !important;
          border: 1px solid $rs_analytics_pass;
          transition: all 0.3s linear;
        }
        .FAIL {
          box-shadow: 0px 0px 12px $rs_analytics_fail !important;
          border: 1px solid $rs_analytics_fail;
          transition: all 0.3s linear;
        }
        .WARNING {
          box-shadow: 0px 0px 12px $rs_analytics_warning !important;
          border: 1px solid $rs_analytics_warning;
          transition: all 0.3s linear;
        }
        .SKIPPED {
          box-shadow: 0px 0px 12px $grey_dark !important;
          border: 1px solid $grey_dark;
          transition: all 0.3s linear;
        }
        .FLAKY {
          box-shadow: 0px 0px 12px $rs_analytics_flaky !important;
          border: 1px solid $rs_analytics_flaky;
          transition: all 0.3s linear;
        }
        .testDetailsCard {
          flex-basis: 20%;
          max-height: 95px;
          height: 95px;
          display: flex;
          flex-direction: column;
          background-color: $white_original;
          border-radius: 15px 6px 15px 15px;
          cursor: pointer;
          box-shadow: 0px 0px 15px $rs_shadaow;
          transition: all 0.3s linear;

          .testDetailsHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .testDetailsHeaderContent {
              display: flex;
              flex-direction: column;
              padding: 10px 10px 0;
            }
            .iconBlock {
              width: 36px;
              height: 31px;
              border-radius: 15px 6px 0 15px;
              display: flex;
              align-items: flex-end;
              padding: 2px 0px 2px 2px;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
          .textContent {
            margin: 5px;
            background-color: $rs_analytics_grey;
            min-height: 40px;
            border-radius: 5px;
            padding: 5px;
          }
        }

        // TODO :: defects-card-details-styling :: START
        .defectsDetailsCard {
          flex-basis: 20%;
          max-height: 95px;
          min-height: 70px;
          display: flex;
          flex-direction: column;
          background-color: $white_original;
          border-radius: 15px 6px 15px 15px;
          // cursor: pointer;
          box-shadow: 0px 0px 15px $rs_shadaow;
          transition: all 0.3s linear;

          .defectsDetailsHeader {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .defectsDetailsHeaderContent {
              display: flex;
              flex-direction: column;
              padding: 10px 8px 0;
              gap: 10px;
            }
            .iconBlock {
              width: 36px;
              height: 31px;
              border-radius: 15px 6px 0 15px;
              display: flex;
              align-items: flex-end;
              padding: 2px 0px 2px 2px;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        // TODO :: defects-card-details-styling :: END
      }
      .scriptTrendChartBlock {
        width: 100%;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        background-color: $white_original;
        border-radius: 24px;
        box-shadow: 0px 0px 15px $rs_shadaow;
        padding: 10px;

        .scriptTrendHeader {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .leftHeader {
            color: $rs_analytics_blue_header;
          }
          .rightHeader {
            color: $rs_blue_font;
          }
        }
      }
    }
    .scriptsBody::-webkit-scrollbar {
      display: none;
    }
    .uncheckedFilter {
      position: absolute;
      top: 48px;
      right: 16px;
      z-index: 10;
      transition: all 0.3s linear;
    }
    .uncheckedFilter:hover {
      background-color: $SkyLightblue;
      border-radius: 10px;
    }
    .checkedFilter {
      width: 25%;
      padding: 8px 0;
      display: flex;
      justify-content: center;
    }
    .analytics_filters_header_open {
      background: $white_original;
      border-radius: 20px;
      position: fixed;
      min-width: 21%;
      max-height: 75vh;
      margin-right: 2px;
      z-index: 2;
      padding: 8px;
      box-shadow: 0px 0px 15px $rs_shadaow;
    }
    .analytics_filters_header_open::-webkit-scrollbar {
      display: none;
    }
    .analytics_filters_header_closed {
      border-radius: 10px;
      position: fixed;
      display: inline;
      margin-top: 50px;
      margin-left: -10px;
      z-index: 2;
    }
    .analytics_filters_header_closed:hover {
      background: var(--rs-select-off-white);
      transition: all ease-out 0.2s;
    }
    .analytics_filter_icon {
      padding: 4px;
      transition: all 1s ease-out;
    }
  }
}
.topFailuresBlock {
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .topFailuresChartBlock {
    min-width: 39%;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: $white_original;
    border-radius: 15px;
    box-shadow: 0px 0px 15px $rs_shadaow;

    .failureChartHeader {
      width: 100%;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftHeaderFailure {
        color: $rs_analytics_fail;
      }
      .rightHeaderFailure {
        color: $label-gray-text;
      }
    }
  }
  .topFailureTable {
    width: 60%;
    background: $white_original;
    box-shadow: 0px 0px 15px $rs_shadaow;
    border-radius: 15px;
  }
}

.failureAnalysisBlock {
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .failureAnalaysisChartBlock {
    min-width: 39%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    background-color: $white_original;
    border-radius: 15px;
    box-shadow: 0px 0px 15px $rs_shadaow;
    gap: 25px;

    .failureAnalysisHeader {
      color: $rs_analytics_fail;
    }
    .progressBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .failureAnalysisTable {
    width: 60%;
    background: $white_original;
    box-shadow: 0px 0px 15px $rs_shadaow;
    border-radius: 15px;
  }
}
.failureReasonsBlock {
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 24px;
  box-shadow: 0px 0px 15px $rs_shadaow;
  padding: 10px;
  background-color: $white_original;

  .failureReasonsCarouselBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 24px;
    box-shadow: 0px 0px 15px $rs_shadaow;
    padding: 10px;
    background-color: $white_original;

    .failureReasonsHeader {
      color: $rs_analytics_fail;
    }
  }
  .failureReasonsTable {
    width: 100%;
    border-radius: 24px;
    box-shadow: 0px 0px 15px $rs_shadaow;
  }
}

.dropBlock {
  max-width: 111px;
  max-height: 10vh;
  position: relative;

  .suiteName-trimmer {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropInput {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    background-color: $tr-bottom-color;
    border-radius: 8px;
    cursor: pointer;
  }
  .dropInputDisable {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $skyblue_light;
    border-radius: 8px;
    cursor: not-allowed;
    border: 1px solid $gray_white;
    gap: 10px;
  }

  .dropBox {
    width: 120px;
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $white_original;
    position: absolute;
    transition: all 0.3s linear;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    .option {
      width: 120px;
      height: 40px;
      padding: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s linear;
      color: $grey_darkest;
    }
    .option:hover {
      background-color: $tr-bottom-color;
    }
    .disabled {
      width: 120px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 5px;
      background-color: $rs_bg_whitgrey;
      pointer-events: none;
      transition: all 0.3s linear;
    }
  }
}

// TODO :: SEVERITY, PRIORITY, MODULE , STATUS STYLING :: START
.defectStatisticSection {
  display: flex;
  width: 100%;
  max-height: 300px;
  justify-content: space-between;
  gap: 10px;
  .leftGarpgBlock {
    height: inherit;
    padding: 10px;
    background-color: $white_original;
    border-radius: 15px;
    box-shadow: 0px 0px 15px $rs_shadaow;
  }
  .rightTableBlock {
    width: inherit;
    background: $white_original;
    box-shadow: 0px 0px 15px $rs_shadaow;
    border-radius: 15px;
    min-height: 245px;
  }
}

// TODO :: SEVERITY, PRIORITY, MODULE , STATUS STYLING :: END

//  TODO :: List of all defect  Section Styling:: START

.all_defectes_list_section {
  min-height: 450px;
}

//  TODO :: List of all defect  Section Styling:: END
