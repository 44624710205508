@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.authcontent {
  margin-top: -53px;
  margin-left: 0px;
}
.borderStyle3 {
  border-top: 1.5px solid;
  border-color: $ff-border;
}

.authorization-no-auth-body-width {
  width: 650px;
}

.size-of-no-auth-body {
  height: 300px;
  width: 100%;
}
.border-style-in-auth1 {
  border-top: 1px solid;
  border-color: $ff-border;
}

.authorization-select {
  width: 251px;
  color: $ff-text;
}

.userPassword-container {
  margin: 16px 20px 0px 0px;
}

.jwt-bearer-container {
  margin: 8px 0px;
  padding-right: 11px;
  height: 306px;
  overflow-y: scroll;

  .jwt-bearer {
    height: 100%;
    width: 100%;
    background-color: $rs_bg_whitgrey;
    border-radius: 5px;
    display: flex;

    .jwt-select-algo {
      min-width: 215px;
      padding: 8px 9px 0px 8px;
      height: 100%;
    }

    .jwt-algo-container {
      width: 100%;
      height: 298px;
      margin: 4px 8px 4px 0px;
      border-radius: 5px;
      border: 1px solid $ff-border;
      border-top: none;
      background-color: $ff-white;

      .algo-heading {
        width: 100%;
        background-color: $ff-primary;
        border-radius: 5px;
        height: 35px;
        line-height: 35px;
        color: $ff-white;
      }

      .payload-container {
        max-height: 261px;
        padding-bottom: 8px;
        overflow-y: scroll;

        .advance-underline {
          border-top: 1px solid $ff-border;
          margin: 0px 8px;
        }

        .hs-algo-container {
          .secret-input-key {
            min-width: 198px;
            text-align: left;
            margin: 8px 10px 10px 8px;
            position: relative;

            .jwt-input input[type='text']:focus {
              @extend .fontPoppinsRegularSm;
              border: 1px solid $ff-border;
              box-shadow: none;
              text-align: left;
              letter-spacing: 0px;
              padding-bottom: 8px;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}

.jwt-select-title {
  color: $gray_dark_60;
}

.advance-header-text {
  @extend .fontPoppinsRegularSm;
  color: $gray_dark_60;
  text-align: left;
  padding-left: 50px;
}

.ace-container {
  width: auto;
  margin-left: 8px;

  .ace-editor {
    border: 1px dashed $ff-border;
    border-left: none;
    border-radius: 5px 0px 0px 5px;
  }

  .ace_cursor {
    color: $gray_white;
  }
}

.private-key-container {
  width: 100%;
  margin-right: 8px;
  height: 112px;

  .private-key-textarea {
    width: 100%;
    border: 1px solid $ff-border;
    height: 112px;
    resize: none;
    overflow: scroll;
    word-wrap: break-word;
    white-space: pre;

    &::-webkit-scrollbar {
      height: 4px;
    }
  }
}

.tooltip-icon {
  position: absolute;
  top: 2px;
  left: 57px;
  z-index: 9;
}

.advance-input-tooltip {
  position: absolute;
  top: 2px;
  left: 143px;
  z-index: 4;
}

.jwt-advance-input {
  width: 198px;
}

.file-container {
  display: flex;
  align-items: center;
  margin: 2px 48px 2px 0px;
}

.private-key_payload {
  position: absolute;
  margin: 8px 0px 0px 68px;
}

.modal-container {
  box-shadow: 0px 0px 4px $shadow_black;
}

.file-name {
  @extend .fontPoppinsRegularSm;
  margin-left: 8px;
  color: $blue_dark;
}

.jwt-variable-selector {
  position: absolute;
  z-index: 10;
  top: 140px;
  left: 290px;
}

.heading-text {
  color: $ff-text;
}

.jwt-local-file-warning {
  @extend .fontPoppinsMediumXs8px;
  color: $ff-error;
  padding: 5px 0px 0px 140px;
}

@mixin api-input-field {
  width: 251px;
  border: 1px solid $light_gray_50;
  height: 35px;
  border-radius: 5px;
  padding: 9px 8px;
  padding-bottom: 9px;
  color: $ff-text;
}

.api-key-container {
  .api-key-group {
    width: 283px;
    height: 57px;

    .api-key-input {
      @include api-input-field;

      &:hover {
        border: 1px solid $ff-primary;
      }
    }
  }

  .api-value-group {
    width: 283px;
    height: 67px;
    margin-left: 67px;

    .api-key-input {
      @include api-input-field;

      &:hover {
        border: 1px solid $ff-primary;
      }
    }
  }

  .api-add-to-group {
    width: 251px;
    height: 57px;
    margin-left: 67px;
  }
}
