@import '@src/css_config/variable.scss';

.dialog-container {
  z-index: 1300 !important;
  .css-1ivgbss {
    width: 100%;
  }
  * {
    text-transform: unset;
  }

  .dialog-footer {
    position: fixed;
    bottom: 0px;
    height: 48px;
    background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 0px 10px;
    opacity: 1;
    width: inherit;
    padding: 20px;
    border-top: 1px solid $rs_light_gray;
    z-index: 999;
    text-align: right;
  }

  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    border-radius: 5px 0px 0px 5px;
  }

  .header-close-icon {
    position: absolute;
    right: 10px;
  }
  .dialog-content {
    height: calc(100% - 125px);
    overflow-y: auto;
    position: absolute;
    width: 100%;
    overflow-x: auto;
  }
}
