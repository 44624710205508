@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.custom_label {
  color: $rs_label;
  &.required {
    &:before {
      content: '*';
      color: $red;
      margin-right: 4px;
    }
  }
  &.error {
    color: $red;
  }
}
