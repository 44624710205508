@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.save-api-request {
  width: 500px;
  height: 100%;
  border-radius: 10px 0 0 0;
  background: $white_original 0% 0% no-repeat padding-box;
  padding: 0;
  opacity: 1;
}
.save-api-input-header {
  height: 35px;
  width: 470px;
}
.modal-header-api {
  border-bottom: 1px solid $white_dark;
  padding: 8px 8px;
  position: relative;
  height: 5.5%;
  background: $rs_primary;
  border-radius: 10px 0px 0px 0px;
  h2.title {
    @extend .fontPoppinsMediumSm;
    text-align: left;
    letter-spacing: 0px;
    color: $white_original;
    opacity: 1;
  }
  .close {
    color: $white_original;
    position: absolute;
    opacity: 1;
    width: 8px;
    height: 8px;
    right: 20px;
    top: 5px;
    &:hover {
      border: 2px solid;
      border-radius: 50%;
      margin: -2px -2px 0 0;
    }
  }
}
.modal-footer-save-api {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-right: 23px;
  margin-bottom: 20px;
}
.modal-input-name {
  margin-top: 3px;
}
.modal-input-select {
  margin-top: 30px;
}
.modal-input-pass {
  margin-top: 11px;
}
.modal-input-fail {
  margin-top: 32px;
}
.save-request-footer {
  position: fixed;
  bottom: 0;
  width: 500px;
}
.curl-modal-header {
  @extend .modal-header-api;
  border-bottom: none;
  height: 35px;
  padding: 10px;
}
