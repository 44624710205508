/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */

:root {
  --darkGray: #707070;
  --textGray: #727171;
  --darkWhite: #fbfcfd;
  --shadedWhite: #f6f7f9;
  --lightBlue: #1648c6;
  --shadedBlue: #e5ecfc;
  --shadowBlack: #00000029;
  --whiteOriginal: #ffffff;
  --bg-blue: #434db8;
  --shadow-black: rgba(0, 0, 0, 0.85);
  --bg-slate: #e0e3f6;
  --colorGray: #3c3838;
}

.jsoneditor {
  height: 400px !important;
  width: 1100px !important;
}

.console_output_container_Response {
  height: 100% !important;
  overflow: auto;
  color: #1a1a1a;
  min-height: 160px !important;
}

.console-footer {
  margin-bottom: 24px;
}
.ace_gutter-layer,
.ace_print-margin-layer {
  overflow: hidden !important;
}

.response-section-collapse-height {
  height: 288px;
  width: 97%;
  margin: 10px auto;
}
.third_Div {
  box-shadow: 0px 0px 6px var(--shadowBlack);
}
.responseHeader {
  margin-left: 0px;
  margin-right: 0px;
  height: 40px;
  background: var(--whiteOriginal) 0% 0% no-repeat padding-box;
  opacity: 1;
}
.parentContent {
  width: 100%;
  border: 2;
  height: 294px;
}
.response-nav-bar {
  color: var(--textGray);
  width: 60px;
  position: relative;
  bottom: 8px;
  height: 25px !important;
  font-weight: 500 !important;
}
.tooltip-nav-bar {
  padding: 10px 10px;
  height: 40px;
}
.tooltip-nav-bar:hover {
  /* background-color:var(--shadedBlue); */
  padding: 10px 10px;
  height: 40px;
}

.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 80%;
}

.ace_scrollbar.ace_scrollbar-h {
  height: 4px !important;
  width: 60%;
}

.ace-tm .ace_gutter {
  background: #e2e2f7 !important;
}

/* Overall Ace Editor div */
.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}
.ace-console-wrp .project_label {
  padding-left: 0;
}
div#UNIQUE_ID_OF_DIV {
  border: 1px solid grey;
  /* margin-left: 22px; */
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */
.editor__console {
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  border: 1px solid grey !important;
  box-sizing: content-box !important;
  color: red;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}
.grid-full-height {
  height: 100%;
}
.editorRole {
  flex: 1 1;
}

.GroupIcon {
  position: absolute;
  margin-top: 3%;
  right: 4%;
  cursor: pointer;
}
.hovered-icons-response-body,
.search_icon_in_response_body:hover {
  background-color: var(--whiteOriginal);
  color: var(--bg-blue);
  border-radius: 50%;
  box-shadow: 4px 20px 23px 0px var(--shadow-black);
}
.search_icon_in_response_body {
  color: var(--colorGray);
}
.response-body-code-editor-format {
  border: 2px dashed var(--bg-slate);
  border-radius: 10px;
  width: 97%;
  margin: 0 auto;
  height: 230px;
}
.response-body-button-alignments {
  width: 35%;
  border: 2;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.hovered-icons-response-body-clipboard {
  height: 15px;
  width: 15px;
}

.check-circle-outline-copyIcon {
  color: var(--bg-blue);
}
.ace-common-styles {
  font-size: 12px;
}
.curl-code-editor-style {
  margin: 0 16px 16px 16px;
}
.curl-code-editor-style .ace_gutter-cell {
  padding-left: 14px;
}
.curl-code-editor-style .ace_gutter-layer {
  text-align: left;
}
