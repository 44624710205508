@import '@styles/_variables';

.top-border {
  width: 100%;
  background-color: currentColor;
  height: 10px;
}

.bg-info {
  background-color: $primary-light;
}

.bg-success {
  background-color: $rs_success;
}

.bg-warning {
  background-color: $rs_warning;
}

.bg-danger {
  background-color: $red;
}

.footer {
  background-color: $sky-blue;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  align-items: center;
}

.button {
  padding: 12px 20px;
  background-color: $gray-light;
  border: 1px solid $gray-200;
  border-radius: 2px;
  color: $dark;
  margin-left: 8px;
  margin-right: 8px;

  &:hover:not(&:disabled):not(&.primary-button) {
    background-color: $border-gray;
    color: $white;
  }

  &.primary-button {
    background-color: $white;
    border-color: $cancel_btn_border;
    color: $cancel_btn_border;
    height: 27px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.92;
      background-color: $cancel_btn_hover_color;
      color: $white;
    }
  }
}
