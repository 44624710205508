@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';

.search-wrap {
  width: 100%;
  height: 100%;
}

.search-element-box {
  @extend .search-wrap;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.title-element {
  color: $primary;
}

.dropdown-box {
  width: 40%;
  display: flex;
  flex-direction: column;
  background-color: $ff-white;
  box-shadow: 0px 0px 20px $shadow_black;
  margin-top: 5px;
  transition: all 0.3s linear;
  border-radius: 10px;
  z-index: 10000;

  .dropdown-box-options {
    max-height: 223px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
  }
}

.drop-item {
  color: $license_tooltip_bg;
  width: 100%;
  padding: 8.5px 16px;
  transition: all 0.3s linear;
  cursor: pointer;
}

.selected-item,
.drop-item:hover {
  color: $white;
  background-color: $ff-primary;
}

.search-block {
  width: 100%;
  padding: 8px 12px;
  position: relative;
}

.search-input {
  width: 100% !important;
  height: 35px !important;
  border: 1px solid $rs_tabs_border_color !important;
  padding: none !important;
  border-radius: 5px;
  padding: none !important;
}

.search-input::placeholder {
  margin-bottom: 10px;
  color: $rs_tabs_border_color;
}

.search-input[type='text']:focus {
  padding-bottom: 8px;
  background: $ff-white !important;
}

.search-icon {
  position: absolute;
  top: 18px;
  right: 26px;
}

.no-data-found {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
}