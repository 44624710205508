@import './variable.scss';
@import '@styles/rs_fonts.scss';

.primary-tab {
  padding-top: 4px;
  &.bottom-space-sm {
    margin-bottom: 10px;
  }
  li {
    margin-right: 24px;
  }
  a {
    display: flex;
    @extend .fontPoppinsRegularMd;
    line-height: 24px;
    color: $tab_font_color;
    padding-right: 2px;
    img {
      max-width: 14px;
      margin-right: 6px;
      @media (min-width: $xl) {
        // above 1280
        max-width: 14px;
        margin-right: 6px;
      }
    }
    &:hover {
      border-bottom: 1px solid $tab_font_color;
    }
    &.selected_tab_style {
      @extend .fontPoppinsSemiboldMd;
      border-bottom: 2px solid $tab_font_color;
    }
    @media (min-width: $xl) {
      // above 1280
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: $xl) {
    // above 1280
    padding-top: 4px;
    margin-bottom: 20px;
  }
}

.repo-dev-tab-height {
  height: 44px;
  @media (min-width: $xl) {
  }
}
