table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

table th {
  /* border-bottom: 1px solid #ddd; */
}

/* 
table td,
table th {
    padding: 4px;
    text-align: left;
} */

/* table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.thead_style {
  color: var(--text-3c3838);
  text-align: left;

  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 12px;
  font-family: OpenSans-SemiBold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-weight: 400;
}

table tr:hover {
  background-color: #ddd;
}

.table-style th {
  padding: 13px;
  color: var(--lm-normal-525252);
  text-align: left;
  /* font: normal normal bold 12px/17px Open Sans; */
  letter-spacing: 0px;
  color: #3c3838;
  font-family: OpenSans-Bold, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-weight: 600;
  font-size: 12px;

  top: 257px;
  left: 112px;
  width: 1776px;
  height: 56px;
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.table-style td {
  color: var(--information-2b71f7);
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  padding: 13px;

  top: 423px;
  left: 112px;
  width: 1776px;
  height: 56px;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  opacity: 1;
}

tbody {
  text-align: center;
}

input {
  color: black;
}

.table_link_blue_color {
  color: #2b71f7;
}

.add-user-table td,
.add-user-table th {
  text-align: left;
  width: 200px;
}

.user {
  width: 196px;
  height: 37px;
  font-size: 12px;
  border-bottom: none;
}
.add-use-table {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  opacity: 1;
}
