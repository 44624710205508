@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';
.main-group {
  width: 254px;
  height: 35px !important;
  .timeperiodheader {
    width: 100%;
    height: 100% !important;
    .timeperiodheader-box {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 5px;
      cursor: pointer;
      .time-period-text {
        @extend .fontPoppinsSemiboldXs;
        color: $rs_primary;
      }
    }
  }
}
.main-time-period-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  @extend .fontPoppinsSemiboldXs;
  .time-period-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    .fromdate {
      width: 222px;
      height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid $rs_border;
      border-radius: 5px;
      .date-picker {
        border-radius: 5px;
        display: flex;
        align-items: center;
        height: 33px;
        cursor: pointer;
        padding: 0px 5px;
      }
      &:hover {
        width: 222px;
        height: 35px;
        border: 1px solid $rs_primary;
        border-radius: 5px;
        z-index: 99;
      }
      .input-date {
        height: 25px;
        cursor: pointer;
      }
    }
  }
  .radio-btn-container {
    width: 100%;
    height: 145 px;
  }
  .radioButton-custom-graph-filter {
    &.MuiButtonBase-root {
      &.Mui-checked {
        color: $ff-primary;
      }
    }
  }
}
.script-type-header {
  width: 100%;
  height: 35px;
  background-color: $periwinkle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-family: 'Poppins-Medium';
  cursor: pointer;
  div:first-child {
    @extend .fontPoppinsSemiboldXs;
    color: $rs_primary;
    font-size: 13px;
    font-weight: 500;
  }
}
.expandTypeScriptContainer {
  height: 250px;
  padding: 0px 20px 10px 20px;
  .check-box {
    height: 90%;
    width: 100%;
    overflow-y: auto;
  }
}

.expandTypeScriptContainer {
  height: 250px;
  padding: 0px 20px 10px 10px;
  .check-box {
    padding-top: 10px;
    padding-left: 10px;
    height: 90%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .storybook-checkbox--overlay {
      margin-right: 15px;
      .storybook-checkbox--check {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
}
