.length-script {
  display: inline-block;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.if-failed-length-pre-post {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
