@import '../../../../css_config/variable.scss';

.data-set-modal {
  &.modal-dialog .modal-container {
    background-color: #fbfcfd;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 8px #000000a1;
    padding: 0;
    max-width: calc(45% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
  }
  .data-modal-size {
    width: 1598px;
    width: calc(45vw - 100px);
    top: 196px;
    left: 161px;
    height: 679px;
  }
  .data-View-size {
    width: 1598px;
    width: calc(45vw - 100px);
    top: 196px;
    left: 161px;
    height: 75vh;
  }
  // #wrapper {
  //     white-space: nowrap;
  //     width: 140px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  // }
}
