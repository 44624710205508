@import '@styles/variables';
@import '@styles/_variables.scss';
.card-wrapper {
  border: 1px solid $gray;
  border-radius: 10px;
  box-shadow: 0px 0px 6px $border-muted;
  margin-right: 5px;
  width: 97%;

  .card-header {
    color: $primary;
    background-color: $sky-blue;
    padding: 8px 16px;
    border-bottom: 1px solid $gray;
    border-radius: 10px 10px 0px 0px;
  }

  .card-content-coupon {
    min-height: 75px;
    background-color: $white;
    padding: 14px 24px;
    color: $dark;
    .discounted-value {
      color: $success;

      &::before {
        content: '';
        border: 1px solid $success;
        width: 8px;
        height: 0px;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      .dollar {
        color: $success;
      }
    }
  }

  .card-content {
    min-height: 224px;
    background-color: $white;
    padding: 14px 24px;
    color: $dark;
    .discounted-value {
      color: $success;

      &::before {
        content: '';
        border: 1px solid $success;
        width: 8px;
        height: 0px;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      .dollar {
        color: $success;
      }
    }
  }

  .dollar {
    color: $dark-gray;
  }
  .card-footer {
    border-top: 1px solid $sky-blue-dark;
    min-height: 40px;
    padding: 8px 24px;
    background-color: $white;
    border-radius: 0px 0px 10px 10px;

    .label {
      color: $dark;
    }

    .value {
      color: $primary;
    }
  }
}
