:root {
  --white: #ffffff;
  --rs-select-off-white: #f5f6fc;
  --border-off-white: #f0f0f0;
  --btnBackground: #ecedf8;
  --rs-text-label: #172b4d;
  --rs-green: #5ca700;
  --rs-yellow: #ffc600;
  --rs-red: #c50303;
  --rs-gray: #727171;
  --rs-orange: #e57300;
  --rs-blue: #434db8;
  --rs-header-gray: #b8b5d0;
  --rs-faded-black: #0000001a;
  --rs-border-color: #e5ecfc;
  --rs-text-color: #3c3838;
  --rs-shadow-black: #00000029;
  --rs-text-gray-msg: #7c828a;
  --rs-bg-success-icon: #e3fac8;
  --rs-bg-failed-icon: #f7cfca;
  --rs-bg-flaky-icon: #f3d1b0;
  --rs-bg-warning-icon: #fcf1c9;
  --rs-bg-skipped-icon: #dbdbdb;
  --bg-filter-icon: #f0f3f9;
  --bg-exe-comparison: #f6f7fa;
  --border-search-bar: #8d93d4;
}

.rs-blue {
  color: var(--rs-blue);
}
.rs-text-black {
  color: var(--rs-text-color);
}

.rs-passed {
  color: var(--rs-green);
}
.rs-warning {
  color: var(--rs-yellow);
}
.rs-failed {
  color: var(--rs-red);
}
.rs-skipped {
  color: var(--rs-gray);
}
.rs-flaky {
  color: var(--rs-orange);
}

.analyticsBody {
  background-color: var(--white);
}

.analyticsBody-container {
  max-height: 80vh;
  overflow-y: scroll;
}

.unselected-button {
  padding: 10px;
  width: 231px;
  height: 52px;
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 6px var(--rs-shadow-black);
  border-radius: 8px;
  opacity: 1;
}
.section-header {
  text-align: left;
  color: var(--rs-header-gray);
}
.section-header-right {
  color: var(--rs-blue);
}
.section-box {
  background: var(--white);
  border-radius: 24px;
  box-shadow: 0px 0px 6px var(--rs-shadow-black);
}

.section-left {
  background: var(--white);
  box-shadow: 0px 0px 6px var(--rs-shadow-black);
  border-radius: 24px;
}
.section-right {
  background: var(--white);
  box-shadow: 0px 0px 6px var(--rs-shadow-black);
  border-radius: 24px;
}

.AnalyticPagination > nav > ul > li > button {
  height: 20px;
  border-radius: 0;
  border-color: var(--rs-border-color);
  margin-left: 0px;
  margin-right: 0px;
}
.AnalyticheaderStyle {
  background-color: transparent;
  color: var(--rs-blue);
  border-bottom: 1px solid var(--btnBackground);
}

.AnalyticArrow {
  height: 20px;
  width: 15px;
  border: 2px solid black;
}

#projectTypereportcard > svg[class='MuiSvgIcon-root'] {
  width: 7em;
  height: 4em;
}

.tabledata-trimmer {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

select > option {
  height: 35px;
}

.tooltip_box {
  height: 60px;
  width: 180px;
  padding: 12px 14px 14px 14px;
  font-size: 12px;
}

.d-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

#filter_icon {
  height: 34px;
  margin-left: 96px;
  background: var(--bg-filter-icon);
  margin-top: -1px;
}
#Analytic_Drop_Down {
  font-size: 10px;
}
MenuItem:hover {
  color: var(--rs-blue);
}
#gaugechart > div > svg > g > g > text {
  display: none;
}
#gotopageParent > .gotopage {
  width: 40px;
  height: 22px;
  border: 1px solid var(--rs-border-color) !important;
  padding: 2px;
}
.flex-ana-container {
  height: 100%;
  min-height: 85vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-ana-item {
  text-align: center;
  font-size: 16px;
  color: var(--rs-text-gray-msg);
}
.projecttype {
  font-size: 18px;
  color: var(--rs-text-color);
  opacity: 1;
}
.notTaggedScript {
  font-size: 25px;
  color: #100303;
  text-align: center;
}
.PageNOValidation {
  float: right;
  margin-top: -15px;
  font-size: 10px;
  color: #ea0322;
  margin-right: 4px;
}
.paginationSection {
  height: 52px;
  margin-top: -3px;
}
.Page_NO_Validation {
  float: right;
  margin-top: -25px;
  font-size: 10px;
  color: #ea0322;
  margin-right: 4px;
}
.globalfilter {
  border: none;
  background: transparent;
}

.analytics_filter_text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: var(--rs-text-color);
  background: var(--white);
  width: 80%;
  padding-left: 3%;
  padding-top: 3%;
}
.analytics_filter_icon {
  padding: 4px;
  transition: all 1s ease-out;
}
.analytic_filters_section {
  background: var(--rs-select-off-white);
  border-radius: 8px;
  transition: all 0.3s ease-out;
}

.not-tagged {
  display: inline-block;
  height: 300px;
}
.not-tagged-section {
  height: 52px;
  padding-top: 17px;
  font-size: 14px;
  color: #100303;
  text-align: center;
}
.tagged-section {
  height: 250px;
}
.tagged-count {
  color: var(--rs-text-color);
  font-size: 12px;
  padding-left: 10px;
  margin-top: -8px;
}
.tag_name {
  color: #fbfcfd;
  font-size: 12px;
  padding-left: 5px;
  padding-top: 5px;
}
.tagged_script {
  height: 250px;
}

.selected_tag {
  background: #f9f1f2;
  border: 1px solid #cf3434;
}

.selected_tag_case {
  background: #cf3434;
}

.unselected_tag {
  background: #fbfcfd;
  border: 1px solid #fbfcfd;
}
.unselected_tag_case {
  background: #eb6161;
}

.tagged_message {
  height: 250px;
  border-radius: 10px;
  align-items: center;
  background: #fbfcfd;
  padding-left: 38%;
  padding-top: 12%;
  font-size: 18px;
  color: var(--rs-text-gray-msg);
}
.analyitc_index {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 10px;
}
.analyticstable_message {
  padding: 10px;
  text-align: center;
  color: var(--rs-gray);
}
.analyticstable_message_expand {
  text-align: center;
  color: var(--rs-gray);
}

.tagname-trimmer {
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#nottag-gauge-chart svg {
  height: 140px;
}
.unselected_tag:hover {
  background: #f9f1f2;
  border: 1px solid #fbfcfd;
}

.analytics-table {
  border: none;
}
.scripts_icon_passed {
  right: -16px;
  background: var(--rs-bg-success-icon);
  position: absolute;
  top: -8px;
  left: 6px;
  border-radius: 50px 15px 0px 50px;
  padding: 4px 6px;
}
.scripts_icon_failed {
  right: -16px;
  background: var(--rs-bg-failed-icon);
  position: absolute;
  top: -8px;
  left: 6px;
  border-radius: 50px 15px 0px 50px;
  padding: 4px 6px;
}
.scripts_icon_warning {
  right: -16px;
  background: var(--rs-bg-warning-icon);
  position: absolute;
  top: -8px;
  left: 6px;
  border-radius: 50px 15px 0px 50px;
  padding: 4px 6px;
}
.scripts_icon_skipped {
  right: -16px;
  background: var(--rs-bg-skipped-icon);
  position: absolute;
  top: -8px;
  left: 6px;
  border-radius: 50px 15px 0px 50px;
  padding: 4px 6px;
}
.scripts_icon_flaky {
  right: -16px;
  background: var(--rs-bg-flaky-icon);
  position: absolute;
  top: -8px;
  left: 6px;
  border-radius: 50px 15px 0px 50px;
  padding: 4px 6px;
}

.script_card_below_text {
  background: var(--border-off-white);
  padding: 10px;
  border-radius: 5px;
}
.script_trend {
  letter-spacing: 0px;
  color: var(--rs-blue);
}
.testscripts_details {
  text-transform: capitalize !important;
}
.testscripts_details_count {
  color: var(--rs-text-label);
}
.scripts_status {
  color: var(--rs-text-label);
  background-color: var(--white);
}

.failure_tabledata_path {
  margin-left: 9%;
}
.failure_tabledata_percentage {
  margin-left: 23%;
}
.failure_table_date {
  margin-left: -9%;
}
.analytics-testscript-table {
  max-height: 300px;
  background: var(--white);
  padding: 5px;
  border-radius: 24px;
}

.analytics-table-header {
  height: 40px;
  padding: 10px 5px;
}
.analytics-table-container {
  max-height: 225px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
}

.analytics-table-body {
  background: var(--white);
}

.testscript-table {
  background: var(--white);
  box-shadow: 0px 0px 6px var(--rs-shadow-black);
  border-radius: 24px;
  padding: 5px;
}
.testscript-table-container {
  max-height: 225px;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
}
.testscript-table-message {
  color: var(--rs-text-gray-msg);
  padding: 10px;
  text-align: center;
}
.testscript-message {
  color: var(--rs-text-gray-msg);
  padding: 10px;
  text-align: center;
}
.passed_scripts_select {
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 12px var(--rs-green);
  border: 1px solid var(--rs-green);
  border-radius: 15px 6px 15px 15px;
}
.passed_scripts_unselect {
  background: var(--white);
}
.failed_scripts_select {
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 12px var(--rs-red);
  border: 1px solid var(--rs-red);
  border-radius: 15px 6px 15px 15px;
}
.failed_scripts_unselect {
  background: var(--white);
}
.warning_scripts_select {
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 12px var(--rs-yellow);
  border: 1px solid var(--rs-yellow);
  border-radius: 15px 6px 15px 15px;
}
.warning_scripts_unselect {
  background: var(--white);
}
.skipped_scripts_select {
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 12px var(--rs-gray);
  border: 1px solid var(--rs-gray);
  border-radius: 15px 6px 15px 15px;
}
.skipped_scripts_unselect {
  background: var(--white);
}
.flaky_scripts_select {
  background: var(--rs-select-off-white);
  box-shadow: 0px 0px 12px var(--rs-orange);
  border: 1px solid var(--rs-orange);
  border-radius: 15px 6px 15px 15px;
}
.flaky_scripts_unselect {
  background: var(--white);
  box-shadow: 0px 0px 12px var(--rs-faded-black);
  border: 1px solid var(--border-off-white);
  border-radius: 15px 6px 15px 15px;
}
.analytics-table {
  border: none;
}
.analytics_section {
  height: 300px;
}
.tag_filtermenu {
  height: auto;
}

.searchBtnIcon {
  background: var(--btnBackground);
  border-radius: 50%;
}

.search-button {
  outline: none;
}
.search-button input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

.search-button input[type='search'] {
  background-color: var(--btnBackground);
  background: url(@assets/analytics/searchIcon-rs.svg) no-repeat 9px center;

  width: 55px;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.search-button input[type='search']:focus {
  width: 130px;
  background: var(--btnBackground);
}
#expand-search input[type='search'] {
  width: 2px;
  height: 10px;
  padding-left: 12px;
  color: transparent;
  cursor: pointer;
  background: url(@assets/analytics/searchIcon-rs.svg) no-repeat 6px center;
  border: none;
}

#expand-search input[type='search']:hover {
  background-color: var(--btnBackground);
}

#expand-search input[type='search']:focus {
  width: 130px;
  padding-left: 32px;
  color: var(--rs-text-color);
  cursor: auto;
  --tw-ring-offset-shadow: none;
  --tw-ring-shadow: none;
  box-shadow: none;
  background-color: var(--btnBackground);
}

.failureHeader {
  color: var(--rs-red);
}

.analyticsTableHeaderText {
  color: var(--rs-blue);
  border-bottom: 1px solid var(--btnBackground);
}

.modalHead-trimmer {
  white-space: nowrap;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand-failer-modalTable {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--rs-border-color);
  border-radius: 15px 5px 5px 15px;
}

.expandIconBtn:hover {
  background: var(--btnBackground);
}
.expandIconContainer {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.expandIconContainer svg {
  height: 15px;
}
.expandIconContainer:hover {
  cursor: pointer;
  background-color: var(--btnBackground);
}
.expIcon {
  transition: all linear 0.3s;
}

.failureReason {
  width: 50%;
  background: var(--white);
  box-shadow: 0px 0px 24px var(--rs-faded-black);
  border-radius: 24px;
  opacity: 1;
}
.failureReasonsChartBlock {
  background-color: var(--white);
  margin: 0px 20px -9px -27px;
  height: 125px;
}

.excecution_comparison {
  color: var(--rs-blue);
}

.exc_comparison_RadioBar {
  background: var(--white);
  box-shadow: 0px 0px 10px var(--rs-faded-black);
  border-radius: 7px;
  color: var(--rs-text-label);
}

.expand-exeComparsion-modalTable {
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  width: 83%;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--rs-border-color);
  border-radius: 15px 5px 5px 15px;
}

.analyticSelectInput {
  border: none;
  background-color: var(--btnBackground);
  border-radius: 8px;
  color: var(--rs-text-label);
  padding: 4px 27px 4px 4px;
  min-width: 25%;
}

.analyticSelectInput:focus {
  box-shadow: 0px 0px 4px var(--rs-faded-black);
  border: none;
}
.analyticSelectInput > option {
  background-color: var(--white);
  padding: 2px;
}

.analyticSelectInput > option:hover {
  background-color: var(--border-off-white);
}

.resetBtn {
  display: inline-block;
  color: var(--rs-blue);
  padding: 7px;
}
.resetBtn:hover {
  display: inline-block;
  border: none;
  border-radius: 7px;
  background-color: var(--btnBackground);
  color: var(--rs-blue);
  padding: 7px;
}

.exe-comparion-section {
  background: var(--bg-exe-comparison);
  border-radius: 24px;
}

.indicator_passed {
  color: var(--rs-green);
}
.indicator_passed > span {
  background: var(--rs-green);
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;
}

.indicator_failed {
  color: var(--rs-red);
}
.indicator_failed > span {
  background: var(--rs-red);
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;
}
.indicator_warning {
  color: var(--rs-yellow);
}
.indicator_warning > span {
  background: var(--rs-yellow);
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;
}

.indicator_skipped {
  color: var(--rs-gray);
}
.indicator_skipped > span {
  background: var(--rs-gray);
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;
}

.indicator_flaky {
  color: var(--rs-orange);
}
.indicator_flaky > span {
  background: var(--rs-orange);
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  width: 7px;
}

.exeModuleGraph,
.exeScriptGraph {
  width: 170px;
}

.exe-compariosn-table-shadow {
  max-height: 300px;
  background: var(--white);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--rs-faded-black);
}
.comparison-section {
  box-shadow: 0px 0px 10px var(--rs-faded-black);
  padding: 10px 0px 20px;
  background: var(--white);
  border-radius: 10px;
}

.tableHead-trimmer {
  white-space: nowrap;
  max-width: 370px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Failure Reason Block styling :: BEGIN */
.failerReason_card {
  max-height: 130px;
  width: 89%;
  margin: 10px 15px;
  border-radius: 24px;
  border: 1px solid var(--rs-red);
  opacity: 0.5;
}

.failerReason_card:hover {
  box-shadow: 0px 0px 12px var(--rs-red);
  transition: all ease-out 0.3s;
}

.failerReason_card_selected {
  max-height: 130px;
  width: 89%;
  margin: 10px 15px;
  border-radius: 24px;
  border: 1px solid var(--rs-red);
  box-shadow: 0px 0px 12px var(--rs-red);
  transition: all ease-out 0.3s;
}

.failureReaon_CardHeader {
  color: var(--white);
  background: var(--rs-red);
  padding: 5px 10px;
}

.failure_Carosel {
  background-color: var(--white);
  max-width: 1100px;
  min-width: 700px;
}

.carouselArrow {
  height: 20px;
  width: 20px;
  margin: 0px 13px;
  z-index: 3;
}

.tagChartCircle {
  width: 155px;
  margin-top: -21px;
}
.tagFailedCount {
  display: flex;
  flex-direction: column;
  margin-left: -30px;
  margin-top: -30px;
  word-break: break-word;
  gap: 4px;
}

.tagged_carosal > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
  margin: 0 3px;
}

/* Failure Reason Block styling :: BEGIN */

/* Failure Analysis custom Progress ::  START */

.progressContainer {
  cursor: pointer;
  width: 90%;
  height: 20px;
  border: 2px solid;
  border-radius: 10px;
  background-color: transparent;
  padding: 2px;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
}
.progressValue {
  border: none;
  border-radius: 10px;
  height: 90%;
  transition: all 0.3s linear;
}
/* Failure Analysis custom Progress ::  END */

/* tab navigation styling :: start */
.tab-navbar-section {
  padding: 0px 4px;
  position: sticky;
  top: 10px;
  z-index: 1;
}

.tab-header {
  position: sticky;
  top: 0px;
  z-index: 1;
}

/* tab navigation styling :: end*/
.scriptTrendBlock {
  width: 100%;
  overflow: hidden;
}
.scriptTrendBlock::-webkit-scrollbar {
  display: none;
}

/* SearchBar Common component styling :: START */

@keyframes slide-from-left {
  0% {
    left: 1%;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

.searchDisplay {
  display: block;
  position: relative;
  animation: slide-from-left 0.3s ease-in;
}

.searchDisplay > .expanded-tab-search {
  background-image: url(@assets/analytics/searchIcon-rs.svg);
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 25px 15px;
  border-radius: 30px;
  height: 25px;
  outline: none;
  background-color: var(--btnBackground);
  border: 1px solid var(--border-search-bar);
  cursor: text;
  padding: 12px 18px 12px 23px;
  /* max-width:auto; */
}
.searchDisplay > .expanded-tab-search:focus {
  max-width: 200px;
  cursor: auto;
  --tw-ring-offset-shadow: none;
  --tw-ring-shadow: none;
  box-shadow: none;
  background-color: var(--btnBackground);
  -webkit-transition: ease-in-out;
  transition: all ease-in-out 0.3s;
}

.searchDisplay > input[type='search']::-webkit-search-cancel-button {
  visibility: hidden;
}

.searchDisplay > .close_search {
  background: var(--rs-blue);
  opacity: 0.8;
  border-radius: 10px;
  color: var(--white);
  display: inline-block;
  height: 18px;
  margin-left: -22px;
  margin-top: 4px;
  position: absolute;
  width: 18px;
  z-index: 2;
  padding: 4px;
  cursor: pointer;
}
.searchDisplay > .close_search:hover {
  opacity: 1;
  transition: all ease-in-out;
}
.searchDisplay > .close_search > .splitBar {
  position: absolute;
  top: 0px;
  color: #111;
  left: -5px;
  opacity: 1;
  pointer-events: none;
  display: inline-block;
  height: 16px;
  width: 0px;
  border: 1px solid #111;
  cursor: none;
}
.searchDisplay > .bar-searchIcon-disable {
  border-radius: 12px;
  display: inline-block;
  height: 22px;
  margin-left: -50px;
  margin-top: 0px;
  position: absolute;
  width: 22px;
  z-index: 2;
  padding: 5px;
  pointer-events: none;
}
.searchDisplay > .bar-searchIcon-active {
  border-radius: 12px;
  display: inline-block;
  height: 22px;
  margin-left: -50px;
  margin-top: 0px;
  position: absolute;
  width: 22px;
  z-index: 2;
  padding: 5px;
  cursor: pointer;
}

.searchhidden > .expanded-tab-search {
  display: none;
}

.searchhidden > .open_search {
  display: block;
  border: none;
  border-radius: 50%;
  background: url(@assets/analytics/searchIcon-rs.svg) no-repeat 5px center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.searchhidden > .open_search:hover {
  background-color: var(--btnBackground);
  transition: all ease-in-out;
}

/* SearchBar Common component styling :: START */
