@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

#addRows {
  margin-left: 16%;
  width: 180px;
}

.cont_div {
  width: 100%;
  margin: 0 auto;
}

.disabled-btn {
  text-align: center;
  letter-spacing: 0px;
  color: $grey_dark;
  padding: 4px 14px;
  border-radius: 3px;
}

.query-disabled-btn {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: $grey_dark;
  border-radius: 2px;
  opacity: 1;
  border: 1px solid $grey;
  padding: 0px 14px 2px 14px;
}

.query-disabled-btn:hover {
  color: $blue_dark;
  border: 1px solid $blue_dark;
}

.actiontooltip {
  position: relative;
  display: inline-block;
}

.actiontooltip .actiontooltiptext {
  visibility: hidden;
  width: 67px;
  background-color: $grey_dark;
  color: $white_original;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 68%;
  left: 165%;
  margin-top: 10px;
  margin-left: -60px;
}

.opacity_search {
  opacity: 1;
}

.infoIcon {
  color: $grey_dark;
  opacity: 1;
}

.infoIcon:hover {
  color: $blue_dark;
}

.box_value {
  min-width: 70%;
  min-height: 75px;
  max-height: 50vh;
  width: 22vw;
  background-color: $white_original;
  background: $white_original 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  border: 1px solid $white_original;
  opacity: 1;
  border-radius: 7px;
  text-align: left;
}

.request-headers-section {
  position: absolute;
  top: 105px;
  right: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}
.request-headers-section .headers-btn {
  border: 1px solid $grey;
  border-radius: 2px;
  opacity: 1;
  letter-spacing: 0px;
  color: $grey_dark;
  padding: 4px 14px;
}
.request-headers-section .btn-right {
  color: $grey_dark;
  margin-right: 8px;
}
.request-headers-section .headers-btn:hover {
  color: $blue_dark;
  border: 1px solid $blue_dark;
}
.request-headers-section .btn-right:hover {
  color: $blue_dark;
}
.proj-count-request-header {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0px;
  color: $blue_dark;
  opacity: 1;
  background: $white_dark 0% 0% no-repeat padding-box;
  border-radius: 28px;
  margin-right: 12px;
  @extend .fontPoppinsSemiboldMd;
}
.query-parameter-section {
  position: absolute;
  top: 105px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 38px;
}

.query-parameter-section .query-parameter-btn {
  border: 1px solid $grey;
  border-radius: 2px;
  opacity: 1;
  font-size: 14px;
  letter-spacing: 0px;
  color: $grey_dark;
  padding: 0px 14px 2px 14px;
}
.query-parameter-section button.query-parameter-btn:hover {
  color: $blue_dark;
  border: 1px solid $blue_dark;
}
.query-parameter-section .btn-right {
  color: $grey_dark;
  margin-right: 6px;
}
.query-parameter-section .btn-right:hover {
  color: $blue_dark;
}
.proj-count-query-header {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0px;
  color: $blue_dark;
  opacity: 1;
  background: $white_dark 0% 0% no-repeat padding-box;
  border-radius: 28px;
  margin-right: 12px;
  @extend .fontPoppinsSemiboldMd;
}
.proj-count-query-header:hover,
.proj-count-request-header:hover {
  font-size: 0 !important;
}

.proj-count-request-header:hover::before {
  font-size: 12px;
  content: attr(data-hover);
}
.proj-count-query-header:hover::before {
  font-size: 12px;
  content: attr(data-hover);
}
.query-param-table-head {
  background: $skyblue_dark;
  color: $rs_primary;
  letter-spacing: 0;
  opacity: 1;
  height: 35px;
}
.query-param-scroll {
  overflow: auto;
  height: 325px;
  display: block;
}
.request-section-disable-btn {
  border: 1px solid $grey_light;
  border-radius: 2px;
  opacity: 0.5;
  letter-spacing: 0px;
  color: $grey_dark;
  padding: 4px 14px;
}
