// @import "@styles/_variables";
@import '@src/css_config/variable.scss';
.text-success {
  color: $rs_analytics_pass;
}
.text-danger {
  color: $rs_analytics_fail;
}
.text-warning {
  color: $rs_analytics_warning;
}

.tick-svg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: none;
  :global {
    .MuiSvgIcon-root {
      width: 70%;
      height: auto;
    }
  }
}

.popup-title {
  margin-bottom: 8px;
}
.download-title {
  background-color: transparent !important;
  border-color: $rs_analytics_blue_header !important;
  color: $rs_analytics_blue_header !important;
  height: 27px;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $rs_analytics_blue_header !important;
    color: $white_original !important;
  }
}

.statement {
  color: $license_tooltip_bg;
  &.text-dark {
    color: $license_tooltip_bg;
  }
}
