.cancelBtn {
  width: 75px;
  height: 32px;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
}
.btnStyle {
  width: 63px;
  height: 32px;
  border: 1px solid var(--primary-1648c6);
  background: #1648c6 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
  color: #f6f7f9;
}
.resultHeading {
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px;
  font-family: 'Open Sans';
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}
.tHeading {
  height: 17px;
  top: 269px;
  left: 184px;
  font-family: 'Poppins-SemiBold';
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #3c3838;
  opacity: 1;
}
.tableStyle {
  height: 40px;
  background: rgb(240, 243, 249);
  display: flex;
  width: 100%;
}
.headingData {
  font-size: 14px;
  margin-top: 10px;
  color: #727171;
  padding-left: 2rem;
  font-family: 'Poppins-Medium';
}
.arrowUp {
  color: blue;
  margin-left: 4px;
  margin-top: -0.6rem;
  height: 34px;
}
.arrowDown {
  color: blue;
  margin-left: -24px;
  margin-top: 1.3rem;
}
