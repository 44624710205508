@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.select-project-dropdown-wrapper {
  :global {
    margin-left: 3px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: $grey_light;
    border: none !important;

    .react-dropdown-select-item {
      @include fontPoppins(Poppins-Regular, 14px);

      &:hover {
        color: $primary;
      }
    }

    .react-dropdown-select-input {
      margin-left: -1px;
      @include fontPoppins(Poppins-Regular, 14px);
    }

    .react-dropdown-select-item-selected {
      &:hover {
        color: $white_original;
      }
    }
  }
}
