@import '../../../css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.fontFamilyOpenSans {
  font-family: 'OpenSans-SemiBold';
}

.fontFamilyOpenRegular {
  font-family: 'OpenSans-Regular';
}

.collumnCell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 155px;
  float: left;
}

.collumnCellSpan {
  color: $whitish_blue;
}

.backgroundColor {
  background-color: $white_light;
}

.content_area_style_tcTemplate {
  background: $white_light 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .headingStyle {
    @extend .fontPoppinsSemiboldXlg;
    top: 382px;
    left: 207px;
    height: 17px;
    color: $grey_dark;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
  }

  .common-label {
    @extend .fontPoppinsRegularMd;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
  }

  .contentDataStyle {
    width: 55%;
    letter-spacing: 0px;
    color: $grey_darkest;
  }

  .contentDataStyleMTC {
    width: 100%;
    letter-spacing: 0px;
    color: $grey_darkest;
  }

  .editorContentDataStyle {
    width: 100%;
    letter-spacing: 0px;
    color: $grey_darkest;
    overflow: scroll;
    &::-webkit-scrollbar {
      height: 5px;
    }
  }

  .editContentDataStyle {
    letter-spacing: 0px;
    color: $grey_darkest;
  }

  .editContentDataStyle_TextArea_Date {
    width: 100%;
    letter-spacing: 0px;
    color: $grey_darkest;
  }

  .iconStyle {
    width: 18px;
    height: 18px;
    font-size: large;
  }
}

.editDate {
  margin-left: 20px;
  margin-top: -6px;
}

.datePickerAlignment {
  width: 360px;
}

.modal_dialog_TC {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: $white_light;
    border: 1px solid $grey_white;
    box-shadow: 0px 0px 8px $black_original;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 87vh;

    .modal_header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      h2.title {
        font-size: 16px;
        font-weight: 600;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      padding: 24px 24px 0;
      height: 50vh;
      overflow-y: scroll;
      overflow-x: none;
    }

    .modal_body_DropeDown {
      height: auto;
      padding: 24px 24px 0;
    }

    .modal-footer {
      border-top: 1px solid $white_dark;
      padding: 10px 10px 0;
      text-align: right;
      margin-bottom: 20px;

      button {
        margin-left: 18px;
      }
    }
  }
}

// --------------------------------------------------------------------
.modal_dialog_sb {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: $white_light;
    border: 1px solid $grey_white;
    box-shadow: 0px 0px 8px $black_original;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 40vh;

    .modal_header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      h2.title {
        font-size: 16px;
        font-weight: 600;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      padding: 24px 24px 0;
      height: calc(100% - 110px);
      flex: 1 1;

      overflow-y: auto;
    }

    .modal_body_DropeDown {
      height: auto;
      padding: 24px 24px 0;
    }

    .modal-footer {
      border-top: 1px solid $white_dark;
      padding: 10px 10px 0;
      text-align: right;
      margin-top: 10px;

      button {
        margin-left: 18px;
      }
    }
  }
}

// =============================

.modal_dialog_sm {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: $white_light;
    border: 1px solid $grey_white;
    box-shadow: 0px 0px 8px $black_original;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 62vh;

    .modal_header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      h2.title {
        font-size: 16px;
        font-weight: 600;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      padding: 24px 24px 0;
      overflow-y: scroll;
      overflow-x: none;
      height: auto;

      input[type='file'] {
        color: rgba(0, 0, 0, 99%);
      }
    }

    .modal_body_DropeDown {
      height: auto;
      padding: 24px 24px 0;
    }

    .modal-footer {
      border-top: 1px solid $white_dark;
      padding: 10px 10px 0;
      text-align: right;
      margin-bottom: 20px;
      margin-top: 10px;

      button {
        margin-left: 18px;
      }
    }
  }
}

// =================================

.modal_dialog_md {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: $white_light;
    border: 1px solid $grey_white;
    box-shadow: 0px 0px 8px $black_original;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 76vh;

    .modal_header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      h2.title {
        font-size: 16px;
        font-weight: 600;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      padding: 24px 24px 0;
      overflow-y: scroll;
      overflow-x: none;
      height: 38vh;
    }

    .modal_body_DropeDown {
      height: auto;
      padding: 24px 24px 0;
    }

    .modal-footer {
      border-top: 1px solid $white_dark;
      padding: 10px 10px 10px;
      text-align: right;
      margin-bottom: 20px;
      margin-top: 10px;

      button {
        margin-left: 18px;
      }
    }
  }
}

.actionIcons {
  visibility: hidden;
}

.configHover:hover .actionIcons {
  visibility: visible;
}

.testStepTable {
  border: 0.05rem solid rgba(197, 197, 197, 0.486);
  border-spacing: 0rem;
}

.modalBody_Expand {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: $white_light;
    border: 1px solid $grey_white;
    box-shadow: 0px 0px 8px $black_original;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 96vw;

    .modal_header {
      border-bottom: 1px solid $white_dark;
      padding: 12px 12px;
      position: relative;

      h2.title {
        font-size: 16px;
        font-weight: 600;
        color: $grey_darkest;
      }

      .close {
        color: $grey_darkest;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      overflow-y: auto;
    }
  }
}

.columnResizer {
  cursor: col-resize;
  width: 1px;
  padding: 0.5px;
}

@media (min-width: $sm) {
  .responsiveTestDetails {
    width: auto;
  }

  .modal_dialog_TC {
    .modal_container {
      height: 87vh;
    }
  }
}

@media (min-width: $md), (min-width: $lg), (min-width: $xl) {
  .responsiveTestDetails {
    width: auto;
  }

  .modal_dialog_TC {
    .modal_container {
      height: 87vh;
    }
  }
}

.create_Issue_Modal {
  width: 192px;
  background-color: #ffffff;
  z-index: 1000;
}

.content-height {
  overflow-y: auto;
  overflow-x: hidden;
  height: 63vh;
}

#selectCustomField {
  font-size: small;
  padding-bottom: 0.5px;
  border-bottom: 2px solid #cecaca;
}

::placeholder {
  font-size: small;
  padding-bottom: 0.5px;
}

.add-field-drop-down {
  margin-left: 430px;
}

.MuiAccordion-rounded:last-child {
  box-shadow: none;
}

.backgroundColor {
  background: $white_light 0% 0% no-repeat;
}

.project_label_TCM {
  @extend .fontPoppinsSemiboldLg;
  color: var(--primary-1648C6);
  text-align: left;
  letter-spacing: 0px;
  color: $black_original;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.textarea-resize {
  resize: none;
}

.content_area_style_TCM {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  top: 195px;
  left: 112px;
  height: calc(100vh - 89px);
}

.MuiList-root {
  .test-case-select {
    &.MuiButtonBase-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px;
      padding-left: 10px;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}

.parentImgDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

#secImg {
  width: 500px;
  height: 430px;
  margin-top: 50px;
}

.textarea_content_style_Defect {
  border: 1px solid #989797;
  border-radius: 5px;
  height: 133px !important;
  max-height: 133px;
  resize: none;
  overflow: scroll !important;
}

.attachmentinfoFontColor {
  color: $grey_dark;
  @extend .fontPoppinsRegularSm;
}

.bug-modal-style {
  margin-left: -171px;
  margin-top: 24px;
}

#text_field {
  @extend .fontPoppinsMediumLg;
}

.word_wrap {
  overflow: hidden;
  white-space: nowrap;
}

.text_width_100 {
  width: 100% !important;
}

.left-alert {
  position: absolute;
  display: flex;
  justify-content: center;
}
