@import '@styles/rs_fonts.scss';

@import '../../../css_config/variable.scss';

$description-border: #dbd8d8;
.pluginHeader {
  display: flex;
  align-items: center;
  height: 37px;
  width: 80vw;
  background: $skyblue_light 0% 0% no-repeat padding-box;
  opacity: 1;
}

.pluginHeaderFont {
  @extend .fontPoppinsSemiboldXlg;
  color: var(--text-3c3838);
  margin-left: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

.iconsWidth {
  width: 92px;
  height: 82px;

  span {
    img {
      height: auto;
      width: 40px;
    }
  }
}

.pluginRowHeight {
  height: 137px;
}

.iconsWidth:hover {
  border-radius: 5px;
  box-shadow: 0px 0px 6px #00000029;
  background-color: #f0f3f9;
}

.iconText {
  @extend .fontPoppinsRegularMd;
  text-align: center;
  color: var(--text-3c3838);
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

#hr {
  border: 1px solid #9e9d9d;
  width: 98%;
  margin-left: 0.75rem;
}

.upload_file_area {
  height: 16vh;
  width: 98.4%;
  overflow: hidden;
}

.import_jar_modal {
  height: 300px;
  width: 782px;
}

.modal_footer {
  border-top: 1px solid #e5ecfc;
  padding: 8px;
  text-align: right;
  margin-top: 45px;
  margin-bottom: 10px;

  button {
    margin-left: 18px;
  }
}

.browserStack_check_modal {
  width: 660px;
  height: fit-content !important;
}

.dataCenter {
  color: #525252;
}

#defaultDataCenter {
  font-size: small;
}

.tableHeader {
  background-color: #f0f3f9;
}

.tableRow {
  display: flex;
}

.modal_Height {
  height: fit-content !important;
}

.modal_dialog {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    background-color: #fbfcfd;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 8px #000000a1;
    padding: 0;
    max-width: calc(100% - 100px);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 43vh;

    .modal_header {
      border-bottom: 1px solid #e5ecfc;
      padding: 12px 12px;
      position: relative;

      h2.title {
        @extend .fontPoppinsMediumLg;
      }

      .close {
        color: #3c3838;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .modal_body {
      padding: 24px 24px 0;
      height: calc(100% - 110px);
      flex: 1 1;

      .mat-card {
        height: 100%;
      }

      overflow-y: auto;
    }

    .modal_footer {
      border-top: 1px solid #e5ecfc;
      padding: 10px 10px 0;
      text-align: right;

      button {
        margin-left: 18px;
      }
    }
  }
}

.instance-details-table {
  max-height: 49vh;
  overflow: scroll;
}

.modal_height {
  height: 90vh;
}

.description_box {
  height: 210px;
  overflow: auto;
  resize: none;
  background: none;
  font-size: 12px;
  color: cadetblue;
  border: 1px solid #dbd8d8;
  border-radius: 4px;
}

.MuiAutocomplete-option {
  @extend .fontPoppinsRegularMd;
}

.encode {
  -webkit-text-security: disc;
}

.alert_position_style_Bug_Modal {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: -64px;
}

.JIRA-name-trimmer {
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
}

.content_area_style_Int {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  top: 195px;
  left: 112px;
  height: calc(100vh - 89px);
}

.base_h {
  height: 148px;
}

.description-field {
  border: 1px solid $description-border;
}
.description-field:focus {
  border: 1px solid $description-border;
}
.card-container {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  width: 18vw;
  height: 14vh;
  &:hover {
    box-shadow: 0 0 5px 3px #e2e8f0;
  }
}
.plugin-img-container {
  width: 45px;
  height: auto;
}

@media screen and (max-width: 1041px) {
  .plugin-Content {
    font-size: 5px;
  }
  .plugin-img-container {
    width: 45px;
    height: auto;
  }
  .card-container {
    height: 13vh;
    &:hover {
      box-shadow: 0 0 5px 3px #e2e8f0;
    }
  }
}

.create-instance-modal {
  [class*='-MuiAutocomplete-endAdornment'] {
    top: auto;
  }
}
