#headData {
  color: rgb(79, 0, 255);
  cursor: pointer;
  border-bottom: 1px solid blue;
  width: 100%;
  margin-left: -1rem;
}
.hearderData {
  width: 44.3rem;
  font-size: 16px;

  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}
.btnGroup {
  display: flex;
  justify-content: flex-end;
}
.Headerbreadcrumb {
  top: 99px;
  left: 173px;
  width: 73px;
  height: 16px;
  font: normal normal 16px Segoe UI;
  color: #1648c6;
  text-align: left;
  font: normal normal normal 14px Segoe UI;
  letter-spacing: 0px;
  color: rgba(22, 72, 198, 1);
  opacity: 1;
  font-family: 'Poppins-Regular';
}
.activeBreadcrumb {
  top: 99px;
  left: 285px;
  width: 143px;
  height: 16px;
  text-align: left;
  font: normal normal normal 14px Segoe UI;
  letter-spacing: 0px;
  color: rgba(82, 82, 82, 1);
  opacity: 1;
  font-family: 'Poppins-Regular';
}
.heading {
  font-size: 14px;
  line-height: 24px;
  color: #1648c6;
  padding-right: 6px;
  font-weight: 600;
  border-bottom: 1px solid #1648c6;
  font-family: 'Poppins-Regular';
  margin-left: -14px;
  cursor: pointer;
}
.history {
  margin-left: 20px;
  font-family: 'Poppins-Medium';
  color: #1648c6;
  opacity: 1;
  letter-spacing: 0px;
  font-weight: 500;
}
.history:hover {
  border-bottom: 1px solid #1648c6;
}
.primaryBtn {
  width: 63px;
  height: 32px;
  background: #1648c6 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
  color: white;
}
.cancelBtn {
  background: white;
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  color: black;
  margin-right: -1px;
  width: 75px;
  height: 32px;
}
.dtConfig {
  margin-left: 20px;
  margin-top: -0.5rem;
  color: #3c3838;
  opacity: 1;
  width: 142px;
  height: 19px;
  font-family: 'Poppins-SemiBold';
}
.historyHeading {
  width: 174px;
  height: 19px;
  font-family: 'Open Sans';
  letter-spacing: 0px;
  color: #1648c6;
  opacity: 1;
}
