@import '@src/css_config/variable.scss';

.popup-container {
  width: 276px;
  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.custom-footer {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: $ff-pop-up-footer-bg;
  .footer-left {
    width: 50%;
    .link {
      color: $ff-primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .footer-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }
}
