@import '@styles/rs_fonts.scss';
@import '../../css_config/variable.scss';

// input[type='file'] {
//     color: rgba(0, 0, 0, 0)
// }

.editFileModal {
  width: 640px;
  height: 290px;
}

.uploadFileModal {
  height: 650px;
  width: 640px;
}

.action-element {
  display: none;
}

tr:hover .action-element {
  display: inline;
}

.fileName {
  white-space: nowrap;
  width: 251px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
}

.downloadFileContent {
  @extend .fontPoppinsSemiboldMd;
  color: $ff-text;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  width: 100%;
}

.tableHeadingStyle {
  height: 48px;
  padding: 8px 14px;
}

.tableheader {
  @extend .fontPoppinsSemiboldSm;
  color: #3c3838;
  text-align: left;
}

.tableheaderbackground {
  background: #f3f4f6;
}

.tablebodybackground {
  background-color: white;
}

.tablebodybackground:hover {
  background-color: white;
}

.tabletext {
  @extend .fontPoppinsRegularMd;
  text-align: left;
}

.separator {
  border: none;
  border-top: 2px dotted #ccf1fe;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.notabledata {
  @extend .fontPoppinsRegularMd;
  color: #3c3838;
}

.fileButton {
  display: block;
  margin-top: 4px;
  cursor: default !important;
}

.fileButton span {
  color: rgba(0, 0, 0, 0);
}

.folder-details-modal {
  width: 640px;
  height: 581px;
}

.searchIcon {
  color: #727171;
}

.divider-file {
  border-top: 2px soild #ccf1fe;
  height: 1px;
}

.file-Name {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: #525252;
  font-size: 18px !important;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  font-size: 18px !important;
}

.MuiFormLabel-root.Mui-error {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  font-size: 18px !important;
}
