@import './../../sass/sass-utils/color';
@import './../../sass/sass-utils/variable';
@import './../../sass/sass-utils/mixins';

.comment {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  &:not(:last-child) {
    margin: 0 0 16px 0;
  }
  & .comment-col1 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0;
    & img {
      margin: 0 0 6px 0;
    }
  }
  & .comment-col2 {
    width: inherit;
    margin: 0 0 0 12px;
    & h6 {
      color: $title-text-color;
      font-size: $small-text;
      font-weight: $font-weight-medium;
      line-height: 1.375rem;
    }
    & p {
      color: $body-text-color;
      font-size: $regular-text;
      white-space: pre-wrap;
      word-break: break-word;
      line-height: 20px;
      @media screen and (max-width: 425px) {
        font-size: $small-text;
      }
    }
    & .comment-footer {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      margin: 8px 0 0 0;
      position: relative;
      right: 2px;
    }
    & .edit-comment-footer {
      @extend .comment-footer;
      justify-content: flex-end;
    }
    & .replyComments {
      display: flex;
      margin: 15px 0 0 -5px;
      position: relative;
      left: -10px;
      width: 100%;
      & .threadComments {
        & .avatar {
          margin: 0;
        }
        @extend .replyComments;
        margin: 0 0 0 -5px;
        & .comment-col2 {
          margin-bottom: 8px;
        }
      }
      & .threadComments:nth-of-type(2) {
        @extend .replyComments;
        margin-bottom: 0;
      }
    }
  }
}
