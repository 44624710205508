@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.detailsOfproject {
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  margin: 8px;
  padding: 2px;
  margin-right: 36px;
}
.user-modal-dropdown {
  border-bottom: 2px solid $grey_light;
}

.background-for-btn {
  background: $skyblue_dark;
}
