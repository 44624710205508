$border-muted: #00000029;
$dark: #3c3838;
$muted: #727171;
$primary: #434db8;
$border-gray: #989797;
$sky-blue-light: #fbfcfd;
$danger: #ea0322;
$white: #ffffff;
$success: #5ea606;
$black: #000000;
$gray: #e1dfdf;
$sky-blue: #f0f3f9;
$sky-blue-medium: #f7fcf8;
$danger-light: #fff7f8;
$sky-blue-dark: #ccf1fe;
$dark-gray: #b5b5b5;
$primary-light: #3377f9;
$primary-medium: #3a62c9;
$primary-bg-dark: #303aa2;
$gray-light: #eeeded;
$gray-200: #dfdfdf;
$davy-gray: #525252;
$green: #079328;
$green-200: #07932833;
$warning: #e87807;
$red: #c50303;
$red-200: #c5030333;
$black-110: #0000001c;
$white-grey: #f5f6fc;
$white-medium: #d9dbf1;
$white-light: #fbfbfe;
$black-white: #fff0;
$rs_black: #00000080;
$rs_blue_font: #454db2;
$rs_password_info_icon: #797979;
$rs_grey_name: #6f7075;
$rs_success: #5ca700;
$rs_warning: #ffc600;

$rs_disabled_text: #00000061;
$rs_light_grey: #c9c9c9;
$rs_scroll_color_grey: #e6e6e6;
$rs_tabs_border_color: #e0e0e0;
$Gray97: #f7f7f7;
$license_row_card_bgg: #f6f7f9;
//coupon
$coupon-green: #008000;
$coupon-red: #ff0000;
$license_filter_blue: #f1f3f8;
$cancel_btn_border: #6f7070;
$cancel_btn_hover_color: #707070;
$license_heading_black: #3c3838;
$license_label_gray: #8f8e8e;
$rs_tabs_border_color: #e0e0e0;
$select_dropdown_bg: #fbfbfe;
$alert_footer_bg: #f0f3f9;

//V2.0 colors
$ff-primary: #434db8;
$ff-red-text: #a80000;
$ff-red-bg: #c30000;
$ff-gray-text: #6f7070;
$ff-gray-bg: #707070;
$ff-white: #ffffff;
$ff-text: #3c3838;
$ff-icon-default: #808080;
$ff-border: #e0e0e0;
$ff-placeholder: #c4c3c3;
$ff-disable: #d8d7d7;
$ff-hover-bg: #e0e0e0;
$ff-label-header: #8f8e8e;
$ff-success: #5ca700;
$ff-error: #c50303;
$ff-warning: #ffc600;
