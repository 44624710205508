@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';
.variable-dropdown {
  position: absolute;
  height: 400px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  .search-icon .MuiSvgIcon-root {
    font-size: 1rem;
  }
}
.webservice-variable-dropdown {
  @extend .fontPoppinsRegularSm;
  display: flex;
  align-items: center;
  height: 35px;
}
.webservice-variable-dropdown-container {
  @extend .fontPoppinsSemiboldMd; 
  color: $deep_blue;
  font-weight: bolder;
}
