@import '@styles/_variables.scss';

.close-icon-wrapper {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
}

.wrapper {
  display: flex;
  padding: 32px;
  max-height: 90vh;
  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }

  .title-description {
    color: $license_heading_black;
  }

  .left-section {
    flex: 0 0 50%;
    width: 50%;
    padding: 20px;
    border-right: 1px solid $border-gray;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .image {
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    .title {
      margin: 18px 0px;
      text-align: center;
    }

    .title-description {
      text-align: center;
    }
  }

  .right-section {
    flex: 0 0 50%;
    width: 50%;
    padding: 30px 0px 30px 60px;
    height: 100%;

    .title-description {
      margin-bottom: 18px;
    }
  }
}

.close-icon-button {
  border: 1px solid $cancel_btn_border;
  color: $cancel_btn_border;
  width: 51px;
  height: 25px;
  border-radius: 4px;
  transition: all linear 0.3s;
}
.close-icon-button:hover {
  background-color: $cancel_btn_hover_color;
  color: $white;
}
.switch-license-btn {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 5px;
  padding: 5px 12px;
  transition: all linear 0.3s;
}
.switch-license-btn:hover {
  background-color: $primary;
  color: $white;
}
.license-name {
  color: $license_heading_black;
}
