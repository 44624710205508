@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.licenseDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .fixed {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $rs_analytics_blue_header;
      color: $white_original;
      padding: 5px 5px;
      height: 35px;

      .headerTitle {
        @extend .fontPoppinsMediumSm;
      }
      .closeIcon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .closeIcon:hover {
        border: 1px solid;
        cursor: pointer;
      }
    }
    .licenseNameBlock {
      margin-top: 10px;
      display: flex;
      padding: 10px;
      background-color: $rs_grey_medium;
      height: 35px;
    }
  }
  .detailsBlock {
    width: 100%;
    height: 78%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;

    .privilegeBlock {
      display: flex;
      align-items: center;
      padding: 10px;
      max-height: 40px;
    }
    .featureBlock {
      width: 100%;
      display: flex;
      padding: 10px;
      align-items: flex-start;
    }

    .expiryDetails {
      width: 100%;
      padding: 10px;
      border-bottom: 2px dashed $grey_border;
    }
    .contactBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    .futurePlanDetails {
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .futureLicensePlanDetails {
        width: 100%;
        min-height: 320px;
        padding: 10px;
        border: 1px solid $rs_blue_shadow;
        border-radius: 10px;
        box-shadow: 0px 3px 23px $rs_blue_shadow;
        display: flex;
        flex-direction: column;

        .futureHeader {
          width: 100%;
          display: flex;
          align-items: center;
        }
        .featureBlock {
          width: 100%;
          display: flex;
          padding: 10px;
          align-items: flex-start;
        }
      }
    }
  }
  .buttonBlock {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    background-color: $rs_bg_whitgrey;
    padding: 5px 10px;
  }
  .leftBlock {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $black_original;
  }
  .rightBlock {
    width: 60%;
    display: flex;
    align-items: center;
    color: $black_original;
  }
  .active {
    color: $active_license;
  }
  .expired {
    color: $red;
  }
  .expiringSoon {
    color: $license_about_to_expire;
  }
  .inactive {
    color: $grey_dark;
  }
  .email {
    color: $rs_analytics_blue_header;
    @extend .fontPoppinsSemiboldSm;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $rs_analytics_blue_header;
  color: $white_original;
  padding: 5px 10px;

  .headerTitle {
    @extend .fontPoppinsMediumSm;
  }
  .closeIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .closeIcon:hover {
    border: 1px solid;
    cursor: pointer;
  }
}
.licenseNameBlock {
  margin-top: 10px;
  display: flex;
  padding: 10px;
  background-color: $rs_grey_medium;
}
.leftBlock {
  width: 40%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $black_original;
}
.rightBlock {
  width: 60%;
  display: flex;
  align-items: center;
  color: $black_original;
}
.privilegeBlock {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  max-height: 30px;
}
