@import './variable.scss';
@import '@styles/rs_fonts.scss';

.label-color {
  color: $rs_analytics_blue_header;
}

.table-border-bottom {
  border-bottom: 1px solid $grey_light;
}
.text-color {
  color: $grey_darkest;
}
