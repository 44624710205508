.drag {
  visibility: hidden;
}
.visibleData:hover .drag {
  visibility: visible;
}
.selectedElm {
  font-size: 14px;
  line-height: 24px;
  color: #1648c6;
  padding-right: 19px;
  font-weight: 600;
  border-bottom: 1px solid #1648c6;
  font-family: 'Poppins-SemiBold';
  margin-left: -14px;
  cursor: pointer;
}
.nonSelectedElm {
  font-size: 14px;
  font-weight: 500;
  color: rgb(22, 72, 198);
  font-family: 'Poppins-Regular';
}
.locatorSelectedElm {
  font-size: 14px;
  line-height: 24px;
  color: #1648c6;
  padding-right: 19px;
  font-weight: 600;
  border-bottom: 1px solid #1648c6;
  font-family: 'Poppins-SemiBold';
  margin-left: 16px;
  cursor: pointer;
}
.primaryButton {
  color: #f6f7f9;
  width: 141px;
  height: 32px;
  background: #1648c6 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 2px;
  opacity: 1;
}
