@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';

.warning-content-block {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding-top: 15px;
  max-width: 432px;
  .warning-text {
    color: $ff-warning;
  }
  .checkbox-content {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-left: -7px;
  }
}

.warning-footer {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $alert_footer_bg;
  padding: 0 12px;
  .footer-left {
    width: 50%;
    .footer-link {
      color: $ff-primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .footer-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    .cancel-btn {
      width: 67px;
      height: 25px;
      color: $ff-gray-text;
      border: 1px solid $ff-gray-text;
      border-radius: 4px;
      &:hover {
        background-color: $ff-gray-bg;
        border: 1px solid $ff-gray-bg;
        color: $ff-white;
      }
    }
    .continue-btn {
      width: 67px;
      height: 25px;
      color: $ff-primary;
      border: 1px solid $ff-primary;
      border-radius: 4px;
      &:hover {
        background-color: $ff-primary;
        border: 1px solid$ff-primary;
        color: $ff-white;
      }
    }
  }
}
.user-height {
  height: 311px;
}
.admin-height {
  height: 276px;
}
.custom-tooltip {
  :global {
    .MuiTooltip-tooltip {
      background-color: $ff-text;
      border: 1px solid $ff-primary;
    }
    .MuiTooltip-arrow {
      color: $ff-text;
    }
  }
}
