/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */
:root {
  --lightWhite: #f6f7f9;
  --hoverWhite: #e5ecfc;
  --lightBlue: #1648c6;
  --darkBlue: #434db8;
  --light_white_50: #f5f6fc;
  --light_white_border: #e0e3f6;
  --white-original: #ffffff;
  --gray_light_50: #cacaca;
}
.pointerClass {
  margin-left: -20px !important;
  z-index: 100 !important;
  /* z-index: 100 !important; */
}

.console_output_container_prerequisite_element {
  border: 1px solid grey;
  height: calc(100% - 100px);
  overflow: auto;
  color: red;
}

.wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

/* Tab label */

.sub_tab_name {
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

/* secondary-btn-restApi */

.secondary-btn-restApi {
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  width: 152px;
  height: 31px;
}

/* Package Name Label Attribute*/

.console_output_container {
  border: 1px solid grey;
  height: 136px !important;
  width: 95%;
  margin-left: 2%;
  overflow: auto;
}
.ace-editor-custom-assert > .ace_editor {
  position: relative;
  left: 18%;
  width: 80% !important;
  top: -80px;
}
.ace-editor-custom-assert-toggle > .ace_editor {
  position: relative;
  left: -2%;
  width: 100% !important;
  top: 0px;
}
.console-footer {
  margin-bottom: 24px;
}

.ace_gutter-layer,
.ace_print-margin-layer {
  overflow: hidden !important;
}

.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 80%;
}

.ace_scrollbar.ace_scrollbar-h {
  height: 7px !important;
  width: 60%;
}

/* Overall Ace Editor div */

.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}

.ace-console-wrp .project_label {
  padding-left: 0;
}

div#UNIQUE_ID_OF_DIV {
  border: 1px solid grey;
  margin-left: 2%;
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */

.editor__console {
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  border: 1px solid grey !important;
  box-sizing: content-box !important;
  color: red;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}

.grid-full-height {
  height: 100%;
}

.editorRole {
  flex: 1 1;
}

.ace-console-wrp-prerequiste {
  height: 300px !important;
  width: calc(100% - 50px);
  margin: auto;
}

.GroupIconPreRequisite {
  /* margin-top: 2%;
    right: 24%; */
  cursor: pointer;
}

.GroupIconPreRequisiteValue {
  cursor: pointer;
}
.Assert-Editor {
  font-size: 12px;
}
.Assert-Editor > .ace_scrollbar-h {
  background-color: var(--lightWhite);
  min-width: fit-content;
}
.assert-left-side-panel {
  position: relative;
  letter-spacing: 0;
  color: var(--darkBlue);
  background: var(--light_white_50);
  border: 2px solid var(--lightBlue);
  opacity: 1;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  width: calc(100% - 25px);
  margin-right: 25px;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
}
.assert-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  transform: rotate(44deg);
  height: 85.71%;
  width: 24.5px;
  left: 100%;
  transform-origin: top left;
  background-color: var(--light_white_50);
  aspect-ratio: 1/1;
  border-right: 2px solid var(--darkBlue);
  border-top: 2px solid var(--darkBlue);
  border-radius: 2px;
  z-index: -1;
}
@media (max-resolution: 90dpi) {
  .assert-left-side-panel::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    transform: rotate(45deg);
    height: auto;
    width: 23px;
    left: 100%;
    transform-origin: top left;
    background-color: var(--light_white_50);
    aspect-ratio: 1/1;
    border-right: 2px solid var(--darkBlue);
    border-top: 2px solid var(--darkBlue);
    border-radius: 2px;
    z-index: -1;
  }
}
.assert-left-side-panel:hover {
  background: var(--darkBlue) 0% 0% no-repeat padding-box;
  color: var(--light_white_50);
  opacity: 1;
}
.assert-left-side-panel:hover::after {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}
.assert-left-side-panel-text-customize {
  width: 160px;
  display: block;
  text-align: left;
  z-index: 2;
  position: relative;
}
.wrapper-of-assert-editor {
  overflow: auto;
  border-top: 2px dashed var(--light_white_border);
  border-bottom: 2px dashed var(--light_white_border);
  border-right: 2px dashed var(--light_white_border);
  border-radius: 10px;
  position: relative;
}
.assert-editor-customization {
  width: 100%;
  margin: 0 auto;
  min-height: 200px;
  overflow: auto;
}
.expand-icon-custom-assert:hover {
  background-color: var(--white-original);
  border-radius: 50%;
}

.compile-save-expand-section-container {
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  padding: 6px 0 2px 0;
  align-items: center;
  float: left;
  width: 99.2%;
  gap: 10px;
  background-color: var(--light_white_border);
  position: relative;
  left: 8px;
}
.compile-save-expand-sub-container {
  position: absolute;
  left: 0px;
  background: var(--darkBlue);
  color: var(--white-original);
  top: 0px;
  padding: 19px 0px;
  height: 30px;
}
.compile-save-section {
  border: 1px solid;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.Assert-Editor .ace_gutter {
  background-color: var(--light_white_border);
}
.prerequisite-main-container-sub {
  border: 2px solid var(--gray_light_50);
  padding: 10px;
  background-color: var(--white-original);
  overflow: auto;
}
.custom-assert-console-visibility {
  height: 275px;
}
.custom-assert-console-hide {
  height: 300px;
}
.assertEditor-height-with-console-expand {
  height: 190px;
}
.assertEditor-height-with-expand {
  height: 286px;
}
.assertEditor-height {
  height: 205px;
}
