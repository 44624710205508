@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';
.console_output_container_snippet_element {
  height: 75px;
  overflow: auto;
  color: $grey_dark;
  padding-left: 20px;
  width: 100%;
  border: 2px dashed $border_gray_200;
  border-top: none;
  border-radius: 0 0 15px 15px;
  @extend .fontPoppinsRegularMd;
}

.wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

/* Tab label */

.sub_tab_name {
  color: $grey_darkest;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  position: relative;
  left: -11px;
  top: 2px;
}

/* secondary-btn-restApi */

.secondary-btn-restApi {
  border: 1px solid $grey;
  border-radius: 2px;
  opacity: 1;
  width: 152px;
  height: 31px;
}

/* Package Name Label Attribute*/

.console_output_container {
  border: 1px solid $grey_dark;
  height: 136px !important;
  width: 95%;
  margin-left: 2%;
  overflow: auto;
}

.console-footer {
  margin-bottom: 24px;
}

.UNIQUE_ID_OF_DIV.ace_gutter-layer,
.UNIQUE_ID_OF_DIV.ace_print-margin-layer {
  overflow: hidden !important;
}

.UNIQUE_ID_OF_DIV.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 100%;
}

.UNIQUE_ID_OF_DIV.ace_scrollbar.ace_scrollbar-h {
  height: 7px !important;
  width: 100%;
  overflow: scroll;
}

.UNIQUE_ID_OF_DIV.ace-tm .ace_gutter {
  background: $whitish_blue !important;
}

/* Overall Ace Editor div */

.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}

.ace-console-wrp .project_label {
  padding-left: 0;
}

div#UNIQUE_ID_OF_DIV {
  border: 1px solid transparent;
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */

.editor__console {
  background-color: $white_original !important;
  box-sizing: border-box !important;
  border: 1px solid $grey_dark !important;
  box-sizing: content-box !important;
  color: $grey_dark;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}

.grid-full-height {
  height: 100%;
}

.editorRole {
  flex: 1 1;
}

.ace-console-wrp-prerequiste {
  height: 300px !important;
  width: calc(100% - 50px);
  margin: auto;
}

.disabled-btn {
  text-align: center;
  letter-spacing: 0px;
  color: $grey_light;
  padding: 4px 14px;
  border: 1px solid $grey_light;
  opacity: 1;
  border-radius: 2px;
  cursor: not-allowed;
  border: none;
}

.GroupIconPreRequisite {
  margin-top: -40px;
  cursor: pointer;
}

.GroupIconPreRequisiteValue {
  cursor: pointer;
}
#UNIQUE_ID_OF_DIV .ace_gutter {
  background-color: $border_gray_200;
}
.ace-common-styles {
  @extend .fontPoppinsRegularSm;
}
