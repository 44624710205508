@import './sass-utils/variable';
@import './sass-utils/mixins';
@import './sass-utils/color';
@import '@styles/rs_fonts.scss';

.commentbox-footer {
  margin: 16px 38px 0 0;
  display: flex;
  justify-content: flex-end;
}

.dividercomments {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 7px;
  width: 16px;
  height: 100%;

  & .threadline {
    width: 2px;
    background-color: $threadline-color;
    height: 100%;
  }

  &:hover {
    & .threadline {
      background-color: $primary;
    }
  }
}

.comment-label {
  font-size: $smallest-text;
  color: $body-text-color;
  margin: 0 0 4px 0;
}

@media (max-width: 630px) {
  .comment-area {
    width: 90% !important;
  }
}

.reply-to-user-tag {
  padding: 4px;
  margin: 0 4px 0 0;
  display: inline-block;
  background-color: lighten($color: $primary, $amount: 35);
  text-decoration: none;
  color: $primary-hover;
}

.p-inputtext {
  @extend .fontPoppinsRegularMd;
}
