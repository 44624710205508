:root {
  --lightWhite: #f6f7f9;
  --lightGray50: #efecfc;
  --gray: #b0aeae;
  --white: #ffffff;
  --red50: #d11414;
  --green50: #43b84f;
  --white_dark: #e5ecfc;
  --bg-white: #e0e0e0;
  --bg-blue: #434db8;
  --text-black: #000000;
  --gray60: #c5c5c5;
  --gray40: #707070;
  --gray_bg: #f2f2f2;
}
.passButton {
  background-color: var(--green50);
  border-radius: 61px;
  color: white;
  padding: 1px 29px;
  text-align: center;
  width: 60px;
  height: 20px;
}

.failButton {
  background-color: #c50303;
  border-radius: 61px;
  color: white;
  padding: 1px 29px;
  text-align: center;
  width: 60px;
  height: 20px;
}
.skippedButton {
  background-color: gray;
  border-radius: 61px;
  color: white;
  padding: 1px 29px;
  text-align: center;
  width: 60px;
  height: 20px;
}

/* Header Label Color*/
.HeaderColor {
  background-color: var(--lightWhite);
}
.assert-result-success {
  background: var(--green50) 0% 0% no-repeat padding-box;
  color: var(--white);
  letter-spacing: 0px;
  opacity: 1;
  padding: 4px 0 4px 12px;
}
.assert-result-failure {
  background: var(--red50) 0% 0% no-repeat padding-box;
  color: var(--white);
  letter-spacing: 0px;
  opacity: 1;
  padding: 4px 0 4px 20px;
  border-left: 2px solid var(--white);
}
.assert-result-skipped {
  background: var(--gray) 0% 0% no-repeat padding-box;
  color: var(--white);
  letter-spacing: 0px;
  opacity: 1;
  padding: 4px 0 4px 20px;
  border-left: 2px solid var(--white);
}

.assert-results-tooltip-icon {
  margin-top: 3px;
}

.assert-result-all-header {
  display: flex;
  flex-direction: row;
  background-color: var(--bg-blue);
  color: var(--white);
  height: 30px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}
.assert-result-rest-header {
  display: flex;
  flex-direction: row;
  background-color: var(--bg-white);
  color: var(--text-black);
  height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.assert-all-success-header {
  width: 33%;
  padding: 5px 0 0 16px;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}
.assert-all-failed-header {
  width: 33%;
  padding: 5px 0 0 12px;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}
.assert-all-skipped-header {
  width: 26%;
  padding: 5px 0 0 12px;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}
.assert-all-total-header {
  width: 8%;
  padding: 5px 0 0 12px;
  font-family: 'Poppins-SemiBold';
  font-size: 12px;
}
.assert-passed-response {
  width: 33.33%;
  border-left: 1px solid var(--lightGray50);
}
.assert-passed-response {
  margin-top: 12px;
  margin-left: 12px;
}

.main-container-for-assert-result {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
}

.assert-all-passed-response {
  grid-column-start: 1;
  grid-column-end: 1;
}
.assert-all-passed-response-failed {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 2;
}
.assert-all-passed-response-skipped {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 3;
}

.assert-all-section-one {
  width: 2px;
  background-color: var(--white_dark);
  position: absolute;
  left: 33.33%;
  display: inline;
}
.assert-all-section-two {
  width: 2px;
  background-color: var(--white_dark);
  position: absolute;
  left: 66.66%;
  display: inline;
}
.assert-result-collapse {
  height: 219px;
}
.assert-result-button-alignments {
  width: 97%;
  margin: 0 auto;
  margin-top: 4px;
}

.button-container {
  .assert-All-button-common {
    width: fit-content;
    font-weight: 500;
    padding: 4px 12px;
    line-height: 1.5;
  }
}
.active-border-of-assert-All-button {
  border: 2px solid var(--bg-blue);
}
.active-border-of-assert-Passed-button {
  border: 2px solid var(--green50);
}
.active-border-of-assert-Failed-button {
  border: 2px solid var(--red50);
}
.active-border-of-assert-Skipped-button {
  border: 2px solid var(--gray40);
}
.disable-border-of-assert-All-button,
.disable-border-of-assert-Passed-button,
.disable-border-of-assert-Failed-button,
.disable-border-of-assert-Skipped-button {
  border: 2px solid var(--gray60);
}
.border-of-assert-All-button,
.border-of-assert-Passed-button,
.border-of-assert-Failed-button,
.border-of-assert-Skipped-button {
  border: 2px solid var(--gray40);
}
.active-color-of-assert-All-button {
  color: var(--bg-blue);
}
.active-color-of-assert-Passed-button {
  color: var(--green50);
}
.active-color-of-assert-Failed-button {
  border: 2px solid var(--red50);
}
.hover-color-of-assert-All-button,
.hover-color-of-assert-Passed-button,
.hover-color-of-assert-Failed-button,
.hover-color-of-assert-Skipped-button {
  color: var(--white);
}
.disable-color-of-assert-All-button,
.disable-color-of-assert-Passed-button,
.disable-color-of-assert-Failed-button,
.disable-color-of-assert-Skipped-button {
  color: var(--gray60);
}
.color-of-assert-All-button,
.color-of-assert-Passed-button,
.color-of-assert-Failed-button,
.active-color-of-assert-Skipped-button,
.color-of-assert-Skipped-button {
  color: var(--gray40);
}

.assert-result-length {
  width: 100%;
  margin: 0 auto;
}
.assert-result {
  width: 100%;
  height: 300px;
  min-height: 220px;
  border: 2px solid var(--gray_bg);
}
.assert-result-header {
  width: 100%;
  margin: 10px 0 auto;
}
