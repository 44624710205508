.progress-container {
  opacity: 0.5;
  position: fixed;
  z-index: 99;
  &::after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
  }
}
.z-2 {
  z-index: 2;
}
