/*= @author Aravindhakumar Raman
 * - description - Program Elements Section
 * - description - Ace Editor screen
 */

:root {
  --white_light: #fbfcfd;
  --skyblue_light: #f6f7f9;
  --grey_light: #d2d2d2;
  --grey_dark: #727171;
  --blue_dark: #1648c6;
  --grey50: #707070;
  --white_dark: #e5ecfc;
  --grey_darkest: #3c3838;
  --blue_background: #434db8;
  --white_original: #ffffff;
  --white_dark_50: #e0e3f6;
  --white_dark_40: #f5f6fc;
  --gray_border: #c5c5c5;
  --gray_50: #969696;
  --gray_background_50: #e0e3f6;
  --gray_light_50: #cacaca;
  --black_shadow: rgba(0, 0, 0, 0.26);
}

.console_output_container_prerequisite_element {
  border: 1px solid grey;
  height: calc(100% - 100px);
  overflow: auto;
  color: red;
}
.prerequisite_console_output_container {
  height: 50px;
  overflow: auto;
  background: var(--white_original) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: calc(100%);
  border-left: 2px dashed var(--white_dark_50);
  border-right: 2px dashed var(--white_dark_50);
  border-bottom: 2px dashed var(--white_dark_50);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.prerequisite-left-side-bar {
  background: var(--skyblue_light) 0% 0% no-repeat padding-box;
  border-right: 10px solid var(--white_dark_40);
}
.prerequisite_left_sidebar_section_one {
  display: flex;
  opacity: 1;
  padding: 6px 0 2px 0;
  align-items: center;
  float: left;
  width: 20%;
}
.assert_left_sidebar_section_one {
  display: flex;
  opacity: 1;
  align-items: center;
  float: left;
  width: 19.5%;
}

.prerequisite_left_sidebar_section_two {
  align-items: center;
  border-left: none;
  padding: 5px 0 3px 0;
}
.prerequisite_left_sidebar_section_two button {
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  height: 25px;
  border-radius: 5px;
  padding: 6px 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.prerequisite_left_sidebar_hide {
  padding: 6px 3px 3px 3px;
  float: left;
  border: 1px solid var(--grey_light);
}
.prerequisite-main-container {
  display: flex;
  height: 295px;
}
.prerequisite-main-container-section-one {
  width: 20%;
  float: left;
  background: var(--skyblue_light) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0px;
  overflow: auto;
}
.prerequisite-main-container-section-sub {
  width: 20%;
  float: left;
  background: var(--white_dark_40);
  position: sticky;
  top: 0px;
  overflow: auto;
}
.prerequisite-main-container-section-two {
  width: 80%;
  border-left: none;
}
.prerequisite-main-container-full-section {
  width: 100%;
  margin-left: 8px;
}
.width-50 {
  width: 50%;
}

.GroupIconPreRequisiteSection {
  top: 42%;
  left: 94%;
  position: absolute;
}
.prerequisite-maincontainer-section-two {
  background: var(--skyblue_light) 0% 0% no-repeat padding-box;
  border: 1px solid var(--grey_light);
  opacity: 1;
  align-items: center;
}

.prerequisite-maincontainer-section-two label {
  letter-spacing: 0px;
  color: var(--white_original);
  opacity: 1;
}
.expand-console-label-section {
  background: var(--blue_background) 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 35px;
  opacity: 1;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 1px;
  width: calc(100%);
  margin-top: 10px;
}
.expand-console-label-section label {
  letter-spacing: 0px;
  color: var(--white_original);
  opacity: 1;
  margin-left: 20px;
  height: 30px;
  display: flex;
  align-items: flex-end;
}
.clear_console_section {
  align-items: center;
}
.clear_console_section button:hover {
  color: var(--white_original);
  background: var(--blue_background) 0% 0% no-repeat padding-box;
}
.clear_console_section button {
  letter-spacing: 0px;
  color: var(--grey_dark);
  text-transform: capitalize;
  opacity: 1;
}
.console-output {
  letter-spacing: 0px;
  color: var(--grey50);
  opacity: 1;
}
.collapse-group-icon {
  display: flex;
  align-items: center;
  padding: 3px;
  background: var(--skyblue_light) 0% 0% no-repeat padding-box;
  border: 1px solid var(--grey_light);
}
.prerequisite-left-sidebar-label {
  text-align: left;
  letter-spacing: 0px;
  background-color: var(--blue_background);
  height: 35px;
  opacity: 1;
  border-radius: 10px 10px 0px 0px;
  width: calc(100%);
  margin-top: 10px;
}
.prerequisite-group-icon {
  cursor: pointer;
}

/* Tab label */

.sub_tab_name {
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: var(--grey_darkest);
  opacity: 1;
}

/* secondary-btn-restApi */

.secondary-btn-restApi {
  border: 1px solid #525252;
  border-radius: 2px;
  opacity: 1;
  width: 152px;
  height: 31px;
}

/* Package Name Label Attribute*/

.console_output_container {
  border: 1px solid grey;
  height: 136px !important;
  width: 95%;
  margin-left: 2%;
  overflow: auto;
}

.console-footer {
  margin-bottom: 24px;
}

.ace_gutter-layer,
.ace_print-margin-layer {
  overflow: hidden !important;
}

.ace_scrollbar.ace_scrollbar-v {
  width: 7px !important;
  height: 80%;
}

.ace_scrollbar.ace_scrollbar-h {
  height: 7px !important;
  width: 60%;
}

.ace-tm .ace_gutter {
  background: #e2e2f7 !important;
}

/* Overall Ace Editor div */

.ace-console-wrp {
  min-height: 300px;
  width: calc(100% - 50px);
  margin: auto;
}

.ace-console-wrp .project_label {
  padding-left: 0;
}

div#UNIQUE_ID_OF_PREREQUISITE_DIV {
  border: 0;
  margin: 0;
}

.editor__footer--left {
  display: flex;
  flex-direction: column;
}

.editor__footer--right {
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.programheight {
  height: 80% !important;
}

.lab {
  margin-top: 10% !important;
}

/* Console area */

.editor__console {
  background-color: #ffffff !important;
  box-sizing: border-box !important;
  border: 1px solid grey !important;
  box-sizing: content-box !important;
  color: red;
}

/* Pass Message Label */

.grid-flex-wrp {
  display: flex;
  flex-direction: column;
}

.grid-full-height {
  height: 100%;
}

.editorRole {
  flex: 1 1;
}

.ace-console-wrp-prerequiste {
  height: 300px !important;
  width: calc(100% - 50px);
  margin: auto;
}

.disabled-btn {
  text-align: center;
  letter-spacing: 0px;
  color: var(--grey_dark);
  padding: 4px 14px;
  border-radius: 3px;

  border: 1px solid var(--disable-e1dfdf);
  border: 1px solid #e1dfdf;
  border-radius: 2px;
  opacity: 0.5;
}

.GroupIconPreRequisite {
  cursor: pointer;
}

.GroupIconPreRequisiteValue {
  cursor: pointer;
}

.pointerClass {
  margin-left: -20px !important;
  z-index: 100 !important;
}
.pre-disabled-btn {
  text-align: center;
  color: var(--black_shadow);
  border-radius: 2px;
  padding-right: 14px;
  border: 1px solid var(--grey_dark);
}
.disabled-btn-custom-assert {
  text-align: center;
  color: var(--black_shadow);
  border: 1px solid var(--grey_dark);
}
.prerequisite-left-side-panel,
.snippet-left-side-panel,
.snippet-create-left-side-panel {
  position: relative;
  letter-spacing: 0;
  color: var(--blue_dark);
  background: var(--white_dark_40);
  border: 2px solid var(--blue_dark);
  opacity: 1;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  width: calc(100% - 25px);
  margin-right: 25px;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
}
.snippet-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  transform: rotate(43deg);
  height: 82%;
  width: 23px;
  left: 100%;
  transform-origin: top left;
  background-color: var(--white_dark_40);
  aspect-ratio: 1/1;
  border-right: 2px solid var(--blue_background);
  border-top: 2px solid var(--blue_background);
  border-radius: 0 2px 0 0;
  z-index: 1;
}
@media (max-resolution: 90dpi) {
  .snippet-left-side-panel::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    transform: rotate(45deg);
    height: auto;
    width: 22px;
    left: 100%;
    transform-origin: top left;
    background-color: var(--white_dark_40);
    aspect-ratio: 1/1;
    border-right: 2px solid var(--blue_background);
    border-top: 2px solid var(--blue_background);
    border-radius: 0 2px 0 0;
    z-index: 1;
  }
}
.snippet-create-left-side-panel:hover,
.snippet-create-left-side-panel:hover::after,
.snippet-left-side-panel:hover,
.snippet-left-side-panel:hover::after {
  background: var(--blue_background) 0% 0% no-repeat padding-box;
  color: var(--white_dark_40);
  opacity: 1;
}
.snippet-create-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  transform: rotate(43deg);
  height: 82%;
  width: 23px;
  left: 100%;
  transform-origin: top left;
  background-color: var(--white_dark_40);
  aspect-ratio: 1/1;
  border-right: 2px solid var(--blue_background);
  border-top: 2px solid var(--blue_background);
  border-radius: 0 2px 0 0;
  z-index: 1;
}
@media (max-resolution: 90dpi) {
  .snippet-create-left-side-panel::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    transform: rotate(45deg);
    height: 77%;
    width: 22px;
    left: 100%;
    transform-origin: top left;
    background-color: var(--white_dark_40);
    aspect-ratio: 1/1;
    border-right: 2px solid var(--blue_background);
    border-top: 2px solid var(--blue_background);
    border-radius: 0 2px 0 0;
    z-index: 1;
  }
}
.prerequisite-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  transform: rotate(45deg);
  height: auto;
  width: 24px;
  left: 100%;
  transform-origin: top left;
  background-color: var(--white_dark_40);
  aspect-ratio: 1/1;
  border-right: 2px solid var(--blue_background);
  border-top: 2px solid var(--blue_background);
  border-radius: 0 3px 0 0;
  z-index: 1;
}
@media (max-resolution: 90dpi) {
  .prerequisite-left-side-panel::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    transform: rotate(45deg);
    height: auto;
    width: 22px;
    left: 100%;
    transform-origin: top left;
    background-color: var(--white_dark_40);
    aspect-ratio: 1/1;
    border-right: 2px solid var(--blue_background);
    border-top: 2px solid var(--blue_background);
    border-radius: 0 3px 0 0;
    z-index: 1;
  }
}
.prerequisite-left-side-panel:hover {
  background: var(--blue_background) 0% 0% no-repeat padding-box;
  color: var(--white_dark_40);
  opacity: 1;
}
.prerequisite-left-side-panel:hover::after {
  background-color: var(--blue_background);
  border-color: var(--blue_background);
}

.prerequisite-button-structure-active {
  border: 1px solid var(--blue_dark);
}
.ace_Gutter {
  background-color: var(--white_original);
  background-repeat: no-repeat;
  background-origin: padding-box;
  color: var(--gray_50);
}
#prerequisite-ace-editor .ace_gutter {
  background-color: var(--gray_background_50);
}

.prerequisite-left-side-text-customization {
  width: 155px;
  display: block;
  text-align: left;
  z-index: 2;
  position: relative;
}
.prerequisite-editor-wrapper {
  overflow: auto;
  border-right: 2px dashed var(--gray_background_50);
  border-top: 2px dashed var(--gray_background_50);
  border-bottom: 2px dashed var(--gray_background_50);
  border-radius: 10px;
}
.prerequisite-editor-customization .ace_gutter {
  background-color: var(--white_dark_50);
}
.prerequisite-editor-customization {
  width: 100%;
  margin: 0 auto;
  min-height: 200px;
  overflow: auto;
}
.prerequisite-left-side-bar-section-one {
  display: flex;
  opacity: 1;
  padding: 9px 0 3px 0;
  align-items: center;
  float: left;
  width: 19.6%;
  position: absolute;
  left: 0px;
  background-color: var(--blue_background);
  color: var(--white_original);
}
.prerequisite-left-side-bar-section-hide {
  display: flex;
  opacity: 1;
  padding: 5px 10px 8px 15px;
  align-items: center;
  float: left;
  position: absolute;
  left: 0px;
  background-color: var(--blue_background);
  color: var(--white_original);
}
.prerequisite-sub-left-sidebar {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  align-items: center;
  float: left;
  width: 100%;
  background-color: var(--white_dark_50);
  position: relative;
  left: 8px;
}
.snippet-heading-alignments {
  width: 100%;
  display: flex;
}
.prerequisite-main-container-sub {
  border: 2px solid var(--gray_light_50);
  padding: 10px 10px 12px 10px;
  background-color: var(--white_original);
  overflow: auto;
}
.prerequisite-main-container-sub-height {
  height: 280px;
}
.prerequisite-ace-container-open-height {
  height: 90%;
}
.prerequisite-ace-container-close-height {
  height: 95%;
}
.prerequisiteEditor-height-with-console-expand {
  height: 200px;
}
.prerequisiteEditor-height-with-expand {
  height: 320px;
}
.prerequisiteEditor-height {
  height: 252px;
}
.prerequisite-left-sidebar-border {
  border-right: 17px solid var(--white_dark_40);
}
.prerequisite-blank-div {
  background-color: var(--white_dark_40);
  width: 9px;
}
.pre-requisite-isVisible-icon-rotate {
  transform: rotate(-90deg);
}
.ace-common-styles {
  font-size: 12px;
}
