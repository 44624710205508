.templete-conatiner {
  position: absolute;
  margin-left: 291px;
  width: 310px;
}

.templete-conatiner-top80 {
  top: -80px;
}

.templete-conatiner-top50 {
  top: -50px;
}
.templete-conatiner-top2 {
  top: -2px;
}
.templete-conatiner-top9 {
  top: -9px;
}
