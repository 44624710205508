@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';
.wrapper {
  .renew-information-button {
    color: $muted;
    font-size: 13px;

    .renew-button {
      color: $warning;
    }
  }

  .licenses-drop-button {
    width: 207px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $rs_primary;
    height: 28px;
    border: 1px solid var(--grey_light);
    border-radius: 20px;
    padding: 5px;

    &:hover:not(.open) {
      border: 1px solid $rs_primary;
      border-radius: 20px;
      .shield-icon {
        color: $rs_primary;
      }
    }

    &.open {
      background-color: $white-grey;
      border: 1px solid $rs_primary;
      border-radius: 20px;
      color: $rs_primary;

      .shield-icon {
        color: $rs_primary;
      }
    }
  }
}

.license-drop-box {
  margin-top: 4px;
  :global {
    .MuiList-root {
      width: 200px;
      max-height: 224px;
      background: $white-light;
      .MuiMenuItem-root {
        @include fontPoppins(Poppins-Regular, 12px);
        padding: 9px 10px;
        width: 100%;
        color: $rs_primary;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &.Mui-selected {
          background-color: $rs_primary;
          color: $white-light;
        }
      }

      .MuiButtonBase-root {
        display: block;
      }
    }
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      top: 85px !important;
      left: 1148px !important;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background: $rs_scroll_color_grey 0% 0% no-repeat padding-box;
}

.license-component {
  position: absolute;
  right: 10px;
  z-index: 99;
}

.no-license-text {
  justify-content: center;
  color: $muted;
}
.active {
  color: $active_license;
}
.expired {
  color: $red;
}
.expiringSoon {
  color: $license_about_to_expire;
}
