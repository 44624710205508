@import '../../../../../css_config/variable.scss';

.testCase {
  color: #727171;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.templateSize {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

.wrapContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contentDataStyle {
  width: 100%;
  letter-spacing: 0px;
  color: $grey_darkest;
  text-align: left;
}

.labelWidth {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40ch;
}

.label-overflow {
  overflow-wrap: break-word;
  word-break: break-all;
}

.content-height {
  overflow: auto;
}

.word-overflow {
  word-wrap: break-word;
}

.table-width-resize {
  --column_resize_before_width: 100px;
  max-width: 100px;
  width: 100px;
}

.test-case-table-row {
  background-color: $SkyLightblue;
}

.test-case-table-even-row {
  background-color: $white_light;
}

.test-case-table-odd-row {
  background-color: $skyblue_light;
}

.columnResizer {
  width: 0.01px;
  padding: 0;
}

.table-cell-text {
  letter-spacing: 0px;
  color: $grey_darkest;
}
.common-label-text {
  color: var(--text-3c3838);
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
}

.link-placeholder-text input::placeholder {
  font-size: 9px;
}

.select-selected-value {
  font-size: 12px !important;
  display: inline-block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiList-root {
  .manual-test-case-select {
    &.MuiButtonBase-root {
      flex-direction: column;
      align-items: flex-start;
      padding: 5px;
      padding-left: 10px;
      font-size: 0.75rem;
      line-height: 1rem;
      display: flow-root;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.auto-complete input[type='text'] {
  border: none;
}
.MuiAutocomplete-listbox {
  height: 10rem;
}
.user-input-container input[type='text'] {
  font-size: 12px !important;
}
