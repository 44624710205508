// screen size
$xs: 0px;
$sm: 780px;
$md: 1024px;
$lg: 1280px; //no need to add media qry for this
$xl: 1536px;
$xxl: 1920px;

//color paletts
$license_tooltip_bg: #3c3838;
$license_card_border: #e1dfdf;
$color_blue: #434db7;
$blue: #2b71f7;
$blue_ultra_light: #8ba4e3;
$blue_light:#dbeafe;
$light_blue: #5580ec;
$blue_dark: #1648c6;
$tag_blue: #436bd0;
$whitish_blue: #d7dae1;
$primary: #3a62c9;
$rs_primary: #434db8;
$rs_blue: #172b4d;
$rs_placeholder: #c4c3c3;
$rs_border: #e0e0e0;
$rs_disabled: #d8d7d7;
$rs_label: #8f8e8e;
$rs_text: #5e6c84;
$success: #079328;
$info: #2b71f7;
$error: #f01d1d;
$orange_light: #e0b94f;
$rs_blue: #172b4d;
$rs_blue_font: #454db2;
$rs_shadaow: #0000001a;
$rs_analytics_pass: #5ca700;
$rs_analytics_fail: #c50303;
$rs_analytics_warning: #ffc600;
$rs_analytics_flaky: #e57300;
$rs_analytics_grey: #f0f0f0;
$rs_analytics_blue_header: #434db8;
$rs_blue_shadow: #434db8a7;
$skyblue: #d8d9db;
$skyblue_light: #f6f7f9;
$skyblue_dark: #f0f3f9;
$rs_border: #e0e0e0;
$grey: #525252;
$grey_dark: #727171;
$grey_darker: #555555;
$grey_darkest: #3c3838;
$grey_light: #d2d2d2;
$grey_light_white: #a9a9a9;
$grey_medium: #989797;
$rs_grey_medium: #c9c9c9;
$license_scroll_color: #d9dbf1;
$license_scroll_hover: #b8bdef;
$off_bule: #9ecaed;
$bg_history_card: #eff2fe;
$bg-filter-footer: #f3f4ff;

$rs_bg_whitgrey: #f5f6fc;
$rs_light_gray: #e6e6e6;
$grey-light-medium: #707070;
$rs_bg_whitgrey: #f5f6fc;
$rs_gray_dark: #8a8787;
$rs_light_gray: #e6e6e6;
$check_box_grey: #d8d7d7;
$gray_dark_50: #616064;
$license_row_card_bg: #f6f7f9;
$white: #eeeded;
$white_original: #ffffff;
$grey_white: #e1dfdf;
$white_light: #fbfcfd;
$white_dark: #e5ecfc;
$white_accordion: #f8f9f9;
$light_grey_white: #dad5d5;
$light_grey_disable: #00000042;
$gray_white: #cecaca;
$error-bg: #ffeaea;
$black: #0a0a0a;
$black_original: #000;
$shadow_black: #00000029;
$shodow_light_black: #0000001a;

$highlight_bg_yellow: #ffff00;

$grey_border: #707070;
$active_license: #14aa00;
$license_about_to_expire: #fd7500;
$green: #79b62f;
$green_dark: #5ea606;
$red: #c50303;
$red_light: #db2222;
$yellow: #d9a903;
$yellow_dark: #efbb07;
$red_dark: #ff0000;
$red_darkest: #ea0322;
$alice_blue: #f0f8ff;
$error_red: #f44336;
$warning: #e87807;
$valencia: #de4b4b;

$SkyLightblue: #f0f3f9;
$Lightwhite: #e1dfdf;
$Lightgray: #525252;
$slateBlack: rgba(0, 0, 0, 0.24);
$deep_black: rgba(0, 0, 0, 0.85);
$badge-blue: #2554ca;
$gainsboro: #dfdfdf;
$persianGreen: #00a740;
$freeSpeechRed: #c70000;
$grey_Nevada: #6f7070;
$payne_Gray: #5e6c84;
$rs_Disabled: #f7f7f7;
$dark-grey: #a6a6a6;
$Light_red: #ff4e4e;
$shade_red: #df4747;
$gray_44: #707070;
$gray_light: rgb(229, 231, 235);
$gray_40: #6f7070;
$gray_30: #f3f3f6;
$scroll_bar_grey: #e6e6e6;
$shawdow_grey: #808080;
$periwinkle: #e4e6f4;
// table row
$tr-bottom-color: #ecedf8;

//signUp button Color
$orange: #db7221;
$black_light: #323232;
//Table colors
$clickable_feild_color: $blue;
$non_clickable_feild_color: $grey_dark;
$modal_non_clickable_feild_color: $grey_darkest;

// btn colors

$submit_btn_background_color: $blue_dark;
$submit_btn_border_color: $blue_dark;
$submit_btn_font_color: $skyblue_light;

// ---------------------------------------
$cancel_btn_background_color: $white;
$cancel_btn_border_color: $white;
$cancel_btn_font_color: $black;

// ---------------------------------------

$clear_btn_border_color: $grey;
$clear_btn_font_color: $grey_dark;

// tab colos

$tab_font_color: $blue_dark;

//header
$left_nav_color: $grey_dark;
$left_nav_color_dark: $grey_darker;

//pagination
$current_page_num_box_color: $light_blue;
$current_page_count_color: $white_original;

//content area header
$content_area_header_font_color: $black_original;

//radio btn style

$radio_btn_label_color: $grey_dark;
$selected_radio_label_color: $grey_darkest;

//result status colors
$result_passed_color: $green;
$result_failed_color: $red;
$result_warning_color: $yellow;
$result_skipped_color: $grey_dark;

// column chart
$label-gray-text: #7c828a;
$transparent: transparent;
$deep_blue: #1b337f;
$light_white: #fbfbfe;
$light_gray_50: #e0e0e0;
$light_white_50: #f2f2f2;
$light_gray_100: #c5c5c5;
$black_original_100: #000000;
$border_gray_200: #e0e3f6;
$gray_dark_60: #8f8e8e;
$white_dark_50: #cacaca;
$gray_dark_70: #dfe3f6;
$blue_dark_50: #444eb8;
$rs_gray: #6f7070;
$rs_gray_bg: #f2f2f2;
$off_white: #f6f5fd;
$rs_light_dark_gray: #707070;
$rs_light_dark_blue: #405b91;
$blue-dashed-border: #e0e3f6;
$rs_gray_btn: #919191;
$gray73: #bababa;
$config_license: #0000003c;
$blue_bright: #2862ff;
$red_dark: #b32c4a;
$blue_saleforce: #147ad6;
$cyan: #18c1c1;
$green65: #399365;

//V2.0 colors
$ff-primary: #434db8;
$ff-red-text: #a80000;
$ff-red-bg: #c30000;
$ff-gray-text: #6f7070;
$ff-gray-bg: #707070;
$ff-white: #ffffff;
$ff-text: #3c3838;
$ff-icon-default: #808080;
$ff-border: #e0e0e0;
$ff-placeholder: #c4c3c3;
$ff-disable: #d8d7d7;
$ff-bg-disabled: #f7f7f7;
$ff-hover-bg: #f5f6fc;
$ff-options-text: #8a8787;
$ff-dropdown-bg: #fbfbfe;
$ff-dropdown-shadow: #00000029;
$ff-label-header: #8f8e8e;
$ff-success: #5ca700;
$ff-error: #c50303;
$ff-warning: #ffc600;
$light_blue_50: #ccd6ea;
$ff-blue: $blue;
$ff_MUI_checkBox_checked_color: $ff-primary;
$ff-black-original: $black_original_100;
$ff-gray-dark: $grey_dark;
$ff-blue-100: #3b429f;
$ff-drag-drop-bg: #434DB80D;
$ff-box-shadow-grey: #00000045; 
$ff-pop-up-footer-bg: #f0f3f9;
$ff_skyBlue: #1f78b4;
$ff_skyLight: #a6cee3;
$ff_lightGreen: #b2df8a;
$ff_orange: #e39512;
$ff_lightPink: #d859ff;
$ff_lightBrown: #b87243;
$ff_purple: #8163e0;
$ff_marker_yellow: #f5fb00;

//chatbot colors
$ff-light-grayish-white-color: #f5f5f5;
$ff-light-silver: #f9f9f9;
$ff-lavender-blue: #eaebf7;
$ff-medium-light-gray: #cacaca;
$ff-light-slate-blue: #ced7dd;
$ff-soft-sky-blue: #e6e8f5;
$ff-violet-Blue: #4d48bd24;
$bright_gray: #ececec;
$ff-lavender-purple: #4d48bd;
$ff-ash-gray: #e4e4e4;
$ff-vivid-violet: #9747ff;
$ff-cobalt-blue: #434db81c;
:export {
  red_dark: $red_dark;
  grey_dark: $grey_dark;
  yellow_dark: $yellow_dark;
  blue_bright: $blue_bright;
  blue_saleforce: $blue_saleforce;
  cyan: $cyan;
  ff_primary: $ff-primary;
  ff_red-text: $ff-red-text;
  ff_red-bg: $ff-red-bg;
  ff_gray-text: $ff-gray-text;
  ff_gray-bg: $ff-gray-bg;
  ff_white: $ff-white;
  ff_text: $ff-text;
  ff_icon-default: $ff-icon-default;
  ff_border: $ff-border;
  ff_placeholder: $ff-placeholder;
  ff_disable: $ff-disable;
  ff_hover-bg: $ff-hover-bg;
  ff_label-header: $ff-label-header;
  ff_success: $ff-success;
  ff_error: $ff-error;
  ff_warning: $ff-warning;

  ff_skyBlue: $ff_skyBlue;
  ff_skyLight: $ff_skyLight;
  ff_lightGreen: $ff_lightGreen;
  ff_orange: $ff_orange;
  ff_lightPink: $ff_lightPink;
  ff_lightBrown: $ff_lightBrown;
  ff_purple: $ff_purple;
}
