/*Developer -:  Rahul Agrawal*/
:root {
  --border-gray: #989797;
  --muted: #727171;
  --skyblueLight: #f6f7f9;
  --grayLight: #d2d2d2;
  --deepGray: #3c3838;
  --lightWhite: #f0f3f9;
  --darkWhite: #fbfcfd;
  --lightBorder: #e0e0e0;
  --rsPrimary: #434db8;
}

.min-height-request-body-tab {
  min-height: 332px;
}
.request-body .ace_editor {
  width: 100%;
  height: 330px;
}
.request-body-select-dropdown #mui-component-select-bodyType {
  padding: 6px 12px;
  font-size: 12px !important;
  text-align: left;
}
.request-body-select-dropdown svg {
  right: 8px;
}
.select-file-primary-button-request-body {
  border: 1px solid var(--border-gray);
  border-radius: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--muted);
  width: 129px;
  height: 29px;
  line-height: 29px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.select-file-primary-button-request-body svg {
  width: 14px;
  transform: rotate(30deg);
  margin: 0;
}

/*No file select Span*/
.no-select-file-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.auth-button-select {
  padding: 5px 10px;
  border: 1px solid var(--border-gray);
  border-radius: 2px;
  color: var(--muted);
  width: 111px;
  height: 32px;
  margin-top: 4px;
  cursor: pointer;
}
.auth-file-choose-button {
  position: relative;
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 17px;
  color: var(--muted);
  max-width: 100px;
  padding-top: 2px;
}
/* override background from ace-editor */
.request-body-ace-editor > .ace_gutter {
  background: none;
}
.request-body-ace-editor .ace_scroller > .ace_content > .ace_marker-layer > .ace_active-line {
  background: var(--darkWhite);
}
.request-body-main-container-section-bottom {
  width: 20%;
  float: left;
  background: var(--skyblueLight) 0% 0% no-repeat padding-box;
  border: 1px solid var(--grayLight);
  border-bottom: none;
}

.request-body-main-container-section-top {
  width: 20%;
  float: left;
  background: var(--skyblueLight) 0% 0% no-repeat padding-box;
  border: 1px solid var(--grayLight);
  border-top: none;
}
.common-background-color-request-body {
  background-color: var(--skyblueLight);
}
.graphql-header-text-left,
.graphql-header-text-right {
  position: relative;
  color: var(--deepGray);
}
.graphql-header-text-right {
  right: 390px;
}
.graphql-header-text-left {
  right: 470px;
}
.background-graphql-header {
  background-color: var(--skyblueLight);
}
.graphql-header-height {
  height: 295px;
  overflow: auto;
}
.form-data-file-background {
  background: var(--lightWhite);
  border-radius: 15px;
  width: 175px;
}
.form-data-file-background:hover {
  background: var(--darkWhite);
  border-radius: 15px;
}
.background-graphql-header {
  background-color: var(--skyblueLight);
}

/*file Select Parent div and Hover Effect*/
.selectFileContainer {
  width: 62%;
}

.hover-item {
  background-color: inherit;
}

.hover-item:hover {
  color: var(--rsPrimary);
  border: 1px solid var(--rsPrimary);
}
.header-text-in-graphql {
  color: var(--deepGray);
}
.cross-icon-margin {
  margin-top: 0.6px;
}
.request-body-left-side-bar {
  padding: 3px;
  float: left;
  border: 1px solid var(--grayLight);
}

.span-container {
  display: flex;
}
/* .inline-span{
 padding-right: 10px;
} */
.inline-span label {
  margin-left: 0px;
  margin-right: -8px;
}
.inline-span label span:nth-child(2) {
  margin-left: 8px;
}
.choose-file-icon {
  margin-left: 40px;
  font-size: large;
}
.inline-span label .MuiFormControlLabel-label {
  font: 12px/14px Poppins-Regular !important;
}
.inline-span label span svg {
  width: 17px;
}
.select-file-primary-button-request-body-choose-file svg {
  width: 14px;
  transform: rotate(30deg);
  margin: 0;
}
.choose-file-request-form-data {
  border: 1px solid var(--lightBorder);
  font-size: 12px !important;
  border-radius: 4px;
  text-align: left;
  letter-spacing: 0px;
  width: 75%;
  height: 35px;
  line-height: 28px;
  padding: 0 7px;
  display: flex;
  align-items: center;
}

.form-data-choose-file-image + .select-file-primary-button-request-body-choose-file {
  width: fit-content;
}

.select-file-text {
  width: inherit;
}

.form-data-choose-remove-image {
  position: absolute;
  right: 10px;
}
