@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

.configPage {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fbfcfd;
  box-shadow: 0px 0px 6px #00000029;
}

.bgColor {
  background-color: #fbfcfd;
}

.configPageHeading {
  top: 223px;
  left: 136px;
  width: 212px;
  height: 27px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  @extend .fontPoppinsRegularXLg;
}

.actionIcons {
  visibility: hidden;
}

.configHover:hover {
  background-color: $skyblue_dark;
}

.configHover:hover .bgColor {
  background-color: $skyblue_dark;
}

.configHover:hover .actionIcons {
  visibility: visible;
}

.headerStyle {
  background-color: $skyblue_dark;
  color: #3c3838;
}

.headerStyle:hover {
  background-color: $skyblue_dark;
}

.configdataStyle {
  @extend .fontPoppinsRegularMd;
  opacity: 1;
}

.disableColor {
  font-size: 10.5pt;
  opacity: 0.5;
  color: $grey_dark;
}

.dropdownWidth {
  width: 140px !important;
}

.executionWidth {
  width: 165px;
}

.osWidth {
  width: 20px;
}

.browserWidth {
  width: 135px;
}

.deviceWidth {
  width: 196px;
  opacity: 1;
}

.accessWidth {
  width: 60px;
}

.statusWidth {
  width: 55px;
}

.configactionWidth {
  width: 68px;
}

.Configpopupheader {
  color: #3c3838 !important;
  font-size: 16px !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
}

.sepratorLine {
  border-bottom: 2px solid #e5ecfc;
}

.dashedLine {
  border-bottom: 2px dashed #e5ecfc;
}

.userContainer {
  width: 575px;
  height: 361px;
  box-shadow: 0px 0px 6px #00000029;
}

.giveAcessstyle {
  font-size: 11px;
}

.user-table-header {
  color: $grey_dark;
  padding-left: 30px;
  padding-top: 12px;
  background-color: $skyblue_dark;
  height: 40px;
}

.user-table-data {
  padding-left: 30px;
  padding-top: 12px;
  height: 40px;
  color: $grey_dark;
}

.userswitch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.userswitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.userslider {
  position: absolute;
  cursor: pointer;
  border: 1px solid gray;
  top: 0;
  left: 0;
  width: 33px;
  right: 0;
  bottom: -1px;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.userslider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .userslider {
  background-color: transparent;
  border: 1px solid #1648c6;
}

input:focus + .userslider {
  box-shadow: 0 0 1px #1648c6;
}

input:checked + .userslider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  background-color: #1648c6;
}

.userslider.round {
  border-radius: 34px;
}

.userslider.round:before {
  border-radius: 50%;
}

.userScrool {
  overflow-y: scroll !important;
}

.configmoreIcon {
  color: blue;
}

.searchableDevice {
  width: 196px !important;
  border: none !important;
}

.clickableColor {
  color: #1648c6;
}

.dropdownLabel {
  font-size: 10px;
  color: #1648c6;
}

.marginupperdanger {
  border-top: 3px solid #ea0322;
}

.configsearch:hover {
  color: blue;
}

.configSearchField {
  outline: none !important;
}

.selectOsClass {
  box-shadow: 0px 2px 4px #0000004d;
  border-radius: 10px;
  padding-top: 9px;
  font-size: 14px;
  cursor: pointer;
}

.selectOsClass:hover {
  background-color: $skyblue_dark;
}

.selectedbgcolor {
  background-color: $skyblue_dark;
}

.noData {
  font-size: 14px;
  color: $grey_dark;
}

.exeenvclass:hover {
  background-color: $skyblue_dark;
  color: #1648c6;
}

.dropdownItems {
  margin: 0px !important;
}

.dropDownHeight {
  max-height: 250px;
}

.cautionDimention {
  width: 640px;
  height: 217px;
}

.deviceValidation {
  font-size: 12px;
}

.browserStack-check-modal {
  width: 640px;
  height: 285px;
}

.browserStack-add-modal {
  width: 640px;
  height: 620px;
}

.clickHereMargin {
  margin-top: 15% !important;
}

.input-error {
  color: #ea0322;
  text-align: left;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  letter-spacing: 0px;
}

.encode {
  -webkit-text-security: disc;
}

.exeWidth {
  width: 130px !important;
}

.devicedropWidth {
  width: 155px !important;
}

.browserdropWidth {
  width: 50px !important;
}

.osWidth {
  width: 100px !important;
}

.searchableDevice .react-dropdown-select-dropdown {
  width: auto;
}

.osimageWidth {
  width: 64px !important;
}

.osVersionWidth {
  width: 75px !important;
}

.alert-position-style_variable {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: 114px;
}

.alert-position-style_global_variable {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: 300px;
}
.env-config-bg-color {
  background: $white_original;
}
