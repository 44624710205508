@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.dashBoard-start {
  height: 180px;
  width: 300px;
}
.label_style {
  width: 100px;
}
.access-header {
  margin-left: 20px;
}

.custom-graph-common {
  background: $tr-bottom-color;
  font-family: 'Poppins-Medium';
  color: $ff-primary;
  border-radius: 21px;
  text-align: center;
  height: 24px;
  border: 1px solid $rs_scroll_color_grey;
  transition: border-color 0.3s ease;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    border-color: $ff-primary;
  }
  .customGraph-title {
    padding: 8px;
    @extend .fontPoppinsMediumSm;
  }
}
.custom-Graph-Add-DefectIcon {
  height: 24px;
  width: 24px;
  margin-left: -1px;
  margin-top: 0px;
}
.custom-graph-Btn {
  position: absolute;
  right: 20px;
}

.QuickStartCustomGraphAddBtn {
  margin-top: 5px;
}

.icons-style {
  color: $ff-primary;
}
.select-field-graph {
  width: 420px;
  margin-top: 2px;
}
.custom-chip {
  border: 1px solid $ff-icon-default;
  background-color: transparent;
  border-radius: 5px;
}

.custom-chip:hover {
  background-color: $primary;
}
.table_link {
  color: $ff-primary;
}
.custom-svg {
  .MuiSelect-icon {
    margin-right: 8px;
    color: $ff-icon-default;
  }
}
.custom-select-dropdown {
  .MuiPopover-root.MuiMenu-root .MuiMenu-list .MuiMenuItem-root.MuiButtonBase-root {
    padding: 0;
  }
}
.radioButtonPadding.MuiFormGroup-row {
  .MuiFormControlLabel-root {
    padding-left: 10px;
  }
}
.chart-containers {
  height: 477px;
  width: auto;
  background-color: $Gray97;
  position: relative;
}

.graphic-container {
  max-width: 1200px;
  overflow-y: hidden;
  overflow-x: auto;
}
.custom-radio-checked {
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: $ff-primary;
  }
}
.custom-field-height {
  height: 35px;
}
.custom-auto-height {
  height: auto;
}
.selected-value-field {
  color: $grey_darkest;
}
.label-header-style {
  color: $ff-text;
}
.placeHolderStyle {
  color: $ff-placeholder;
}
.table-content-height {
  height: 35px;
}

.filters_header_open {
  background: $white_original;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  right: 14px;
  box-shadow: 0px 0px 15px $rs_shadaow;
  top: 45px;
  min-width: 254px;
  // max-height: 369px;
  padding-bottom: 15px;
  padding-top: 8px;
  .filter-container {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    .filter {
      height: 100%;
      width: 100%;
      padding-left: 5px;
      @extend .fontPoppinsSemiboldSm;
    }
  }
  .main-filter-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .react-datepicker {
      border: none;
      right: 10px;
      .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker__navigation--previous {
        left: 15%;
      }
      .react-datepicker__navigation--next {
        right: 15%;
      }
      .react-datepicker__header {
        text-align: center;
        background-color: white;
        border-bottom: none;
        border-top-left-radius: 0.3rem;
        padding-top: 8px;
        position: relative;
        @extend .fontPoppinsRegularSm;
        .react-datepicker__current-month {
          @extend .fontPoppinsSemiboldMd;
          border-bottom: 1px solid $ff-border;
        }
      }
      .react-datepicker__month {
        @extend .fontPoppinsRegularSm;
      }
    }
  }
}

.filters_header_open::-webkit-scrollbar {
  display: none;
}
.chartHeader {
  color: $rs_primary;
}
.radio_groups_style {
  max-height: 219px;
  overflow-y: auto;
  padding-bottom: 15px;
}
.dashboard-radio {
  &.MuiButtonBase-root.MuiRadio-root.Mui-checked {
    color: $ff-primary;
  }
}
.chip-styling {
  .css-6od3lo-MuiChip-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 6px;
    padding-right: 8px;
    white-space: nowrap;
  }
}
.label-margins {
  width: 100%;
}
.label-margin {
  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 5px 10px;
    }
    .Mui-disabled {
      .MuiButtonBase-root:hover .MuiChip-label {
        -webkit-text-fill-color: $ff-white;
      }
      .MuiButtonBase-root:hover .MuiSvgIcon-root {
        color: $ff-white;
      }
      .MuiButtonBase-root:hover {
        background-color: $primary-bg-dark !important;
      }
      .MuiChip-label {
        -webkit-text-fill-color: $ff-text;
      }
      .MuiSvgIcon-root {
        color: $ff-text;
      }
      -webkit-text-fill-color: $ff-text;
    }
  }

  .chip-component-custom-graph {
    .MuiButtonBase-root {
      background-color: $ff-white;
      border-radius: 5px;
      border: 1px solid $ff-border;
      .MuiChip-label {
        color: $ff-text;
        &:hover {
          color: $ff-white;
        }
      }
    }
    .MuiSvgIcon-root {
      color: $ff-text;
      &:hover {
        color: $ff-white !important;
      }
    }
    .MuiButtonBase-root:hover .MuiChip-label {
      color: $ff-white !important;
    }
    .MuiButtonBase-root:hover .MuiSvgIcon-root {
      color: $ff-white;
    }
    .MuiButtonBase-root:hover {
      background-color: $primary-bg-dark !important;
    }
  }
}

.custom-action-icons:hover {
  color: $rs_primary;
  background-color: $white_original;
  border-radius: 100%;
}
.dashBoard-textarea-style {
  height: 76px !important;
  overflow-y: auto;
  resize: none;
  background: none;
  font-size: 12px;
  border: 1px solid #dbd8d8;
  border-radius: 5px;
  width: 420px;
}
.customSelect-field {
  width: 420px;
  min-height: 35px;
  margin-top: 2px;
}
.customSelect-field-graph {
  width: 420px;
  min-height: 35px;
  margin-top: 2px;
}
.dashBoard-access {
  height: auto;
  width: 450px;
  position: sticky;
}
.menu-items-details {
  transform-origin: top right;
  position: absolute;
  border: 1px solid $rs_border;
  top: 30px;
  right: -15px;
  z-index: 40;
  height: 55px;
  width: 168px;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px $rs_shadaow;
  background-color: $white_original;
  --tw-ring-color: $black_original_100;
  --ring-opacity: 0.05;
  &:focus {
    outline: none;
  }
}
.dashboard-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-label {
    display: block;
    color: $rs_blue;
    text-align: left;
    letter-spacing: 0px;
    @extend .fontPoppinsMediumSm;
    @media (min-width: 640px) {
      padding-top: 0.5rem;
    }
    @media (min-width: 640px) {
      margin-top: 1px;
    }
  }
  .label-value {
    @extend .fontPoppinsRegularSm;
    max-width: 150px;
    margin-top: 0.25rem;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
  }
  .dashboard-top-content {
    .dashboard-createdOn {
      margin-left: 20px;
    }
  }
  .description-val {
    @extend .fontPoppinsRegularSm;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
    float: left;
    width: auto;
  }
  .permission-label {
    color: $ff-primary;
    display: block;
    text-align: left;
    letter-spacing: 0px;
    @extend .fontPoppinsSemiboldSm;
    @media (min-width: 640px) {
      margin-top: 1px;
    }
    @media (min-width: 640px) {
      padding-top: 0.5rem;
    }
  }
}
.create-dashboard-box {
  display: flex;
  flex-direction: column;
  align-items: start;
  .textFieldDashboard {
    .storybook-input {
      height: 35px;
    }
  }
  .dashboardDescription {
    .textarea {
      height: 76px;
    }
  }
  .MuiFormControl-root {
    width: inherit;
  }
  .dashBoard-textarea-style {
    width: 100%;
  }
  .customSelect-field {
    width: inherit;
  }
  .select-user-tbox {
    width: 100%;
  }
  .create-dashboard-theader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}
.custom-graph-box {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  .radioGraphContainer {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .MuiFormGroup-row {
      gap: 20px;
    }
  }

  .selectContainer {
    width: 100%;
    height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
  }
  .select-content-box {
    width: 100%;
    .MuiOutlinedInput-root {
      &:hover .MuiOutlinedInput-notchedOutline,
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $rs_primary;
        border-width: 1px;
      }
    }

    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      opacity: 0.5;
    }
    .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline {
      border-color: $red;
      border-width: 1px;
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: $red;
      border-width: 1px;
    }

    .form-control-select {
      width: 100%;
      .customSelect-field {
        width: 100%;
      }
    }
  }
  .radioButton-custom-graph {
    &.MuiButtonBase-root {
      &.Mui-checked {
        color: $ff-primary;
      }
    }
  }
}
.rotate-text {
  transform: rotate(-90deg);
}
.height {
  height: calc(100vh - 112px);
  overflow-y: auto;
}

.list-options {
  .MuiTypography-root {
    @extend .fontPoppinsRegularSm;
    color: $ff-text;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.menuItems-container {
  .MuiMenuItem-root {
    height: 35px;
    &:hover .MuiCheckbox-root {
      color: $ff-white;
    }
    &:hover .MuiTypography-root {
      color: $ff-white;
    }
    .Mui-checked {
      color: $ff-primary;
    }
  }
}
.dashboard-table-container {
  tbody {
    display: block;
    height: 444px;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.dashBoard-project-row:hover {
  height: 35px;
}
.dashBoard-project-row:hover .project-action {
  display: flex;
}
.error-message {
  color: $red;
}
.search-dashBoard {
  align-items: end;
}
.no-result-found {
  svg {
    height: 130px;
  }
}
.label-box {
  z-index: 100;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: left top;
  bottom: 19%;
  left: 35px;
  width: 250px;
  display: flex;
  justify-content: center;
}
.chart-label {
  color: $grey_darkest;
}

.dashBoard-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pieChart-legend-container {
  .legend-container {
    margin-top: 0px;
  }
  .legend-container-wrapper {
    margin-left: 120px;
  }
}
.dashBoard-table-height {
  .ff-table {
    margin-bottom: 20px;
  }
}
.custom-dashBoard-input-field input[type='text'] {
  @extend .fontPoppinsRegularSm;
  border: 1px solid $ff-border;
  box-shadow: none;
  text-align: left;
  letter-spacing: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
}
