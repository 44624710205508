@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.checkbox-column-style {
  width: 4%;
  padding: 8px;
  font-size: 12px;
  margin-left: 16px;
}

.table-header-column {
  @extend .fontPoppinsSemiboldSm;
  padding: 8px;
  color: $grey_darkest;
}

.table-body-column {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
  padding: 8px;
  overflow-wrap: break-word;
}

.close-icon-style {
  float: right;
  margin-top: -22px;
  color: $red_darkest;
  cursor: pointer;
}

.error-font-style {
  @extend .fontPoppinsRegularSm;
  color: $red_darkest;
}

.width-style {
  width: 40px;
}

.editable-field-color {
  color: $blue_dark;
}

.table-body-height-style {
  height: 61vh;
}

.tableHeightForRecoredeEle {
  height: 61vh;
}

#action-style {
  visibility: hidden;
}

#action-style:hover {
  display: block;
}

.dropdown-table-header-style {
  margin-top: -20px;
}

.table-body-column .css-1480iag-MuiInputBase-root-MuiInput-root {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_dark;
  opacity: 1;
  width: 120px;
  padding: 8px;
  overflow-wrap: break-word;
}

.locator_type_padding {
  padding: 7px;
}

.dropdownRecordedWidth {
  width: 80%;
}
