@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.select-drop-down {
  .react-dropdown-select {
    :disabled {
      opacity: 0.5 !important;
    }
    height: 36px;
    @extend .fontPoppinsRegularMd;
    color: $ff-text;
    border: 1px solid $ff-border !important;
    border-radius: 4px !important;
    padding-left: 8px !important;
    &:focus-within {
      box-shadow: none !important;
      border: 1px solid $ff-primary !important;
    }
    .react-dropdown-select-dropdown {
      background-color: $ff-dropdown-bg;
      border-radius: 5px;
      max-height: 160px;
      overflow: auto;
    }

    .react-dropdown-select-content {
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      span {
        @extend .fontPoppinsRegularSm;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 5px);
      }
    }

    .react-dropdown-select-input {
      border-radius: 3px;
      margin-left: 0;
    }
  }
}

.select-options-dropdown {
  background-color: $ff-dropdown-bg;
  padding: 16px 0;
  box-shadow: 0px 0px 6px $ff-dropdown-shadow !important;
  color: $ff-options-text;
  @extend .fontPoppinsRegularMd;
  div {
    height: 35px;
    div {
      margin: 0px;
      padding-left: 2px;
      padding-top: 2px;
      vertical-align: middle;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background-color: $ff-primary;
      color: $ff-white;
    }
    &.selected-option {
      background-color: $ff-primary;
      color: $ff-white;
    }
  }
}

.border-red-error {
  .react-dropdown-select {
    border: 1px solid $ff-error !important;
  }
}

.field-error-message {
  margin-left: 1.5px;
  @extend .fontPoppinsRegularXs;
  color: $ff-error;
  margin-bottom: -15px;
}
