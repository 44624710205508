@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.textfield-container {
  p {
    margin-left: 1.5px;
    @extend .fontPoppinsRegularXs;
    color: $red;
  }
  input {
    color: $grey_darkest;
    box-shadow: 0px 0px 6px #00000000;
    @include fontPoppins(Poppins-Regular, 12px);
    &:focus {
      @include fontPoppins(Poppins-Regular, 12px);
    }
    &:disabled {
      opacity: 0.5;
    }
    &::placeholder {
      @include fontPoppins(Poppins-Regular, 12px);
      color: $rs_placeholder;
    }
  }

  div > input[type='number'] {
    width: 100%;
  }
  div > input[type='number']:focus {
    border: none;
    border-color: transparent;
    box-shadow: none;
  }
}
