.scrollbar {
  overflow-x: auto;
}

.modal-body-height {
  height: 398px;
}
.table-height {
  height: 19.25rem;
}
