@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';

@keyframes slide-from-right {
  0% {
    left: 100%;
    opacity: 0;
  }

  100% {
    left: 50%;
    opacity: 1;
  }
}

.search-all-project-wrapper {
  position: fixed;
  left: 215px;
  background-color: $primary;
}

.search-box-only {
  @extend .fontPoppinsRegularLg;
  width: 100%;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: unset;
  font-size: '15px';
  border-bottom: 1px solid $white;
  background-color: $primary;
  color: $white;
  padding: 10px 0px 10px 45px;
}

.search-all-project-box {
  min-width: 280px;
  max-width: 331px;
  width: 15%;
  background-color: $primary;
  position: fixed;
  left: 210px;
  box-shadow: 0px 0px 6px $rs_black;
  border: 1px solid $primary;
  border-radius: 5px;
  animation: all 0.1s ease-out;
  top: 72px;
}

.search-all-project-box-collapsed {
  @extend .search-all-project-box;
  left: 74px;
}

.rsk-project-row-hover {
  background-color: $primary;
  width: 100%;
  padding: 0px 15px;
  height: 36px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.rsk-project-row-hover:hover {
  background-color: $white;
  color: $primary;
  width: 100%;
  padding: 0px 15px;
  height: 36px;
}

.rsk-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Created placeholder mixin
@mixin placeholder($color, $size: '') {
  &::-webkit-input-placeholder {
    color: $color;

    @if $size != '' {
      font-size: $size;
    }
  }

  &:-moz-placeholder {
    color: $color;

    @if $size != '' {
      font-size: $size;
    }
  }

  &::-moz-placeholder {
    color: $color;

    @if $size != '' {
      font-size: $size;
    }
  }

  &:-ms-input-placeholder {
    color: $color;

    @if $size != '' {
      font-size: $size;
    }
  }
}

// Use placeholder mixin (the size parameter is optional)
[placeholder] {
  @include placeholder($dark-gray);
}

.search-projects-scroll {
  height: auto;
  background: $primary;
  overflow-y: scroll;
}

.search-projects-scroll::-webkit-scrollbar {
  width: 4px;
}

.search-projects-scroll::-webkit-scrollbar-track {
  -webkit-border-radius: 1px;
  border-radius: 2px;
}

.search-projects-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: $white;
}

.search-project-icon {
  position: absolute;
  left: 10px;
  top: 16px;
  height: 14px;
  width: 14px;
  color: $white;
  margin-top: -3px;
}

.search-all-project-icon {
  position: absolute;
  left: 8px;
  top: 7px;
}

.search-project-project-name {
  @extend .fontPoppinsRegularLg;
  position: absolute;
  left: 45px;
  top: 7px;
}

.search-project-project-type {
  position: relative;
  margin-left: -7px;
  margin-top: 7px;
}

.search-project-tooltip {
  @extend .fontPoppinsRegularLg;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 190px;
  display: block;
  position: absolute;
  top: 6px;
  left: 45px;
}

.search-project-no-project {
  @extend .fontPoppinsRegularMd;
  color: $white;
  margin-left: 40px;
  margin-top: 10px;
}

.rsk-project-name-label-style {
  text-align: left;
  letter-spacing: 0px;
  color: $white;
}
