@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

.content-container {
  width: 547px;
  height: max-content;
  max-height: 597px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 23px;
  background: $white_original;
  border-radius: 13px;
  box-shadow: 0px 0px 16px $shadow_black;
  opacity: 1;
  justify-content: space-evenly;

  .content-header {
    font-size: 17px;
    color: $rs_primary;
    font-family: Poppins-Bold;
    line-height: 24px;
  }

  .signin-footer {
    @extend .fontPoppinsSemiboldSm;
    display: flex;
    height: 17px;
    margin-right: 170px;
    margin-top: 20px;
    .signin-footer-text-color {
      color: $black;
    }
    .signin-footer-signin-color {
      color: $rs_primary;
    }
  }
}

.sign-in-button {
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  background: $orange;
  border: 1px solid $orange;
  box-shadow: 0px 0px 14px #0000003b;
  color: $skyblue_light;
  width: 466px;
  height: 42px;
  opacity: 1;
}

.forgot-password {
  @extend .fontPoppinsSemiboldSm;
  color: $rs_primary;
}

.text-green {
  color: $green;
}

.expired-text {
  color: #f8981c;
}

.btn-bg-white {
  background: $white_original 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px $shadow_black;
  color: $orange;
}

.btn-bg-orange {
  background: $orange 0% 0% no-repeat padding-box;
  color: $white_original;
}

.common-btn {
  border-radius: 20px;
  border: 1px solid $orange;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  width: 300px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divide-or {
  position: relative;
  height: 1px;
  background-color: $black;
  width: 80%;
  margin: 38px auto;
  span {
    position: absolute;
    top: -14px;
    left: 60px;
    display: inline-block;
    width: 80px;
    background-color: $white_original;
    border-radius: 40%;
    color: $black;
    text-align: center;
  }
}

.content-border {
  border-bottom: 2px solid $orange;
  margin-top: 8px;
  width: 50px;
}

.common-padding {
  padding: 24px 45px;
}

.orange-text {
  color: $orange;
}

.primary-text {
  color: $ff-primary;
}

.signup-padding {
  padding: 16px 16px 26px;
}

.azure-signin-padding {
  padding: 36px 45px;
}

.common-input-label {
  color: $black_light;
}

.azure-signin-btn {
  border-radius: 21px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  background: $orange;
  border: 1px solid $orange;
  box-shadow: 0px 0px 14px #0000003b;
  color: $skyblue_light;
  width: 250px;
  height: 42px;
  opacity: 1;
}

.account-blocked-error {
  font-size: 12px;
  padding: 15px 0px;
  .highlight {
    strong {
      letter-spacing: 0.22px;
    }
  }
}

.account-blocked-error :first-child :last-child {
  margin-top: 15px;
}
.common-padding-account-locked {
  padding: 24px 25px;
  position: relative;
}
.contact-us-btn {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 0px 14px $shadow_black;
  border: 1px solid $orange;
}

.blocked-content-container {
  width: 550px;
  height: fit-content;
  max-height: 597px;
  min-height: 246px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 23px;
  background: $white_original;
  border-radius: 13px;
  box-shadow: 0px 0px 16px $shadow_black;
  opacity: 1;

  .content-header {
    @extend .fontPoppinsBold;
    font-size: 17px;
    color: $rs_primary;
    line-height: 24px;

    .lock-icon {
      position: absolute;
      top: 27px;
      left: 176px;
    }
  }
}
