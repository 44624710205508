@import './variable.scss';
@import '@styles/rs_fonts.scss';

@mixin common_button_Style {
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 3px 10px;
  text-transform: none;
  @extend .fontMontserratMediumXs;

  @media (min-width: $xl) {
    // above 1280
    font-size: 10px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 5px;
    height: 32px;
  }
}

.rs_primary_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $rs_blue_font;
    color: $rs_blue_font;
    width: fit-content;

    &:hover {
      background: $rs_blue_font;
      color: $white_original;
    }

    &:disabled {
      border-color: $rs_gray_bg;
    }

    &.icon-btn {
      svg {
        position: relative;
        top: 3px;
      }
    }
  }
}

.rs_secondary_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $rs_gray;
    color: $rs_gray;
    width: fit-content;

    &:hover {
      background: $rs_gray;
      color: $submit_btn_font_color;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.rs_destructive_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $rs_analytics_fail;
    color: $rs_analytics_fail;
    width: fit-content;

    &:hover {
      background: $rs_analytics_fail;
      color: $submit_btn_font_color;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
.ff-upload-btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $ff-border;
    color: $ff-placeholder;
    width: fit-content;

    &:hover {
      background: $ff-primary;
      color: $ff-white;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.ff-modal-header-btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border: 1px solid $ff-white;
    color: $ff-white;
    width: fit-content;

    &:hover {
      background: $ff-white;
      color: $ff-primary;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.web_service_upload_btn {
  .MuiButtonBase-root {
    @include common_button_Style;
    border-radius: 5px;
    padding: 6px 12px;
    height: 25px;
    border: 1px solid $grey_Nevada;
    color: $grey_Nevada;
    width: fit-content;

    &:hover {
      background: $ff-primary;
      color: $ff-white;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
.web_service_disable_upload_btn {
  @extend .web_service_upload_btn;
  opacity: 0.5;
}
