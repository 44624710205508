@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';

.t_header {
  width: 310px;
}

.Configcircle {
  margin-left: 3rem;
  margin-top: 0.8rem;
}

.sideHeaders {
  margin-left: 20px;
  font-weight: 500;
  color: $blue_dark;
}

.breadCrumbBlueLink {
  color: $blue_dark;
  font-size: 14px;
}

.breadCrumbGrayLink {
  color: $grey_dark;
  font-size: 14px;
}

.config_Page {
  height: 26rem;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: $white_original 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.boxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 420px;
  background-color: $white_original;
}

.changeMemoryBoxModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: 265px;
  background-color: $white_original;
  box-shadow: 24px;
  padding: 4px;
}
.headerMemoryChange {
  width: 540px;
  height: auto;
  display: flex;
  border-top: 8px solid $rs_analytics_warning;
  margin-top: -4px;
  margin-left: -4px;
}
.successImg {
  top: 318px;
  left: 540px;
  width: 96px;
  height: 96px;
  opacity: 1;
}

.memorySvgImgStyle {
  width: 68.98px;
  height: 63.8px;
  margin-left: 20px;
  margin-top: 34px;
}
.svgYellow {
  color: $rs_analytics_warning;
}
.memory-button-cancel {
  border: 1px solid $gray_40;
  opacity: 1;
  color: $gray_40;
  padding: 6px 12px;
  height: 25px;
  border-radius: 5px;
  margin: 5px;
  line-height: 1;
  margin-left: 390px;
}
.memory-delete-button {
  letter-spacing: 0px;
  color: $rs_primary;
  opacity: 1;
  border: 1px solid $rs_primary;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  margin: 5px 12px 0 0;
  line-height: 1;
  margin-left: 18px;
}
.distribute-cancel {
  border: 1px solid $rs_primary;
  opacity: 0.5;
  color: $rs_primary;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  border-radius: 4px;
  line-height: 1;
}
.distribute-update {
  color: $rs_primary;
  opacity: 1;
  border: 1px solid $rs_primary;
  border-radius: 5px;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  line-height: 1;
}
.distribute-update:hover {
  color: $white_original;
  background-color: $rs_primary;
}
.footerBlue {
  width: 100%;
  height: 67px;
  background-color: AliceBlue;
  margin-top: 23px;
}

// css start for landing page
.card-header {
  color: $license_tooltip_bg;
}
.available-card {
  color: $rs_analytics_blue_header;
}
.cancel-info {
  border: 1px solid $grey_Nevada;
  color: $grey_Nevada;
  border-radius: 4px;
  width: 59px;
  height: 25px;
  background-color: transparent;
}
.cancel-info:hover {
  background: $grey_Nevada;
  color: white;
}

.delete-info {
  border-color: $rs_analytics_blue_header;
  color: $rs_analytics_blue_header;
  border-radius: 4px;
}
.delete-info:hover {
  background: $rs_analytics_blue_header;
  color: white;
}

.table-line {
  border-bottom: 1px solid;
  color: $rs_tabs_border_color;
}
.total_memory {
  color: $rs_analytics_pass;
}
.assigned_memory {
  color: $rs_analytics_warning;
}
.used_memory {
  color: $rs_analytics_fail;
}

.total-memory-card:hover {
  box-shadow: 0 0 5px 3px $rs_analytics_pass;
  border-color: $rs_analytics_pass;
}
.available-memory-card:hover {
  box-shadow: 0 0 5px 3px $rs_analytics_blue_header;
  border-color: $rs_analytics_blue_header;
}
.assigned-memory-card:hover {
  box-shadow: 0 0 5px 3px $rs_analytics_warning;
  border-color: $rs_analytics_warning;
}
.used-memory-card:hover {
  box-shadow: 0 0 5px 3px $rs_analytics_fail;
  border-color: $rs_analytics_fail;
}
.distribute-text {
  color: $grey_darkest;
}
.distribute-input-field input[type='text'] {
  border: none;
  box-shadow: none;
  outline: none !important;
  text-align: left;
  letter-spacing: 0px;
  padding-bottom: 6px;
  padding-top: 6px;
  height: 22px;
  padding-left: 10px;
}

.distribute-memory-width input[type='text'] {
  width: 297px;
}

.distribute-width {
  width: 418px;
}

.custom-input {
  .label-input {
    min-height: 50px !important;
  }
}
.label-header {
  color: $rs_blue;
}
.user-role {
  color: $rs_analytics_blue_header;
}
.project-details-row {
  color: $payne_Gray;
}
.project-details-header {
  color: $rs_primary;
}

.disabled_delete {
  color: $license_card_border;
}
.projectDetailsDataa {
  position: relative;
  z-index: 999;
}
.error_message {
  color: $rs_analytics_fail;
}

.memory-dialog-container {
  .MuiDrawer-paperAnchorRight {
    border-radius: 10px 0px 0px 10px;
  }
}

.Select-component {
  border: 1px solid $grey_dark;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 110px;
  height: 34px;
}
.border-valid {
  border: 1px solid $rs_analytics_fail;
}
.border-invalid {
  border: 1px solid $grey_dark;
}
.disabledField {
  background: $Gray97;
}
.details-no-data-style {
  color: $black_original;
}
.details-data-style {
  color: $payne_Gray;
}
.memory-drawer-footer {
  width: 100%;
  height: 52px;
  background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
  margin-top: 23px;
}
.header-style {
  color: $rs_analytics_blue_header;
}
.content-style {
  color: $license_tooltip_bg;
}
.closed-text {
  color: $rs_analytics_fail;
}
.memory-state-button {
  vertical-align: top;
  background-color: transparent;
  color: $grey_Nevada;

  &:hover {
    background-color: transparent;
    color: $rs_primary;
  }
}
.memory-delete-footer {
  width: auto;
  height: 35px;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  margin-top: 20px;
}
.table-content-width {
  max-width: fit-content;
}
.details-background:hover {
  background-color: $rs_primary;
  padding-top: 5px;
  color: $white_light;
}
