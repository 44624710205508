@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.main-section {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding-bottom: 20px;

  .service-down-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 28px 0;
    padding-left: 76px;
    padding-right: 10px;

    .logo-section {
      height: 71px;
      width: 350px;
    }
  }

  .contact-us-section {
    height: 30px;
    width: 108px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 41px;
    cursor: pointer;
    position: relative;

    .rounded-border {
      height: 27px;
      width: 27px;
      border: 1px solid $rs_analytics_blue_header;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: -19%;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;

      .contact-us-img {
        color: $rs_analytics_blue_header;
        height: 15.11px;
        width: 15.11px;
        margin-bottom: 2px;
      }
    }

    .rounded-border-arrow {
      .contact-us-img-arrow {
        color: $rs_analytics_blue_header;
        height: 15.11px;
        width: 15.11px;
        margin-bottom: 2px;
        opacity: 0;
        position: absolute;
        top: 28%;
        left: 19%;
        transition: all 0.5s ease;
      }
    }

    .contact-us-text {
      @extend .fontPoppinsSemiboldSm;
      color: $rs_analytics_blue_header;
    }

    &:hover {
      .rounded-border {
        transform: translate(360%, -50%);
      }

      .contact-us-img {
        transform: translate(-420%, 4%);
        opacity: 0;
      }

      .contact-us-img-arrow {
        transform: translate(406%, -7%);
        opacity: 1;
      }
    }
  }

  .service-down-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side-section {
      display: flex;
      flex-direction: column;
      width: 451px;

      .service-down-title {
        height: 132px;
        width: 353px;
        display: flex;
        flex-direction: column;
        color: $rs_analytics_blue_header;
        margin-left: 58px;
        margin-bottom: 40px;
      }

      .service-down-context {
        @extend .fontPoppinsSemiboldLg;
        width: 451px;
        height: 97px;
        color: $rs_light_dark_gray;
        margin-left: 58px;
        margin-bottom: 60px;
      }

      .service-down-footer-content {
        @extend .fontPoppinsSemibold-size-28;
        width: fit-content;
        height: 38px;
        color: $rs_light_dark_blue;
        margin-left: 173px;
      }
    }

    .right-side-section {
      height: 485px;
      width: 771px;
    }
  }
}
