@import '@src/css_config/variable.scss';

.capture-response-time,
.capture-b-time,
.capture-status-line,
.capture-session-id,
.capture-detail-cookie,
.capture-detail-cookies,
.capture-content-type,
.capture-cookie,
.capture-cookies,
.capture-verify-status-code,
.capture-response-body,
.capture-response,
.capture-header,
.capture-json-path,
.capture-header-key {
  position: relative;
  left: 270px;
}
.capture-response-data,
.default-assert-data {
  position: static;
  overflow: auto;
  height: 260px;
  width: 80.1%;
  padding: 0;
  user-select: none;
  background-color: $white_original;
  border-bottom: 1px solid $grey_light;
  border-right: 1px solid $grey_light;
  border-left: 1px solid $grey_light;
}
.sections-main-container {
  position: absolute;
  height: 0px;
  left: 0px;
  display: flex;
  gap: 5px;
  top: 0px;
  bottom: 21px;
}
.dropdown-container {
  display: flex;
}

.icons-container {
  display: flex;
  height: 30px;
  position: relative;
  top: 20px;
  margin-left: 24px;
}

.capture-verify-status-code {
  width: 300px;
  bottom: 30px;
}
.capture-response-body {
  width: 300px;
  bottom: 103px;
}
.capture-response {
  width: 300px;
  bottom: 142px;
}
.capture-header {
  width: 300px;
  bottom: 180px;
}
.capture-header-key {
  bottom: 233px;
  left: 240px;

  > .first-inp {
    width: 130%;
  }
}
.capture-json-path {
  bottom: 214px;
  left: 240px;

  > .first-inp {
    width: 130%;
  }
}
.capture-header-key {
  width: 500px;
  bottom: 254px;
  > .first-inp {
    width: 130%;
  }
}
.capture-content-type {
  width: 300px;
  bottom: 300px;
}
.capture-cookie {
  width: 500px;

  bottom: 337px;
  > .first-inp {
    width: 130%;
  }
}
.capture-cookies {
  width: 300px;
  bottom: 381px;
}
.capture-detail-cookie {
  width: 500px;

  bottom: 415px;
  > .first-inp {
    width: 130%;
  }
}
.capture-detail-cookies {
  width: 300px;
  bottom: 461px;
}
.capture-session-id {
  width: 300px;
  bottom: 500px;
}
.capture-status-line {
  width: 300px;
  bottom: 543px;
}
.capture-b-time {
  width: 300px;
  bottom: 584px;
}
.cdfr-dropdown {
  height: 200px;
  width: 242px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  position: absolute;
  background-color: $white_original;
  overflow-x: hidden;
}
