@import '@styles/rs_fonts.scss';

.dropdown-tree-popup {
  min-width: 180px;
}

.overlay-dropdown-tree {
  position: relative;
}

.overlay-dropdown-tree .dropdown-tree-popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 24px;
  z-index: 10000;
}

.dropdown-tree-popup .shadow-lg {
  max-height: 184px;
  overflow: auto;
}

.dropdown-tree-wrapper #table-body {
  height: fit-content;
  overflow: auto;
  max-height: 300px;
}

.dropdown-tree-wrapper #table-body tbody {
  display: none;
}

.dropdown-tree-search-input-wrapper {
  position: relative;
}

.dropdown-tree-search-input {
  width: 100%;
  input {
    @extend .fontPoppinsRegularMd;
  }
}

.dropdown-tree-button-arrow {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #727171;
}
.dropdown-tree-button-arrow-recorded {
  right: 1%;
  float: right;
  // margin-top: -24px;
  color: #727171;
}

.dropdown-tree-popup {
  display: none;
}

#dropdown-select-tree {
  .fancytree-statusnode-nodata {
    display: none !important;
  }
  .no-simple-tree-data-found {
    display: none;
    color: #3e3e3e;
  }
  .fancytree-plain span.fancytree-title {
    border: none;
    max-width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1648c6;
  }
  span.fancytree-icon {
    display: none;
  }
  span.fancytree-node {
    min-height: 30px;
  }
  span.ws-wrap span.fancytree-title {
    white-space: normal;
  }
  .fancytree-connectors .fancytree-exp-n span.fancytree-expander,
  .fancytree-connectors .fancytree-exp-n span.fancytree-expander:hover {
    background-image: none;
  }
  ul.fancytree-connectors.fancytree-container li {
    background: none;
  }
  .fancytree-connectors .fancytree-exp-nl span.fancytree-expander,
  .fancytree-connectors .fancytree-exp-nl span.fancytree-expander:hover {
    background: none;
  }
  .fancytree-exp-c span.fancytree-expander,
  .fancytree-exp-cl span.fancytree-expander {
    background: url(../../assets/Closed_Arrow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
  }
  .fancytree-exp-e span.fancytree-expander,
  .fancytree-exp-ed span.fancytree-expander {
    background: url(../../assets/Closed_Arrow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px;
  }
  .fancytree-exp-el span.fancytree-expander,
  .fancytree-exp-edl span.fancytree-expander {
    background: url(../../assets/Closed_Arrow.svg);
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 20px;
  }
}

$lineColor: rgba(22, 72, 198, 0.22);
$lineHoverColor: #1648c6;
