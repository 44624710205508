$primary-color: #1648c6;
$white-color: #ffffff;
$font-color: #727171;
$white-bg-color: #fbfcfd;

.white-bg-td {
  background-color: $white-bg-color;
}

.qrs-details-div-data-height {
  height: 275px;
  overflow-y: scroll;
}

.qrs-system-grid-container {
  height: 360px;
  grid-template-rows: 360px;
  .qrs-machine-table {
    height: calc(100% - 20px);
    overflow-y: scroll;
  }
  .qrs-details-div {
    background-color: $white-bg-color;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    height: 100%;
  }

  .headless-switch {
    position: initial;
    right: 47px;
    top: 108px;
  }
}

.qrs-shadow {
  margin: 10px;
  border-radius: 3px;
  height: fit-content;
  -webkit-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -7px rgba(0, 0, 0, 0.75);
}

.dropdown-width {
  width: 30%;
}
