@import '@src/css_config/variable.scss';

.machine-table-row {
  color: $black;
  border: 1px solid $grey_white;
  padding-left: 4.5px;
  border-radius: 0.3rem;
  display: block;
  margin-bottom: 10px;
  margin-right: 10px;
  height: 40px;

  .table-row-div {
    .machine-name {
      border-left: 1px solid $grey_white;
      width: 150px;
    }
    .access-status {
      .access-status-error {
        color: $rs_analytics_fail;
      }
      .access-status-success {
        color: $rs_analytics_pass;
      }
      .access-status-warning {
        color: $rs_analytics_warning;
      }
    }
  }
}

.selectedRow {
  background-color: $skyblue_light;
  border-left: 4.5px solid $rs_primary;
  padding-left: 0;
}

.errorRow {
  border: 2px solid $rs_analytics_fail;
}
.errorRowWithoutLeftBorder {
  @extend .errorRow;
  border-left: 4.5px solid $rs_primary;
}
