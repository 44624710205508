.configMenu ul .drop {
  position: relative;
}
.drop .inner {
  cursor: pointer;
  transition: 5s linear;
  background: #434db8;
  color: #fff;
}
.drop:hover .sub-menu-icon {
  margin-right: 2px;
}

input[type='number']:not(.default-input) {
  border: none;
  border-bottom: 1px solid grey;
  width: fit-content;
  background: transparent;
}
input[type='number']:not(.default-input)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  display: block;
  width: 8px;
  color: #333;
  text-align: center;
  position: relative;
}
.numberField:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: transparent;
  border-bottom: 2px solid grey;
}
input[type='number']:not(.default-input)::-webkit-inner-spin-button {
  background: url('../../assets/input-inner-spin-button.png') no-repeat 50% 50%;
  width: 14px;
  height: 14px;
  padding: 4px;
  position: relative;
  right: 4px;
  border-radius: 28px;
}
.left_navigation {
  cursor: pointer;
  color: #fff;
  border-radius: 0px;
  margin-right: -1px;
}
.left_navigation li:hover {
  background: #fff;
  color: #434db8;
}
