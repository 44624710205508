@import '@styles/rs_fonts.scss';

$progress-filled-line-bg: #147ad6;
$progress-unfilled-line-bg: #7388a95a;
$error-msg: #f44336;

.progress-line-wrapper-container {
  background-color: $progress-unfilled-line-bg;
  border-radius: 5px;
  width: 100%;
  height: 8px;

  .filled-bar {
    border-radius: 5px;
    height: 8px;
    background-color: $progress-filled-line-bg;
    animation: progress-animation 2s linear;
  }
}

.memory-total-consumption {
  min-height: 280px;
  height: 97%;

  .legend-wrapper {
    background-color: #f6f9fe;
    height: 97%;
  }
}

//MbDropDown file

.mbdrown-down-main-container {
  width: 20px;
  margin-left: 9rem;
  margin-top: -3rem;
}

//email config
.center-div-group-btn {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.addrow-maxheight {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  .error-label {
    color: $error-msg;
  }

  .error-msg-fontsize {
    @extend .fontPoppinsRegularSm;
    color: $error-msg;
    word-wrap: break-word;
    height: 40px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    width: 222px;
  }
}

.error-input-border {
  border-bottom: 2px solid $error-msg !important;
}

.delete-outline-icon-line-height {
  :global {
    .MuiSvgIcon-root {
      height: 1.9em;
      position: relative;
      top: -7px;
    }
  }
}

//modal div

.modal-container {
  width: 38rem;
  height: 467px;
  min-height: 420px;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  border-radius: 0.125rem;
  border-radius: 0.125rem;
  overflow: hidden;
  padding-right: 1.5rem;
  transform: translate(-50%, -50%);
}

.label-name {
  font-size: 14px;
  font-family: OpenSans-Regular;
  color: blue;
}

.email-config-input-border {
  padding-bottom: 0px;
  border-bottom: 2px solid var(--lightGray) !important;
}

.email-config-input-placeholder {
  padding: 0;
  margin-top: 5px;

  &::placeholder {
    text-indent: 1px;
  }
}

.email-config-input-padding {
  :global {
    .MuiInputBase-input {
      padding: 5px 0 4px;
      &:focus {
        padding: 5px 0 2px;
      }
      &::placeholder {
        color: #727171;
        opacity: 0.9;
      }
    }
  }
}

.distribute-memory-total-card {
  margin-left: -151px;
  margin-top: 55px;
  width: 55px;
}

.distribute-memory-icon {
  height: 100px !important;
  width: 70px !important;
  margin-top: -0.7rem !important;
}

.distribute-memory-outlined-icon {
  height: 100px !important;
  width: 70px !important;
  margin-top: 0.3rem !important;
  margin-left: 10.6rem !important;
}

//Detail modal

.recipient-details-srollbar {
  margin-top: 2px;
  display: flex;
  max-height: 140px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-header {
  padding-left: 2.8rem;
}
