@import '../../css_config/variable.scss';
.infoMargin {
  border-top: 8px solid $blue;
}
.infoMadalTitleColour {
  color: $grey_darkest;
}
.infoMadalTitleInfoColour {
  color: $blue;
}
.infoModalFooter {
  background-color: $SkyLightblue;
}
