@import '../../../../../styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

$primary-color: #1648c6;
$white-color: #ffffff;
$font-color: #727171;
$white-bg-color: #fbfcfd;
$secondary-blue-color: #3a62c9;
$shadow-color: #00000029;
$red-color: #c50203;
$options-hover-bg: #f0f3f9;
$options-hover-color: #1648c6;
$error-message: #ea0322;

.details-div {
  background-color: $ff-white;
  box-shadow: 0px 0px 6px $shadow-color;
  border-radius: 4px;
  height: fit-content;
}

.config-table-header {
  background-color: $ff-white;
  border-radius: 4px;
  color: $black_original;

  th {
    background-color: $ff-white;
    position: sticky;
    top: 0;
    z-index: 1;

    &:hover {
      background-color: $ff-white;
    }
  }

  &:hover {
    background-color: $white-bg-color;
  }
}

.allign-div-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-row-style {
  box-shadow: 0px 0px 6px $shadow-color;
  background-color: $white-bg-color;
}

.config-table-row {
  &:hover {
    background-color: $white-bg-color !important;
  }
}

.details-div-data-height {
  height: 55vh;
}

.details-section-height {
  height: fit-content;
}
.access-details-table-height {
  height: 66vh;
  overflow-y: scroll;
}

.config_div_table_height {
  height: 75vh;
  overflow-y: scroll;
  background: $ff-white;
}

.system-config-execution-dropdown {
  @extend .fontPoppinsRegularMd;
  width: 30vh !important;
  margin-left: 3px;
  border: none !important;
  color: $font-color;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 160px;
    overflow: auto;
  }

  .react-dropdown-select-content span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 155px;
  }

  .react-dropdown-select-input {
    margin-left: -1px;
  }
}

.system-config-execution-dropdown-spare {
  @extend .fontPoppinsRegularMd;
  width: 30vh !important;
  margin-left: 3px;
  border: none !important;
  color: $font-color;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 105px;
    overflow: auto;
  }

  .react-dropdown-select-content span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 155px;
  }

  .react-dropdown-select-input {
    margin-left: -1px;
  }
}

.option-truncate {
  .react-dropdown-select-dropdown div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .react-dropdown-select-content span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.searchable-dropdown-custom {
  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 250px;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:focus-within {
    box-shadow: none !important;
  }
}
.searchable-dropdown-custom-error {
  border-bottom: 1px solid $ff-error !important;
  width: 100% !important;
  padding: 4px 0px !important;
}
.searchable-dropdown-custom-error {
  border: 1px solid $ff-error !important;
  width: 100% !important;
  padding: 4px 4px !important;
  border-radius: 4px !important;
}

.searchable-dropdown-custom {
  border: 1px solid $ff-border !important;
  width: 100% !important;
  padding: 4px 4px !important;
  border-radius: 4px !important;
}

.system-config-os-dropdown {
  width: 30vh !important;
  margin-left: 3px;
  border: none !important;
  color: $font-color;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 210px;
  }
}

.system-config-os-dropdown-spare {
  width: 30vh !important;
  margin-left: 3px;
  border: none !important;
  color: $font-color;

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    max-height: 150px;
  }
}

.exe-env-options-dropdown {
  border-radius: 1px;
  background-color: $white-bg-color;
  font-family: Poppins-Regular;

  div {
    div {
      margin: 5px 0px 0px;
      padding: 2px 4px;
    }

    &:hover {
      background-color: $ff-primary;
      color: $ff-white;
      .Mui-checked {
        color: $ff-white;
      }
      .MuiButtonBase-root.MuiCheckbox-root {
        color: $ff-white;
      }
    }
    .Mui-checked {
      color: $ff-primary;
    }
  }
}

.config-action-buttons {
  display: flex;
  justify-content: space-between;

  .btn-styles {
    border-radius: 3px;
    text-align: center;
    letter-spacing: 0px;
    padding: 4px 14px;
    @extend .fontPoppinsMediumMd;
  }

  .delete-btn {
    border: 1px solid $red-color;
    background-color: $red-color;
    color: $white-color;

    &:hover {
      opacity: 0.92;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .sec-btn {
    color: $secondary-blue-color;
    border: 1px solid $secondary-blue-color;
    background-color: $white-color;

    &:hover {
      opacity: 0.92;
      background-color: $primary-color;
      color: $white-color;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.browser-logo {
  height: 16px;
  width: 16px;
}

.os-logo {
  height: 14px;
  width: 14px;
}

.download-modal-logo {
  width: 28px;
  height: 29px;
}

.info-modal-margin {
  border-top: 3px solid $secondary-blue-color;
}

.overflowWrap {
  overflow-wrap: break-word;
}

.system-config-web-section {
  display: flex;
  justify-content: space-between;
  background: $rs_gray_bg;
  height: 30px;
  align-items: center;
}

.select-dropdown-width {
  width: 30%;
}
