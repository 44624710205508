@import '@styles/_variables.scss';

.user_profile_style {
  top: 7px !important;
  left: -3px !important;
  :global {
    .MuiList-root {
      width: 100%;
      background: $white;
      border-radius: 50px;
      padding-bottom: unset;
      .MuiMenuItem-root {
        padding: 10px 16px;
        width: 100%;
        color: $red;
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.25rem;
        &:hover,
        &.Mui-selected {
          background-color: $red;
          color: $white;
        }
      }
      .MuiButtonBase-root {
        justify-content: center !important;
      }
    }
  }
}

.rsGrey {
  color: $rs_grey_name;
}
