@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';
@import '@styles/_variables.scss';

.analyticsPage {
  height: 80vh;
}

.testscriptTable {
  tbody {
    max-height: 185px;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}
.analyticstable {
  tbody {
    max-height: 185px;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}

.defects-analytics-table-header {
  height: 40px;
  padding: 10px 5px;
  color: $red;
}

.defect-analytics-table-body {
  tbody {
    max-height: 160px;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}

.date-border {
  border: 1px solid;
  border-color: $grey_dark;
  width: 85px;
}
.picker-border {
  color: $grey_dark;
  font-size: 14px;
  margin-top: 6.6px;
  margin-left: 7.5px;
}

.analyticstableExpanded {
  tbody {
    max-height: 85vh;
    table-layout: fixed;
    display: block;
    overflow: hidden;
    overflow-y: auto;
  }
  tr,
  td {
    tbody {
      max-height: 150px;
      table-layout: fixed;
      display: block;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}

.defectanalytics-table-expanded {
  tbody {
    max-height: 85vh;
    table-layout: fixed;
    display: block;
    overflow: hidden;
    overflow-y: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}

.all-defects-list-table {
  tbody {
    max-height: 360px;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid;
    border-color: $tr-bottom-color;
  }
}

.accodrion-bug-summary-header {
  background-color: $rs_primary;

  div {
    color: $white_original;
  }
}

// Analytics Tab Router Navigation Styling :: BEGIN

.tab_navbar {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border-bottom: 2px solid;
    color: $rs_primary;
    font-weight: 600;
  }
}

// Analytics Tab Router Navigation Styling :: END

// scripts-result-accordion styling ::  BEGIN
.result-body-big-modal {
  height: 36rem;
  .step-result-inner-modal-analytics {
    position: fixed;
    top: 88px;
  }
}

.text-format-common {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.base-card {
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: $white_light 0% 0% no-repeat padding-box;
}
.highlight {
  border: 2px solid $off_bule;
  border-radius: 1px;
  outline: none;
  box-shadow: 0 0 10px $off_bule;
}

.link-text-color {
  color: $blue;
  &:hover {
    color: $light_blue;
  }
}
.card-box-shadow {
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
}

.text-value {
  @extend .text-format-common, .fontPoppinsRegularMd;
  line-height: 17px;
  color: $grey_darkest;
}

.result-primary {
  color: $blue_dark;
}

.result-passed,
.status-passed {
  color: $green;
}

.result-failed,
.status-failed {
  color: $red;
}

.result-warning,
.status-warning {
  color: $yellow;
}

.result-skipped,
.status-skipped {
  color: $grey_dark;
}

.table-header {
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  opacity: 1;
  color: $grey_darkest;
  letter-spacing: 0px;
  text-align: left;
  line-height: 2rem;
}
.table-scroll-height {
  max-height: 19vh;
}
//more details styles

.more-details {
  @extend .fontPoppinsSemiboldLg;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
}

.platform-chevron {
  color: $grey_dark;
}
.table-body {
  @extend .text-format-common;
  line-height: 2rem;
  color: $grey_dark;
}
//chart styles
.legend {
  margin-left: 18rem;
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.circle {
  display: inline-block;
}
.graph-labels {
  @extend .fontPoppinsRegularMd;
  letter-spacing: 0px;
  color: $blue_dark;
}
.chart-tooltip-wrapper {
  display: block;
  position: relative;
  width: 64px;
}

.chart-tooltip {
  position: relative;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-70%);
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: $grey_darkest;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px $shadow_black;
}

.chart-tooltip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.chart-tooltip.top {
  top: 0px;
  width: 165px;
  left: 115px;
  height: 65px;
}
/* CSS border triangles */
.chart-tooltip.top::before {
  top: 100%;
  border-top-color: $white_dark;
}

#chart .apexcharts-tooltip {
  color: $white_original;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  $white_original-space: normal !important;
  left: -10px;
}

#chart .apexcharts-tooltip span {
  padding: 6px 10px;
  display: inline-block;
}

.chart-mark-0 {
  margin-top: -39px;
  margin-left: 74px;
}

//module result styles
.result-tree-bs {
  .search_text {
    display: flex;
    justify-content: space-between;
  }
  .script-name {
    @extend .fontPoppinsSemiboldLg;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
  }
  .tree-coldata-style {
    color: var(--dm-normal-989797);
    text-align: left;
    letter-spacing: 0px;
    color: $grey_dark;
    opacity: 1;
  }
}

//accordion styles
.result-header {
  @extend .text-format-common, .fontPoppinsSemiboldSm;
  height: 38px;
  color: $rs_blue_font;
  @media (min-width: $sm) {
    font-size: 12px;
    height: fit-content;
  }
  @media (min-width: $lg) {
    height: 38px;
  }
  @media (min-width: $xl) {
    height: 38px;
  }
}

.result-header-overView {
  @extend .text-format-common, .fontPoppinsSemiboldSm;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  height: 38px;
  color: $grey_darkest;
  @media (min-width: $sm) {
    font-size: 12px;
    height: fit-content;
  }
  @media (min-width: $lg) {
    height: 38px;
  }
  @media (min-width: $xl) {
    height: 38px;
  }
}

.step-result {
  @extend .text-format-common, .fontPoppinsRegularSm;
  color: $blue;
}
.result-body {
  .accordion {
    min-height: 5vh;
    &:hover {
      background-color: $white_accordion;
    }
  }
  .accordion-row-min-h {
    min-height: 5vh;
  }
  .panel {
    background-color: $white_original;
    display: none;
    min-height: 5vh;
    position: relative;
  }
  .result-subHeader {
    @extend .text-format-common, .fontPoppinsMediumSm;
    color: $black;
    cursor: pointer;
  }
  .collapse-icon {
    display: none;
    font-size: 17px;
    color: $grey_darkest;
  }

  .expand-icon {
    color: $grey_darkest;
    font-size: 17px;
    display: inline-block;
  }

  .step-header {
    min-height: 5vh;
    font-size: 10px;
    &:hover {
      background-color: $white_accordion;
    }
  }

  .result-status {
    @extend .text-format-common, .fontPoppinsRegularSm;
  }

  .result-duration {
    @extend .text-format-common;
    @extend .fontPoppinsRegularSm;
    color: $grey_darkest;
  }
  .iteration-header {
    @extend .text-format-common, .fontPoppinsMediumSm;
    min-height: 5vh;
    color: $blue;
    &:hover {
      background-color: $white_accordion;
    }
  }

  .iteration-step {
    @extend .text-format-common, .fontPoppinsMediumSm;
    color: $grey_darkest;
  }
  .iteration-data-set {
    @extend .text-format-common, .fontPoppinsMediumSm;
    @apply px-2 py-0.5 ml-1;
    @apply rounded-lg;
    background-color: $white_dark;
    color: $blue;

    &.file-count {
      background-color: $white_dark;
      color: $blue_dark;
      font-size: 10px;
    }
  }
  .section {
    @extend .text-format-common, .fontPoppinsSemiboldMd;
    min-height: 5vh;
    color: $blue;
  }
  .section-hover {
    @extend .section;
    background-color: $white_accordion;
  }
}
.accordion-inactive {
  visibility: hidden;
  width: 0;
  height: 0;
}
.status-filter {
  background: $white_light 0% 0% no-repeat padding-box;
  box-shadow: 3px 0px 13px $shadow_black;
  border-radius: 5px;
}
.filter {
  width: 100%;
  height: 20px;
  background: $white_original;
  &:hover {
    background-color: $ff-primary;
    color: $ff-white;
  }
}
.selected-filter {
  background-color: $white;
}

// scripts-result-accordion styling ::  END

// Defect log Filter styling:: START
.defect_log_Modal {
  position: absolute;
  background-color: $white_original;
  top: 35px;
  right: 2px;
  width: 750px;
  border-radius: 20px;
  box-shadow: 0px 2px 3px 2px $shadow_black;
  overflow: hidden;
  z-index: 2;

  .filter_Header {
    background-color: $rs_primary;
    color: $white;
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
    align-items: center;
  }

  .Close_btn {
    display: inline-block;
    padding: 6px;
  }

  .Close_btn:hover {
    background-color: $white_light;
    border-radius: 50%;
    transition: background-color 0.5s ease;
  }

  .segment-section {
    background: $white_original;
    border-radius: 10px;
    -webkit-box-shadow: 0px25px 3px 2px $shadow_black;
    -moz-box-shadow: 0px 2px 3px 2px $shadow_black;
    box-shadow: 0px 2px 3px 2px $shadow_black;
    max-height: 115px;
    .segment-block {
      max-height: 90px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .Filter_Slectors_Sections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    .selector_Header {
      border-bottom: 2px solid $rs_primary;
    }
  }
}

.wapper_custom_dropdown > div > button {
  border-radius: 6px;
  width: 142px;
}

.wapper_custom_dropdown > div > div > div > div > div {
  min-width: 140px;
  width: 142px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 105px;
}

.wapper_custom_dropdown > div > div > div > div > div > div {
  @extend .fontPoppinsRegularXs;
  padding: 0px 5px;
  min-width: 140px;
  height: 20px;
  cursor: pointer;
}

.wapper_custom_dropdown > div > div > div > div > div > div > input[type='checkbox'] {
  height: 10px;
  width: 10px;
  cursor: pointer;
}

// Defect log Filter styling:: END

// TODO :: Close Icon white to blue transfrom :: START
// NOTE ::  USE THIS CLASS IN  SPAN TAG
.close-icon-x-white-bule {
  background-image: url('../../assets/analytics/ShrinkIconWhite.svg');
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    background-image: url('../../assets/analytics/ShrinkIcon.svg');
    background-color: $white;
    transition: all 0.2s;
  }
}

// TODO :: Close Icon white to blue transfrom :: END

// TODO :: disable the outline filter section checkbox :: START
.category {
  div {
    input[type='checkbox']:focus {
      outline: none;
      --tw-ring-shadow: none;
      box-shadow: none;
    }
    div {
      input[type='checkbox']:focus {
        outline: none;
        --tw-ring-shadow: none;
        box-shadow: none;
      }
    }
  }
}
// TODO :: disable the outline filter section checkbox :: END

.donut-chart-scroll {
  .apexcharts-canvas ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    border-radius: 5px;
  }
  .apexcharts-canvas ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .apexcharts-canvas ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background: $rs_scroll_color_grey 0% 0% no-repeat padding-box;
  }

  .apexcharts-tooltip {
    transform: translateX(10px) translateY(10px);
    overflow: visible;
    white-space: normal;
    .defects-donut-chart-custom-tooltip {
      @extend .fontPoppinsRegularXs;
      padding: 5px;
      display: inline-block;
      overflow-wrap: break-word;
      word-break: break-all;
      max-width: 160px;
      background-color: $white;
      color: $black;
    }
  }
}

.ff-checkbox-style {
  input[type='checkbox'] {
    box-shadow: 0px 0px 3px inset $border-muted;
    border-radius: 3px;
    cursor: pointer;
  }
  input[type='checkbox']:focus {
    outline: none;
    --tw-ring-shadow: none;
    box-shadow: none;
  }
  input[type='checkbox']:checked {
    color: $rs_primary;
  }
}
.navBarBorder {
  border-color: $rs_primary;
}
.status-filter-box {
  background: $ff-white;
  box-shadow: 0px 0px 12px $shadow_black;
  border-radius: 5px;
  width: 75px;
  height: 136px;
  z-index: 1;
  margin-top: 10px;
  .filter-box {
    width: 100%;
    height: 24px;
    background: $ff-white;
    padding-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: $ff-primary;
      color: $ff-white;
    }
    .selected-filter-box {
      background-color: $white;
    }
  }
  .filter-label-box {
    @extend .fontPoppinsRegularSm;
    text-transform: capitalize;
  }
}

.custom-dashBoard-tab-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-primary;
  opacity: 1;
  :hover {
    background-color: $rs_Disabled;
    text-align: left;
    letter-spacing: 0px;
    color: $license_tooltip_bg;
    opacity: 1;
  }
}
.custom-dashBoard-selected-tab-style {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-primary;
  opacity: 1;
}
