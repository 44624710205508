@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.project-label {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  height: 20px;
  width: 229px;
}

.margin-top-style {
  margin-top: -2px;
}

.tab-style {
  padding-top: 4px;
  &.bottom-space-sm {
    margin-bottom: 10px;
  }
  li {
    margin-right: 24px;
  }
  a {
    display: flex;
    @extend .fontPoppinsSemiboldMd;
    line-height: 24px;
    color: $rs_analytics_blue_header;
    padding-right: 2px;
    border-bottom: 2px solid #434db8;
    @media (min-width: $xl) {
      // above 1280
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: $xl) {
    // above 1280
    padding-top: 4px;
    margin-bottom: 20px;
  }
}

.license-config {
  height: 92px;
  border: 1px solid $light_gray_50;
  border-radius: 10px;
}

.license-config:hover {
  background-color: $rs_bg_whitgrey;
  box-shadow: 0px 0px 20px $config_license;
}

.label-color {
  color: $license_tooltip_bg;
}

.search-width {
  width: 400px;
}

.table-style:hover {
  background-color: transparent !important;
}

.license_lev_timezone {
  table tr:hover {
    background-color: transparent !important;
  }
}

.warning-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 564px;
  height: 235px;
  background-color: $white_original;
  box-shadow: 24px;
  padding: 4px;
}

.headerwarning {
  width: 564px;
  height: auto;
  display: flex;
  border-top: 2px solid $rs_analytics_warning;
  margin-top: -4px;
  margin-left: -4px;
}

.action-button-color:hover {
  color: $ff-primary;
}
