.switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 14px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  border: 1px solid gray;
  cursor: pointer;
  top: -2px;
  left: 0;
  right: -2px;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 11px;
  width: 11px;
  left: -1px;
  bottom: 1px;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #434db8;
  border: 1px solid #434db8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #434db8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #ffffff;
}

/* Rounded sliders */

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
  left: 1.5px;
}

.drop-down {
  width: 70px;
  height: 25px;
  font-size: 11px;
  padding: 0px 3px !important;
  margin-left: 0% !important;
}

/* toogle switch */

.check {
  margin-top: 20px;
  margin-bottom: 50px;
}

.page {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #1648c6;
  font-family: 'OpenSans-SemiBold';
}

.btn-primary {
  background-color: #1648c6;
}

.btn-disabled {
  cursor: not-allowed;
  opacity: 0.32;
}
