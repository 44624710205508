@import '@src/css_config/variable.scss';

@mixin center {
  display: grid;
  place-items: center;
}
.vnc-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content !important;
  max-height: 100% !important;
  background: $white_original;
  box-shadow: 0px 0px 11px $black;
  border-radius: 4px;
  opacity: 1;
}
.vnc-viewer {
  @include center();
  &--maximize {
    width: 1250px;
    height: 545px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    opacity: 1;
    @include center();
    img {
      height: 540px;
      width: 1200px;
    }
  }
  &--minimize {
    width: 620px;
    height: 285px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    opacity: 1;
    @include center();
    img {
      height: 285px;
      width: 620px;
    }
  }
}
.video-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vnc-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 0px 17.81px;
  border-radius: 8px 0px 0px 0px;
  border-bottom: 1px solid $blue_light;
}
.vnc-modal-header-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
}
.vnc-modal-bottom {
  height: 35px;
  top: 411px;
  left: 351px;
  padding: 6px 16px 5px 461px;
  gap: 0px;
  border-radius: 0px 0px 8px 8px;
  opacity: 1;
  background: $rs_bg_whitgrey;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.vnc-modal-header-icons {
  display: flex;
  gap: 15px;
}
