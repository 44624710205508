@import '@styles/rs_fonts.scss';
.dashboard {
  max-height: calc(100vh - 50px);
  overflow-y: auto;

  :global {
    foreignObject {
      height: 150px !important;
    }

    .apexcharts-tooltip {
      color: #3c3838 !important;
      background-color: #ffffff !important;
      border: 0 !important;
      border-radius: 0.5rem;
    }
  }
}

.small-donut-chart {
  background: linear-gradient(to right, #fbfcfd 0%, #fbfcfd 45%, #f6f9fe 1px, #f6f9fe 100%);

  :global {
    .apexcharts-legend {
      width: 52% !important;
      right: 0 !important;
      padding: 0;
      min-height: 150px;
    }

    .apexcharts-legend-series {
      margin-right: 0 !important;
      margin-left: 0 !important;
      display: flex !important;
      align-items: center;

      &[seriesname='nodata'] {
        display: none !important;
      }

      .apexcharts-legend-marker {
        margin-right: 0.5rem;
      }
    }
  }

  > div {
    min-height: 150px !important;
    > div {
      min-height: 150px !important;
      height: 100% !important;
      > svg {
        height: 150px !important;
        > foreignObject {
          height: 100% !important;
          @extend .fontPoppinsRegularXs;
        }
      }
    }
  }
}

.donut-chart-with-description-and-footer {
  max-height: 400px;
  padding: 5px;

  :global {
    .apexcharts-legend {
      width: 50% !important;
      right: 0 !important;
      padding: 0;
    }

    .apexcharts-legend-series {
      margin-right: 0.25rem !important;
      margin-left: 0 !important;
      background-color: #f6f9fe;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      height: 100%;
      max-height: 100px;

      &[seriesname='nodata'] {
        background: none !important;

        .apexcharts-legend-marker {
          display: none;
          background: none !important;
        }

        .apexcharts-legend-text {
          font: normal normal 600 16px/30px Open Sans;
          font-weight: 600 !important;
          color: #7c828a !important;
          margin-top: 105px;
          margin-left: 22px;
          font-family: OpenSans-Regular;

          div p {
            margin-left: 24px !important;
            font-family: OpenSans-SemiBold !important;
          }
        }
      }
    }

    .apexcharts-legend-marker {
      flex-shrink: 0;
      margin-right: 0.25rem;
      top: 2.5px !important;
    }

    .apexcharts-datalabels {
      opacity: 1;
    }
  }

  :nth-child(1) {
    > div {
      > svg {
        height: 100% !important;
        > foreignObject {
          height: 100% !important;
        }
      }
    }
  }

  .footer {
    background-color: #f0f3f9;
  }
}

.shadow {
  box-shadow: 0px 5px 10px #00000029;
}

.piechart-tooltip-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 14px;
  padding: 4px;

  &.piechart-tooltip {
    height: 87px;
    min-height: 87px;
    font: normal normal normal 14px Open Sans;
    font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji';
    font-size: 14px;
    font-weight: 500;
    font-size: 12px;
    width: 220px;
    color: #3c3838;
    background: #ffffff;
    box-shadow: 0px 0px 4px #00000029;
  }
}

.column-tooltip {
  display: flex;
  flex-direction: column;
  min-height: 54px;
  gap: 10px;
  padding: 10px;

  &.flex-direction {
    flex-direction: column;
  }

  &.column-custom-phoneOS-tooltip {
    min-height: 20px;
  }
}

.clients-section-header-dropdown-filter-wrapper {
  &[aria-expanded='true'] {
    background-color: #f0f3f9 !important;
  }

  min-width: 181px;

  :global {
    min-height: 48px !important;
    border: 0 !important;
    padding: 0.375rem 1.5rem !important;
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    font-weight: 700;

    .react-dropdown-select-type-single {
      justify-content: flex-end;
    }

    .react-dropdown-select-dropdown-handle {
      svg {
        width: 33px !important;
        height: 30px !important;
        margin-top: -1.8px !important;
        transform: rotateX(180deg);
      }
    }

    .react-dropdown-select-dropdown {
      width: 100%;
      margin-right: 1rem;
      padding: 1rem 0rem;
      background: #fbfcfd 0% 0% no-repeat padding-box;
      border: 0.5px solid #e5ecfc;
      background: #fbfcfd 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000029;
      border: 0.5px solid #e5ecfc;
      border-radius: 5px;
      opacity: 1;
    }

    .react-dropdown-select-item {
      background-color: #fff;
      padding: 0.5rem 1rem;
      color: #727171;
      @include fontPoppins(Poppins-Regular, 14px);

      &:hover {
        background-color: #f0f3f9 !important;
        color: #1648c6 !important;
      }
    }

    .react-dropdown-select-item-selected {
      background-color: #f0f3f9 !important;
      color: #1648c6 !important;
    }
  }
}

.client-access-chart {
  background: linear-gradient(to right, #fbfcfd 0%, #fbfcfd calc(100% - 48%), #f6f9fe 1px, #f6f9fe 100%);

  max-height: 195px;

  :global {
    .apexcharts-legend {
      width: 45% !important;
      right: 0 !important;
      padding: 0;
    }

    .apexcharts-legend-series {
      margin-right: 0 !important;
      margin-left: 0 !important;
      display: flex !important;
      align-items: center;
    }
  }
}

.client-section-small-chart {
  min-height: 180px;
  height: 100%;

  :global {
    .apexcharts-tooltip {
      top: auto !important;
      transform: translateX(-50%);
      left: 0 !important;
    }
  }
}

.column-tooltip-memory-consume {
  height: 30px;
  width: 200px !important;
  padding: 10;
}

.consume-statistics-container {
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
  margin-top: 8px;

  .heading-consume {
    color: #3c3838;
    @extend.fontPoppinsSemiboldXlg;
  }

  .toggle-btn {
    color: #727171;
    width: 65px;
    height: 30px;
    @extend.fontPoppinsRegularSm;
    &.active {
      color: white;
      width: 92px;
      height: 30px;
      background: #1648c6;
      border: 1px solid #1648c6;
      border-radius: 3px;
      text-align: center;
      @extend.fontPoppinsRegularSm;
    }
  }
}

@keyframes progress-animation {
  from {
    width: 0%;
  }
}

.progress-line-wrapper {
  background-color: #7388a95a;
  border-radius: 5px;
  width: 100%;

  .filled {
    border-radius: 5px;
    animation: progress-animation 2s linear;
  }
}

.memory-total-consumption {
  min-height: 280px;
  height: 97%;

  .legend-wrapper {
    background-color: #f6f9fe;
    height: 97%;
  }
}

.memory-total-consumption-span {
  color: #7c828a;
  @extend.fontPoppinsRegularSm;
}

.section-header {
  background-color: #fbfcfd;
}

.chart-card {
  background-color: #fbfcfd;
  height: 100%;
}

.individual-memory-consumption {
  @extend .fontPoppinsSemiboldXlg;
  color: #3c3838;
}

.individual-project-heading {
  font: normal normal normal 14px/19px Open Sans;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
  margin-left: 4px;
}

.individual-project-footer {
  color: #7c828a;
  @extend.fontPoppinsRegularSm;
  margin-left: 4px;
  text-align: left;
}

.memory-project-type-para {
  @extend.fontPoppinsRegularDashboard-memory;
  width: 144px;
  white-space: nowrap;
  color: #3c3838;
  text-align: left;
}

.memory-project-type-heading {
  @extend.fontPoppinsSemiboldMd;
  color: #3c3838;
  text-align: left;
  margin-left: 4px;
  margin-bottom: 6px;
}

.memory-project-type-heading-active {
  @extend.fontPoppinsSemiboldMd;
  text-align: left;
  margin-left: 4px;
  margin-bottom: 6px;
  color: #1648c6;
}

.memory-no-project-container {
  text-align: center;
  margin-top: 35px;
  height: 100px;
  font: normal normal normal 16px/20px Open Sans;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  color: #7c828a;
}

.memory-project-type-cart {
  width: 100%;
  background-color: #fbfcfd;
  border-radius: 5px;
  height: 99.9%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  &:hover {
    background-color: #f6f9fe;
  }

  &.active {
    background-color: #f0f3f9;
  }
}

.project-type-card-height {
  height: 93%;
  padding: 4px;
}

.project-bar {
  background: #f6f9fe 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 10px;

  .card {
    border-color: #707070;

    .title {
      color: #1648c6;
      text-transform: capitalize;
      @extend.fontPoppinsRegularLg;
    }

    .text {
      color: #3c3838;
      @extend.fontPoppinsRegularLg;
      word-break: break-word;
    }
  }
}

.resources {
  .section-title {
    color: #3c3838;
    text-transform: uppercase;
    font-family: OpenSans-SemiBold;
  }

  .card-wrapper {
    background: #81b9d7 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 10px;
    // height: 185px;
    height: 160px;
    width: 100%;

    .card {
      background: #fbfcfd 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 10px #00000029;
      border-radius: 10px;
      // height: 170px;
      height: 146px;
      width: 100%;
      padding-top: 28px;
      padding-bottom: 12px;

      .value {
        color: #3c3838;
        @extend.fontPoppinsSemiboldDashboard-resources;
      }

      .label {
        color: #3c3838;
        @extend.fontPoppinsRegularMd;
        text-transform: capitalize;
      }
    }

    &:hover {
      box-shadow: 0px 3px 20px #81b9d7cc;

      .card {
        padding-top: 22px;
        padding-bottom: 12px;

        .value {
          @extend.fontPoppinsSemiboldDashboard-resources-active;
          color: #1648c6;
        }

        .label {
          @extend.fontPoppinsRegularLg;
          color: #1648c6;
        }
      }
    }

    @media (min-width: 1024px) {
      height: 185px;

      .card {
        height: 170px;
      }
    }
  }
}

.seperated-bar-chart {
  padding-top: 35px;
  min-height: 230px;
  height: 100%;

  .footer {
    background-color: #ededed;

    .label {
      border-color: #d6d9dc;
      color: #3c3838;
      @extend.fontPoppinsSemiboldXs;
    }
  }

  :global {
    .apexcharts-tooltip {
      top: auto !important;
      transform: translateX(-50%);
      left: 0 !important;
    }
  }
}

.striped-list {
  .label {
    @extend.fontPoppinsRegularSm;
    color: #3c3838;
    box-shadow: 0px 5px 10px #00000029;
    margin-top: 1px;

    &:nth-child(odd) {
      background-color: #ffffff;
    }

    &:nth-child(even) {
      background-color: #f0f3f9;
    }

    text-overflow: ellipsis;
    overflow: hidden;
  }

  .value {
    @extend.fontPoppinsRegularSm;
    color: #3c3838;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

:global {
  .flex-grow,
  .flex-1 {
    min-width: 0;
    min-height: 0;
  }
}

.info-select-box {
  background-color: #ffffff;
  box-shadow: 0px 5px 10px #00000029;

  .label {
    @extend.fontPoppinsRegularMd;
    color: #3c3838;
    padding-top: 11px;
    padding-bottom: 10px;
  }

  .dropdown {
    background-color: #727171;
    color: #ffffff;

    &.disabled {
      opacity: 0.5;
    }

    div:first-child {
      height: 100%;
    }

    :global {
      .react-dropdown-select {
        padding: 11px 12px 10px 12px;
        border-bottom: none !important;

        &[disabled] {
          opacity: 1;
        }

        .react-dropdown-select-type-single {
          @include fontPoppins(Poppins-Regular, 14px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .react-dropdown-select-dropdown-handle {
          svg {
            width: 33px !important;
            height: 23px !important;
            margin-top: -1.8px !important;
            transform: rotateX(180deg);
          }
        }

        .react-dropdown-select-input {
          display: none;
        }

        .react-dropdown-select-dropdown {
          width: 100%;
          margin-right: 1rem;
          padding: 1rem 0rem;
          background: #fff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 10px #00000029;
          border: none;
          opacity: 1;
        }

        .react-dropdown-select-item {
          background-color: #fff;
          padding: 0.5rem 1rem;
          @include fontPoppins(Poppins-Regular, 14px);
          color: #727171;

          &:hover {
            background-color: #f0f3f9 !important;
            color: #1648c6 !important;
          }
        }

        .react-dropdown-select-item-selected {
          background-color: #f0f3f9 !important;
          color: #1648c6 !important;
        }

        .react-dropdown-select-dropdown-handle {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.execution-suites-and-schedules-section {
  .content-wrapper {
    min-height: 310px;
    height: 100%;
    max-height: 350px !important;
  }
}

.execution-suite-client-machines-item {
  overflow: visible !important;
  font-weight: 600;
  font-family: OpenSans-SemiBold !important;

  .value {
    overflow: visible;
    font-family: OpenSans-Regular;
    font-weight: 400;
  }

  .dropdown {
    background-color: #727171;
    color: #ffffff;
    font-weight: 400;

    &.disabled {
      opacity: 0.5;
    }

    div:first-child {
      height: 100%;
    }

    :global {
      .react-dropdown-select {
        padding: 11px 12px 10px 12px;
        border-bottom: none !important;

        &[disabled] {
          opacity: 1;
        }

        .react-dropdown-select-type-single {
          font-size: 12px;
          font-family: OpenSans-Regular;
          font-weight: 400;
          color: #ffffff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .react-dropdown-select-dropdown-handle {
          svg {
            width: 33px !important;
            height: 23px !important;
            margin-top: -1.8px !important;
            transform: rotateX(180deg);
          }
        }

        .react-dropdown-select-input {
          display: none;
        }

        .react-dropdown-select-dropdown {
          width: 100%;
          margin-right: 1rem;
          padding: 1rem 0rem;
          background: #fff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 10px #00000029;
          border: none;
          opacity: 1;
        }

        .react-dropdown-select-item {
          background-color: #fff;
          padding: 0.5rem 1rem;
          font-size: 14px;
          font-family: OpenSans-Regular;
          color: #727171;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background-color: #f0f3f9 !important;
            color: #1648c6 !important;
            font-size: 14px;
            font-family: OpenSans-Regular;
          }
        }

        .react-dropdown-select-item-selected {
          background-color: #f0f3f9 !important;
          color: #1648c6 !important;
          font-family: OpenSans-Regular;
        }

        .react-dropdown-select-dropdown-handle {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.client-os-version-heigth {
  min-height: 280px;
  max-height: 400px;
}
.client-os-version-container {
  margin-bottom: 10px;
}
.chart-consume-statistics {
  height: 310px;
}

.small-radical-width-30 {
  width: 30%;
}

.small-radical-width-70 {
  width: 70%;
}
.chart-container {
  overflow: hidden;
}

.tooltip-container {
  overflow: visible;
}
