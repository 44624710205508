@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.testdev-steps {
  .btn-save {
    @extend .fontPoppinsRegularMd;
    background: $blue_dark 0% 0% no-repeat padding-box;
    border: 1px solid $blue_dark;
    border-radius: 2px;
    margin-left: 15px;
    text-align: center;
    /* font: normal normal 600 14px/19px Open Sans; */
    letter-spacing: 0px;
    color: $skyblue_light;
    opacity: 1;
    padding: 4px;
    top: 226px;
    left: 1772px;
    width: 75px;
    height: 32px;
  }

  .btn-save:disabled {
    background: $blue_ultra_light 0% 0% no-repeat padding-box;
    border: 1px solid $blue_ultra_light;
    cursor: not-allowed;
  }

  .items-heading {
    font-size: 0.875rem;
    line-height: 0.25rem;
  }

  .add-step {
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .content-height {
    // height: 80vh !important;
    /* overflow-y: scroll; */
    background-color: $white_original !important;
    min-height: 92vh;
    /* min-height: 87vh; */
  }

  .h-82 {
    height: 82%;
  }

  .steps-checkbox {
    // display: none;
    border-width: 2px;
  }

  .steps-checkbox:checked {
    display: inline-block;
  }

  .stepGroup {
    display: none;
  }

  .steps-checkbox:checked .stepGroup {
    display: inline-block;
  }

  .step-options {
    display: none;
  }

  .steps:hover {
    background-color: #f3f4f6;
    border-width: 2px;
  }

  .step-selected {
    background-color: #f3f4f6;
    border-width: 2px;
  }

  .steps:hover .steps-checkbox {
    display: inline-block;
  }

  .steps:hover .step-options {
    display: inline-block;
  }

  .addNextStep {
    display: none;
  }

  #nlpSuggestion {
    display: block;
    font-size: 0.74rem;
    line-height: 1rem;
  }

  .tdtooltip {
    position: relative;
    display: inline-block;
  }

  .tdtooltip .tdtooltiptextAddNext {
    visibility: hidden;
    width: 65px;
    background-color: gray;
    color: $white_original;
    text-align: center;
    border-radius: 6px;
    padding: 4px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: -28%;
    left: 385%;
    margin-top: 10px;
    margin-left: -60px;
  }

  .tdtooltip:hover .tdtooltiptextAddNext {
    visibility: visible;
  }

  .tdtooltip .tdtooltiptext {
    visibility: hidden;
    width: 65px;
    background-color: gray;
    color: $white_original;
    text-align: center;
    border-radius: 6px;
    padding: 4px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: -70%;
    left: 120%;
    margin-top: 10px;
    margin-left: -60px;
  }

  .tdtooltip:hover .tdtooltiptext {
    visibility: visible;
  }

  #addStepDiv {
    display: none;
  }

  // .sg {
  //   font-size: 0.40rem;
  //   border: 1px solid #EFBB07;
  //   border-radius: 9px;
  //   padding: 0px 6px;
  //   color: #EFBB07;
  //   height: fit-content;
  // }
  .nlp {
    font-size: 0.45rem;
    border: 1px solid red;
    border-radius: 9px;
    padding: 0px 6px;
    color: red;
    height: fit-content;
  }

  .pe {
    font-size: 0.45rem;
    border: 1px solid green;
    border-radius: 9px;
    padding: 0px 6px;
    color: green;
    height: fit-content;
  }

  .nlp-dropdown {
    height: 170px;
    overflow-y: scroll;
  }

  .nlp-input-div {
    margin-top: -476px;
    margin-left: 30.8rem;
    margin-bottom: 1rem;
    width: 60%;
  }

  .nlp-input-div-header {
    background: aliceblue;
    position: sticky;
    top: 0;
    height: 40px;
  }

  .nlp-div-body {
    overflow-y: scroll;
    height: 447px;
  }

  .quick-start {
    width: 20.3rem;
  }

  .scroll-height {
    height: 190px;
    overflow-y: scroll;
  }

  .suggestion-dropdown {
    position: absolute;
    height: 160px;
    width: 43%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .lineBreak {
    word-break: break-word;
    width: max-content;
  }

  .arrow {
    float: right;
    margin-right: 63%;
  }

  .arrow-steps {
    float: right;
    margin-right: 82%;
  }

  .arrow-conditions {
    float: right;
    margin-right: 68%;
  }

  .parameter-suggestion-dropdown {
    position: absolute;
    height: 210px;
    width: 17%;
    margin-left: 213px;
    margin-top: -45px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .return-dropDown {
    width: 295px;
    height: auto;
    right: -5%;
    margin-top: -44px;
  }

  .parameter-text {
    @extend .fontPoppinsSemiboldMd;
    padding-left: 18px;
    padding-top: 10px;
    color: $grey_darkest;
  }

  .parameter-header {
    background-color: $skyblue_dark;
  }

  .actionIcons:checked {
    visibility: visible;
  }

  .element-dropdown {
    position: relative;
    // background-color: #f1f1f1;
    height: 160px;
    width: 68%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .result-badge-passed {
    background-color: $result_passed_color;
  }

  .result-badge-primary {
    background-color: $blue_dark;
  }

  .result-badge-failed {
    background-color: $result_failed_color;
  }

  .result-badge-warning {
    background-color: $result_warning_color;
  }

  .result-badge-skipped {
    background-color: $result_skipped_color;
  }

  .result-badge-na {
    background-color: $result_skipped_color;
  }

  .step-result {
    text-align: left;
    // font-size: 14px;
    letter-spacing: 0px;
    color: $blue;
    opacity: 1;
  }

  #hoverable {
    visibility: hidden;
  }

  #hovered:hover #hoverable {
    visibility: visible;
  }

  .truncate-common {
    @extend .fontPoppinsRegularMd;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
  }

  .step-name-truncate {
    @extend .truncate-common;
    max-width: 400px;
  }
  .stepgroup-step-name-truncate {
    @extend .truncate-common;
    max-width: 870px;
  }
  .step-result-truncate {
    @extend .truncate-common;
    max-width: 340px;
  }

  .step-iteration-header {
    @extend .fontPoppinsSemiboldMd;
  }

  .actionIcons:indeterminate {
    visibility: visible;
  }

  .parametersAside {
    margin-left: 18.5px !important;
  }
}

.table-width {
  width: 1%;
}

.actions-style {
  width: 10%;
  min-width: 140px;
  max-width: 150px;
}

.search-nlp,
.search-nlp:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid blue !important;
  background-color: transparent;
  resize: none;
}

.search-nlp:focus {
  box-shadow: none !important;
}

.no-steps {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status_message {
  display: flex;
  align-items: center;
}

.quickstartcontent {
  position: absolute;
  margin-top: 120px;
}

.inputBox {
  visibility: hidden;
}

.create_Issue_Modal {
  width: 150px;
  background-color: #ffffff;
}

.result-passed {
  color: $result_passed_color;
}

.result-primary {
  color: $blue;
}

.result-failed {
  color: $result_failed_color;
}

.result-warning {
  color: $result_warning_color;
}

.result-skipped {
  color: $result_skipped_color;
}

.step-break {
  word-wrap: break-word;
  white-space: nowrap;
}
.highlight-text{
  background-color: $ff_marker_yellow;
  color: $ff-text;
}

.nlp-details-hide {
  display: none;
}

.nlp-details-show:hover + .nlp-separator + .nlp-details-hide,
.nlp-details-hide:hover,
.nlp-separator:hover + .nlp-details-hide {
  display: block;
  cursor: pointer;
}

.nlp-separator {
  position: relative;
}

.w-45 {
  width: 45%;
}

.nlp-details-header {
  @extend .fontPoppinsSemiboldMd;
  letter-spacing: 0px;
  color: $black_original;
  opacity: 1;
  text-align: left;
  margin-left: 15px;
}

.nlp-details {
  @extend .fontPoppinsRegularMd;
  letter-spacing: 0px;
  color: $grey_dark;
  text-align: left;
  margin-left: 15px;
}

.nlpdiv-check {
  white-space: pre-wrap;
  word-break: break-word;
}

.nlp-info {
  padding: 5px 0 5px 12px;
}

.nlp-type-details {
  width: fit-content;
  text-align: left;
}

.nlpdiv-check {
  white-space: pre-wrap;
  word-break: break-word;
}

.nlp-type-NLP {
  padding: 0px 6px;
  border: 1.5px solid $red;
  color: $red;
  font-size: 0.5rem;
  border-radius: 9px;
  margin-left: 5px;
}

.nlp-type-step-group {
  padding: 0px 6px;
  border: 1.5px solid $yellow_dark;
  color: $yellow_dark;
  font-size: 0.5rem;
  border-radius: 9px;
  margin-left: 5px;
}

.nlp-type-program-elements {
  padding: 0px 6px;
  border: 1.5px solid $green_dark;
  color: $green_dark;
  font-size: 0.5rem;
  border-radius: 9px;
  margin-left: 5px;
}

.step-group-width {
  width: fit-content;
}

.length-script {
  display: inline-block;
  white-space: nowrap;
  width: 370px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.precondition-container {
  width: 400px;
  display: inline-block;

  .checked-box {
    position: relative;
    top: -3px;
  }
}

.stepgroup-button-width-101 {
  width: 101%;
}

.steps-w-48 {
  width: calc(48% + 3rem);
}

.steps-w-45 {
  width: calc(45% - 1.5rem);
}

.left {
  left: calc(43% + 1rem);
}

.MuiSelect-select.MuiSelect-select {
  @extend .fontPoppinsRegularMd;
}

.MuiFormLabel-root {
  @extend .fontPoppinsRegularXLg;
}

.MuiMenuItem-root {
  @extend .fontPoppinsRegularMd;
}

.steps-left-102 {
  left: 102%;
}

#operator.MuiSelect-root.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input,
#input2.MuiSelect-root.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  padding-bottom: 0px;
}
#operator,
#input2 {
  border-bottom: 2px solid blue;
}
.web-service-steps-section {
  background-color: $gray_30;
  box-shadow: 0px 0px 6px $shadow_black;
  opacity: 1;
  top: 153px;
  height: 35px;
}
.web-service-steps-btn {
  background: $white_original 0% 0% no-repeat padding-box;
  border: 1px solid $rs_analytics_blue_header;
  border-radius: 4px;
  opacity: 1;
  padding: 4px 10px;
  margin-top: 5px;
  &:hover {
    background: $blue_dark_50 0% 0% no-repeat padding-box;
    color: $white_original;
  }
}

.edit-element-disable-style {
  pointer-events: none;
}
