@import '@styles/variables';
@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

.stepper-wrapper {
  .step-button-wrapper {
    padding: 4px 1px;

    .step-button {
      padding: 12px;
      color: $sky-blue-light;
      background-color: $gray;
      text-transform: uppercase;
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: $primary;
      }

      &.completed {
        background-color: $primary;
        opacity: 0.8;
        display: flex;
        text-align: center;

        span {
          flex-grow: 1;
        }
      }
    }
  }

  .step-navigator-button {
    @extend .fontPoppinsRegularSm;
    padding: 6px 20px;
    border-radius: 5px;
    margin-right: 24px;

    transition: all 0.3s linear;

    &.next {
      color: $rs_blue_font;
      background-color: $white;
      border: 1px solid $rs_blue_font;

      &:hover {
        color: $white;
        background-color: $rs_blue_font;
      }
    }

    &.signup-btn {
      width: 506px;
      height: 46px;
      background-color: $orange;
      @extend .fontPoppinsSemiboldMd;
      color: $white;
      border: 1px solid $orange;
    }

    &.back {
      color: $dark;
      background-color: $white;
      border: 1px solid $gray-200;
    }
  }
}
