@import '@styles/_variables.scss';
@import '@styles/rs_fonts.scss';
@import '@src/css_config/variable.scss';

@mixin fl_button_Style {
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  @extend .fontPoppinsRegularXs;
  @media (min-width: $xl) {
    // above 1280
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 5px;
    height: 32px;
  }
}

#license-scroll::-webkit-scrollbar,
.license-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  height: 4px;
}
#license-scroll::-webkit-scrollbar-track,
.license-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}

#license-scroll::-webkit-scrollbar-thumb,
.license-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: $license_scroll_color;
}
.license-scroll::-webkit-scrollbar-thumb:hover {
  background-color: $license_scroll_hover;
  cursor: pointer;
}

.support-link {
  color: $ff_primary;
}
.primaryBtn {
  @include fl_button_Style;
  background: transparent;
  border: 1px solid $ff-primary;
  color: $ff-primary;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: $ff-primary;
    color: $white_original;
    opacity: 0.92;
  }
  &:disabled {
    opacity: 0.5;
  }
  &.icon-btn {
    svg {
      position: relative;
      top: 3px;
    }
  }
}

.license-header {
  color: $primary;
  border-bottom: 2px solid $primary;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.license-page {
  padding: 0 5px;
  height: 82vh;
  overflow-y: hidden;
  background-color: $white_original;

  .filter-buttons {
    margin-right: 8px;
    button {
      @extend .fontPoppinsRegularSm;
      padding: 4px;
      background-color: $license_filter_blue;
      color: $dark;
      min-width: 61px;
      border: 1px solid $gray;
      height: 29px;
    }

    .active {
      border-radius: 5px 0px 0px 5px;
      &:hover {
        border: 1px solid $green;
        color: $green;
      }

      &.selected {
        background-color: $green-200;
        border: 1px solid $green-200;
      }
    }

    .expired {
      border-radius: 0px 5px 5px 0px;
      &:hover {
        border: 1px solid $red;
        color: $red;
      }

      &.selected {
        background-color: $red-200;
        border: 1px solid $red-200;
      }
    }
  }

  .clear-filters-button {
    padding: 4px;
    color: $muted;

    &:hover {
      color: $primary;
    }

    &:disabled {
      color: $gray;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .search-input {
    :global {
      .MuiInputBase-root {
        height: 100%;
        background-color: $sky-blue-light;
        width: 270px;

        .MuiInputBase-input {
          border-bottom: 0 !important;
          height: 100%;
          padding: 0;
          padding-left: 16px;
          padding-right: 16px;
        }

        .MuiInputAdornment-root {
          button {
            color: $muted;

            &:hover {
              color: $primary;
            }

            &:disabled {
              color: $gray;
            }
          }
        }

        fieldset {
          border-color: $border-gray;
        }
      }
    }
  }
  .buy-new-license {
    background-color: $primary;
    height: 30px;
  }
  .default-license-alert {
    position: absolute;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    top: 1%;
    width: max-content;
  }
  .licenseRowCardContainer {
    height: 76vh;
    overflow: auto;
    padding: 0 5px;
  }
  .no-license-text {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .search-button {
    border: 1px solid $primary;
    background: transparent;
  }
}

.user-info {
  color: $white;
  background-color: $primary;
}

.tabs-link-border {
  border-bottom: 2px solid $rs_tabs_border_color;
}
.license-tabs {
  padding-top: 4px;
  margin-bottom: -2px;
  background-color: $white_original;
  &.bottom-space-sm {
    margin-bottom: 10px;
  }
  &.tab-border {
    border-bottom: 1px solid $rs_analytics_blue_header;
    opacity: 0.5;
  }
  li {
    margin-right: 24px;
  }
  a {
    &.selected-license-tab {
      @extend .fontPoppinsSemiboldMd;
      border-bottom: 2px solid $rs_analytics_blue_header;
    }
  }
}

.license-selected-tab-style {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $color_blue;
  opacity: 1;
}

.license-default-tab-style {
  @extend .fontPoppinsRegularMd;
  text-align: left;
  letter-spacing: 0px;
  color: $license_tooltip_bg;
  opacity: 1;
  :hover {
    text-align: left;
    letter-spacing: 0px;
    color: $blue_dark;
    opacity: 1;
    background-color: $rs_Disabled;
  }
}
.license-tab-navbar-section {
  position: sticky;
  top: 10px;
  z-index: 1;
  width: 100%;
}

.license-history-icon {
  padding: 5px;
  margin-left: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 1px $shadow_black;
    background-color: $white_original;
    border-radius: 5px;
  }
}
.no-payment-history {
  color: $rs_analytics_blue_header;
}
