@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.text-format-common {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.no-suggestions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.parent-container {
  .exception-container {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    .exception-header {
      .exception-label {
        margin-left: 14px;
        @extend .text-format-common, .fontPoppinsSemiboldMd;
        color: $grey_dark;
        margin-right: 4px;
      }
      .exception-value {
        @extend .text-format-common, .fontPoppinsRegularMd;
        color: $grey_dark;
      }
    }
    .expand-icon {
      cursor: pointer;
      height: 14px;
      width: 14px;
      margin-right: 17px;
    }
  }
  .suggestion-content {
    margin-top: 8px;
    height: 450px;
    overflow-y: auto;
    overflow-x: auto;
  }
}

.suggestion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .suggestion-title {
    margin-top: 8px;
    margin-left: 14px;
    @extend .text-format-common, .fontPoppinsSemiboldLg;
  }
  .close-icon {
    @extend .minimize-icon;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: border-color 0.3s;
    &:hover {
      border-color: $primary;
    }
  }
  .minimize-icon {
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
  }
}
.accordian-content {
  margin: 8px;
  height: 220px;
}
.possiblefix-label {
  @extend .text-format-common, .fontPoppinsSemiboldMd;
  margin-top: 8px;
  padding-left: 26px;
}
.possiblefix-value {
  @extend .text-format-common, .fontPoppinsRegularMd;
  padding-left: 41px;
}
