:root {
  --deepGray: #3c3838;
  --lightGray: #727171;
  --bluesGray: #293241;
  --lightGreen: #79b62f;
  --blue: #1648c6;
  --skyBlue: rgb(97, 175, 248);
  --red: #c50303;
  --deepRed: #e63946;
  --white: #fff;
  --lightblue: #add8e6;
  --black: #00000029;
  --rs-blue-primary: #454db2;
  --rs-black: #263248;
}
.backLink {
  color: var(--rs-blue-primary);
}

.blue-link {
  color: #434db8;
}
.container {
  margin-top: 5rem;
  padding: 3rem 4rem;
  border-radius: 4px;
  background: var(--white);
  box-shadow:
    10px 10px 20px rgba(222, 222, 230, 0.4),
    -10px -10px 20px rgba(222, 222, 230, 0.8),
    -8px 8px 30px #dedee6;
  margin-left: 25rem;
  width: 50%;
}
.container h1 {
  font-size: 2rem;
  color: var(--bluesGray);
  margin-bottom: 2rem;
}
.container label,
.container input {
  color: var(--bluesGray);
}
.container label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}
.container input,
.container select {
  border: 1px solid var(--lightGray);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  padding-left: 1rem;
  font-size: 1rem;
}
.PhoneInputCountrySelect .dropdown Select {
  width: 50px;
}

.placeholderpass input[type='password'] {
  border: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--lightGray);
  padding-bottom: 0;
  font-size: 12px;
  padding-bottom: 0px;
  width: 370px;
}
#projecttype:focus {
  color: var(--lightGray);
}
#licensesType,
#licensePlan {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--lightGray);
}

option {
  padding: 5px 5px;
}
.container .form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.container button {
  color: var(--white);
  width: 40%;
  font-size: 1.2rem;
  background: rgb(97, 175, 248);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 0.7rem 1.2rem;
  margin-top: 0.8rem;
}
.container button:hover {
  background: rgb(97, 175, 248);
}
.container .input-error {
  border-color: var(--deepRed);
}
.container .error {
  color: var(--deepRed);
  font-size: 1rem;
  margin-top: 0.3rem;
}
.container .disabled-btn {
  cursor: not-allowed;
  background: rgb(97, 175, 248);
}
.cards {
  background: var(--white);
  margin-top: 425px;
}

.errorMsg {
  color: var(--red);
  font-size: 10px;
}
.error-msg {
  color: var(--red);
  padding-bottom: 3px;
}
.mainContainer {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.leftImg {
  height: 726px;
  width: 100%;
}
.secondText {
  color: var(--rs-blue-primary);
  font-size: 20px;
  margin-left: -60px;
}
.belowText {
  color: var(--rs-blue-primary);
}
.lastText {
  color: var(--lightGray);

  width: 490px;
}
.signInText {
  line-height: 1.5;
  color: var(--deepGray);
}

.blankLast {
  width: 357px;
  height: 20px;
}
.forgetPassword {
  color: var(--rs-blue-primary);
  position: relative;
  left: 20px;
}
.submitButton {
  border-radius: 2px;
  color: var(--white);
  background: var(--blue);
  border: 1px solid var(--blue);
}
.leftsideimg {
  margin-top: 120px;
  margin-left: 155px;
  height: 78px;
  width: 386px;
}
.leftsideimgSignup {
  margin-top: 92px;
  margin-left: -59px;
  margin-top: 9px;
  height: 39px;
  width: 195px;
}
.leftsideimg1 {
  margin-top: 30px;
  position: relative;
}
.leftsideimg2 {
  width: 520px;
  height: 404px;
  margin-top: 23px;
  position: relative;
  margin-left: 0px;
}
.error_Captcha {
  color: var(--red);
  margin-top: -26px;
}
.backToSign {
  left: 135px;
  position: relative;
  top: -20px;
}

@media only screen and (min-width: 1024px) {
  .mainContainer {
    display: block;
    background-repeat: no-repeat;
    background-position: revert;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 655px) and (min-width: 360px) {
  .leftImg {
    margin-top: 120px;
  }
  .mainContainer > .signUpPage {
    margin-top: -50px;
    margin-left: 30px;
  }
  .gridContent.signUp {
    margin-left: 98px;
    margin-top: 115px;
    margin-right: -200px;
  }
  .mobile-hide.leftsideimg {
    margin-left: 140px;
    margin-right: auto;
    padding-top: 0px;
    margin-bottom: 100px;
    margin-top: 50px;
  }
  .mobile-hide.leftsideimg1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: -70px;
    width: 80%;
    margin-bottom: 100px;
  }
  .mobile-hide.leftsideimg2 {
    display: none;
  }
  .gridContent {
    margin-left: 56px;
    margin-right: 20px;
    margin-top: 270px;
    position: relative;
  }

  .headingSetP {
    margin-top: 0px;
    margin-left: -24px;
  }
  .backFromSet {
    margin-left: 44px;
  }
  .MuiInputAdornment-positionEnd {
    position: relative;
    margin-left: -92px;
  }
  .makeStyles-pass-2 .MuiInputBase-input {
    min-width: 222px;
    background: transparent;
  }
  .gridContent > .signinUserText {
    margin-bottom: 9px;
    position: absolute;
    margin-top: -120px;
  }
  .gridContent > .signinUserText > h3 {
    margin-top: 35px;
    font-size: 25px;
  }
  .signinUser {
    padding-bottom: 20px;
  }
  .signinUserEmail {
    margin-top: -20px;
    padding-bottom: 15px;
  }
  .signinAll {
    margin-top: -75px;
    margin-right: 10px;
  }
  .forgetPassword {
    margin-right: 0px;
  }
  .mt-10 > button {
    width: 335px;
  }
  .mobile-hide.leftsideimg.forgetPassPic {
    margin-left: 160px;
  }
  .mobile-hide.leftsideimg1.forgetPassPic {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg1.emailSendPic {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.emailSendPic {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.phoneValidImg {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.phoneValidImg {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.setPassImg {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.setPassImg {
    margin-left: 145px;
    margin-top: 100px;
  }
  .mobile-hide.leftsideimg.passwordSet {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.passwordSet {
    margin-left: 84px;
  }
  .mobile-hide.leftsideimg.resSucImg {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.resSucImg {
    margin-left: 100px;
  }
}
@media only screen and (max-width: 820px) and (min-width: 660px) and (orientation: landscape) {
  .mobile-hide.leftsideimg {
    padding-top: 18px;
    margin-bottom: -5px;
    margin-left: 100px;
    margin-top: 130px;
  }
  .mainContainer > .leftImg {
    height: 560px;
  }

  .mobile-hide.leftsideimg.signUpPic {
    margin-left: 180px;
    padding-left: 90px;
  }
  .mobile-hide.leftsideimg.emailSendPic {
    margin-left: 13px;
  }
  .mobile-hide.leftsideimg1.emailSendPic {
    margin-top: 50px;
    position: absolute;
  }
  .mobile-hide.leftsideimg.passwordSet {
    margin-left: -5px;
  }
  .mainContainer > .passSet {
    margin-top: 50px;
    margin-left: -20px;
  }
  .mainContainer > .emailSend {
    margin-top: 40px;
  }
  .mainContainer > .forgetPass {
    margin-top: 140px;
  }
  .mainContainer > .resSuc {
    margin-top: 40px;
    margin-left: 120px;
  }
  .mainContainer > .setPass {
    margin-top: -20px;
    margin-left: 120px;
  }
  .mainContainer > .phoneValid {
    margin-top: 30px;
    margin-left: 120px;
  }
  .mobile-hide.leftsideimg1.passwordSet {
    margin-top: 40px;
    position: absolute;
  }
  .mobile-hide.leftsideimg.forgetPassPic {
    margin-top: 20px;
  }
  .mobile-hide.leftsideimg1.forgetPassPic {
    margin-left: 6px;
  }

  .mobile-hide.leftsideimg1.signUpPic {
    margin-left: 100px;
    margin-top: 15px;
  }

  .mobile-hide.leftsideimg1 {
    width: 80%;
    margin-top: 30px;
  }
  .mobile-hide.leftsideimg2 {
    display: none;
  }
  .verificationGrid {
    margin-left: 94px;
  }

  .gridContent {
    margin-left: -20px;
    margin-right: 20px;
    margin-bottom: 15px;
    margin-top: -50px;
    position: relative;
  }
  .gridContent.signUp {
    margin-top: 160px;
    margin-left: -100px;
  }
  .signinAll {
    margin-top: -17px;
  }
  .gridContent > .signinUserText > h3 {
    margin-top: 10px;
    font-size: 25px;
  }
  .mt-10 > button {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 770px) and (min-width: 760px) {
  .vl {
    display: none;
  }
  .mobile-hide.leftsideimg2 {
    display: none;
  }
}
.MuiFormHelperText-root.Mui-error {
  white-space: pre-line;
  /* line-height: 14px; */
  font-size: 10px;
  padding-top: 0px;
  margin-left: 0px;
  color: var(--red) !important;
  margin-top: 2px;
}

.mainContainer1 img {
  height: 100%;
  width: 100%;
}

.loginContainer {
  width: 700px;
  background-color: transparent;
}
.loginGrid {
  margin-right: 120px;
  padding: 43px 0px 0px 27px;
  border-radius: 10px;
  background: var(--white);
  margin-top: 143px;
}
.loginGrid h1 {
  font-size: 34px;
  font-weight: 800;
}
.loginGrid input {
  width: 430px;
}

.loginMenus {
  margin-right: 120px;
  padding: 43px 0px 0px 27px;
  border-radius: 10px;
  background: var(--white);
  margin-top: 143px;
}
.loginMenus button {
  width: 173px;
  margin-top: 6px;
  margin-left: 5px;
  height: 40px;
}
#recaptcha-container {
  visibility: hidden;
}

.warning button {
  width: 400px;
  color: var(--white);
  margin-left: 50px;
  margin-top: 50px;
}
.warning_button button {
  width: 300px;
  margin-left: 90px;
}
.warning button a {
  color: var(--white);
  font-size: 12px;
}
.warning-division {
  float: left;
  width: 450px;
  padding-left: 40px;
}
.forgot-email-warning {
  width: 450px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
}

.SignInGrid {
  margin-right: 150px;
  width: 50%;
  margin-top: -50px;
}
.loginbody {
  height: 100%;
  margin-top: -85px;
}
.SignInGrid h1 {
  font-size: 34px;
  font-weight: 800;
}
.division {
  width: 410px;
}
.division input {
  width: 15px;
}
.SignInGrid input {
  width: 430px;
}
.SignInGrid select {
  border-bottom: 2px solid #cecaca;
  width: 363px;
  display: block;
  margin-top: 5px;
  border-radius: 0px;
}
.Warningmessages {
  width: 500px;
  font-size: 30px;
  margin-top: 25px;
  text-align: center;
}

.warning a {
  font-size: 12px;
  color: var(--white);
}
.warning-text {
  width: 450px;
  margin-left: 12px;
  margin-right: 50px;

  padding: 24px 0 20px 0px;
}
.warning-textotp {
  width: 471px;
  margin-left: 12px;
  margin-right: 50px;
  padding: 40px 0 20px 0px;
}
#otp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  margin-top: 4px;
  margin-bottom: 45px;
}
#otp input {
  width: 44px;
  height: 38px;
  box-shadow: 0px 0px 16px #00000040;
  border-radius: 7px;
  opacity: 1;
}
.details {
  width: 450px;
  margin-left: -104px;
  margin-top: -6px;
}
.pswbody {
  margin-top: 172px;
}
.resendOtp {
  display: block;
  padding-left: 128px;
  width: 400px;
  font-size: 12px;
}
.resendspan {
  padding-left: 124px;
  font-size: 12px;
  width: 500px;
  padding-top: 20px;
}
.resendOtp span {
  color: var(--rs-blue-primary);
}
.warning_button {
  margin-top: 5px;
}
.SignInGrid button {
  width: 50px;
  margin-top: 6px;
  margin-left: 170px;
}

.loginGrid a {
  color: var(--rs-blue-primary);
}
.SignInGrid h6 {
  font-size: 22px;
  font-weight: 600;
}
.SignInGrid a {
  color: var(--blue);
  font-weight: 700;
}
input.password:focus {
  outline: none;
}

.phoneInput Select {
  width: 50px;
  border-bottom: 1.6px solid var(--deepGray);
}
.phonenumberValidation {
  position: absolute;
  padding-top: 41px;
  color: var(--red);

  font-size: 0.75rem;
}
.newUser > a {
  color: var(--rs-blue-primary) !important;
}
.warning_button a {
  color: rgb(158, 153, 153);
}
.actiovationcss {
  background-color: var(--white);
  color: rgb(124, 223, 124);
}

.dropdown {
  display: block;
  width: 320px;
  margin-top: 6px;
  padding-bottom: 20px;
  margin-top: -15px;
}
.dropdown Select {
  width: 50px;
  border-bottom: 1.6px solid #b1adad;
}

.pwd-container {
  position: relative;
  width: 295px;
}
.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  left: 370px;
  top: 3px;
}
.password-details {
  border: none;
  border-bottom: 1.6px solid #c4bebe;
}
#phoneNumbers1 {
  width: 374px;
  margin-left: 10px;
  border-bottom: 1px solid var(--black);
}
#phoneNumbers1:focus {
  border-bottom: 1px solid var(--blue);
}
.plusphonebutton {
  margin-left: 152px;
}
.errormsg {
  color: var(--red);
  padding-left: 12px;
}
.rightimg img {
  margin: 0px;
  height: 110px;
  width: 110px;
  margin-left: 180px;
}
.Resendlink {
  color: var(--rs-blue-primary);
}
.forgetpassword {
  color: var(--rs-blue-primary);
  float: right;
  margin-right: 70px;
  margin-top: 0px;
}
.foretpassword {
  margin-bottom: 10px;
  width: 85%;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}
.PhoneInput {
  display: flex;
  align-items: center;
  width: 374px;
  border: none;
}
/* responsive code stated  */

#leftImage {
  position: absolute;
  height: 700px;
}
.loginbody {
  height: 100%;
  margin-top: -60px;
}
.secondText {
  color: var(--rs-blue-primary);
}
.downBelow {
  margin-left: -50px;
}

.leftsideimg {
  margin-left: 85px;
  margin-top: 50px;
  height: 78px;
  width: 270px;
}
.leftsideimg2 {
  width: 500px;
  height: 350px;
  position: relative;
}
.belowText {
  color: var(--rs-blue-primary);
}

.lastText {
  color: var(--lightGray);

  width: 490px;
}

.loginGrid {
  margin-right: 120px;
  padding: 43px 0px 0px 27px;
  border-radius: 10px;
  background: var(--white);
  margin-top: 143px;
}
.loginGrid h1 {
  font-size: 34px;
  font-weight: 800;
}
.loginGrid input {
  width: 430px;
}
.loginGrid button {
  position: relative;
  left: -50px;
  margin-top: 10px;
}

.loginGrid a {
  color: var(--blue);
}
.vllogin {
  border-left: 1px solid var(--black);
  height: 750px;
  margin-left: 45%;
  position: absolute;
  margin-top: -595px;
}

.signinUserText > h3 {
  line-height: 1.5;
  color: var(--deepGray);
}
.signinUserText > span {
  color: var(--lightGray);
}
.star {
  color: var(--red);
}
.redText {
  color: var(--red);
}
.grayText {
  color: var(--lightGray);
}
.errorBelow {
  color: var(--red);
  position: fixed;
}
.extraHeight {
  width: 357px;
  height: 20px;
}
.code-error-message {
  color: var(--red);
  position: fixed;
  margin-top: -27px;
  margin-left: 22px;
}
.loginDivision {
  width: 340px;
}
#forgetPassword > a {
  color: var(--rs-blue-primary);
  position: relative;
  bottom: 40px;
  left: 5px;
}
.submitButton {
  border-radius: 2px;
  color: var(--white);
  background: var(--blue);
  border: 1px solid var(--blue);
  width: 173px;
  height: 40px;
}
.helperText {
  position: absolute;
}
/* iphone7 portrait */
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .MuiInputBase-input {
    width: 200px;
  }
  .forgotHeading {
    top: 20px;
    margin-left: -52px;
  }
  .loginGridForget > .warning-division,
  .warning_button,
  .backtologin {
    left: 40px;
  }

  .rightSymbol {
    left: 226px;
  }
  .toolTip {
    left: 230px;
  }
  .vlSignUp {
    display: none;
  }
  .SignInGrid button {
    margin-left: 140px;
  }
  .dropdown {
    width: 260px;
  }
  .MuiInput-underline {
    width: 300px;
  }
  .leftsideimgSignup {
    margin-left: -44px;
  }
  .MuiSvgIcon-root {
    right: -260px;
  }
  .h3Class {
    position: relative;
    top: -10px;
  }
  .h3ClassSpan {
    position: relative;
    top: -10px;
  }
  .secondSignUp {
    font-size: 17px;
    left: 15px;
    position: relative;
  }
  .secondText {
    margin-left: -47px;
    font-size: 17px;
  }
  .flexRow2 {
    padding-left: 0px;
    top: -20px;
    position: relative;
  }
  .division {
    margin-top: 20px;
  }
  #g-recaptcha {
    left: -20px;
  }
  /* login page start */
  .logoAndText {
    position: relative;
    top: -10px;
    z-index: 2;
  }
  .leftsideimg2,
  .downBelow,
  .vllogin {
    display: none;
  }
  .mainContainer > .signUpPage {
    position: relative;
    top: 31px;
    left: 200px;
  }
  .py-12 {
    position: relative;
    top: -516px;
    left: -23px;
  }
  .forgotHeading > .forgotPassHead {
    position: relative;
    width: 328px;
  }
  .forgotHeading > .forgotPassSubHead {
    top: -214px;
    position: relative;
  }
  .secondText {
    font-size: 17px;
    position: relative;
    bottom: 76px;
    left: 184px;
  }
  .MuiInputAdornment-positionEnd {
    position: relative;
    right: 85px;
  }

  .mt-10 > button {
    width: 200px;
  }
  .MuiInput-input {
    max-width: 68%;
  }
  .MuiInputBase-root > input {
    max-width: 100%;
  }
  .MuiInput-root {
    position: relative;
    width: 250px;
  }
  .loginDivision {
    position: relative;
    right: 90px;
  }
  .testoptimize-app {
    height: 600px;
  }
  /* login page end */
  .flexRow {
    flex-direction: column;
  }
}
/* iphone7 landscape */
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .registerButton {
    margin-top: 10px;
  }
  .flexRow {
    flex-direction: column;
  }

  .divisionTop {
    margin-top: 0px;
  }
  .flexRow2 {
    padding-left: 0px;
    margin-top: -20px;
  }
  .h3Class {
    position: absolute;
    top: -150px;
    left: 8px;
  }
  .h3ClassSpan {
    position: relative;
    top: -85px;
    left: 9px;
  }
  .division {
    position: relative;
    left: 128px;
  }
  #g-recaptcha {
    left: -80px;
    top: 20px;
  }
  /* login page start */
  .rootClass {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 35px;
    height: 290px;
  }
  .MuiInput-root {
    border-bottom: 0.5px solid #766f6f;
    position: relative;
  }
  .vllogin {
    display: none;
  }
  .downBelow {
    display: none;
  }
  .leftImg {
    margin-top: 120px;
  }
  .leftImg {
    height: 726px;
    width: 650px;
  }
  .mainContainer > .signUpPage {
    margin-top: -50px;
    margin-left: 30px;
  }
  .leftsideimgSignup {
    margin-top: 70px;
    margin-left: -65px;
  }
  .secondSignUp {
    left: -6px;
    position: relative;
  }

  .space-y-6 {
    position: relative;
    top: -140px;
    margin-left: 0px;
  }
  .mt-10 {
    margin-top: -60px;
    position: relative;
  }
  #forgetPassword > a {
    position: relative;
    left: 111px;
  }
  .signUp {
    margin-left: -248px;
    margin-top: 43px;

    margin-right: -200px;
  }

  .secondText {
    position: relative;
    top: -97px;
    left: 139px;
  }
  .mainContainer {
    margin-top: 92px;
    display: grid;
    place-items: center;
    width: 93%;
    height: 100%;
  }
  .logoAndText {
    margin-left: -10px;
  }
  .logoAndText.logoTextForgot {
    position: relative;
    left: -100px;
    top: -100px;
  }
  .loginGridForget > .forgotHeading {
    position: relative;
    top: -364px;
    width: 496px;
    left: 454px;
  }
  .logoOnlyPhone {
    margin-top: -8px;
    margin-left: -80px;
  }
  .mobile-hide.leftsideimg1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: -70px;
    width: 80%;
    margin-bottom: 100px;
  }
  .mobile-hide.leftsideimg2 {
    display: none;
  }
  .gridContent {
    margin-left: -20px;
    margin-right: 20px;
    margin-top: 30px;
    position: relative;
    margin-left: -500px;
    margin-top: 150px;
  }

  .space-y-6 {
    margin-left: 12px;
    margin-top: 70px;
  }
  .gridContent > .signinUserText {
    margin-bottom: 9px;
    position: absolute;
    margin-top: -314px;
    margin-left: 13px;
  }
  .gridContent > .signinUserText > h3 {
    margin-top: -6px;
    font-size: 25px;
  }
  .signinUser {
    padding-bottom: 20px;
  }
  .signinUserEmail {
    margin-top: -20px;
    padding-bottom: 15px;
  }
  .signinAll {
    margin-top: -176px;
    margin-right: 10px;
  }
  .forgetPassword {
    margin-right: 126px;
  }
  .mt-10 > button {
    margin-top: 50px;
    width: 200px;
  }
  .mobile-hide.leftsideimg.forgetPassPic {
    margin-left: 160px;
  }
  .mobile-hide.leftsideimg1.forgetPassPic {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg1.emailSendPic {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.emailSendPic {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.phoneValidImg {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.phoneValidImg {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.setPassImg {
    margin-left: 100px;
  }
  .mobile-hide.leftsideimg.setPassImg {
    margin-left: 145px;
    margin-top: 100px;
  }
  .mobile-hide.leftsideimg.passwordSet {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.passwordSet {
    margin-left: 84px;
  }
  .mobile-hide.leftsideimg.resSucImg {
    margin-left: 145px;
    margin-top: 130px;
  }
  .mobile-hide.leftsideimg1.resSucImg {
    margin-left: 100px;
  }
  .vlSignUp {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .mainContainer {
    display: block;
    background-repeat: no-repeat;
    background-position: revert;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-height: 500px) and (max-width: 759px) {
  /* login page start */
  .space-y-6 {
    margin-left: 12px;
    margin-top: 50px;
  }
  .verificationGrid {
    margin-left: 3px;
  }
}
/* ipad landscape and portrait */
@media only screen and (max-width: 1023px) and (min-width: 760px) {
  .logoAndText {
    margin-top: 170px;
  }
  .mobile-hide.leftsideimg {
    margin-top: 170px;
    margin-left: 80px;
    min-width: 200px;
    min-height: 60px;
  }
  .secondText {
    position: relative;
    left: 136px;
    top: 3px;
  }
  #secondSignUp {
    position: relative;
    left: -2px;
    margin-left: -60px;
    top: 3px;
  }
  .vllogin {
    display: none;
  }
  .leftsideimg2 {
    display: none;
    position: absolute;
  }
  .downBelow {
    display: none;
  }
  /* login page end */
  /* signup page start*/
  .signUpPage {
    left: 100px;
    top: 0px;
  }
  .vlSignUp {
    display: none;
  }
  .signUpContent {
    width: 115%;
    position: relative;
    left: -320px;
    top: 80px;
  }
}

/* only Ipad portrait */
@media only screen and (max-width: 800px) and (min-width: 590px) {
  .logoTextForgot {
    left: 0px;
    top: -126px;
  }
}
/*only ipad landscape*/
@media only screen and (max-width: 1023px) and (min-width: 801px) {
  .logoTextForgot {
    left: 37px;
    top: 5px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1024px) {
  /* login page start */
  .vllogin {
    display: none;
  }
  .loginGrid {
    margin-right: 50px;
  }
}

.MuiInput-root {
  position: relative;
}

.lastTextForgot {
  color: var(--deepGray);
  width: 550px;
  margin-right: 22px;
}
.downBelowForgot {
  margin-left: 50px;
}
.belowTextForgot {
  color: var(--deepGray);

  width: 400px;
}
.vlForgot,
.vlEmail {
  border-left: 1px solid var(--black);
  height: 739px;
  margin-left: 550px;
  position: absolute;
  margin-top: -595px;
}
/* Forget password  ipad*/
@media only screen and (max-width: 769px) and (min-width: 750px) {
  .logoAndText.loginPageText {
    top: -32px;
  }
  .logoAndText {
    margin-left: -20px;
    position: relative;
    bottom: 110px;
    z-index: 2;
  }

  .logoAndTextSuccessOnly {
    position: relative;
    left: 0px;
  }
  .logoTextSet {
    position: relative;
    left: -66px;
  }
  .headingSetP {
    margin-left: -142px;
    margin-top: -50px;
  }
  .backFromSet {
    margin-left: -70px;
  }
  .vlForgot {
    display: none;
  }
  .downBelowForgot {
    display: none;
  }
  .forgotHeading {
    margin-left: -60px;
  }
  .warning_button,
  .warning-division,
  .backtologin {
    margin-left: -60px;
  }
}
/*kindle portraite*/
@media only screen and (max-width: 650px) and (min-width: 590px) {
  .otpLogoText {
    left: 100px;
  }

  .headingSetP {
    left: -87px;
  }
  .loginPageText > .secondText {
    top: 20px;
    margin-left: -58px;
  }
  .logoAndTextSuccessOnly > .secondText {
    top: 13px;
    margin-left: -55px;
  }
  .logoOnlyPhone > .leftsideimg {
    left: -38px;
  }
  .logoOnlyPhone > .secondText {
    margin-left: 100px;
  }
  .loginGridPhn > .phoneInput {
    top: -214px;
    left: 17px;
    position: relative;
  }
  .loginGridPhn > .warning_button {
    left: 11px;
  }
  .warnDivSetPBut {
    margin-top: 349px;
  }
  .backFromSet {
    left: -200px;
  }
  .logoSetText > .secondText {
    margin-left: 140px;
  }
  .logoSetText {
    margin-top: -64px;
    margin-left: -20px;
  }
  .logoOnlyEmail > .leftsideimg,
  .secondText {
    margin-left: 77px;
  }
  .otpLogoText > .secondText {
    margin-left: -60px;
  }

  #downBelowWarning > .leftsideimg {
    top: 20px;
    right: 20px;
    position: relative;
  }
  #downBelowWarning > .secondText {
    top: -73px;
    left: 41px;
    position: relative;
  }
  .otpLogoText > .leftsideimg {
    right: 200px;
  }
  .logoTextOnlySet > .leftSideImgSucc {
    margin-left: 120px;
  }
  .veriFicationGrid > .gridContent,
  .secondText,
  .otpText,
  .veriButton {
    left: 70px;
    bottom: 230px;
  }

  .logoTextForgot > .veriButton {
    position: relative;
    left: 185px;
  }
  .loginPageText > .leftsideimg {
    left: -53px;
  }
  .loginPageText > .secondText {
    left: 144px;
    position: relative;
  }
  .logoAndTextSuccessOnly > .secondText {
    left: 200px;
    position: relative;
  }
  .logoTextForgot > .leftsideimg {
    left: -13px;
  }

  .loginGridForget > .gridContent,
  .warning-division,
  .warning_button,
  .backtologin {
    top: -180px;
  }
  .loginGridForget > .warning-division,
  .warning_button,
  .backtologin {
    top: -180px;
    position: relative;
  }
  .leftsideimg {
    position: relative;
    top: 100px;
  }
  .loginGrid > .gridRegSuc {
    margin-left: 0px;
    top: -105px;
  }
  .warningMessages {
    bottom: 200px;
    right: -10px;
  }
  .gridContent {
    margin-left: 0px;
  }
  .vlSetPSucc,
  .vlEmail,
  .vlSetP {
    display: none;
  }
  .leftSideImgSucc {
    position: relative;
    right: 40px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 590px) {
  .vlForgot,
  .vllogin,
  .downBelow {
    display: none;
  }
  .downBelowForgot {
    display: none;
  }
  .logoAndText.loginPageText {
    bottom: 0px;
  }
  .logoAndText {
    position: relative;
    bottom: 100px;
    z-index: 2;
  }
}
/* Forget password  iphone7 landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .vlForgot {
    display: none;
  }
  .downBelowForgot {
    display: none;
  }
  .setPassImg {
    position: relative;
    right: 68px;
  }
  .warnDivSetPBut {
    margin-left: 35px;
  }
  .logoOnlyEmail {
    top: -80px;
    left: -152px;
  }
  .mainContainer > .leftImg {
    margin-bottom: 510px;
  }
  .Warningmessages {
    display: block;
    position: relative;
    top: -360px;
    left: 452px;
  }
  .gridRegSuc {
    margin-left: -630px;
  }
  .logoTextOnlySet {
    top: -45px;
    left: -132px;
  }
  .logoTextForgot {
    margin-top: 0px;
    margin-left: -50px;
  }
  .logoAndText {
    margin-top: -27px;
    margin-left: 90px;
  }
  .resSuc > .logoAndTextSuccessOnly {
    position: relative;
    top: -60px;
  }
  .logoAndTextSuccessOnly > .leftsideimg {
    left: -70px;
    position: relative;
  }
  .loginGrid > .gridRegSuc {
    left: 546px;
    top: -388px;
  }
  .logoSetText {
    position: relative;
    top: 45px;
    right: 111px;
  }
  .setLoginGrid {
    margin-top: 57px;
  }
  .warning-division {
    position: relative;
    left: -50px;
    bottom: 380px;
  }
  .warnDivSetP,
  .warnDivSetPBut {
    left: 0px;
  }
  .backtologin {
    width: 450px;
    text-align: center;
    position: relative;
    bottom: 364px;
    padding-top: 20px;
  }

  .warning_button {
    position: relative;
    bottom: 377px;
    left: -36px;
  }
  .phn_warning_btn {
    position: relative;
    bottom: 377px;
    left: -36px;
  }
  .mainContainer {
    height: 24%;
  }
  .otpLogoText > .leftsideimg {
    right: -16px;
    top: 100px;
    position: relative;
  }
  .otpLogoText > .secondText {
    top: 0px;
    left: 154px;
  }
  .verificationGrid {
    left: 606px !important;
    bottom: 311px;
  }
  .warningMessages {
    bottom: 374px;
    left: 455px;
  }
  #downBelowWarning {
    top: -120px;
    position: relative;
  }
  .logoOnlyPhone {
    margin-left: -12px;
    width: 393px;
    top: -19px;
  }
  .loginGridPhn > .phoneInput {
    position: relative;
    top: -331px;
  }
  .loginGridPhn > .warning_button {
    top: -321px;
  }
  .verificationGrid {
    top: -315px;
  }
}

/* Forget password  iphone7 portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .vlForgot {
    display: none;
  }
  .backSignInSet {
    left: -152px;
    position: relative;
  }
  .logoOnlyPhone {
    margin-left: -12px;
    width: 393px;
    top: 19px;
    left: 20px;
  }
  .gridRegSuc {
    margin-left: 72px;
  }
  .veriFicationGrid {
    left: 130px;
    bottom: 30px;
    position: relative;
  }
  .loginGridPhn > .WarningPhone {
    top: -221px;
    left: -42px;
    width: auto;
  }
  .loginGridPhn > .phoneInput {
    position: relative;
    top: -241px;
    left: 20px;
  }
  .loginGridPhn > .warning_button {
    top: -222px;
    left: -80px;
  }
  .loginGrid > .gridRegSuc {
    top: -120px;
    left: 20px;
  }
  .warnDivSetP {
    left: 45px;
    margin-top: 150px;
  }
  .warnDivSetPBut {
    left: 42px;
  }

  .gridOnlySuccessFul {
    margin-left: -10px;
  }
  .gridContendOnlyEmail {
    left: 20px !important;
    width: 400px;
  }
  #downBelowWarning {
    top: 100px;
    z-index: 2;
    position: relative;
  }
  .warningMessages {
    bottom: 140px;
    left: 107px;
  }
  .downBelowForgot {
    display: none;
  }
  .verificationGrid {
    bottom: 250px;
    left: 25px;
    width: 330px;
  }
  .otpText {
    bottom: 270px;
    position: relative;
    left: 64px;
  }
  .veriButton {
    right: 100px;
  }
  .warning_button,
  .warning-division,
  .backtologin {
    margin-left: 15px;
    position: relative;
    top: -170px;
  }
  .py-12 {
    position: relative;
    top: 29px;
    left: -44px;
  }
}

/*set password*/
.lastTextSetP {
  color: var(--lightGray);
  width: 540px;
  margin-right: 20px;
}
.vlSetP {
  border-left: 3px solid var(--black);
  height: 739px;
  margin-left: 550px;
  position: absolute;
  margin-top: -595px;
}
/* set password  ipad*/
@media only screen and (max-width: 1023px) and (min-width: 760px) {
  .vlSetP {
    display: none;
  }
  .leftsideimg2.downBelow {
    display: none;
  }

  .logoTextOnlySet {
    right: 20px;
    position: relative;
    top: -40px;
  }
}
/* set password  iphone7 landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .vlSetP {
    display: none;
  }
  .gridOnlySuccessFul {
    position: relative;
    left: 295px;
  }
}
/* set password  iphone7 portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .vlSetP {
    display: none;
  }
  .logoTextSet {
    position: absolute;
  }
}
/*password set successfully*/
.vlSetPSucc {
  border-left: 3px solid var(--black);
  height: 739px;
  margin-left: 550px;
  position: absolute;
  margin-top: -595px;
}

/* password set successfully  ipad portrait*/
@media only screen and (max-width: 790px) and (min-width: 560px) {
  .vlSetPSucc {
    display: none;
  }
  .logoTextSet {
    position: relative;
  }
  .WarningPhone > h1 {
    margin-left: 110px;
    width: auto;
  }

  .loginGridPhn > .warning_button {
    right: 22px;
  }
  .logoTextOnlySet {
    margin-top: -60px;
  }
  .logoOnlyPhone {
    position: relative;
    left: -40px;
  }

  .logoAndTextSuccessOnly {
    top: -110px;
    left: -58px;
  }
  .otpLogoText {
    right: 118px;
    top: -168px;
  }
  .otpText {
    position: relative;
    right: 30px;
  }
  .veriButton {
    position: relative;
    right: 120px;
  }
  #downBelowWarning {
    top: -103px;
    left: -13px;
    position: relative;
  }
}
/* password set successfully  ipad landscape*/
@media only screen and (max-width: 1023px) and (min-width: 791px) {
  .vlSetPSucc {
    display: none;
  }
  #downBelowWarning {
    position: relative;
    left: -77px;
  }
  .verificationGrid > h5 {
    top: 10px;
  }
  .otpLogoText {
    right: 114px;
    top: -27px;
  }
  .logoOnlyPhone {
    top: 10px;
  }
  .veriFicationGrid {
    top: -100px;
    position: relative;
  }
  .logoTextSet {
    position: relative;
    left: 44px;
    top: 0px;
  }
  .logoOnlyPhone {
    position: relative;
    left: -40px;
  }
  .logoOnlyEmail {
    position: relative;
    left: 16px;
    top: 22px;
  }
  .logoAndTextSuccessOnly {
    position: relative;
    top: -14px;
  }
}
/* password set successfully iphone7 landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .vlSetPSucc {
    display: none;
  }
  .gridContendOnlyEmail {
    position: relative;
    left: 264px;
  }
}
/* password set successfully  iphone7 portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .vlSetPSucc {
    display: none;
  }
}

/* phone validation iphone7 landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .phoneInput {
    position: relative;
    bottom: 390px;
    right: 35px;
  }
}

/* phone number successfully  iphone7 portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .loginGridPhn {
    margin-left: 250px;
  }
}

/* email send  ipad*/
@media only screen and (max-width: 1023px) and (min-width: 760px) {
  .vlEmail {
    display: none;
  }
}
/* email send iphone7 landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .vlEmail {
    display: none;
  }
  .loginPageText {
    width: 400px;
    margin-top: 314px;
    height: 88px;
  }
}
/* email send iphone7 portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  .vlEmail {
    display: none;
  }
}
/* warning activation portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
}

/* verification code portrait*/
@media only screen and (max-width: 449px) and (min-width: 310px) and (orientation: portrait) {
  #otp {
    right: -5px;
  }
  .resendOtp {
    right: 180px;
  }
}
/* verification page landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  #otpText {
    position: relative;
    bottom: 356px;
    left: 100px;
  }
  .veriButton {
    position: relative;
    bottom: 377px;
    left: 98px;
  }
  .verificationGrid {
    position: relative;
    left: 595px;
  }
}
/* verification page landscape*/
@media only screen and (max-width: 767px) and (max-height: 500px) {
  .loginTextOnly {
    position: relative;
    top: -23px;
    right: 170px;
  }
  .landScapeLogin {
    position: absolute;
    left: 230px;
  }
  .landscapeSignIn {
    position: absolute;
    left: 231px;
  }
}

/* inline style start */

.warTextPhn {
  color: var(--deepGray);
  margin-left: -30px;
  width: 554px;
}
.phnValidBtn {
  background-color: var(--rs-blue-primary);
  color: var(--white);
  border-radius: 10px;
}
#validPhn {
  color: var(--red);
  height: 10px;
  position: fixed;
  margin-top: 6px;
}
.phoneNumberText {
  color: var(--lightGray);
}
.phnValidHead {
  color: var(--deepGray);
  position: relative;
  right: 145px;
  width: 300px;
}
.emailSendHead {
  color: var(--deepGray);
}
.emailSendSubHead {
  color: var(--deepGray);
  font-size: 14px;
  margin-left: 20px;
}
.emailSendSpan {
  margin-left: 3px;
  color: var(--rs-blue-primary);
}
.emailLink {
  color: var(--rs-blue-primary);
}
.forgotPassHead {
  color: var(--deepGray);
  margin-left: 40px;
}
.forgotPassSubHead {
  color: var(--deepGray);

  padding-bottom: 30px;
  margin-left: 40px;
  text-align: left;
}
.forgotPassButton {
  background: var(--rs-blue-primary);
  color: var(--white);
  border-radius: 10px;
  width: 295px;
  height: 40px;
  margin-top: -10px;
}
.backToForgot {
  color: var(--rs-blue-primary);
  position: relative;
  right: 135px;
}
.alert {
  background-color: transparent !important;
  margin-top: -70px;
  padding-bottom: 40px;
  margin-left: 53px;
  color: var(--lightGreen);
  width: 267px;
  font-size: 18px;
  position: absolute;
}
.activationSuccess {
  width: 100%;
  display: flex;
  margin-top: 2px;
  color: var(--lightGreen);
}

.green-message {
  color: var(--lightGreen);
}

.resend-alert {
  width: 99%;
  margin-top: 0px;
}

.width-97-per {
  width: 97%;
}
.alertIconActivationLink,
.alertIconRegSuc {
  color: var(--lightGreen);

  width: 30px !important;
  height: 30px !important;
}
.regSucc {
  width: 500px;
}
.warTxtRegSuc {
  color: black;
  margin-left: 0px;
}
.resActLink {
  color: var(--rs-blue-primary);
}
.backToSuccess {
  color: var(--rs-blue-primary);
}
.setPasHead {
  margin-left: 122px;

  color: var(--deepGray);
  width: max-content;
}
.setPasSubHead {
  margin-left: 122px;
  color: var(--deepGray);
}
.goodPass {
  color: var(--lightGreen);
  position: fixed;
  margin-top: -2px;
}
.set-password-info{
  position: absolute;
  right:  9rem;
  top: 19rem;
}
.password-match {
  color: var(--lightGreen);
  margin-top: -1rem;
}
.setPassBtn {
  background-color: var(--blue);
  color: var(--white);
  margin-left: 40px;
}
.backSignInSet {
  color: var(--rs-blue-primary);

  margin-left: -255px;
}
.alertVeri {
  background-color: var(--white);
  margin-left: -106px;
  color: var(--lightGreen);
}
.checkCircle {
  width: 46px;
  height: 46px;
}
.PhnChaSuc {
  display: flex;
  flex-wrap: wrap;
}
.verificationHead {
  margin-right: 20px;
  position: relative;
  left: -110px;
  top: -30px;
}
.verificationSubHead {
  margin-left: -170px;
  margin-top: -22px;
  padding-left: 63px;
  width: 750px;
  color: var(--deepGray);
}
.verificationSubHead > p > a {
  color: var(--rs-blue-primary);
}
.ifThisIs {
  width: 400px;
  margin-left: -116px;
  margin-top: -14px;

  color: var(--deepGray);
}
.veriFicationCodeSpan {
  color: var(--rs-blue-primary);
}
.itMayTakeText {
  color: var(--deepGray);
  width: 500px;
  right: 234px;
}
.verifyContinueButton {
  margin-top: 40px;
  background-color: var(--blue);

  color: var(--white);
  position: relative;
  right: 196px;
}

.warningAlert {
  background-color: transparent !important;
  margin-left: 65px;
  margin-top: -400px;
  padding-bottom: 5px;
  color: var(--lightGreen);
  width: 267px;

  position: absolute;
  top: -10%;
  right: 10%;
}
.linkedSendSuccess {
  width: 290px;
  display: flex;
}
.warningIcon {
  margin-left: 186px;
}
.warningActivationHeading {
  color: var(--deepGray);
  width: 400px;
  position: relative;
  left: 36px;
}
.warningActivationSubHeading {
  color: var(--deepGray);

  width: 551px;
  position: relative;
  left: -85px;
  text-align: center;
}
.backFromWarning {
  margin-top: 10px;

  color: var(--rs-blue-primary);
}
.backFromPassSuccess {
  margin-left: -27px;

  color: var(--blue);
}

.margin-top-92 {
  margin-top: 92px;
}

.margin-top-88 {
  margin-top: 88px;
}

.letter-spacing-29 {
  letter-spacing: 0.29px;
}

.gap-34 {
  gap: 34px;
}
.letter-spacing-25 {
  letter-spacing: 0.25px;
}
/* iphone 4-5 landscape */
@media only screen and (max-height: 350px) and (max-width: 570px) {
  .loginTextOnly.loginPageText {
    top: 0px;
  }
  .otpLogoText {
    margin-left: 20px;
    margin-top: 0px;
  }
  .verificationGrid {
    top: -204px;
    margin-left: -459px;
  }
  .otpText {
    top: -237px;
    margin-left: 78px;
  }
  .veriButton {
    top: -206px;
    left: -22px;
  }
  .logoOnlyPhone {
    top: 65px;
    position: relative;
    left: 44px;
  }
  .WarningPhone {
    left: 555px;
  }
  .phoneInput {
    margin-left: 56px;
  }
  .logoSetText {
    left: -30px;
  }
  .warnDivSetPBut {
    left: -2px;
  }
  .backFromSet {
    left: -190px;
  }
  .logoTextOnlySet {
    margin-top: 20px;
    margin-left: 30px;
  }
  .logoAndTextSuccessOnly {
    margin-top: 10px;
  }
  .gridOnlySuccessFul {
    left: 350px;
  }
  #downBelowWarning {
    top: -32px;
    left: 14px;
    position: relative;
  }
  .gridContendOnlyEmail {
    margin-left: -206px;
  }
  .loginTextOnly {
    position: relative;
    left: -95px;
    top: 34px;
  }
  .logoAndText.logoTextForgot {
    position: relative;
    top: -10px;
    left: -70px;
  }
  .loginGridForget > .forgotHeading {
    left: 474px;
  }
  .loginGridForget > .warning-division {
    left: -35px;
  }

  .loginGridForget > .warning_button {
    left: -35px;
  }
  .loginGridForget > .backtologin {
    left: -22px;
  }
  #forgetPassword > a {
    position: relative;
    left: -10px;
  }
  .logoTextForgot {
    position: relative;
    top: -13px;
    left: 76px;
  }
  .Warningmessages {
    margin-left: -477px;
  }
  .warning-division,
  .warning_button {
    margin-left: 12px;
  }
  .gridContent > .signinUserText {
    margin-left: 80px;
  }
  .signinAll {
    margin-left: 70px;
    width: 350px;
  }
}
/* only iphone 5 portrait */
@media only screen and (max-height: 590px) and (max-width: 330px) and (min-height: 531px) {
  .warning_button,
  .warning-division {
    margin-left: 84px;
  }
  .rightimg {
    left: 118px !important;
  }
  .backFromSet {
    margin-left: 170px;
  }
  .gridOnlySuccessFul {
    margin-left: 94px !important;
  }
  .gridRegSuc {
    top: -85px !important;
    left: 40px !important;
    margin-left: 20px;
  }
  .gridRegSuc > .-ml-5 {
    margin-left: 194px;
  }
  .headingSetP {
    position: relative;
    right: 80px;
  }
  .WarningPhone {
    top: 20px;
  }
  .loginGridPhn > .WarningPhone {
    top: -59px;
  }
  .loginGridPhn > .phoneInput {
    top: -77px;
    left: 49px;
  }
  .loginGridPhn > .warning_button {
    top: -40px;
    left: -127px;
  }
  .warnDivSetP {
    left: 4px;
  }
  .backFromSet {
    left: 47px;
  }
  .warnDivSetPBut {
    left: 4px;
  }
  .emailSendSpan {
    left: 217px;
    position: relative;
  }
  .verificationGrid {
    position: relative;
    top: -130px;
    left: 155px;
  }
  .otpText {
    bottom: 170px;
    position: relative;
    left: 222px;
  }
  #forgetPassword > a {
    position: relative;
    left: 120px;
    bottom: 45px;
  }
  .loginGridForget {
    left: -30px;
    top: -42px;
    position: relative;
  }
  .loginGridForget > .forgotHeading {
    top: 217px;
    position: relative;
  }
  .loginGridForget > .warning-division {
    top: 10px;
  }
  .loginGridForget > .warning_button > button {
    width: 260px;
    top: 170px;
  }
  .loginGrid a {
    color: var(--blue);

    margin-left: -215px;
  }
  .leftsideimg {
    top: 100px;
    left: 227px;
    position: relative;
  }
  .secondText {
    position: relative;
    left: 211px;
    font-size: 14px;
    top: 100px;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: -81px;
  }
  .gridContent {
    margin-left: 84px;
  }
  #downBelowWarning {
    left: 6px;
    top: -28px;
  }
  .warningMessages {
    bottom: 84px;
    position: relative;
  }
  .backFromWarning {
    position: relative;
  }
  .gridOnlySuccessFul {
    margin-left: 15px;
  }
  .backFromPassSuccess {
    left: 183px;
    position: relative;
  }
  .warningMessages {
    position: relative;
    left: 30px;
  }
  .otpText > #otp {
    margin-left: -285px;
  }
  .otpText > .itMayTakeText {
    left: -307px;
  }
  .veriFicationGrid > .warning_button {
    left: -130px;
  }
}
.otpText input:focus {
  border: 1px solid var(--rs-blue-primary);
}
/* iphone 4 portrait */
@media only screen and (max-height: 530px) and (max-width: 330px) {
  .resSuc > .logoAndTextSuccessOnly {
    position: relative;
    top: 130px;
  }
  .logoAndTextSuccessOnly {
    margin-left: -48px;
  }
  .logoOnlyPhone {
    margin-top: 10px;
    margin-left: -39px;
  }
  .headingSetP {
    top: 15px;
    left: 471px;
  }
  .otpLogoText {
    margin-left: -63px;
    margin-top: -93px;
  }
  .verificationGrid {
    margin-left: -570px;
    margin-top: 86px;
  }
  .gridRegSuc {
    margin-left: -637px;
  }
  .otpText {
    margin-left: -73px;
  }
  .veriButton {
    left: -130px;
    top: -371px;
  }
  .loginGridPhn > .phoneInput {
    left: 103px;
  }
  .loginGridPhn > .warning_button {
    left: 4px;
  }
  .WarningPhone {
    margin-left: 137px;
    margin-bottom: 114px;
  }
  .logoOnlyPhone {
    left: 20px;
  }
  .logoSetText {
    margin-left: -38px;
    margin-top: 0px;
  }
  .setLoginGrid {
    top: -149px;
    left: -30px;
    position: relative;
  }
  .logoTextOnlySet {
    margin-left: -15px;
  }
  .gridOnlySuccessFul {
    margin-left: -600px;
  }
  .backFromPassSuccess {
    margin-left: -27px;
  }
  .logoOnlyEmail {
    margin-left: -42px;
  }
  .gridContendOnlyEmail {
    margin-left: 20px;
  }
  #downBelowWarning {
    left: 100px;
  }
  .warningMessages {
    bottom: 236px;

    position: relative;
    left: 508px;
  }
  .loginGrid > .gridRegSuc {
    top: -270px;
    left: 510px;
  }
  .loginTextOnly.loginPageText {
    top: -10px;
    left: 56px;
  }
  .secondText {
    position: relative;
    left: 126px;
    font-size: 14px;
  }
  .loginTextOnly {
    top: 58px;
    left: 45px;
  }
  .forgotHeading {
    position: relative;
    left: 507px;
  }
  .logoAndText {
    position: relative;
    top: 131px;
    left: 142px;
  }
  .logoAndText.logoTextForgot {
    left: 10px;
    top: 129px;
    position: relative;
  }
  .loginGridForget > .forgotHeading {
    position: relative;
    left: 516px;
    top: -169px;
  }
  .loginGridForget > .warning-division {
    top: -400px;
    left: 3px;
  }

  .loginGridForget > .warning_button {
    top: -400px;
    left: 3px;
  }
  .loginGridForget > .backtologin {
    top: -400px;
  }
  .gridContent > .signinUserText {
    margin-left: 152px;
  }
  .signinAll {
    margin-left: 141px;
    width: 254px;
  }
  .loginGrid {
    margin-left: 165px;
  }
  .Warningmessages {
    margin-left: 60px;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: -81px;
  }
  #forgetPassword > a {
    position: relative;

    left: -73px;
    top: -48px;
  }
  .loginGridForget {
    margin-top: 350px;
  }
}

.token-expired-icon {
  width: 20%;
  height: auto;
  margin-top: 32px;
}
.token-expired-text {
  color: #f8981c;
  margin-top: 16px;
}
.resendActivationLink {
  text-align: center;
  font-weight: 700;
}

.token-expired-content {
  display: inline-block;
}
.warning-text-token-expired {
  width: 455px;

  padding: 16px 0 16px 0px;
}
.logo-style {
  height: 60px;
  padding-left: 16px;
  padding-top: 16px;
}

.logo-label-text {
  color: var(--rs-blue-primary);
}

.active-user-alert-text {
  width: 70%;
  margin-top: 16px;
}
.warning-activation-content {
  position: absolute;
  left: 60%;
  top: 20%;
}
.lastTextActivation {
  color: var(--deepGray);

  width: 590px;
}
.verifyContinueFadeButton {
  margin-top: 40px;
  background-color: var(--lightblue);

  color: var(--white);
  position: relative;
  right: 196px;
}

.user-activated-alert {
  margin-top: 0px;
  background: none;
}

.registration_Container {
  width: 547px;
  height: 497px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 13px;
  opacity: 1;
  padding: 36px 47px;
}
.registration_success_header {
  font-size: 26px;
  font-family: Poppins-SemiBold;
  color: var(--lightGreen);
  letter-spacing: 0.47px;
}
.registration_success_message_container {
  width: 453px;
  height: 146px;
  margin-top: 21px;
  color: var(--rs-black);
  letter-spacing: 0.25px;
}
.registration_success_message_container > .message {
  line-height: 21px;
  text-align: center;
}
.registration_success_border {
  border-bottom: 2px solid #db7221;
  width: 8%;
  margin: auto;
  height: 12px;
}
