@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

$lineColor: rgba(22, 72, 198, 0.22);
$lineHoverColor: $blue_dark;

.result-tree {
  .tree-header-wrp {
    @extend .fontPoppinsSemiboldSm;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $skyblue_dark;
    height: 48px;
    color: $grey_darkest;
    .tree-header {
      margin: 0 0 0 12px;
      background: transparent;
      &.title {
        flex: 1;
      }
    }
  }
  .result-tree-wrp {
    height: calc(51.4vh - 132px);
    width: 100%;
    overflow: auto;
  }
  .tree-root-wrp {
    @extend .fontPoppinsRegularLg;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    color: $grey_darkest;
    .root-title-wrp {
      margin: 0 0 0 12px;
      background: transparent;
      flex: 1;
      button {
        svg {
          display: inline-block;
        }
      }
      .root-header {
        color: $grey;
        font-weight: bold;
      }
      .root-check-all {
        &.MuiCheckbox-colorPrimary:hover {
          background-color: transparent;
        }
      }
    }
    .root-header {
      margin: 0 0 0 12px;
      font-size: 13px;
      @media (min-width: $sm) {
        margin: 0 0 0 11px;
        font-size: 11px;
      }
      @media (min-width: $md) {
        margin: 0 0 0 11px;
        font-size: 12px;
      }
      @media (min-width: $lg) {
        margin: 0 0 0 12px;
        font-size: 13px;
      }
      @media (min-width: $xl) {
        margin: 0 0 0 12px;
        font-size: 14x;
      }
    }
    .root-check-all {
      color: $blue_dark;
    }
    .root-btn {
      color: $blue_dark;
      margin: 0 10px 0 0;
      &:focus {
        outline: 0;
      }
    }
  }

  .table-tree {
    .no-data-msg {
      display: none;
    }
    ul.fancytree-container {
      padding: 0;
    }
    .fancytree-plain span.fancytree-title {
      border: none;
    }
    .fancytree-plain span.fancytree-node:hover span.fancytree-title {
      background-color: transparent;
      border: none;
    }
    .fancytree-plain span.fancytree-active span.fancytree-title,
    .fancytree-plain span.fancytree-selected span.fancytree-title {
      background-color: transparent;
      border: none;
    }
    .fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-active span.fancytree-title,
    .fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-selected span.fancytree-title {
      background-color: transparent;
    }
    .fancytree-connectors.fancytree-container li {
      border-left: 1px solid $lineColor;
      background: none;
      margin-left: 30px;
    }
    .fancytree-connectors .fancytree-exp-n span.fancytree-expander,
    .fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
      background: none;
      border-bottom: 1px solid $lineColor;
      height: 5px;
      margin-left: 0;
      width: 28px;
    }
    .fancytree-connectors .fancytree-exp-nl span.fancytree-expander,
    .fancytree-connectors .fancytree-exp-nl span.fancytree-expander:hover {
      background: none;
      border-bottom: 1px solid $lineColor;
      border-left: 1px solid $lineColor;
      height: 24px;
      position: relative;
      top: -9px;
      margin-left: 0;
      width: 28px;
    }
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander:hover {
      border-left: 1px solid $lineColor;
      position: relative;
      top: -10px;
      height: 34px;
      margin-left: 0;
      padding-left: 10px;
      width: 28px;
      margin-top: 0;
    }
    .fancytree-exp-c span.fancytree-expander {
      background: url(./skin/icons/arrow-right.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 26px;
    }
    .fancytree-exp-e span.fancytree-expander,
    .fancytree-exp-ed span.fancytree-expander {
      background: url(./skin/icons/arrow-down.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 26px;
    }

    .fancytree-exp-c span.fancytree-expander,
    .fancytree-exp-e span.fancytree-expander,
    .fancytree-exp-ed span.fancytree-expander {
      position: relative;
      &::before {
        width: 10px;
        height: 1px;
        background: $lineColor;
        content: '';
        line-height: 1px;
        display: inline-block;
        position: absolute;
        top: 7px;
        left: -11px;
      }
    }

    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander:hover {
      background: url(./skin/icons/arrow-right.svg);
      background-position: 6px 15px;
      background-repeat: no-repeat;
      background-size: 26px;
    }
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander {
      background: url(./skin/icons/arrow-down.svg);
      background-position: 6px 16px;
      background-repeat: no-repeat;
      background-size: 26px;
    }

    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander {
      position: relative;
      &::before {
        width: 10px;
        height: 6px;
        border-top: 1px solid $lineColor;
        background: $white_original;
        content: '';
        line-height: 1px;
        display: inline-block;
        position: absolute;
        top: 28px;
        left: -1px;
      }
    }

    ul.fancytree-container li.fancytree-lastsib,
    ul.fancytree-no-connector > li {
      border-left: 0;
    }
    .fancytree-node {
      @extend .fontPoppinsRegularMd;
      display: flex;
      align-items: center;
      height: 42px;
      justify-content: flex-end;
      .tree-title {
        flex: 1;
        vertical-align: middle;
        align-items: center;
        display: flex;
      }
      .fancytree-title-wrapper {
        line-height: 40px;
      }
      &:hover {
        background-color: rgba(60, 154, 255, 0.06);
      }
      .fancytree-expander,
      .fancytree-icon {
        width: 18px;
      }
      .fancytree-expander {
        margin-left: 10px;
        margin-top: 0;
      }
      .fancytree-title-wrapper {
        .fancytree-title {
          vertical-align: middle;
        }
      }
      .node-col {
        margin: 0 0 0 12px;
        font-size: 13px;
      }
      @media (min-width: $sm) {
        .node-col {
          margin: 0 0 0 11px;
          font-size: 11px;
        }
      }
      @media (min-width: $md) {
        .node-col {
          margin: 0 0 0 11px;
          font-size: 12px;
        }
      }
      @media (min-width: $lg) {
        .node-col {
          margin: 0 0 0 12px;
          font-size: 13px;
        }
      }
      @media (min-width: $xl) {
        .node-col {
          margin: 0 0 0 12px;
          font-size: 14px;
        }
      }
    }
    ul.fancytree-container ul {
      padding: 0 0 0 3px;
    }
    // Checkbox
    span.fancytree-checkbox {
      background: url(./skin/icons/cb-normal.svg) no-repeat center center;
      background-position: 0 0;
      margin: 2px 0 0 0;
      height: 24px;
      width: 24px;
      cursor: pointer;
      &:hover {
        background-position: 0 0;
      }
    }
    .fancytree-partsel span.fancytree-checkbox {
      background: url(./skin/icons/cb-partsel.svg) no-repeat center center;
    }
    .fancytree-selected span.fancytree-checkbox {
      background: url(./skin/icons/cb-checked.svg) no-repeat center center;
    }
    .state-button {
      vertical-align: top;
      background-color: transparent;
      color: $blue_dark;
      &:hover {
        background-color: transparent;
        color: $blue;
      }
      &:focus {
        outline: none;
      }
      &[disabled] {
        opacity: 0.22;
      }
    }

    .state-col-wrp {
      border: 1px solid #efecfc;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      padding: 4px;
      width: inherit;
      button {
        margin: 0 4px;
        font-weight: 300;
        font-size: 10px;
        color: $grey_dark;
        display: inline-block;
        vertical-align: top;
        padding: 4px 5px;
        border-radius: 0;
        &.state-active {
          color: $blue_dark;
        }
      }
    }
    .count-badge {
      @apply px-2 py-0.5 font-bold ml-1;
      @apply rounded-lg;

      &.file-count {
        background-color: $white_dark;
        color: $blue_dark;
        font-size: 10px;
      }
      &.folder-count {
        background-color: $blue_dark;
        color: $white_original;
        font-size: 10px;
      }
    }

    .badge {
      margin-left: 4px;
      background: #3c9aff8a;
      border-radius: 10px;
      padding: 2px 8px;
      color: $blue_dark;
      font-size: 10px;
      &.subpage {
        background: #3c9aff8a;
      }
      &.element {
        background: #9b3cff8a;
      }
    }
    .add-btn {
      margin-left: 20px;
      color: $blue;
      line-height: 28px;
      height: 30px;
      width: 30px;
      &:focus {
        outline: none;
      }
      &:hover {
        color: #437ce8;
      }
    }
    .add-btn,
    .action-col-wrp {
      opacity: 0;
      transition: all 0.5s ease;
    }
    .tree-root-wrp,
    .fancytree-node {
      &:hover {
        .add-btn,
        .action-col-wrp {
          opacity: 1;
          transition: all 0.5s ease;
        }
      }
    }
    // Show Hide all conditions
    li {
      &.show-conditions {
        display: inherit;
      }
      &.hide-conditions {
        display: none;
      }
    }
    // Hide Tree icons
    .fancytree-ico-ef span.fancytree-icon {
      display: none;
    }
    span.fancytree-icon {
      display: none;
    }

    .fancytree-folder .fancytree-title {
      @extend .fontPoppinsRegularLg;
    }
    .version-text-color {
      color: $blue;
    }
    .locator-count-style {
      color: $blue_dark;
      margin-left: -3px;
    }
    @media (min-width: $sm) {
      .result-tree-wrp {
        min-height: calc(51.4vh - 200px);
        width: 100%;
        overflow: auto;
      }
    }
    @media (min-width: $md) {
      .result-tree-wrp {
        height: calc(51.4vh - 145px);
        width: 100%;
        overflow: auto;
      }
    }
    @media (max-width: $lg) {
      .result-tree-wrp {
        height: calc(51.4vh - 134px);
        width: 100%;
        overflow: auto;
      }
      .add-btn {
        margin-left: 20px;
        color: $blue;
        line-height: 28px;
        height: 30px;
        width: 30px;
      }
    }
    @media (min-width: $lg) {
      .result-tree-wrp {
        height: calc(51.4vh - 140px);
        width: 100%;
        overflow: auto;
      }
    }
    @media (min-width: $xl) {
      .result-tree-wrp {
        height: calc(51.4vh - 170px);
        width: 100%;
        overflow: auto;
      }
      .add-btn {
        margin-left: 20px;
        color: $blue;
        line-height: 28px;
        height: 30px;
        width: 30px;
      }
    }
  }

  // Pre-load Checbox related Images
  body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(./skin/icons/cb-normal.svg) url(./skin/icons/cb-partsel.svg) url(./skin/icons/cb-checked.svg)
      url(./skin/icons/arrow-right.svg) url(./skin/icons/arrow-down.svg);
  }
  // Higlight related styles
  li {
    &.highlight-lines {
      border-left-color: $lineHoverColor !important;
    }
    &.highlight-lines > .fancytree-exp-n span.fancytree-expander,
    &.highlight-lines > .fancytree-exp-nl span.fancytree-expander {
      border-bottom-color: $lineHoverColor !important;
      border-left-color: $lineHoverColor !important;
    }
  }
  .highlight-lines > {
    .fancytree-exp-nl > span > .fancytree-expander,
    .fancytree-exp-nl > span > .fancytree-expander:hover {
      border-bottom-color: $lineHoverColor !important;
      border-left-color: $lineHoverColor !important;
    }
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander:hover {
      border-left-color: $lineHoverColor !important;
    }
    .fancytree-exp-c span.fancytree-expander,
    .fancytree-exp-e span.fancytree-expander,
    .fancytree-exp-ed span.fancytree-expander {
      &::before {
        background: $lineHoverColor !important;
      }
    }
    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander {
      &::before {
        border-top-color: $lineHoverColor !important;
      }
    }
  }

  span.fancytree-title.step-failed {
    color: $red;
  }

  span.fancytree-title.step-passed {
    color: $green;
  }
  span.fancytree-title.step-warning {
    color: $yellow;
  }

  span.fancytree-title.step-skipped {
    color: #868686;
  }

  span.type-stepgroup,
  span.type-precondition,
  span.type-postcondition,
  span.type-dependantscript {
    span.fancytree-title:hover {
      text-decoration: none !important;
      color: $blue !important;
      cursor: default !important;
    }
  }
  .filter-style {
    font-size: 12px;
    font-weight: 500;
    z-index: 1050;
  }
}
.tree-title-width {
  width: calc(100% - 52px);
  @media (min-width: $lg) {
  }
}
.tree-folder-title-max-width {
  @media screen and (min-width: $lg) and (max-width: $xxl) {
    width: calc(100% - 25px);
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    max-width: 238px;
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    max-width: 182px;
  }
}
.tree-folder-title-repo-module-width {
  @media screen and (min-width: $xl) {
    width: calc(100% - 20px);
  }
  @media screen and (min-width: $xs) and (max-width: $xl) {
    width: calc(100% - 10px);
  }
}
.tree-folder-title-all-module-width {
  width: 100%;
}
.tree-folder-title-all-module-width-unset {
  width: unset;
}

.script-id-style-result-section {
  max-width: fit-content;
  margin-right: 2px !important;
}
.name-id-separator {
  color: $ff-blue;
}
