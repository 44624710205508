button.css-1r4vtzz,
button.css-48ayfv {
  height: 25px;
  padding: 0px 0px 2px 4px;
  box-shadow: 0px 0px 0px 1px;
  font-size: 12px;
  width: 100px;
  margin-top: 5px;
  border-color: hsl(0, 0%, 80%);
  background-color: hsl(0, 0%, 100%);
  opacity: 0.5;
}

// button + div {
//   width: 160px;
// }

.css-1qprcsu-option,
.css-1vr111p-option {
  padding: 0px;
  margin: 0px;
  min-width: 0px;
  height: 35px;
  background-color: #f0f3f9;
  color: #727171;
}

.css-1qprcsu-option.css-1qprcsu-option {
  background-color: #f0f3f9;
  color: #727171;
}

.css-11unzgr,
.css-1v99tuv {
  text-align: start;
  font-size: 12px;
}

div.css-1jllj6i-control,
div.css-6y1x9t-control {
  margin: 0px;
  padding: 0px;
  min-width: 0px;
  min-height: 0px;
  border-color: hsl(0, 0%, 80%);
  height: 30px;
}

div.css-6y1x9t-control:hover {
  border-color: hsl(0, 0%, 80%);
}

.css-ik6y5r {
  box-shadow: 0px 0px 0px 1px;
  border: 1px solid;
  border-color: hsl(0, 0%, 80%);
}

.css-1hwfws3 {
  margin: 0px;
  padding: 0px;
  width: 160px;
  height: 30px;
  text-align: center;
}

// ::-webkit-scrollbar {
//   width: 0.5em;
//   height: 0.9em;
// }

// ::-webkit-scrollbar-button {
//   background: hsl(0, 0%, 80%);
//   color:hsl(0, 0%, 100%)
// }

// ::-webkit-scrollbar-thumb {
//   background: #eee
// }
