@import '@styles/_variables.scss';

.license-form {
  max-height: calc(100vh - 50px);
  overflow-y: hidden;
  margin-bottom: 10px;
  .heading {
    color: $license_heading_black;
  }

  .form-layout {
    background-color: $white;
    border-radius: $gray;
    border-radius: 10px;
    padding: 8px;
  }
}

.step-button-wrapper {
  &:first-of-type {
    span {
      &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
  &:last-of-type {
    span {
      &:first-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.form-card {
  background-color: $white;
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  // width: 64.5%; For firecloud integration
  width: 100%;
  margin-right: 8px;
  margin-left: 4px;
  margin-top: 18px;
  min-height: 310px;
}
.license-type-billing-card {
  width: 100%;
  margin-right: 8px;
  margin-left: 4px;
}
.payment-form-card {
  @extend .form-card;
  width: 100%;
}
.explore-image-wrapper {
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  height: auto;
  background-color: $white;
}

.explore-link {
  color: $primary;
  &:hover {
    color: $primary;
  }
}

.explore-feature-img {
  margin-left: 10%;
}

.hide-field {
  display: none;
}
.disabled-field {
  color: $rs_disabled_text;
}

.checked-wrapper {
  display: flex;
  :global {
    .MuiButtonBase-root {
      &.Mui-checked {
        color: $primary;
      }
      &.Mui-disabled {
        color: $primary;
        opacity: 0.3;
      }
    }
  }
}
.fireCloud-card {
  width: 97.5%;
  display: flex;
  padding: 20px;
  margin-top: 10px;
}
.automation-checkbox-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.disabled-parallel-runs {
  pointer-events: none;
  opacity: 0.5;
}

.license-features-upgrade-renew-card {
  @extend .form-card;
  width: 100%;
}
.license-lable-text {
  color: $dark;
}
.license_select_label {
  color: $license_label_gray;
}

.form-card-container {
  background-color: $white;
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  width: 100%;
  margin-right: 8px;
  margin-left: 4px;
  height: auto;
  margin-bottom: 2px;
}
.form-outer-card-container {
  background-color: $license_row_card_bgg;
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  width: 100%;
  margin-right: 8px;
  margin-left: 4px;
  height: auto;
  margin-bottom: 2px;
  padding: 16px;
}
.feature-header {
  background: $alert_footer_bg 0% 0% no-repeat padding-box;
  color: $primary;
  padding: 10px;
  width: 100%;
}
.feature-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 0px 6px $border-muted;
  border-radius: 10px;
  height: 460px;
}
