@import './../../sass/sass-utils/color';
@import './../../sass/sass-utils/variable';
@import './../../sass/sass-utils/mixins';

.comment-box-wrapper {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid $border-color;
  border-radius: 2px;

  &:focus-within {
    outline: 1px solid;
  }

  textarea {
    resize: none;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    overflow: hidden;
    display: flex;
    border: none;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 16px;
    margin: 0;
    color: $body-text-color;

    &::placeholder {
      @include lightenColor($body-text-color, 40);
    }

    &:focus {
      outline: none;
    }
  }
}

.p-mention .p-mention-panel {
  min-width: auto !important;
}

.p-mention-panel .p-mention-items {
  margin: 0px !important;
  padding: 0px !important;
}

.p-mention-item,
.p-highlight {
  pointer-events: none !important;

  > div {
    pointer-events: auto !important;
  }
}

textarea.p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable.p-mention-input {
  width: 90vh !important;
  height: 100px !important;
  overflow-y: scroll !important;
}
