$border-color: #878a8c;

$gost-button-text: #606060;
$body-text-color: #292929;
$title-text-color: #1a1a1b;
$icon-color: $body-text-color;

$primary-hover: #3c69be;
$white: #ffffff;
$body-text: #1c1c1c;
$background-dark: #e3e7e8;
$background: #f5f5f5;
$primary: #4b83ee;

$threadline-color: lightblue;

$primary-disabled: rgba(75, 131, 238, 0.6);

$font-weight-medium: 500;
