@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.os-section {
  border: 1px solid $ff-border;
  border-radius: 3px;
  padding: 5px 5px;
  min-height: 36px;
  background-color: $ff-bg-disabled;
}

.name-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}
