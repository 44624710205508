@import '@src/css_config/variable.scss';

.div-default {
  margin-left: 3%;
}
.disable-style {
  pointer-events: none;
  color: $skyblue_dark;
}
.veriableWidth {
  width: 320px;
  padding: 10px;
  position: relative;
}
.activeDefault {
  border-bottom: 2px solid $blue_dark;
  color: $blue_dark;
  font-weight: 600;
}
.activeDefaultSnippet {
  color: $blue_dark;
  font-weight: bold;
  border-bottom: 2px solid $blue_dark;
  height: 40px;
}
.activeCustom {
  border-bottom: 2px solid $blue_dark;
  color: $blue_dark;
  font-weight: 600;
}
.activeCustomSnippet {
  color: $blue_dark;
  font-weight: bold;
  border-bottom: 2px solid $blue_dark;
  height: 40px;
}
.EnableDefaultAssert {
  display: block;
}
.DisableDefaultAssert {
  display: none;
}
.EnableCustomAssert {
  display: block;
}
.DisableCustomAssert {
  display: none;
}
.default-assert-circle-icon {
  width: 8px !important;
  height: 8px;
  color: $green;
  position: absolute;
  top: 4px;
  right: -4px;
  z-index: 9;
}
.assert-circle-icon {
  @extend .default-assert-circle-icon;
  right: 0px;
}
.captcha-response-circle-icon {
  width: 8px !important;
  height: 8px;
  color: $green;
}
.capture-response-header-text {
  background-color: $skyblue_light;
  color: $grey_darkest;
  margin: 4px 0px 0px 0px;
  > .header-part {
    border: 1px solid $grey_light;
    flex: 0.24;
  }
  > .header-text-part {
    border: 1px solid $grey_light;
  }
}
.capture-response-title-header,
.defaultAssertTitleHeader {
  color: $blue_dark;
}
.custom-assert-header-text {
  color: $grey_darkest;
  margin-left: 20px;
  max-width: 160px;
}
.xml-path-input-two {
  top: 14px;
  position: relative;
  left: 90px;
}
.xml-path-input {
  top: 14px;
  position: relative;
  left: 90px;
}
.response-schema-input {
  top: 14px;
  position: relative;
  left: 90px;
}
.HeadersCss {
  background: $white_light 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.space-for-two-fields {
  left: 42rem;
}
.plus-symbol-for-two-fields {
  left: 25rem;
}
.default-assert-common-select-style {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid $grey_dark;
  padding-bottom: 2px;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: pre;
  color: $grey_darkest;

  -webkit-appearance: none;
  cursor: pointer;
  &:focus {
    box-shadow: none;
    background-position: right 15px;
  }
  option {
    &:checked {
      background-color: $skyblue_dark;
      color: $blue_dark;
    }
    &:not(:checked) {
      background: $white_light;
      color: $grey_dark;
    }
  }
}

.default-assert-common-down-arrow {
  background: url('data:image/svg+xml;utf8,<svg style="color: $grey_darkest" xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16"> <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/> </svg>')
    no-repeat right;
}
.default-assert-common-up-arrow {
  background: url('data:image/svg+xml;utf8,<svg style="color: $grey_darkest" xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>')
    no-repeat right;
  background-position: right 15px;
}
@media (max-width: 640px) {
  .nav-width-default-assert {
    width: 100%;
  }
  .request-body-none-center {
    visibility: hidden;
  }
  .EnableDefaultAssert {
    border: none;
  }
}
.svg-dashboard-height {
  width: 10rem;
  height: 10rem;
}
.assert-left-scrollbar {
  height: 261px;
  overflow: auto;
  background-color: $skyblue_light;
}
.default-assert-field-one {
  margin: 0px 12px 0 16px;
  position: relative;
}
.default-assert-field-one .MuiInputBase-root {
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
}
.default-assert-field-one .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-one .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}
.default-assert-field-one .MuiInputBase-root input,
.default-assert-field-response-time .MuiInputBase-root input,
.default-assert-field-json-path .MuiInputBase-root input,
.default-assert-field-json-custom-input .MuiInputBase-root input,
.default-capture-field-json-custom-input .MuiInputBase-root input,
.default-assert-field-header-key .MuiInputBase-root input,
.default-capture-field-header-key .MuiInputBase-root input,
.default-assert-field-json-name .MuiInputBase-root input {
  border-bottom: none;
  padding: initial;
}
.default-assert-field-one svg {
  position: absolute;
  right: -29px;
  top: 0px;
}
.default-assert-field-response-time {
  width: 245px;
  position: relative;
}
.default-assert-field-response-time .MuiInputBase-root {
  width: 100%;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
  margin-top: 20px;
}
.default-assert-field-response-time .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-response-time .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-assert-field-json-path {
  width: 160px;
  margin: 0px 12px 0 0px;
  position: relative;
}
.default-assert-field-json-path .MuiInputBase-root {
  width: 160px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 6px;
}
.default-assert-field-json-path .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-json-path .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-assert-field-json-path svg {
  position: absolute;
  right: -26px;
  top: 3px;
}
.default-capture-field-json-path {
  width: 280px;
  margin: 0px 12px 0 0px;
}
.default-capture-field-json-path .MuiInputBase-root {
  width: 245px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 6px;
  margin-top: 20px;
}
.default-capture-field-json-path .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-json-path .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}
.default-capture-field-json-path .MuiInputBase-root input {
  border-bottom: none;
  padding-top: 0px;
}
.default-capture-field-json-path label {
  text-align: left;
  width: 245px;
}
.default-assert-field-json-expected-input {
  width: 200px;
  margin: 0px 12px 0 0px;
  position: relative;
}
.default-assert-field-expected-input .MuiInputBase-root {
  width: 183px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 6px;
}
.default-assert-field-expected-input .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-expected-input .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}
.default-assert-field-expected-input .MuiInputBase-root input {
  border-bottom: none;
  padding-top: 0px;
}
.default-assert-field-expected-input svg {
  position: absolute;
  right: -26px;
  top: 3px;
}
.default-assert-field-json-custom-input {
  width: 170px;
  margin: 0px 16px 0 8px;
  position: relative;
}
.default-assert-field-json-custom-input .MuiInputBase-root {
  width: 160px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 6px;
  margin-left: 8px;
}
.default-assert-field-json-custom-input .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-json-custom-input .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-assert-field-json-custom-input svg {
  position: absolute;
  right: -26px;
  top: 3px;
}
.default-capture-field-json-custom-input {
  width: 200px;
  margin: 0px 0px 0px 0px;
}
.default-capture-field-json-custom-input .MuiInputBase-root {
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
  margin-top: 20px;
}
.default-capture-field-json-custom-input .MuiInput-underline .MuiInputBase-root::before,
.default-capture-field-json-custom-input .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-capture-field-json-custom-input svg {
  position: absolute;
  right: -26px;
  top: 3px;
}
.default-assert-field-name {
  width: 300px;
  margin: 0px 12px 0 40px;
}
.default-assert-field-name .MuiInputBase-root {
  width: 245px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
}
.default-assert-field-name .MuiInput-underline.MuiInputBase-root::before,
.default-assert-field-name .MuiInput-underline.MuiInputBase-root::before:hover {
  border-bottom: none;
}
.default-assert-field-name .MuiInputBase-root input {
  border-bottom: none;
  padding-top: 0px;
}
.default-assert-field-name svg {
  position: absolute;
  right: -26px;
  top: 3px;
}
.default-assert-field-header-key {
  width: 200px;
  margin: 0px 16px 0 16px;
  max-width: 260px;
}
.default-assert-field-header-key .MuiInputBase-root {
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
}
.default-assert-field-header-key .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-header-key .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-assert-field-header-key svg {
  position: absolute;
  right: 32px;
  top: 2px;
}
.default-capture-field-header-key {
  width: 220px;
  margin: 0px 0 0 16px;
}
.default-capture-field-header-key .MuiInputBase-root {
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
  margin-top: 20px;
}
.default-capture-field-header-key .MuiInput-underline .MuiInputBase-root::before,
.default-capture-field-header-key .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-capture-field-header-key svg {
  position: absolute;
  right: 36px;
  top: 7px;
}
.default-assert-field-two {
  width: 200px;
  margin: 11px 12px 0 0;
}
.assert-main-div-background-color {
  background-color: $rs_bg_whitgrey;
  height: 330px;
}
.border-in-custom-assert {
  border-right: 5px solid $rs_bg_whitgrey;
}
.assert-radio-button-customization {
  margin-right: 30px;
  display: flex;
  gap: 10px;
}
.assert-main-second-div-padding {
  height: 40px;
  position: relative;
  left: 10px;
}
.customization-of-default-assert-label {
  color: $rs_analytics_blue_header;
  font-weight: bold;
  user-select: none;
  font-size: 12px;
}
.customization-of-custom-assert-label {
  user-select: none;
  color: $grey_darkest;
  font-size: 12px;
}
.default-assert-left-checked-panel {
  position: relative;
  letter-spacing: 0;
  color: $white;
  background-color: $rs_primary;
  border: 2px solid $rs_primary;
  opacity: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  width: calc(100% - 25px);
  margin-right: 19px;
  border-right: none;
  & .MuiCheckbox-root {
    color: $white_original;

    &:hover {
      color: $white_original;
    }

    &.Mui-checked {
      color: $white_original;
    }
  }
}
.default-assert-left-checked-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -2.8px;
  transform: rotate(44deg);
  height: 84.71%;
  width: 26.5px;
  left: 100%;
  transform-origin: top left;
  background-color: $rs_primary;
  aspect-ratio: 1/1;
  border-right: 2px solid $rs_primary;
  border-top: 2px solid $rs_primary;
  border-radius: 2px;
  z-index: 1;
}
.default-assert-left-checked-panel:hover {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $light_white_50;
  opacity: 1;
}
.default-assert-left-checked-panel:hover::after {
  background-color: $rs_primary;
  border-color: $rs_primary;
}
.default-assert-left-side-panel {
  position: relative;
  letter-spacing: 0;
  color: $rs_primary;
  background-color: $skyblue_light;
  border: 2px solid $rs_primary;
  opacity: 1;
  font-size: 14px;
  width: calc(100% - 25px);
  margin-right: 19px;
  border-right: none;
  border-radius: 3px 0px 0px 3px;
}
.default-assert-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -6.68px;
  transform: rotate(45deg);
  height: 100%;
  width: auto;
  left: 97.6%;
  transform-origin: top left;
  background-color: transparent;
  aspect-ratio: 1/1;
  border-right: 2px solid $rs_primary;
  border-top: 2px solid $rs_primary;
  border-radius: 2px;
  z-index: 1;
}
.default-assert-left-side-panel:hover {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $light_white_50;
  opacity: 1;
  cursor: pointer;
  .MuiCheckbox-root.Mui-checked {
    color: $white_original;
  }
  .MuiCheckbox-root {
    color: $white_original;
  }
}
.default-assert-left-side-panel:hover::after {
  background-color: $rs_primary;
  border-color: $rs_primary;
}
.default-assert-left-side-panel-text-customize {
  width: 190px;
  display: block;
  text-align: left;
  z-index: 2;
  position: relative;
}
.verify-status-code-select {
  width: 215px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
}
.verify-status-code-select .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}
.verify-status-code-json-path {
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  min-width: 215px;
}
.verify-status-code-json-path .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}
.verify-status-code-json-path .MuiInputBase-root .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  background: none;
}
.verify-status-code-select .MuiInputBase-root .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  background: none;
}
.verify-status-code-header-key .MuiInputBase-root .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  background: none;
}
.verify-status-code-header-key {
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  min-width: 215px;
}
.verify-status-code-header-key .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}
.verify-status-code-header-key-response-body {
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  min-width: 210px;
}
.verify-status-code-header-key-response-body .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}

.verify-status-code-response-time {
  height: 100%;
  width: 292px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
}
.verify-status-code-response-time .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}
.verify-status-code-name {
  width: 245px;
  margin-left: 28px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
}
.verify-status-code-name .verify-status-specific-class-select {
  text-align: left;
  padding-left: 12px;
}
.header-default-assert {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0px;
  color: $white_original;
  background: $rs_primary 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 2px;
}

.sections-main-container .MuiInput-underline::before,
.sections-main-container .MuiInput-underline::after {
  border-width: 0px;
}
.sections-main-container .MuiInput-underline:hover:before,
.sections-main-container .MuiInput-underline:hover::after {
  border-width: 0px;
}
.sections-main-container .MuiInput-underline:hover:not(.Mui-disabled):before,
.sections-main-container .MuiInput-underline:hover:not(.Mui-disabled):after {
  border-width: 0px;
}
.default-assert-left-side-checkbox {
  border-radius: 3px;
  box-shadow: 0px 0px 3px grey;
}
.assert-section-checkbox {
  z-index: 999;
}
.default-assert-operators-list {
  margin-top: 7px;
  padding: 8px 0;
  top: 338px;
  height: 180px;
  width: 210px;
  &::-webkit-scrollbar-thumb {
    background-color: $rs_light_gray;
  }
}
.default-assert-operators-list ul li:hover,
.default-assert-operators-list ul li.Mui-selected,
.status-code-operators-list ul li:hover,
.status-code-operators-list ul li.Mui-selected,
.json-path-operators-list ul li:hover,
.json-path-operators-list ul li.Mui-selected {
  background: $rs_primary 0% 0% no-repeat padding-box !important;
  color: $white_original;
}

.status-code-operators-list {
  margin-top: 7px;
  padding: 8px 0;
  top: 338px;
  height: 180px;
  width: 215px;
  &::-webkit-scrollbar-thumb {
    background-color: $rs_light_gray;
  }
}

.json-path-operators-list {
  margin-top: 7px;
  padding: 8px 0;
  top: 338px;
  height: 180px;
  width: 215px;
  &::-webkit-scrollbar-thumb {
    background-color: $rs_light_gray;
  }
}

.default-assert-field-json-name {
  width: 160px;
  margin: 0px 16px 0 10px;
}
.default-assert-field-json-name .MuiInputBase-root {
  width: 160px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 1px grey;
  text-align: left;
  padding-left: 8px;
}
.default-assert-field-json-name .MuiInput-underline .MuiInputBase-root::before,
.default-assert-field-json-name .MuiInput-underline .MuiInputBase-root::before:hover {
  border-bottom: none;
}

.default-assert-field-json-name svg {
  position: absolute;
  right: 32px;
  top: 2px;
}
.default-assert-check-box {
  box-shadow: inset 0px 3px 6px $shadow_black;
  border: 1px solid $shawdow_grey;
  border-radius: 3px;
}
.default-assert-check-box[type='checkbox']:checked {
  background-color: $white;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='blue' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.default-assert-left-side-panel .default-assert-check-box[type='checkbox']:checked {
  background-color: $rs_primary;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.default-assert-check-box:checked::before {
  font-size: 16px;
  font-weight: 800;
  position: absolute;
  top: 7px;
  left: 5px;
}
.cdfr-left-scrollbar {
  height: 262px;
  overflow: auto;
  background-color: $skyblue_light;
}
.default-template {
  background-color: $skyblue_light;
}
.assert-tab-container-height {
  height: 16px;
}
.assert-indicator {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: left 0.3s ease-in-out;
  color: $result_passed_color;
}
