@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.errorText {
  @extend .fontPoppinsRegularXs;
  color: $ff-error;
  margin-left: 1.5px;
}

.drawer-body-height {
  overflow-y: auto;
}

.alert-custom-style {
  position: fixed;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.description-field {
  @extend .fontPoppinsMediumSm;
  padding: 16px 8px;
  text-align: left;
  letter-spacing: 0px;
  color: $ff-text;
  padding-bottom: 8px;
  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
  min-height: fit-content;
  overflow: auto;
  resize: none;
  background: none;
  border: 1px solid $ff-border;
  border-radius: 4px;
  &:focus {
    @extend .fontPoppinsMediumSm;
    border: 1px solid $ff-primary;
    box-shadow: none;
    color: $ff-text;
    text-align: left;
    letter-spacing: 0px;
    padding-bottom: 0;
  }
  &.error {
    border: 1px solid $ff-error;
  }
}

.defect-date-picker {
  :global {
    .MuiGrid-root {
      .MuiFormControl-root.MuiTextField-root {
        margin: 0;
        .MuiInputBase-root.MuiInput-root {
          padding: 8px;
          border: 1px solid $ff-border;
          border-radius: 4px;
          box-shadow: none;
        }
        .Mui-focused.MuiInputBase-root.MuiInput-root {
          border: 1px solid $ff-primary;
          box-shadow: none;
        }
        .MuiInputBase-root.MuiInput-root {
          .MuiInputBase-input.MuiInput-input {
            border: none;
            box-shadow: none;
            padding: 0;
          }
          .MuiIconButton-root {
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.defect-config-section {
  background-color: $ff-white;
  opacity: 1;
  height: calc(100vh - 65px);
}

.defect-list {
  max-height: calc(87vh - 30px);
  padding: 0 16px;
  margin-top: 16px;
  overflow-x: hidden;
}
.clickable-text {
  @extend .fontPoppinsMediumSm;
  color: $ff-primary;
}

.tooltip-style {
  width: 150px;
  height: 47px;
  text-align: center;
  :global {
    .MuiTooltip-tooltip {
      background-color: $ff-text;

      color: $ff-white;
      box-shadow: 0px 0px 10px $ff-primary;
    }
  }
}
.info-icon-style {
  height: 12px;
  width: 12px;
  &:hover {
    color: $ff-primary;
  }
}

.align-checkbox {
  .MuiFormGroup-root {
    flex-direction: row;
  }
}

.label-black {
  color: $ff-black-original !important;
  margin-right: 32px;
  margin-left: 4px;
}

.custom-check-box-style {
  :global {
    .Mui-checked {
      color: $ff_MUI_checkBox_checked_color !important;
    }
  }
}

.table-header-style{
  margin-bottom: 8px;
  background-color: $gray_light;
}

.navigation-text-color{
  color: $ff-primary;
  text-decoration: underline;
  cursor: pointer;
}
