$font-family: 'Inter', sans-serif;

$base-size: 16px;

$smallest-text: 12.64px;
$small-text: 14.22px;
$regular-text: 16px;
$large-text: 20.25px;

$small-border-radius: 2px;
