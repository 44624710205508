@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.common-filter-modal {
  color: $grey_darkest;
  .common-filter-header {
    @extend .fontPoppinsRegularMd;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    align-items: center;
    margin-top: -8px;
    background-color: $rs_primary;
    color: $white_original;
  }
  .close-icon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .close-icon:hover {
    border: 1px solid;
    cursor: pointer;
  }

  .common-filter-section {
    width: 552px;
    max-height: 350px;
    overflow: auto;
    padding: 8px;
  }
}

.common-filter-footer {
  background: $bg-filter-footer;
  padding: 5px 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -6px;
  gap: 8px;
}

.modal-arrow-icon {
  top: 35px;
  right: 7px;
  width: 10px;
  height: 10px;
  background: $rs_primary;
  transform: translateY(-50%) rotate(45deg);
  z-index: 0;
}
