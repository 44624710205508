@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';
/*Input Label Field Heading*/
.input-filed-label {
  color: $gray_dark_60;
  letter-spacing: 0px !important;
}

.input-label {
  font-size: 13px !important;
}

/*Tabs Link Buttons */
.tab-button {
  letter-spacing: 0px !important;
}

/*Tabs Links */
.tab-links-color {
  color: $grey_dark;
}

/* Label Sub Heading*/
.label-Sub-Heading {
  color: $grey_darkest !important;
  line-height: 1.25rem;
  margin-left: 32px;
}

/*+Query Parameter & +Request Header  Button */
.web-service-select-file-button {
  border: 1px solid $grey;
  color: $grey_dark;
  width: 156px;
  height: 30px;
  text-align: center;
  opacity: 1;
  border-radius: 2px;
  letter-spacing: 0px;
  &:hover {
    border-color: $blue_dark;
    color: $blue_dark;
  }
}

/* table-head */
.table-th {
  background: $light_white_50;
  color: $grey_darkest;
  height: 48px;
  padding: 0;
}
.request-form-table-head {
  background: $skyblue_dark;
  color: $grey_darkest;
  height: 35px;
  padding: 0;
}

/* Select and Choose file  */
.select-file-primary-button {
  border: 1px solid $grey_medium;
  border-radius: 2px;
  text-align: center;
  letter-spacing: 0px;
  color: $grey_dark;
  width: 104px;
  height: 26px;
  line-height: 25px;
}

/*H-5 sub-heading  */
.sub-heading {
  color: $grey_darkest !important;
  line-height: 1.25rem;
}
/* auth1-file */
.myselect-margin-label {
  margin-top: -7.5px;
}
.TextareaAutosize-oauth1 {
  width: 64vw;
  margin-top: 10px;
  border: 1px solid $grey_light;
  background-color: $white_original;
}

.gap-of-request-form-data-header {
  column-gap: 52rem;
}
.background-body-type-none {
  width: 100%;
  height: 281px;
}
.cross-icon-binary {
  right: 64rem;
}
.cross-icon-binary-less {
  right: 71rem;
}
.request-body-none-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $grey_dark;
  user-select: none;
  pointer-events: none;
}
.body-type-bold {
  color: $grey_darkest;
}
.header-margin-from-url {
  position: absolute;
  right: 236px;
  width: 200px;
}
.file-text-request-form-data {
  color: $blue_dark;
}
.request-url-form-height {
  height: 360px;
  overflow: auto;
}
.input-filed-label-request-body {
  color: $grey_dark;
}
.scroll-request-body {
  height: 295px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.auth-button-info-icon {
  margin-left: 90px;
  margin-top: -15px;
}
.auth-dropdown-select {
  width: 240px;
  color: $grey_dark;
}
.auth-type-select {
  width: 241px;
  letter-spacing: 0px;
  color: $grey_dark;
}

.select-file-disabled-btn {
  border: 1px solid $grey_light;
  border-radius: 2px;
  width: 156px;
  height: 30px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: $grey_light;
  margin-left: 10px;
}
.auth-dropdown-span-style,
.request-body-dropdown-span-style,
.request-form-data-dropdown-span-style {
  text-align: left;
  font-size: 14px;
  &:hover {
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    color: $blue_dark;
  }
}
.request-form-data-dropdown-span-style {
  width: 50px;
}
.fromData-url-table-body-scroll {
  height: 300px;
  width: 100%;
  overflow: scroll;
  position: absolute;
}
.fromData-url-table-body-scroll tr td.request-body-form-data-key {
  padding-left: 5px;
}
.fromData-url-table-body-scroll tr td.request-body-form-data-value {
  padding-left: 11px;
}
.fromData-url-table-body-scroll tr td.action-delete-add-icon-form-data {
  position: absolute;
  right: 97px;
}

.request-body-formdata-checkbox {
  position: absolute;
  left: 11px;
}
.prerequisite-auto-scroll-bar {
  overflow: auto;
  height: 330px;
}
.assert-auto-scroll-bar {
  height: 260px;
}
.custom-assert-main-container-height {
  height: 245px;
}
.custom-assert-main-container-width {
  width: 99%;
}
.max-height-in-authorization {
  height: 535px;
}
.form-data-button-one {
  position: absolute;
  left: 71rem;
}
.form-data-button-rest {
  position: absolute;
  left: 71rem;
}
.css-1mgje9x-MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.makeStyles-customTableContainer-23::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.fromData-url-table-body-scroll::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.max-height-in-authorization::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.assert-scrollbar-customize::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.captcha-data-scrollbar-customize::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.assert-data-scrollbar-customize::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.prerequisite-auto-scroll-bar::-webkit-scrollbar-thumb {
  background-color: $white_dark;
}
.captcha-data-scrollbar-customize {
  height: 291px;
  background-color: $skyblue_light;
  padding-right: 14px;
}
.assert-data-scrollbar-customize {
  height: 295px;
  background-color: $skyblue_light;
  padding-right: 14px;
}
.default-assert-section-container-main {
  height: 260px;
  border: 1px solid $grey_light;
  border-radius: 2px 2px 0px 0px;
  background: $white_original;
}
.request-url-scroll {
  overflow: auto;
  max-height: 230px;
}
.rotate-toggle-menu {
  transform: rotate(180deg);
}
.rotate-toggle-menu-span {
  width: 20px;
  height: 20px;
  background: $white_original;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
}
.default-assert-field-one > .variable-dropdown,
.default-assert-field-two > .variable-dropdown {
  margin-top: 25px;
}
.common-color-variable-selector {
  color: $gray_white;
}
.custom-assert-vertical-scroll {
  max-height: 234px;
}
.prerequisite--vertical-scroll {
  max-height: 281px;
}
.history-height-40 {
  height: 40px;
}
.width-of-975 {
  width: 975px;
}
.history-main-div-height {
  height: 40px;
  width: 30%;
}
.history-width-47 {
  width: 47%;
  &:hover {
    background: $light_gray_100;
  }
}
.callback-container {
  height: 100%;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 40%;
}
.width-of-oath1-container {
  width: 100%;
}
.header-margin-from-url-encoded {
  position: absolute;
  right: 236px;
  width: 280px;
}
.assert-result-expand-collapse {
  height: 328px;
}
.response-section-expand-height {
  height: 400px;
}
.disabled-curl-btn {
  color: #d2d2d2;
  cursor: not-allowed;
}
.common-hover-effect {
  &:hover {
    color: $primary;
    background-color: $white_original;
    border-radius: 50%;
  }
}
.none-body-border {
  border: 1px solid;
  padding: 40px 100px 40px 100px;
}
.enable-common-hover-effect {
  color: $light_blue;
  &:hover {
    background-color: $white_original;
    color: $rs_analytics_blue_header;
    border-radius: 50%;
    box-shadow: 4px 20px 23px 0px $deep_black;
  }
}
.toggle-button-slider-type {
  text-align: left;
}
.toggle-button-slider-type > .MuiToggleButtonGroup-root {
  margin-left: 31px;
  background: $white_original 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 1px grey;
  opacity: 1;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  &:hover {
    background-color: $transparent;
  }
}
.toggle-button-slider-type .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  font-family: 'Poppins-Medium';
  text-transform: capitalize;
  font-size: small;
}
.toggle-button-slider-type .MuiToggleButtonGroup-root button {
  padding: 3px 10px;
}
.delete-icon-hover-effect,
.plus-icon-hover-effect {
  @extend .common-hover-effect;
}
.request-name-textfield > input {
  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}
.request-name-textfield > .common-input-field {
  width: 300px;
  height: 35px;
  border: 1px solid $light_gray_50;
  border-radius: 4px;
  padding: 8px 9px;
  @extend .fontPoppinsRegularSm;
  background-color: $white_original;
  &:focus {
    border: 1px solid $light_gray_50;
    padding: 8px 9px;
    @extend .fontPoppinsRegularSm;
  }
  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}
.common-input-mouse-over input[type='text'],
.common-input-mouse-over input[type='checkbox'] {
  background-color: $skyblue_dark;
  box-shadow: none;
}
.common-input-mouse-out input[type='text'],
.common-input-mouse-out input[type='checkbox'] {
  background-color: $white_original;
  box-shadow: none;
}

.request-name-textfield > .common-urlinput-field {
  width: 300px;
  height: 35px;
  border: 1px solid $light_gray_50;
  border-radius: 4px;
  padding: 9px 8px;
  @extend .fontPoppinsRegularSm;
  &:focus {
    border: 1px solid $light_gray_50;
    padding: 9px 8px;
    @extend .fontPoppinsRegularSm;
  }
  &::placeholder {
    @extend .fontPoppinsRegularSm;
  }
}
.request-url-input {
  width: 300px;
  height: 35px;
  border: 1px solid $light_gray_50;
  border-radius: 7px;
  padding: 0 0 0 5px;
}
.table-header-for-webservice {
  @extend .fontPoppinsRegularSm;
  color: $rs_primary;
}
#DataRequestBodyGrid .table-header-for-webservice.action-items-column-header {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  right: 131px;
}
.min-width-3 {
  min-width: 3rem;
}
.common-input-field-url-form {
  width: 300px;
  height: 35px;
  border: 1px solid $light_gray_50;
  border-radius: 7px;
  padding: 0 0 0 5px;
  &:focus {
    border: 1px solid $light_gray_50;
  }
}

.request-url-encoded-actions {
  top: 13px;
  padding-left: 54px;
}
.history-section-search-icon {
  color: $rs_analytics_blue_header;
  background: $light_white_50;
  border-radius: 50px;
}
.history-tab-text-color {
  color: $rs_analytics_blue_header;
}
.common-table-header-row-for-snippet-history {
  background-color: $light_white_50;
  color: $rs_analytics_blue_header;
  font-size: 12px;
  @extend .fontPoppinsSemiboldMd;
}
.common-table-body-row-for-snippet-history {
  background-color: $white_original;
}
.my-input-style-for-authorization {
  width: 295px;
  border: 1px solid $light_gray_50 !important;
  height: 35px;
  border-radius: 5px;
  padding: 9px 8px;
  padding-bottom: 9px !important;
}
.basic-auth-form-style {
  width: 620px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.basic-auth-error-input-alignment {
  margin-top: 5px;
  margin-left: 50px;
}
.auth-left-side-panel {
  position: relative;
  letter-spacing: 0;
  color: $rs_primary;
  background: $rs_bg_whitgrey;
  border: 2px solid $rs_primary;
  opacity: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  width: calc(100% - 25px);
  margin-right: 25px;
  border-right: none;
}

.auth-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  transform: rotate(44deg);
  height: 84.71%;
  width: 26.5px;
  left: 100%;
  transform-origin: top left;
  background-color: $rs_bg_whitgrey;
  aspect-ratio: 1/1;
  border-right: 2px solid $rs_primary;
  border-top: 2px solid $rs_primary;
  border-radius: 2px;
  z-index: 1;
}
.active-auth1-left-side-panel,
.auth1-left-side-panel {
  position: relative;
  letter-spacing: 0;
  color: $rs_primary;
  background: $rs_bg_whitgrey;
  border: 1px solid $rs_primary;
  opacity: 1;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  width: calc(100% - 20px);
  margin-right: 30px;
  border-right: none;
}
.auth2-left-side-panel-Auth-code,
.active-auth2-left-side-panel-Auth-code {
  position: relative;
  letter-spacing: 0;
  color: $rs_primary;
  background: $rs_bg_whitgrey;
  border: 1px solid $rs_primary;
  opacity: 1;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  width: calc(100% - 30px);
  margin-right: 30px;
  border-right: none;
}
.auth2-left-side-panel-Auth-code::after,
.active-auth2-left-side-panel-Auth-code::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  transform: rotate(47deg);
  height: 71%;
  width: 32px;
  left: 100%;
  transform-origin: top left;
  background-color: $rs_bg_whitgrey;
  aspect-ratio: 1/1;
  border-right: 1px solid $rs_primary;
  border-top: 1px solid $rs_primary;
  border-top-right-radius: 2px;
  z-index: 1;
}
.auth2-left-side-panel-Auth-code:hover {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $white_original;
  opacity: 1;
}
.auth2-left-side-panel-Auth-code:hover::after {
  background-color: $rs_primary;
  border-color: $white_original;
}
.auth1-left-side-panel::after,
.active-auth1-left-side-panel::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  transform: rotate(48deg);
  height: 73%;
  width: 17px;
  left: 100%;
  transform-origin: top left;
  background-color: $rs_bg_whitgrey;
  aspect-ratio: 1/1;
  border-right: 1px solid $rs_primary;
  border-top: 1px solid $rs_primary;
  border-top-right-radius: 2px;
  z-index: 1;
}
.auth-left-side-panel:hover {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $rs_bg_whitgrey;
  opacity: 1;
}
.auth1-left-side-panel:hover {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $white_original;
  opacity: 1;
}
.active-tab-in-auth1,
.active-tab-in-auth2 {
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $white_original;
}
.active-tab-in-auth1::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  transform: rotate(48deg);
  height: 73%;
  width: 17px;
  left: 100%;
  transform-origin: top left;
  aspect-ratio: 1/1;
  border-right: 1px solid $rs_primary;
  border-top: 1px solid $rs_primary;
  border-top-right-radius: 2px;
  z-index: 1;
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $white_original;
}
.active-tab-in-auth2::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  transform: rotate(48deg);
  height: 73%;
  width: 32px;
  left: 100%;
  transform-origin: top left;
  aspect-ratio: 1/1;
  border-right: 1px solid $rs_primary;
  border-top: 1px solid $rs_primary;
  border-top-right-radius: 2px;
  z-index: 1;
  background: $rs_primary 0% 0% no-repeat padding-box;
  color: $white_original;
}
.auth-left-side-panel:hover::after {
  background-color: $rs_primary;
  border-color: $rs_primary;
}
.auth1-left-side-panel:hover::after {
  background-color: $rs_primary;
  border-color: $rs_primary;
}
.header-in-authorization {
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  color: $white_original;
  background: $rs_primary 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}
.input-icon-left-300 {
  left: 300px;
}

.common-modal-input {
  border: 1px solid $light_gray_50 !important;
  border-radius: 6px;
  opacity: 1;
  width: 417px;
  height: 35px;
  padding: 0 10px 0 10px;
}
.save-api-input {
  border: 1px solid $light_gray_50 !important;
  border-radius: 6px;
  opacity: 1;
  width: 450px;
  height: 35px;
  padding: 0 10px 0 10px;
}
.modal-footer-container {
  position: fixed;
  bottom: 0;
  width: 460px;
}
.modal-close-btn {
  @extend .fontMontserratMediumXs;
  border: 1px solid $gray_40;
  opacity: 1;
  color: $gray_40;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  border-radius: 5px;
  margin: 5px 20px;
  line-height: 1;
  &:hover {
    background: $gray_44 0% 0% no-repeat padding-box;
    color: $white_original;
  }
}
.modal-save-btn {
  @extend .fontMontserratMediumXs;
  letter-spacing: 0px;
  color: $rs_primary;
  border: 1px solid $rs_primary;
  border-radius: 4px;
  padding: 6px 12px;
  height: 25px;
  font-weight: 500;
  margin: 5px 12px 0 0;
  line-height: 1;
  &:hover {
    background: $rs_primary 0% 0% no-repeat padding-box;
    color: $white_original;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    border: 1px solid $ff-primary;
    border-radius: 5px;
    background-color: unset;
    cursor: none;
    color: $ff-primary;
  }
}
.modal-footer-section {
  height: 5.5%;
  background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 10px;
  opacity: 1;
}
.variable-details-key {
  @extend .fontPoppinsMediumSm;
  color: $rs_blue;
  padding: 0 20px 0 20px;
}
.variable-details-value {
  @extend .fontPoppinsRegularSm;
  color: $grey_darkest;
  padding: 0 20px 0 20px;
}
.variable-details-value-sub {
  width: 205px;
}
.input-style-for-auth1 {
  border: 1px solid $light_gray_50 !important;
  border-radius: 5px;
  padding: 9px 8px;
  padding-bottom: 9px !important;
  width: 185px;
  height: 32px;
}
.input-style-for-auth2-common {
  @extend .input-style-for-auth1;
  width: 300px;
}
.my-input-style-for-authorization-auth2 {
  @extend .input-style-for-auth1;
  width: 250px;
}
.aws-main-container {
  width: 99%;
  height: 325px;
  overflow: scroll;
}
.common-text-color-in-request {
  font-size: 12px;
  color: $gray_dark_60;
}
.common-heading-color-in-request {
  color: $gray_44;
  font-size: 12px;
}
.auth1-container-layout {
  height: 300px;
  background-color: $rs_bg_whitgrey;
  position: relative;
  left: 8px;
  transform: scale(0.99);
}
.auth1-container-sub-layout {
  width: 17%;
  height: 100%;
}
.auth-left-side-container {
  margin-left: 20px;
  margin-top: 25px;
}
.auth-white-background-container {
  width: 70%;
  height: 93%;
  flex: 1;
  border: 1px solid $white_dark_50;
  background-color: $white_original;
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.auth1-textarea-width {
  width: 60vw;
}
.auth-white-background-sub-container {
  padding: 10px;
  height: 247px;
  overflow-y: auto;
}
.auth-white-background-sub-container::-webkit-scrollbar,
.auth2-white-background-container::-webkit-scrollbar {
  height: 4px;
}
.RSA-section-alignment {
  grid-column: 3/5;
  display: flex;
  justify-content: left;
  align-items: center;
}
.auth2-container-layout {
  background-color: $rs_bg_whitgrey;
  padding: 2px 8px 4px 8px;
  position: relative;
  top: 48px;
  right: 3px;
}
.borderStyle3-oath2 {
  grid-column: 1/4;
  margin-top: 2px;
}
.left-side-pane-in-auth2 {
  width: 192px;
}
.flex1-div-in-auth2 {
  flex: 1;
}
.auth2-white-background-container {
  height: 185px;
  overflow-y: auto;
  background-color: $white_original;
  border: 1px solid $white_dark_50;
  padding: 10px;
}
.auth2-row-gap {
  grid-row-gap: 5px;
}
.flex-alignment-of-auth2-input {
  width: 324px;
  display: flex;
}
.alignment-of-request-token-button {
  grid-column: 1/4;
  display: flex;
  justify-content: flex-end;
}
.auth2-left-side-margin {
  margin-left: 20px;
  margin-top: 5px;
}
.hawk-container {
  width: 99%;
  height: 325px;
  overflow: scroll;
}

.request-url-table {
  height: 316px;
  overflow: auto;
}
.delete-enable-effect {
  color: $grey_dark;
  opacity: 0.9;
  padding: 2px;

  &:hover {
    color: $primary;
    background-color: $white_original;
    border-radius: 50%;
    box-shadow: 2px 10px 15px $primary;
  }
}
.assert-cdfr-delete-enable-effect {
  @extend .delete-enable-effect;
  display: flex;
}

.plus-enable-effect {
  color: $grey_dark;
  opacity: 0.9;
  padding: 2px;

  &:hover {
    color: $primary;
    background-color: $white_original;
    border-radius: 50%;
    box-shadow: 2px 10px 15px $primary;
  }
}
.assert-cdfr-add-enable-effect {
  @extend .plus-enable-effect;
  display: flex;
}

.request-url-actions {
  position: absolute;
  top: 10px;
  left: 56px;
}
.response-success,
.response-failure,
.response-skipped {
  @extend .fontMontserratMediumXs;
  color: $white_original;
  height: 25px;
  border-radius: 5px;
  padding: 6px 12px;
  line-height: 1;
  font-weight: 500;
}
.response-success {
  background-color: $success;
}
.response-failure {
  background-color: $red;
}
.response-skipped {
  background-color: $grey_dark;
}
.custom-assert-snippet-span {
  width: 20px;
  height: 20px;
  background: $white_original;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
}
.request-url-count {
  position: absolute;
  top: 0;
  right: -10px;
}
.request-url-total {
  position: relative;
  right: 18px;
}
.common-password-icon-hover-effect {
  &:hover {
    background-color: $white_original;
    border-radius: 50%;
    box-shadow: 4px 20px 23px 0px $deep_black;
  }
}
.common-auth-star-symbol {
  color: $rs_analytics_fail;
}
.advanced-text-style {
  color: $gray_44;
  @extend .fontPoppinsRegularSm;
}
.selected-authTypeValue {
  color: $rs_analytics_blue_header;
  @extend .fontPoppinsSemiboldSm;
}
.smaller-radio .css-vqmohf-MuiButtonBase-root-MuiRadio-root,
.smaller-radio .css-1a5icme {
  padding: 0px !important;
}
.normal-authTypeValue {
  color: $grey_darkest;
  @extend .fontPoppinsRegularSm;
}
.fill-available-oath1 {
  width: '-webkit-fill-available';
}
.variable-form {
  height: 317px;
  border-bottom: 2px dashed $border_gray_200;
}
.hover-effect-on-history {
  padding: 1.5px;
  &:hover {
    background-color: $white_original;
    cursor: pointer;
    color: $primary;
    border-radius: 50%;
  }
}
.choose-file-header {
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
  margin-top: 10px;
}
.width-380 {
  width: 380px;
}
.customize-beautify-button {
  border: 0.5px solid $grey_dark;
  padding: 7px 5px 8px 6px;
  box-shadow: 0px 0px 1px $deep_black;
  height: 30px;
  width: 30px;
  border-radius: 2px;
  &:hover {
    border: 0.5px solid $rs_analytics_blue_header;
    padding: 5px 5px 6px 6px;
  }
}
.customize-beautify-disable-button {
  border: 0.5px solid $grey_dark;
  padding: 7px 5px 8px 6px;
  height: 30px;
  width: 30px;
  border-radius: 2px;
}
.assert-tab-width {
  width: 120px;
}
.compile-save-button-customization {
  height: 25px;
}

.assert-section-errors {
  @extend .fontPoppinsRegularXs;
  color: $rs_analytics_fail;
  margin-left: 2px;
  text-align: left;
}
.request-parameter-checkbox {
  box-shadow: inset 0px 3px 6px $shadow_black;
  border: 1px solid $shawdow_grey;
  border-radius: 3px;
  outline: none;
}
.request-parameter-checkbox[type='checkbox']:checked,
input[type='checkbox']:indeterminate {
  background-color: $rs_primary;
  &:hover {
    background-color: $rs_primary;
  }
}
.request-parameter-checkbox[type='checkbox']:focus {
  --tw-ring-color: none;
  box-shadow: inset 0px 3px 6px $shadow_black;
}
.request-parameter-checkbox[type='checkbox']:focus:checked {
  background-color: $rs_primary;
}
.assert-left-scrollbar::-webkit-scrollbar,
.cdfr-left-scrollbar::-webkit-scrollbar,
.custom-assert-vertical-scroll::-webkit-scrollbar,
.prerequisite--vertical-scroll::-webkit-scrollbar,
.query-param-scroll::-webkit-scrollbar,
.fromData-url-table-body-scroll::-webkit-scrollbar,
.request-url-scroll::-webkit-scrollbar,
.prerequisite-main-container-sub-height::-webkit-scrollbar,
.prerequisiteEditor-height::-webkit-scrollbar,
.auth-white-background-sub-container::-webkit-scrollbar,
.auth2-white-background-container::-webkit-scrollbar,
.assertEditor-height::-webkit-scrollbar,
.prerequisite-ace-container-open-height::-webkit-scrollbar,
.prerequisiteEditor-height-with-console-expand::-webkit-scrollbar,
.capture-response-data::-webkit-scrollbar,
.default-assert-data::-webkit-scrollbar,
.Response-header-scrollbar::-webkit-scrollbar,
.left-side-panel-width::-webkit-scrollbar,
.Local-view-variable::-webkit-scrollbar,
.cdfr-dropdown::-webkit-scrollbar,
.assert-variable-dropdown::-webkit-scrollbar,
.assertEditor-height-with-console-expand::-webkit-scrollbar,
.ace_scrollbar.ace_scrollbar-h::-webkit-scrollbar,
.variable-dropdown::-webkit-scrollbar,
.variable-dropdown-small::-webkit-scrollbar,
.variable-dropdown-medium::-webkit-scrollbar,
.variable-dropdown-url-encoded::-webkit-scrollbar,
.variable-dropdown-form-data::-webkit-scrollbar,
.ace_scrollbar.ace_scrollbar-v::-webkit-scrollbar,
.request-url-table::-webkit-scrollbar,
.captured-response-scrollbar::-webkit-scrollbar,
.variable-dropdown-bearer-token::-webkit-scrollbar,
.variable-dropdown-jwt-bearer::-webkit-scrollbar {
  background-color: $light_white;
  border-radius: 50px;
}
.variable-dropdown-jwt-bearer::-webkit-scrollbar .variable-dropdown-api-key::-webkit-scrollbar {
  width: 6px;
}
.assert-left-scrollbar::-webkit-scrollbar-thumb,
.cdfr-left-scrollbar::-webkit-scrollbar-thumb,
.custom-assert-vertical-scroll::-webkit-scrollbar-thumb,
.prerequisite--vertical-scroll::-webkit-scrollbar-thumb,
.custom-assert-vertical-scroll::-webkit-scrollbar-thumb,
.query-param-scroll::-webkit-scrollbar-thumb,
.fromData-url-table-body-scroll::-webkit-scrollbar-thumb,
.request-url-scroll::-webkit-scrollbar-thumb,
.prerequisite-main-container-sub-height::-webkit-scrollbar-thumb,
.prerequisiteEditor-height::-webkit-scrollbar-thumb,
.auth-white-background-sub-container::-webkit-scrollbar-thumb,
.auth2-white-background-container::-webkit-scrollbar-thumb,
.assertEditor-height::-webkit-scrollbar-thumb,
.prerequisite-ace-container-open-height::-webkit-scrollbar-thumb,
.prerequisiteEditor-height-with-console-expand::-webkit-scrollbar-thumb,
.capture-response-data::-webkit-scrollbar-thumb,
.default-assert-data::-webkit-scrollbar-thumb,
.Response-header-scrollbar::-webkit-scrollbar-thumb,
.left-side-panel-width::-webkit-scrollbar-thumb,
.Local-view-variable::-webkit-scrollbar-thumb,
.cdfr-dropdown::-webkit-scrollbar-thumb,
.assert-variable-dropdown::-webkit-scrollbar-thumb,
.assertEditor-height-with-console-expand::-webkit-scrollbar-thumb,
.ace_scrollbar.ace_scrollbar-h::-webkit-scrollbar-thumb,
.variable-dropdown::-webkit-scrollbar-thumb,
.variable-dropdown-small::-webkit-scrollbar-thumb,
.variable-dropdown-medium::-webkit-scrollbar-thumb,
.variable-dropdown-url-encoded::-webkit-scrollbar-thumb,
.variable-dropdown-form-data::-webkit-scrollbar-thumb,
.ace_scrollbar.ace_scrollbar-v::-webkit-scrollbar-thumb,
.captured-response-scrollbar::-webkit-scrollbar-thumb,
.variable-dropdown-extra-small::-webkit-scrollbar-thumb,
.request-url-table::-webkit-scrollbar-thumb,
.variable-dropdown-bearer-token::-webkit-scrollbar-thumb,
.variable-dropdown-jwt-bearer::-webkit-scrollbar-thumb .variable-dropdown-api-key::-webkit-scrollbar-thumb {
  background-color: $rs_light_gray;
  border-radius: 6px;
}

.auth1-textfield-section-width {
  width: 210px;
}

.ace_editor {
  line-height: 20px;
  letter-spacing: 1px;
}
.disable-checkbox-bg-color {
  background-color: $rs_primary;
  opacity: 0.5;
}

.my-password-style-for-authorization {
  @extend .my-input-style-for-authorization;
  padding: 9px 36px 9px 8px;
}
.common-input-hover-height {
  height: 45px;
}
.view-variable-masked-checkbox[type='checkbox']:checked {
  background-color: $check_box_grey;
}
.edit-variable-hyperlink-color {
  color: $rs_primary;
}
.view-variable-hyperlink-color {
  @extend .edit-variable-hyperlink-color;
}

.capture-assign-step-label,
.capture-name-text-label {
  color: $gray_dark_60;
  zoom: 1.3;
}
.request-url-form-key {
  height: 45px;
}
.inherit-from-parent-border {
  width: 100%;
  border: 1px solid;
  padding: 25px 40px;
}
.inherit-from-parent-link {
  color: $ff-primary;
}
.inherit-from-parent-text {
  color: $ff-text;
}
.variable-container-bg-color {
  background-color: $ff-white;
}
.ace_editor .ace_placeholder {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  pointer-events: none;
  color: $ff-placeholder;
  z-index: 1;
  @extend .fontPoppinsRegularSm;
}
.plus-disable-effect {
  color: $ff-border;
  padding: 2px;
}
.curl-modal-footer {
  @extend .modal-footer-section;
  height: 35px;
}
.webservice-context-menu-data {
  @extend .fontPoppinsRegularSm;
  width: 135px;
}
.webservice-context-menu-data-hover:hover {
  background-color: $rs_primary;
  color: $white_original;
}
