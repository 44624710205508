@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

.update-label-script-field {
  .autocomplete-search {
    .MuiAutocomplete-hasPopupIcon {
      margin: 8px;
      margin-left: 0px;
      width: 96%;
    }
    .common-label-text {
      margin-left: -12px;
      color: $rs_label;
      @extend .fontPoppinsMediumSm;
    }
  }
}
