@import '@styles/_variables.scss';

.right-left-icon {
  height: 26px;
  width: 26px;
  box-shadow: 0px 0px 6px $rs_black;
  top: 12px;
}
@keyframes slide-from-right {
  0% {
    margin-right: 90%;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}
.collapsed-circle {
  @extend .right-left-icon;
  animation: slide-from-right 0.2s ease-in;
}

@keyframes slide-from-left {
  0% {
    margin-left: 10%;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}
.left-circle {
  @extend .right-left-icon;
  animation: slide-from-left 0.1s ease-in;
}
.fire-flink-logo {
  top: 6px;
  position: relative;
  left: -44px;
}
.fire-flink-collapsed-logo {
  cursor: pointer;
  width: 40px;
  top: 16px;
  position: fixed;
  left: 16px;
}
