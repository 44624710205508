@import '@styles/rs_fonts.scss';

.public-DraftEditor-content {
  min-height: 133px;
  padding: 0 8px;
}

.rdw-link-modal {
  height: 230px;
}

.heading-TextEditor-style {
  h1 {
    font-size: 2em;
    font-weight: bolder;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bolder;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bolder;
  }

  h4 {
    font-size: 1em;
    font-weight: bolder;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bolder;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bolder;
  }

  .rdw-right-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }

  .rdw-left-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }

  .rdw-center-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }

  .rdw-justify-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }

  .rdw-right-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }

  .rdw-left-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }

  .rdw-center-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }

  .rdw-justify-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }
}

.toolbarClass {
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.editorClass {
  @extend .fontPoppinsRegularMd;
}
