@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

$description-color: cadetblue;
$description-border: #dbd8d8;

.input-field {
  div {
    input[type='text'] {
      padding-bottom: 1px;
      @extend .fontPoppinsRegularMd;

      &:focus {
        padding-bottom: 2px;
      }
    }
  }
}

.feild-height {
  height: 56px;
}

.row-cell {
  width: 250px;
}

.modal-height {
  height: 500px;
  overflow-y: auto;
}

.description-field {
  min-height: fit-content !important;
  overflow: auto;
  resize: none;
  background: none;
  font-size: 12px;
  border: 1px solid $description-border;
  border-radius: 4px;
}

.description-field:focus {
  border: 1px solid $description-border;
}
.count-pill {
  background-color: $grey_light;
  color: $grey_white;
  margin-left: 8px;
  border-radius: 4px;
  padding: 3px 1px;
}

.select-dropdown {
  width: 100%;
  margin-left: 3px;
  color: $grey_dark;

  div {
    &.react-dropdown-select-dropdown {
      &.react-dropdown-select-dropdown-position-bottom {
        width: 100%;
        border-radius: 8px;
        max-height: 210px;
      }
    }
  }
}

.hide-characters {
  -webkit-text-security: disc;
}

.jira-chip {
  border: 1px solid $grey_dark !important;
  border-radius: 20px !important;
  margin: 2px !important;
  &.chip-disabled {
    :global {
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon {
        color: $ff-disable;

        &:hover {
          color: $ff-disable;
        }
      }
    }
  }
}

.more-count-button {
  border: 1px solid $rs_primary !important;
  border-radius: 20px;
  color: $rs_primary;
  padding: 6px;
}
