@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';

$lineColor: rgba(22, 72, 198, 0.22);
$lineHoverColor: $blue_dark;

.table-tree {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  .version-text-color {
    color: $grey_medium;
  }

  .locator-count-style {
    color: $blue_dark;
  }

  // Show Hide all conditions
  li {
    &.show-conditions {
      display: inherit;
    }

    &.hide-conditions {
      display: none;
    }
  }

  // Hide Tree icons
  .fancytree-ico-ef span.fancytree-icon {
    display: none;
  }

  span.fancytree-icon {
    display: none;
  }

  .fancytree-folder .fancytree-title {
    @extend .fontPoppinsSemiboldLg;
  }

  .tree-header-wrp {
    @extend .fontPoppinsSemiboldMd;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $skyblue_dark;
    height: 48px;
    color: $grey_darkest;

    .tree-header {
      margin: 0 12px;
      background: transparent;

      &.title {
        flex: 1;
      }
    }
  }

  .tree-root-wrp {
    @extend .fontPoppinsRegularLg;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    color: $grey_darkest;

    .root-title-wrp {
      margin: 0 12px;
      background: transparent;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;

      > span {
        padding: 0 !important;
        margin: 9px !important;
      }

      button {
        svg {
          display: inline-block;
        }
      }

      .root-header {
        color: $grey;
        font-weight: bold;
        display: inline-flex;
        max-width: calc(100% - 100px);
        margin: -7px;
        align-items: center;
      }

      .root-check-all {
        &.MuiCheckbox-colorPrimary:hover {
          background-color: transparent;
        }
      }
    }

    .root-header {
      margin: 0 12px;
      font-size: 16px;

      a.root-title {
        @extend .fontPoppinsRegularLg;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        letter-spacing: 0px;
        color: $blue;
        opacity: 1;
        margin-right: 4px;
        margin-left: -11px;
      }
    }

    .root-check-all {
      color: $blue_dark;
    }

    .root-btn {
      color: $blue_dark;
      margin: 0 10px 0 0;

      &:focus {
        outline: 0;
      }
    }
  }

  .no-data-msg {
    display: none;
  }

  ul.fancytree-container {
    padding: 0;
    background-color: transparent;
  }

  .fancytree-plain span.fancytree-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  .fancytree-plain span.fancytree-node:hover span.fancytree-title {
    background-color: transparent;
    border: none;
  }

  .fancytree-plain span.fancytree-active span.fancytree-title,
  .fancytree-plain span.fancytree-selected span.fancytree-title {
    background-color: transparent;
    border: none;
  }

  .fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-active span.fancytree-title,
  .fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-selected span.fancytree-title {
    background-color: transparent;
  }

  .fancytree-connectors.fancytree-container li {
    border-left: 1px solid $lineColor;
    background: none;
    margin-left: 30px;
  }

  .fancytree-mock-node-wrapper span.fancytree-title {
    cursor: default;
    text-decoration: none;
  }

  .fancytree-connectors .fancytree-exp-n span.fancytree-expander,
  .fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
    background: none;
    border-bottom: 1px solid $lineColor;
    height: 5px;
    margin-left: 0;
    width: 28px;
  }

  .fancytree-connectors .fancytree-exp-nl span.fancytree-expander,
  .fancytree-connectors .fancytree-exp-nl span.fancytree-expander:hover {
    background: none;
    border-bottom: 1px solid $lineColor;
    border-left: 1px solid $lineColor;
    height: 24px;
    position: relative;
    top: -9px;
    margin-left: 0;
    width: 28px;
  }

  .fancytree-exp-el span.fancytree-expander,
  .fancytree-exp-edl span.fancytree-expander,
  .fancytree-exp-cl span.fancytree-expander,
  .fancytree-exp-cl span.fancytree-expander:hover {
    // border-bottom: 1px solid $blue_dark;
    border-left: 1px solid $lineColor;
    position: relative;
    top: -10px;
    height: 34px;
    margin-left: 0;
    padding-left: 10px;
    width: 28px;
    margin-top: 0;
  }

  .fancytree-unselectable span.fancytree-checkbox {
    cursor: not-allowed !important;
  }

  .fancytree-exp-c span.fancytree-expander {
    background: url(./skin/icons/arrow-right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 26px;
  }

  .fancytree-exp-e span.fancytree-expander,
  .fancytree-exp-ed span.fancytree-expander {
    background: url(./skin/icons/arrow-down.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 26px;
  }

  .expand-data-loading {
    .fancytree-exp-e span.fancytree-expander,
    .fancytree-exp-ed span.fancytree-expander {
      background: url(./skin/icons/Spin-1s-22px.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px;
    }

    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander {
      background: url(./skin/icons/Spin-1s-22px.svg);
      background-position: 6px 16px;
      background-repeat: no-repeat;
      background-size: 20px;
    }

    .fancytree-selected span.fancytree-expander {
      background: url(./skin/icons/Spin-1s-22px.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px;
    }

    .fancytree-selected {
      &.fancytree-exp-cl span.fancytree-expander {
        background: url(./skin/icons/Spin-1s-22px.svg);
        background-position: 6px 16px;
        background-repeat: no-repeat;
        background-size: 20px;
      }
    }
  }

  .fancytree-exp-c span.fancytree-expander,
  .fancytree-exp-e span.fancytree-expander,
  .fancytree-exp-ed span.fancytree-expander {
    position: relative;

    &::before {
      width: 10px;
      height: 1px;
      background: $lineColor;
      content: '';
      line-height: 1px;
      display: inline-block;
      position: absolute;
      top: 7px;
      left: -11px;
    }
  }

  .fancytree-exp-cl span.fancytree-expander,
  .fancytree-exp-cl span.fancytree-expander:hover {
    background: url(./skin/icons/arrow-right.svg);
    background-position: 6px 15px;
    background-repeat: no-repeat;
    background-size: 26px;

    &:hover {
      background-position: 6px 15px !important;
    }
  }

  .fancytree-exp-el span.fancytree-expander,
  .fancytree-exp-edl span.fancytree-expander {
    background: url(./skin/icons/arrow-down.svg);
    background-position: 6px 16px;
    background-repeat: no-repeat;
    background-size: 26px;

    &:hover {
      background-position: 6px 16px !important;
    }
  }

  .fancytree-exp-cl span.fancytree-expander,
  .fancytree-exp-el span.fancytree-expander,
  .fancytree-exp-edl span.fancytree-expander {
    position: relative;

    &::before {
      width: 10px;
      height: 6px;
      border-top: 1px solid $lineColor;
      background: $white_light;
      content: '';
      line-height: 1px;
      display: inline-block;
      position: absolute;
      top: 28px;
      left: -1px;
    }
  }

  ul.fancytree-container li.fancytree-lastsib,
  ul.fancytree-no-connector > li {
    border-left: 0;
  }

  .fancytree-ext-filter-hide span.fancytree-node.fancytree-submatch span.fancytree-title {
    color: $blue;
    font-weight: lighter;
  }
  .fancytree-ext-filter-hide span.fancytree-node.fancytree-match span.fancytree-title {
    color: $blue;
    font-weight: normal;
  }

  // .fancytree-has-children.fancytree-ico-cf span.fancytree-icon,
  // .fancytree-ico-ef span.fancytree-icon {
  //   display: none;
  //   background: none;
  // }
  .fancytree-node {
    @extend .fontPoppinsRegularMd;
    display: flex;
    align-items: center;
    height: 42px;
    justify-content: flex-start !important;

    .tree-title {
      flex: 1;
      vertical-align: middle;
      align-items: center;
      display: flex;
    }

    .fancytree-title-wrapper {
      line-height: 40px;
    }

    &:hover {
      background-color: rgba(60, 154, 255, 0.06);
    }

    .fancytree-expander,
    .fancytree-icon {
      width: 18px;
    }

    .fancytree-expander {
      margin-left: 10px;
      margin-top: 0;
    }

    .fancytree-title-wrapper {
      .fancytree-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }

    .node-col {
      margin: 0 12px;
      font-size: 13px;
    }
  }

  ul.fancytree-container ul {
    padding: 0 0 0 3px;
  }

  .fancytree-exp-e span.fancytree-expander,
  .fancytree-exp-c span.fancytree-expander {
    &:hover {
      background-position: center center !important;
    }
  }

  // Checkbox
  span.fancytree-checkbox {
    background: url(./skin/icons/cb-normal.svg) no-repeat center center;
    background-position: 0 0;
    margin: 2px 0 0 0;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
      background-position: 0 0 !important;
    }
  }

  .fancytree-partsel span.fancytree-checkbox {
    background: url(./skin/icons/cb-partsel.svg) no-repeat center center;
  }

  .fancytree-selected span.fancytree-checkbox {
    background: url(./skin/icons/cb-checked.svg) no-repeat center center;
  }

  // Radio Button
  span.fancytree-checkbox.fancytree-radio {
    background: url(./skin/icons/radio_button_unchecked_black_48dp.svg) no-repeat center center;
    background-position: 0 0;
    margin: 2px 0 0 0;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
      background-position: 0 0 !important;
    }
  }

  .fancytree-selected span.fancytree-checkbox.fancytree-radio {
    background: url(./skin/icons/radio_button_checked.svg) no-repeat center center;
  }

  // Pre-load Checbox and RadioButton related Images
  body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(./skin/icons/cb-normal.svg) url(./skin/icons/cb-partsel.svg) url(./skin/icons/cb-checked.svg)
      url(./skin/icons/radio_button_unchecked_black_48dp.svg) url(./skin/icons/radio_button_checked.svg)
      url(./skin/icons/arrow-right.svg) url(./skin/icons/arrow-down.svg);
  }

  .state-button {
    vertical-align: top;
    background-color: transparent;
    color: $blue_dark;

    &:hover {
      background-color: transparent;
      color: $blue;
    }

    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: 0.22;
      pointer-events: auto;
    }
  }

  .state-col-wrp {
    border: 1px solid #efecfc;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    padding: 4px;
    width: inherit;

    button {
      margin: 0 4px;
      font-weight: 300;
      font-size: 10px;
      color: $grey_dark;
      display: inline-block;
      vertical-align: top;
      padding: 4px 5px;
      border-radius: 0;

      &.state-active {
        color: $blue_dark;
      }
    }
  }

  .count-badge {
    @apply px-2 py-0.5 font-bold ml-1;
    @apply rounded-lg;

    &.file-count {
      background-color: $white_dark;
      color: #2554ca;
      font-size: 10px;
      height: 20px;
      width: fit-content;
    }

    &.folder-count {
      background-color: $blue_dark;
      color: white;
      font-size: 10px;
      height: 20px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .badge {
    margin-left: 4px;
    background: #3c9aff8a;
    border-radius: 10px;
    padding: 2px 8px;
    color: $blue_dark;
    font-size: 10px;

    &.subpage {
      background: #3c9aff8a;
    }

    &.element {
      background: #9b3cff8a;
    }
  }

  .add-btn {
    margin-left: 5px;
    color: $blue;
    line-height: 28px;
    height: 30px;
    width: 30px;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #437ce8;
    }
  }

  .add-btn,
  .action-col-wrp {
    opacity: 0;
    transition: all 0.5s ease;
  }

  .tree-root-wrp,
  .fancytree-node {
    &:hover {
      .add-btn,
      .action-col-wrp {
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }

  // Highlight related styles
  li {
    &.highlight-lines {
      border-left-color: $lineHoverColor !important;
    }

    &.highlight-lines > .fancytree-exp-n span.fancytree-expander,
    &.highlight-lines > .fancytree-exp-nl span.fancytree-expander {
      border-bottom-color: $lineHoverColor !important;
      border-left-color: $lineHoverColor !important;
    }
  }

  .highlight-lines > {
    .fancytree-exp-nl span.fancytree-expander,
    .fancytree-exp-nl span.fancytree-expander:hover {
      border-bottom-color: $lineHoverColor !important;
      border-left-color: $lineHoverColor !important;
    }

    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-cl span.fancytree-expander:hover {
      border-left-color: $lineHoverColor !important;
    }

    .fancytree-exp-c span.fancytree-expander,
    .fancytree-exp-e span.fancytree-expander,
    .fancytree-exp-ed span.fancytree-expander {
      &::before {
        background: $lineHoverColor !important;
      }
    }

    .fancytree-exp-cl span.fancytree-expander,
    .fancytree-exp-el span.fancytree-expander,
    .fancytree-exp-edl span.fancytree-expander {
      &::before {
        border-top-color: $lineHoverColor !important;
      }
    }
  }

  @media (max-width: $md) {
    .fancytree-plain span.fancytree-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .add-btn {
      margin-left: 2px;
    }

    .state-col-wrp {
      border: 1px solid #efecfc;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      padding: 1px;
      width: inherit;

      button {
        margin: 0 4px;
        font-weight: 300;
        font-size: 10px;
        color: $grey_dark;
        display: inline-block;
        vertical-align: top;
        padding: 4px 4px;
        border-radius: 0;

        &.state-active {
          color: $blue_dark;
        }
      }
    }

    .fancytree-node {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 12px;
      justify-content: flex-start !important;
    }
  }
  @media (min-width: $xl) {
    .fancytree-plain span.fancytree-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .add-btn {
      margin-left: 20px;
    }
  }
}

.modal-tree-height {
  height: 470px;
}
.tree-title-width {
  width: calc(100% - 52px);
  @media (min-width: $lg) {
  }
}
.tree-folder-title-max-width {
  @media screen and (min-width: $lg) and (max-width: $xxl) {
    width: calc(100% - 25px);
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    max-width: 238px;
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    max-width: 182px;
  }
}
.icon-padding{
  padding-top: 7px;
  padding-left: 10px;
}
.tree-folder-title-repo-module-width {
  @media screen and (min-width: $xl) {
    width: calc(100% - 80px);
  }
  @media screen and (min-width: $xs) and (max-width: $xl) {
    width: calc(100% - 50px);
  }
}
.tree-folder-title-all-module-width {
  width: 100%;
}
.tree-folder-title-all-module-width-unset {
  width: unset;
}

.disable-radio {
  cursor: no-drop !important;
  opacity: 0.5;
}

.project-drop-button {
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary;
  min-height: 36px;
  border: 1px solid var(--grey_light);
  border-radius: 20px;
  padding: 5px;
  margin-left: 390px;
}
.project-drop-button-web {
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary;
  min-height: 36px;
  border: none;
  border-radius: 20px;
  padding: 5px;
  margin-left: 390px;
}
.btn_disabled {
  color: grey;
}

.project-drop-box {
  .MuiMenu-list {
    outline: 0;
    display: flex;
    flex-direction: column;
    width: 123px;
    align-items: flex-start;
    margin-left: 8px;
    .MuiMenuItem-root {
      padding: 5px 5px !important;
    }
    .MuiMenuItem-root:hover {
      color: #2554ca;
    }
  }
}
.script-id-style {
  max-width: fit-content;
  margin-right: 2px !important;
}
.name-id-separator {
  color: $ff-blue;
}
.fancytree-plain span.fancytree-title.script-id-style {
  overflow: visible !important;
  white-space: pre-wrap !important;
}
