@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.screen-width {
  width: 100%;
}
.text-format-common {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.result-base {
  .result-content-height {
    height: 84vh;
  }

  .base-card {
    background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
    background: $white_light 0% 0% no-repeat padding-box;
  }
  .highlight {
    border: 2px solid #6583d46b;
    border-radius: 1px;
    outline: none;
    box-shadow: 0 0 10px #9ecaed;
  }

  .link-text-color {
    color: $blue;
    &:hover {
      color: $light_blue;
    }
  }
  .card-box-shadow {
    .header-testcase-details {
      background-color: $white_original;
      z-index: 100;
    }
    box-shadow: 0px 0px 6px #00000029;
    opacity: 1;
  }

  .text-xss {
    font-size: 10px;
  }

  .text-value {
    @extend .text-format-common, .fontPoppinsRegularMd;
    line-height: 17px;
    color: $grey_darkest;
  }

  .result-primary {
    color: $blue_dark;
  }

  .result-passed,
  .status-passed {
    color: $green;
  }

  .result-failed,
  .status-failed {
    color: $red;
  }

  .result-warning,
  .status-warning {
    color: $yellow;
  }

  .result-skipped,
  .status-skipped {
    color: $grey_dark;
  }

  .table-header {
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    opacity: 1;
    color: $grey_darkest;
    letter-spacing: 0px;
    text-align: left;
    line-height: 2rem;
  }
  .table-scroll-height {
    max-height: 19vh;
  }
  //more details styles

  .more-details {
    @extend .fontPoppinsSemiboldLg;
    text-align: left;
    letter-spacing: 0px;
    color: $grey_darkest;
  }

  .platform-chevron {
    color: $grey_dark;
  }
  .table-body {
    @extend .text-format-common;
    line-height: 2rem;
    color: $grey_dark;
  }
  //chart styles
  .legend {
    margin-left: 18rem;
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    color: $grey_darkest;
    opacity: 1;
  }

  .circle {
    display: inline-block;
  }
  .graph-labels {
    @extend .fontPoppinsRegularSm;
    letter-spacing: 0px;
    color: $blue_dark;
  }
  .chart-tooltip-wrapper {
    display: block;
  }

  .chart-tooltip {
    width: 300px;
    border-radius: 4px;
    text-align: left;
    font-size: 8px;
    letter-spacing: 0px;
    box-shadow: none !important ;
  }

  .chart-tooltip::before {
    content: ' ';
    border: none;
    height: 0;
  }

  .chart-tooltip.top {
    top: 0px;
    height: 65px;
  }
  /* CSS border triangles */
  .chart-tooltip.top::before {
    top: 100%;
    border-top-color: $white_dark;
  }

  #chart .apexcharts-tooltip span {
    padding: 6px 5px;
    display: inline-block;
  }

  .chart-mark-0 {
    margin-top: -39px;
    margin-left: 74px;
  }

  //module result styles
  .result-tree-bs {
    .search_text {
      display: flex;
      justify-content: space-between;
    }
    .script-name {
      @extend .fontPoppinsSemiboldLg;
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      opacity: 1;
    }
    .tree-coldata-style {
      color: var(--dm-normal-989797);
      text-align: left;
      // font-size: 14px;
      letter-spacing: 0px;
      color: $grey_dark;
      opacity: 1;
    }
  }

  //accordion styles
  .result-header {
    @extend .text-format-common, .fontPoppinsSemiboldMd;
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    height: 48px;
    color: $grey_darkest;
    @media (min-width: $sm) {
      font-size: 12px;
      height: fit-content;
    }
    @media (min-width: $lg) {
      height: 48px;
    }
    @media (min-width: $xl) {
      height: 48px;
    }
  }

  .result-header-overView {
    @extend .text-format-common, .fontPoppinsSemiboldSm;
    background: $skyblue_dark 0% 0% no-repeat padding-box;
    height: auto;
    color: $grey_darkest;
    @media (min-width: $sm) {
      font-size: 12px;
      height: fit-content;
    }
    @media (min-width: $lg) {
      height: auto;
    }
    @media (min-width: $xl) {
      height: auto;
    }
  }

  .step-result {
    @extend .text-format-common, .fontPoppinsRegularMd;
    color: $blue;
  }
  .result-body {
    height: 20rem;
    .accordion {
      min-height: 5vh;
      &:hover {
        background-color: $white_accordion;
      }
    }
    .accordion-row-min-h {
      min-height: 5vh;
    }
    .panel {
      background-color: $white_original;
      display: none;
      min-height: 5vh;
      position: relative;
    }
    .result-subHeader {
      @extend .text-format-common, .fontPoppinsSemiboldMd;
      color: $black;
      cursor: pointer;
    }
    .collapse-icon {
      display: none;
      font-size: 22px;
      color: $grey_darkest;
    }

    .expand-icon {
      color: $grey_darkest;
      font-size: 22px;
      display: inline-block;
    }

    .step-header {
      min-height: 5vh;
      font-size: 14px;
      &:hover {
        background-color: $white_accordion;
      }
    }

    .result-status {
      @extend .text-format-common, .fontPoppinsRegularMd;
    }

    .result-duration {
      @extend .text-format-common;
      @extend .fontPoppinsRegularMd;
      color: $grey_darkest;
    }
    .iteration-header {
      @extend .text-format-common, .fontPoppinsSemiboldMd;
      min-height: 5vh;
      color: $blue;
      &:hover {
        background-color: $white_accordion;
      }
    }

    .iteration-step {
      @extend .text-format-common, .fontPoppinsSemiboldMd;
      color: $grey_darkest;
    }
    .iteration-data-set {
      @extend .text-format-common, .fontPoppinsSemiboldXs;
      @apply px-2 py-0.5 ml-1;
      @apply rounded-lg;
      background-color: $white_dark;
      color: $blue;

      &.file-count {
        background-color: $white_dark;
        color: $blue_dark;
        font-size: 10px;
      }
    }
    .section {
      @extend .text-format-common, .fontPoppinsSemiboldMd;
      min-height: 5vh;
      color: $blue;
    }
    .section-hover {
      @extend .section;
      background-color: $white_accordion;
    }
  }
  .accordion-inactive {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .status-filter {
    border: 0.5px solid var(--lm-left-top-nav-border-e5ecfc);
    background: $white_light 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border: 0.5px solid $white_dark;
    border-radius: 5px;
  }
  .filter {
    width: 170px;
    height: 34px;
    background: $white_original;
    border-radius: 5px;
    border: 1px solid $white_original;
    &:hover {
      background-color: $white;
    }
  }
  .selected-filter {
    background-color: $white;
  }

  //machine list suite result styles
  .machine-subHeader {
    @extend .text-format-common, .fontPoppinsSemiboldMd;
    color: $blue;
    cursor: pointer;
  }
  .platform-title {
    @extend .text-format-common, .fontPoppinsRegularMd;
    color: $grey_dark;
    font-size: 13px !important;
  }
  .machine-menu-btn-width {
    width: 72px !important;
  }
}
.platform-details {
  background: $skyblue_dark 0% 0% no-repeat padding-box;
  min-height: 4.2vh;
}
//step tag styles
.tag-icon {
  color: $tag_blue;
}
.actionIcons {
  visibility: hidden;
}
.step-tag {
  .tag-style {
    @extend .fontPoppinsRegularSm;
    color: $grey_darkest;
    line-height: 1.3;
    border-radius: 4px;
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 16px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  &:hover {
    .actionIcons {
      visibility: visible;
    }
  }
  .multi-tags-dropdown {
    .actionIcons {
      visibility: visible;
    }
    .action-col-min-width {
      min-width: 70px;
    }
  }
}

//tag modal styles
.tag-modal.modal-dialog {
  .tag-modal-size {
    height: fit-content;
    width: 640px;
  }
  .tag-label {
    font-size: 12px;
    color: $grey_dark;
  }
  .tag-label-radio {
    @extend .fontPoppinsRegularMd;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: $grey_darkest;
    opacity: 1;
  }
  .tag-scope {
    background: $skyblue_dark 0% 0% no-repeat padding-box;
  }
  .description-style {
    max-height: 80px;
  }
}
.tag-modal.modal-dialog .modal-container .modal-body {
  padding: 20px;
  height: fit-content;
  flex: 1 1;
  overflow-y: auto;
}

.manual-testcase-content {
  @media (min-width: $xl) {
    height: 28rem;
  }
}

.onFocused-row-style {
  position: relative;
  width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 43px;
  padding-top: 15px;
}
.alert-position-style-result {
  width: fit-content;
  max-width: 50%;
  align-self: center;
  position: absolute;
  z-index: 10000;
  margin-top: 0px;
  margin-left: 20px;
}
.no-mtc-info {
  position: relative;
  margin-top: 200px;
}

.modal-content {
  /* Styles for the modal content */
  position: relative;
}

.preview-image {
  /* Styles for the previewed image */
  max-width: 100%;
  height: auto;
  display: block;
}

.image-overlay {
  /* Styles for the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.image-overlay button {
  /* Styles for the buttons (Download and Close) */
  cursor: pointer;
  margin: 10px;
  background-color: $white_original;
  color: $deep_blue;
  border-radius: 50%;
}

.image-overlay button:hover {
  /* Styles for button hover */
  background-color: $deep_blue;
  color: $white_original;
}

.close-icon {
  /* Position close icon to the top right */
  margin-left: auto;
}

.table-cell-text-attachment {
  letter-spacing: 0px;
  color: $rs_analytics_blue_header;
}

.delete-icon-style {
  color: $grey_dark;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: transparent;
}
.delete-icon-style :hover {
  border-radius: 50%;
  color: $deep_blue;
  background: $white_original;
  -webkit-box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
}
.preview-image-style {
  height: 495px;
  width: 1012px;
}
.image-name-width {
  width: 86px;
}

.delete-icon-style-MTC-table {
  color: $grey_dark;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
}

.delete-icon-style-MTC-table :hover {
  border-radius: 50%;
  color: $deep_blue;
  background: $white_original;
  -webkit-box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 25px -5px rgba(0, 0, 0, 0.75);
}
.view-in-expand {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 12px;
}
