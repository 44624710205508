/* Input Field Label Attribute*/

.input-field-label {
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #727171;
  opacity: 1;
  font-weight: 600;
}

/* Input Field input Attribute */

.input-field {
  width: 296px !important;
  height: 34px;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  color: #727171;
  font-weight: 600;
}

/* Button Attribute */

.button_class {
  color: #ffffff;
  height: 31px;
  font-weight: 600;
  font-size: 14px;
  width: 130px;
  background-color: #0a4eb9;
}

.button_class_primary {
  color: white;
  font-weight: 900;
  font-size: 14px;
  height: 31px;
  width: 130px;
  background-color: #6a6d718a;
}
