@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.animate-pulse {
  animation: pulse 1.5s infinite;
}
//---------------------------------------//
.frame {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
@keyframes load {
  0% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(-1px);
  }
}
@keyframes load2 {
  0% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(1px);
  }
}
#loader {
  animation: load 1s ease infinite alternate;
  margin-right: -11px;
}
#loader2 {
  animation: load2 1s ease infinite alternate;
  margin-left: -3px;
}
