@import '@styles/_variables.scss';
@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.header-delete-border {
  width: 600px;
  height: auto;
  display: flex;
  border-top: 4px solid $rs_analytics_warning;
  margin-top: -4px;
  margin-left: -4px;
}

.warning-color {
  color: $rs_analytics_warning;
}

.config-label-button {
  background: $tr-bottom-color;
  font-family: 'Poppins-Medium';
  color: $primary;
  border-radius: 21px;
  font-size: 12px;
  text-align: center;
  height: 34px;
  border: 1px solid $rs_scroll_color_grey;
  position: absolute;
  width: 100px;
  right: 7px;
  transition: border-color 0.3s ease;
}

.config-label-button:hover {
  border-color: $primary;
}

.label-name {
  color: $rs_primary;
}

.input-field-width {
  width: 90%;
}

.description-area-style {
  height: 140px !important;
  resize: vertical !important;
  overflow: auto !important;
  background: none;
  border-radius: 4px;
  border: 1px solid #dbd8d8;
}

.show_action {
  display: none;
}

.show-action-style:hover .show_action {
  display: block;
}

.tab-color {
  color: $rs_analytics_blue_header;
}

.primary-tab-style {
  padding-top: 4px;
  margin-bottom: 5px;
  &.bottom-space-sm {
    margin-bottom: 10px;
  }
  li {
    margin-right: 24px;
  }
  a {
    display: flex;
    @extend .fontPoppinsSemiboldMd;
    line-height: 24px;
    color: $rs_analytics_blue_header;
    padding-right: 2px;
    border-bottom: 2px solid #434db8;
    @media (min-width: $xl) {
      // above 1280
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: $xl) {
    // above 1280
    padding-top: 4px;
    margin-bottom: 20px;
  }
}

.project-label {
  @extend .fontPoppinsSemiboldMd;
  text-align: left;
  letter-spacing: 0px;
  color: $black_original;
  opacity: 1;
  padding-left: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.text-color {
  color: $license_tooltip_bg;
}

.defect-platform-color {
  color: $green65;
}

.details-key {
  color: $rs_blue;
}

.details-value {
  color: $license_tooltip_bg;
}

.details-modal {
  display: 'flex';
  border: 'none';
}

.defect-details-modal {
  width: 500px;
  height: 100%;
  border-radius: 10px 0 0 0;
  background: $white_original 0% 0% no-repeat padding-box;
  padding: 0;
  opacity: 1;
  margin-top: 12px;
}

.details-alert-modal {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 9999;
  margin-left: 300px;
}

.warning-delete-footer {
  width: auto;
  height: 35px;
  background: $skyblue_dark 0% 0% no-repeat padding-box;
}

.label-dropdown {
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: $ff-border;
      }
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: $ff-border;
      }
    }

    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $rs_primary;
      border-width: 1px;
    }
  }

  .MuiChip-root {
    &.MuiChip-outlined {
      border-color: $rs_border;
      &:hover {
        border-color: $rs_primary;
      }

      .MuiChip-label {
        color: $grey_darkest;
        @extend .fontPoppinsRegularSm;
      }
    }

    .MuiChip-deleteIcon {
      height: 18px;
      color: $ff-icon-default;
      &:hover {
        color: $rs_primary;
      }
    }
    height: 24px;
  }
  .MuiAutocomplete-root {
    .MuiAutocomplete-input {
      opacity: 2;
      color: $ff-text;
    }
  }
}
.screen-video-button {
  width: 15rem;
}
