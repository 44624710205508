@import '@src/css_config/variable.scss';

.dialog-container {
  .css-1ivgbss {
    width: 100%;
  }
  * {
    text-transform: unset;
  }

  .dialog-footer {
    position: fixed;
    bottom: 0px;
    height: 35px;
    background: $rs_bg_whitgrey 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 0px 10px;
    opacity: 1;
    width: inherit;
    padding: 20px;
    border-top: 1px solid $rs_light_gray;
    z-index: 999;
    text-align: right;
  }

  .header-close-icon {
    position: absolute;
    right: 10px;
  }
  .dialog-content {
    height: calc(100% - 95px);
    overflow-y: auto;
    position: absolute;
    width: 100%;
    overflow-x: auto;
  }
  .rightButton {
    color: $rs_primary;
    opacity: 1;
    border: 1px solid $rs_primary;
    border-radius: 5px;
    padding: 6px 12px;
    height: 25px;
    font-weight: 500;
    margin: 5px 0 0 0;
    line-height: 1;
  }
  .rightButton-disabled {
    border: 1px solid $gainsboro;
    opacity: 0.5;
    border-radius: 5px;
    padding: 6px 12px;
    height: 25px;
    font-weight: 500;
    margin: 5px 0 0 0;
    line-height: 1;
    border-color: $grey_Nevada;
  }
  .rightButton:hover {
    background: $rs_analytics_blue_header;
    color: $white_original;
  }
  .leftButton {
    border: 1px solid $gray_40;
    opacity: 1;
    color: $gray_40;
    padding: 6px 12px;
    height: 25px;
    font-weight: 500;
    border-radius: 5px;
    margin: 5px 0 0 0;
    line-height: 1;
  }
  .leftButton:hover {
    background: $grey_Nevada;
    color: $white_original;
  }
  .common-closeIcon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .common-closeIcon:hover {
    border: 1px solid;
    cursor: pointer;
  }
}
.drawer-radius-style {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    border-radius: 10px 0px 0px 10px;
  }
}
