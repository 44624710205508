@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

*:focus {
  outline: none;
}

.test-developement {
  /*check if no-steps used anywhere other than test dev*/
  .no-steps {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no-steps-with-pre-post {
    @extend .no-steps;
    margin-top: 2rem;
    .no-steps-table-tree {
      padding-bottom: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .create-modal {
      position: absolute;
      top: 270px;
      left: 440px;
      width: 540px;
      height: 341px;
      background: $white_light 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 11px #000000a1;
      border-radius: 4px;
      opacity: 1;
    }
    .modal-heading {
      text-align: left;
      letter-spacing: 0px;
      color: $grey_darkest;
      opacity: 1;
    }
    .tab-height {
      // height: 21.5rem;
      height: 90%;
    }
    .searchDrpDwn {
      padding: 0 !important;
    }
    .customSearchDropdownStyle {
      border: none !important;
      border-bottom: 2px solid #dad5d5 !important;
      color: $grey_dark !important;
      font-size: 12px !important;
      &:focus {
        border: none !important;
        border-bottom: 2px solid rgb(67, 67, 247) !important;
        box-shadow: none !important;
        outline: none !important;
        text-align: left;
        /* font: normal normal normal 12px/17px Open Sans; */
        letter-spacing: 0px;
        color: $grey_dark !important;
        font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji';
        font-size: 12px !important;
        padding-bottom: 0;
      }
    }

    /* styles for popup */
    .my-radio-btn:disabled {
      opacity: 0.5 !important;
      pointer-events: none;
    }
    .popup-input-bg {
      background: $white_light 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    .dis-btn:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .popup-height-runsettings {
      max-height: fit-content;
    }

    .include-pre-post-tooltip {
      display: none;
    }
    .pre-tool-div:hover .include-pre-post-tooltip {
      display: inline;
    }
    /* end of styles for popup */

    // Actions tooltip
    .actiontooltip {
      position: relative;
      display: inline-block;
    }

    .actiontooltip .actiontooltiptext {
      visibility: hidden;
      width: 67px;
      background-color: gray;
      color: $white_original;
      font-size: 12px;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      bottom: 68%;
      left: 165%;
      margin-top: 10px;
      margin-left: -60px;
    }

    .actionlengthytooltiptext {
      width: 112px !important;
      left: 105% !important;
    }
    .actiontooltip:hover .actiontooltiptext {
      visibility: visible;
    }
    /* style for description on focus/blur events */
    .descriptionStyle {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
    }

    .text-blue-focus {
      color: $blue !important;
    }
    .border-blue-focus {
      border-bottom: $blue !important;
    }
    .text-overflow-style {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden !important;
    }

    .stepGroupInputDiv {
      height: calc(100% - 70px);
    }
    // actions tooltips ends
    .warning-status {
      color: $yellow;
    }

    // className given for lengthy name of module/script coming under toaster message
    .lengthyName {
      display: inline-block;
      white-space: nowrap;
      width: 11em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tosterMsg {
      margin-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .lengthyChar {
      display: inline-block;
      white-space: nowrap;
      width: 17em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .lengthyCharUrl {
      @extend .lengthyChar;
      width: 15em;
    }
    .lengthyCharTimeStamp {
      @extend .lengthyChar;
      width: 102px;
    }
    .lengthyCharName {
      @extend .lengthyChar;
      width: 7em;
    }

    // class for addstepbelow condition
    .ShowAddStepBelow {
      display: inline;
    }
    .HideAddStepBelow {
      display: none;
    }
    @media screen and (max-width: 1024px) {
      .responsive-parameter-div {
        left: 40%;
        right: 20%;
        top: 38% !important;
        width: 57%;
        height: fit-content;
        .responsive-parameter-div-height {
          min-height: 240px;
          height: calc(61vh - 123px);
        }
      }
    }

    .sg {
      /* line-height: 6px; */
      font-size: 0.4rem;
      border: 1px solid #efbb07;
      border-radius: 9px;
      padding: 0px 6px;
      color: #efbb07;
      height: fit-content;
    }
    .return-DropDown {
      width: 187px;
    }

    .dev-child-page-margin-style {
      margin-top: 1px;
    }

    .module_child_page_margin_style {
      margin-top: 4.5px;
    }

    #textareaTd {
      border: none;
      background-color: transparent;
      resize: none;
      outline: none;
    }
    #textareaTd:focus {
      box-shadow: none !important;
    }
    .heightPre-Post {
      height: 40%;
    }

    .dynamicTable {
      margin-right: 8rem;
    }
  }
  .qrs_modal_body {
    max-height: 525px;
  }

  .test-dev-modal {
    &.module-modal {
      .test-dev-popup {
        max-height: 400px;
        min-height: 225px;
      }
    }
    &.script-modal {
      .edit-script-popup {
        max-height: 400px;
        min-height: 200px;
        height: 400px;
      }

      .test-dev-popup {
        max-height: 400px;
        min-height: 225px;
      }
    }
    &.pre-post-modal {
      .test-dev-popup {
        max-height: 400px;
        min-height: 225px;
      }
    }
  }

  .test-dev-modal-height {
    max-height: 525px;
  }

  .name-field-test-dev {
    height: 54.2px;
  }
  .script-type-field-test-dev {
    height: 66.18px;
  }

  .parent-module-field-test-dev {
    height: 70px;
    @extend .fontPoppinsRegularSm;
  }

  .mui-input-text-field {
    .MuiInputBase-root {
      &.MuiInput-root {
        input[type='text'] {
          padding-bottom: 3px;
          &:focus {
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  .variable-input-value {
    .MuiButtonBase-root.masked-checkbox {
      padding: 9px;
      &.Mui-checked {
        color: $rs_primary;
      }
    }
  }
  .stepstyle {
    z-index: 10;
  }
  .width-per-5 {
    width: 5%;
  }
  .width-per-8 {
    width: 8%;
  }
  .width-per-45 {
    width: 45%;
  }
  .width-per-42 {
    width: 42%;
  }
  .download-btn-style {
    position: relative;
    left: 71%;
  }
  .parallel_run_status_style {
    margin-top: -10px;
    margin-right: 16px;
  }

  //Labels Dropdown
  .autocomplete-search {
    .MuiIconButton-root {
      padding: 0px !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
      padding: 3px !important;
      position: relative;
    }
  }

  [class*='-MuiAutocomplete-listbox'] {
    max-height: 12vh !important;
  }

  .step-alert-position-style {
    width: fit-content;
    align-self: center;
    position: absolute;
    z-index: 10000;
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .qrs-modal-header {
    background: $color_blue;
    color: $white_original;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    .qrs-header-title {
      @extend .fontPoppinsMediumMd;
      text-align: left;
      letter-spacing: 0px;
      color: $white_original;
      opacity: 1;
    }
  }

  .qrs-environment-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 14px;
    background: $off_white;
  }
  .qrs-environment-title {
    color: $color_blue;
  }
  .root-module-width-style {
    max-width: 550px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .result-badge-warning {
    background-color: $result_warning_color;
  }

  .script-edit-alert-style {
    width: fit-content;
    align-self: center;
    position: absolute;
    z-index: 10000;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.no-steps-content {
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data-provider-modal-height {
  min-height: 355px;
  height: 80vh;
}

.variable-details-close {
  color: $white_original;
  position: absolute;
  opacity: 1;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 5px;
  &:hover {
    border: 2px solid;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -1px -13px 0 0;
  }
}
.create-edit-close {
  color: $white_original;
  position: absolute;
  opacity: 1;
  width: 8px;
  height: 8px;
  right: 20px;
  top: 5px;
  &:hover {
    border: 2px solid;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: -1px -13px 0 0;
  }
}
.web-service-auth-header {
  color: $ff-primary;
}
.create-edit-data-providers-input {
  input[type='text'] {
    box-shadow: none;
  }
  textarea {
    background-color: transparent;
  }
}

.webservice-auth-btn-disable {
  border: 1px solid $rs_primary;
  border-radius: 4px;
  background: $ff-white 0% 0% no-repeat padding-box;
  color: $ff-blue-100;
  padding: 6px 12px;
  opacity: 0.5;
  height: 25px;
  font-weight: 500;
  margin: 5px 12px 0 0;
  line-height: 1;
}
.webservice-auth-btn {
  border: 1px solid $rs_primary;
  border-radius: 4px;
  background: $ff-white 0% 0% no-repeat padding-box;
  color: $rs_primary;
  padding: 6px 12px;
  opacity: 1;
  height: 25px;
  font-weight: 500;
  margin: 5px 12px 0 0;
  line-height: 1;
  &:hover {
    background-color: $rs_primary;
    color: $ff-white;
  }
}

.filepath-dropdown {
  height: 200px;
  overflow: auto;
  padding: 16px 0;
  margin-top: 8px;
  z-index: 100;
  background-color: $ff-white;
  box-shadow: 0px 8px 16px 0px $slateBlack;
  position: relative;
  .option-wrapper {
    .file-options {
      @extend .fontPoppinsRegularMd;
      padding: 8px 16px;
      cursor: pointer;
      color: $ff-text;
      &:hover {
        background-color: $ff-primary;
        color: $ff-white;
      }
    }
  }
}
.tooltip-style {
  width: 350px;
  height: 47px;
  text-align: center;
  .MuiTooltip-tooltip {
    background-color: $ff-text;
    color: $ff-white;
    box-shadow: 0px 0px 10px $ff-primary;
  }
}
.empty-dropdown-style {
  @extend .fontPoppinsRegularMd;
  @extend .filepath-dropdown;
  height: 72px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}


.input-parameter-container {
  width: 600px;
}

.expandable-content {
  overflow: hidden;
  transition: max-height 0.8s ease-in-out, opacity 0.8s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.expandable-content.expanded {
  max-height: 1000px;
  opacity: 1;
}
