@import '../../css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.modal-split {
  border-bottom: 1px solid $white_dark;
}

.label-overflow {
  overflow-wrap: break-word;
  word-break: break-all;
  @extend .fontPoppinsRegularMd;
}

.details-content-height {
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh;
}

.testCase {
  color: #727171;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.common-label-text {
  color: var(--text-3c3838);
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-SemiBold;
  font-weight: 400;
}

.link-placeholder-text input::placeholder {
  font-size: 9px;
}

.select-selected-value {
  font-size: 12px !important;
  display: inline-block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-selected-value::before {
  border-bottom: 1px solid $light_grey_white !important;
}

.MuiList-root {
  .manual-test-case-select {
    &.MuiButtonBase-root {
      flex-direction: column;
      align-items: flex-start;
      padding: 5px;
      padding-left: 10px;
      font-size: 0.75rem;
      line-height: 1rem;
      display: flow-root;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.auto-complete input[type='text'] {
  border: none;
}

.MuiAutocomplete-listbox {
  height: 10rem;
}

.user-input-container input[type='text'] {
  font-size: 12px !important;
}

.textarea-content-style {
  border: 1px solid #989797;
  border-radius: 5px;
  height: 133px !important;
  max-height: 133px;
  resize: none;
  overflow: scroll !important;
}

.textarea-content-style:focus {
  border: 2px solid #1648c6;
  box-shadow: none;
  outline: none;
}

.templateNotetext {
  display: none;
}

.templateNote:hover .templateNoteDisplay {
  display: none;
}

.templateNote:hover .templateNotetext {
  display: block;
}

.wrapContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.defect_modal_style {
  min-width: 45vw !important;
}

.defect_popup_width {
  width: 580px;
}
