@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';
@import '@styles/rs_fonts.scss';

.pageHeading {
  @extend .fontPoppinsSemiboldXlg;
  top: 223px;
  left: 136px;
  width: 166px;
  height: 22px;
  text-align: left;
  letter-spacing: 0px;
  color: $grey_darkest;
  opacity: 1;
}

.sharedPage {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fbfcfd;
}

.headerStyle {
  background-color: #f0f3f9;
  color: #3c3838;
}

.dataStyle {
  font-size: 10.5pt;
  color: #727171;
}

.state-button {
  vertical-align: top;
  background-color: transparent;
  color: #1648c6;

  &:hover {
    background-color: transparent;
    color: #2b71f7;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.22;
  }
}

.state-col-wrp {
  border: 1px solid #efecfc;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  padding: 4px;

  button {
    margin: 0 4px;
    font-weight: 300;
    font-size: 10px;
    color: #727172;
    display: inline-block;
    vertical-align: top;
    padding: 4px 5px;
    border-radius: 0;

    &.state-active {
      color: #1648c6;
    }
  }
}

.tableShadow {
  box-shadow: 0px 0px 6px #00000029;
}

.sharedtableSize {
  width: 872px;
  height: 400px;
}

.sharedTableHeight {
  height: 480px;
}

.sharedwidth {
  width: 170px !important;
}

.listofShared {
  font-size: 16px;
  color: #1648c6;
}

.pagecount {
  color: #5580ec;
}

.pageArrow {
  font-weight: 800;
  font-size: 20px;
  color: #e1dfdf;
  cursor: auto;
}

.pageborderleft {
  border-left: 2px solid #5580ec;
}

.currentpagecount {
  width: 20px;
  height: 20px;
  background-color: #5580ec;
  border-radius: 1px;
  color: white;
}

.pagecountborder {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
}

.verticalsepratorLine {
  border-left: 2px solid #e5ecfc;
}

.tableHeaderBackground {
  background-color: #f6f7f9;
}

.colourDanger {
  color: #ea0322;
}

.sharedtableHeaderStyle {
  color: #3c3838;
  padding-top: 15px;
  padding-left: 24px;
}

.font17px {
  font-size: 17px;
}

.font14px {
  font-size: 14px;
}

.font12px {
  font-size: 12px;
}

.colourLMNormal {
  color: #727171;
}

.conflictPadding {
  padding-left: 50%;
}

.sharedRowmerge {
  padding-top: 13px;
  min-height: 48px;
}

.sharedRowmerge:hover {
  background-color: #f0f3f9;
}

.rowsepratorLine {
  border-bottom: 1px solid #e5ecfc;
}

.selectedpagecountStyle {
  width: 20px;
  height: 20px;
  background: #1648c6 0% 0% no-repeat padding-box;
  border-radius: 1px;
  color: white;
}

.unselectedpagecountStyle {
  background-color: white;
  border-radius: 1px;
  color: #5580ec;
}

.sharedPagination {
  height: 52px;
  padding-top: 16px;
  padding-right: 16px;
}

.clickablenxtIcon {
  background: var(--text-3c3838) 0% 0% no-repeat padding-box;
  color: #3c3838;
  opacity: 1;
}

.nxtIcon {
  background: var(--disable-e1dfdf) 0% 0% no-repeat padding-box;
  color: #e1dfdf;
  opacity: 1;
}

.dangerColor {
  letter-spacing: 0px;
  color: #c50303;
  opacity: 1;
}

.dltLocator {
  visibility: hidden;
  color: #1648c6;
  font-weight: 700;
}

.dltLocator:hover {
  color: #c50303;
}

.nameColWidth {
  width: 18%;
}

.pagenameColWidth {
  width: 18%;
}

.typeColWidth {
  width: 18%;
}

.eleInWidth {
  width: 18%;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
}

.sharedRowmerge:hover .dltLocator {
  visibility: visible;
}

.sharedsearch {
  width: 20px !important;
  height: 40px !important;
}

.hoverRow:hover {
  background-color: #f0f3f9;
}

.recordedAction {
  visibility: hidden;
}

.hoverRow:hover .recordedAction {
  visibility: visible;
  position: relative;
}

.recpageNameWidth {
  width: 250px;
}

.receleNameWidth {
  width: 195px;
}

.rectypeWidth {
  width: 180px;
  height: 60px;
}

.reclocTypeWidth {
  width: 140px;
}

.recvalTypeWidth {
  width: 115px;
}

.reclocValWidth {
  width: 140px;
}

.recexpWidth {
  width: 150px;
}

.recActionWidth {
  width: 110px;
}

.locValWidth {
  width: 135px;
  margin-right: 10px;
}

.mergeLocWidth {
  width: 130px !important;
}

.Elementtype:hover {
  border-bottom: 2px solid #1648c6;
}

.LocatorType:hover {
  border-bottom: 2px solid #1648c6;
}

.Element-trimmer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
  float: left;
}

.Actionicon {
  position: absolute;
  top: 5px;
}

.Recordedsave[type='button']:disabled {
  opacity: 0.5;
}

.ElementDisplay {
  border-bottom: 2px solid black;
}

.shareloctxt {
  color: #079328;
}

.pageArrowEnable {
  font-weight: 800;
  font-size: 20px;
  color: #1648c6;
  cursor: pointer;
}

.scrolClass {
  overflow-y: auto;
  max-height: 139px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.conflictPageHeader {
  height: 63px;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
}

.conflictPage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.conflictBody {
  height: calc(75vh - 40px);
}

.ConflictEleDisplay {
  width: 20%;
  height: 99%;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
}

.ConflictDisplay {
  width: 78%;
  height: 99%;
  background: var(--lm-workspace-light-fbfcfd) 0% 0% no-repeat padding-box;
  background: #fbfcfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
}

.elementCapsule {
  width: 90%;
  margin-left: 6%;
}

.conflictEleCapsule {
  width: 80%;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border-radius: 5px;
}

.conflictEleCapsuleClicked {
  width: 80%;
  height: 32px;
  border: 1px solid var(--primary-1648c6);
  background: #3a62c9 0% 0% no-repeat padding-box;
  border: 1px solid #1648c6;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border-radius: 5px;
  color: #ffffff;
}

.elementConflictBodyHeader {
  padding-left: 30px;
  height: 42px;
  color: var(--text-3c3838);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: OpenSans-SemiBold;
  letter-spacing: 0px;
  color: #3c3838;
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
}

.conflictHeader-style {
  @extend .fontPoppinsSemiboldXlg;
  color: var(--text-3c3838);
  padding-left: 20px;
  text-align: left;
  letter-spacing: 0px;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

.recordedEleConflictBody {
  padding: 15px 30px 15px 35px;
  max-height: 320px;
  width: 48%;
  border-right: 1px solid #e5ecfc;
}

.projectEleConflictBody {
  padding: 15px 30px 15px 35px;
  max-height: 320px;
  width: 48%;
  border-left: 1px solid #e5ecfc;
}

.conflictBodySubHeader {
  @extend .fontPoppinsSemiboldMd;
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

.conflictBodyText {
  @extend .fontPoppinsRegularMd;
  color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: #525252;
}

.conflictBodyDangerText {
  @extend .fontPoppinsRegularMd;
  color: var(--alert-c50303);
  letter-spacing: 0px;
  color: #c50303;
}

.conflictBodyLocators {
  height: 172px;
  padding: 0px 15px 0px 15px;
  background: var(--lm-left-top-nav-f0f3f9) 0% 0% no-repeat padding-box;
  background: #f0f3f9 0% 0% no-repeat padding-box;
}

.conflictBodyLocatorStyls {
  padding: 15px 20px 15px 20px;
  height: 150px;
  width: 47%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
}

.locatorsMoveIcon {
  height: 172px;
  width: 41px;
  padding-left: 8px;
  background-color: #fbfcfd;
  border-left: 1px solid #e5ecfc;
  border-right: 1px solid #e5ecfc;
  margin: 0px 15px 0px 15px;
}

.conflictBodyLocatorCapsule {
  max-width: 40%;
  padding-right: 10px;
  padding-top: 10px;
}

.locActionIcon {
  display: none;
  padding-left: 8px;
}

.conflictBodyLocatorCapsule:hover {
  max-width: 54%;
  padding-right: 20px;
}

.conflictBodyLocatorCapsule:hover .locActionIcon {
  display: block;
}

.conflictBodyLocatorCapsule:hover .individualLocStyle {
  width: 79%;
}

.individualLocStyle {
  height: 36px;
  width: 100%;
  padding: 0px 18px 0px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

.ConflicteditIcon {
  width: 18px !important;
  height: 18px !important;
}

.unEditableconflictLocatorCapsule {
  max-width: 40%;
  padding-right: 10px;
  padding-top: 10px;
}

.unEditableindividualLocStyle {
  height: 36px;
  width: 100%;
  padding: 0px 18px 0px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

.conflicyBodyFooter {
  border-top: 2px solid #e5ecfc;
  height: 10%;
  padding-top: 2%;
}

.conflictInnerBody {
  height: 50.25vh;
}

.elementCapsuleNameStyle {
  width: 80%;
}

.alert-position-Shared {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 10000;
  margin-left: 40px;
}

.publish-min-width {
  min-width: 153px;
}
