@import './variable.scss';
@import '@styles/rs_fonts.scss';

@mixin fl_button_Style {
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  @extend .fontPoppinsMediumMd;
  @media (min-width: $xl) {
    // above 1280
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 5px;
    height: 32px;
  }
}

@mixin f2_button_Style {
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  border-radius: 5px;

  @extend .fontMontserratMediumXs;
  @media (min-width: $xl) {
    // above 1280
    font-size: 10px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 5px;
    height: 32px;
  }
}

.primary-btn {
  @include fl_button_Style;
  background: $submit_btn_background_color;
  border: 1px solid $submit_btn_border_color;
  color: $submit_btn_font_color;
  &:hover {
    opacity: 0.92;
  }
  &:disabled {
    opacity: 0.5;
  }
  &.icon-btn {
    svg {
      position: relative;
      top: 3px;
    }
  }
}

.secondary-btn {
  @include fl_button_Style;
  border: 1px solid $clear_btn_border_color;
  color: $clear_btn_font_color;
  &:hover {
    border-color: $blue_dark;
    color: $blue_dark;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.gray-btn {
  @include fl_button_Style;
  background: $cancel_btn_background_color;
  border: 1px solid $cancel_btn_border_color;
  color: $cancel_btn_font_color;
  &:hover {
    border-color: $grey_light_white;
    color: $black_original;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.button-primary {
  @include f2_button_Style;
  background: transparent;
  border: 1px solid $rs_primary;
  color: $blue_dark;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: $rs_primary;
    color: $white_original;
    opacity: 1;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button-secondary {
  @include f2_button_Style;
  background: transparent;
  border: 1px solid $grey_Nevada;
  color: $grey-light-medium;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: $grey-light-medium;
    color: $white_original;
    opacity: 1;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
