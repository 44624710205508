@import '@src/css_config/variable.scss';
.selected-action-menu-item {
  color: $ff-white;
  background-color: $ff-primary;
}
.action-menu-item {
  color: $ff-text !important;
}
.details-label {
  color: $rs_blue !important;
}
.details-value {
  color: $ff-text !important;
}
.user-role-section {
  max-height: calc(68vh - 50px);
  border: 1px solid $ff-border;
  padding: 0 16px 2px 16px;
  margin: 16px 16px 0 16px;
  .table-height {
    max-height: calc(53vh - 25px);
    overflow-y: auto;
  }
}
