@import '@src/css_config/variable.scss';
@import '@styles/rs_fonts.scss';

.tabelCellContentValue {
  word-break: break-all;
}
.parent-container-response-header {
  width: 100%;
  height: 280px;
}
.capture-response-alignment-rows {
  background: $light_white_50;
  position: relative;
  top: 4px;
}
.lengthy-char-name {
  width: 15em;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 1rem;
  text-overflow: ellipsis;
}
