@import '@src/css_config/variable.scss';
@import '@src/styles/rs_fonts.scss';
@import '@styles/_variables.scss';

.custom-chip-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: max-content;
  border: 1px solid $grey_dark;
  border-radius: 16px;
  height: 29px;
  font-size: 12px;
  padding: 5px 10px;
  background-color: $white_original;
  transition: all 0.1s;
  gap: 8px;
  margin: 5px;

  .custom-chip-wrapper-text {
    @extend .fontPoppinsRegularSm;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $grey_dark;
  }

  .custom-chip-wrapper-close {
    cursor: pointer;
    background-image: url('../../../assets/svg-imports/cross-icon-gray.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 16px;
    width: 16px;
  }
}
.custom-chip-wrapper:hover {
  background-color: $rs_primary;
  color: $white_original;
  .custom-chip-wrapper-text {
    @extend .fontPoppinsRegularSm;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $white_original;
  }

  .custom-chip-wrapper-close {
    cursor: pointer;
    background-image: url('../../../assets/svg-imports/cross-icon-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid $white_original;
    border-radius: 50%;
  }
}
