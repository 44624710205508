@import './variable.scss';
@import '@styles/rs_fonts.scss';

.execution-table-outer-layer {
  width: auto;
  margin: 10px;
  height: 184px;
  border: 1px solid;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c4c3c3;
  opacity: 1;
  border-radius: 15px;
  overflow: auto;
}
.table-label-color {
  color: $rs_primary;
}

.status-button {
  padding-left: 16px;
  height: 27px;
}

.primaryStatus {
  color: $rs_analytics_pass;
}

.destructiveStatus {
  color: $rs_analytics_fail;
}

.secondaryStatus {
  color: $rs_analytics_warning;
}

.client-machine-header {
  height: 34px;
  background-color: #f2f2f2;
}

.button-alignment {
  top: 54%;
  left: 45%;
}

.table-data-view {
  max-height: 130px;
  display: inline-block;
  width: 100%;
}