:root {
  --lightGray: #727171;
}

.switch1 {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
}

#footer {
  position: fixed;
  height: 52px;
  bottom: 0px;
  left: 6%;
  right: 0.5%;
  margin-bottom: 5px;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.statuschange {
  width: 640px;
  height: 217px;
}

.primaryNo {
  max-width: 26px;
  height: 20px;
  padding-left: 7px;
  padding-right: 7px;
  opacity: 1;
  background: #e1dfdf 0% 0% no-repeat padding-box;
  font-family: 'OpenSans-SemiBold';
  font-size: 10px;
  color: #3c3838;
  border-radius: 6px;
  margin-left: 6px;
}

.slider1 {
  position: absolute;
  border: 1px solid gray;
  cursor: pointer;
  top: -2px;
  left: 0;
  width: 43px;
  height: 18px;
  right: -2px;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider1:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider1 {
  background-color: transparent;
  border: 1px solid #2196f3;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider1:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  background-color: #2196f3;
}

.slider1.round1 {
  border-radius: 17px;
}

.slider1.round1:before {
  border-radius: 50%;
}

.disable-style {
  pointer-events: none;
  color: rgb(168, 163, 163);
}

.download-icon-style {
  color: grey;
  margin-left: 8px;
  width: 15px;
  height: 15px;
}

.download-icon-style:hover {
  color: #1648c6;
  cursor: pointer;
}

.file-download-date-style {
  font-size: 10px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  color: #3c3838;
}

#phoneNumbers {
  width: 220px !important;
}

.fie-icon-style {
  font-size: 18px;
  width: 20px;
  height: 20px;
  color: #1648c6;
}

.marginupper_error {
  border-top: 3px solid red;
}

input:checked + .slider1 {
  background-color: transparent;
  border: 1px solid #1648c6;
}

input:checked + .slider1:before {
  transform: translateX(25px);
  background-color: #1648c6;
}

textarea::placeholder {
  color: #b4b0b0;
}

.user-select-container select:disabled {
  color: #d7d4d4;
}

.user-select-container select option {
  color: #3c3838;
}

.line-style {
  width: 58px !important;
  border-top: 1px solid #e5ecfc;
  margin-top: 21px;
  margin-left: -74px;
  margin-right: -34px;
}

.user-select-container select {
  border-bottom: 2px solid #cecaca !important;
}

.user-select-container select:focus {
  border: none;
  border-bottom: 2px solid #dad5d5 !important;
  box-shadow: none;
  outline: none !important;
  color: var(--lm-normal-525252);
  text-align: left;
  letter-spacing: 0px;
  color: #727171;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 12px;
  padding-bottom: 0;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.cursor {
  cursor: pointer;
}

.dashed_border {
  border-bottom: 1px dashed #ece6e6;
}

.pagecount {
  color: #5580ec;
}

.pageborderleft {
  border-left: 2px solid #e1dfdf;
}

.currentpagecount {
  width: 20px;
  height: 20px;
  background-color: #5580ec;
  border-radius: 1px;
  color: white;
}

.pagecountborder {
  border-top: 2px solid #e5ecfc;
  padding-top: 4px;
  margin-top: auto !important;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.user-input-container input[type='text'] {
  border: none;
  border-bottom: 2px solid #cecaca !important;
  box-shadow: none;
  outline: none !important;
  color: var(--lm-normal-525252);
  text-align: left;
  height: 20px;
  padding-left: 0px;
  letter-spacing: 0px;
  color: #727171;
  font-family: OpenSans-Regular !important;
  font-size: 14px !important;
  padding-bottom: 0;
}

.dropdown-style {
  color: var(--text-3c3838);
  text-align: left;
  letter-spacing: 0px;
  color: #3c3838;
  padding-left: 0px;
  font-size: 12px;
  font-family: OpenSans-Regular, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  padding-bottom: 0;
}

.brezz {
  z-index: 99;
}

tr.project-row {
  height: 48px;
}
.schedule-instance-row-height {
  height: 74px !important;
}
.ReactModal__Overlay {
  z-index: 1300;
  background-color: #0000004d !important;
  overflow: hidden;
}

.optionStyle option {
  height: 100px;
}

.borderStyle2 {
  width: 95%;
  margin-left: 3px;
  border-top: 2px dotted;
  border-color: #eae6e6;
}

.delete_btn_style {
  margin-top: 12px;
  margin-right: 21px;
  float: right;
}

.delete_btn_style_role_details {
  text-align: right;
  padding: 12px 0;
  margin-top: 1px !important;
}

.delete_btn_style_user_details {
  margin-top: 30px;
  float: right;
}

.user_delete_btn_style_userdetails {
  text-align: right;
  margin-top: 16px;
  padding: 0px 0;
}

.role_permission_style {
  box-shadow: 0px 0px 6px #000000a1;
  opacity: 1;
  background-color: #fbfcfd;
  margin: 2px;
  top: 376px;
  margin-right: 12px;
  left: 489px;
  margin-top: 20px;
  padding: 32px;
  height: 321px;
  width: 943px;
}

.role_permission_style1 {
  box-shadow: 0px 0px 6px #000000a1;
  opacity: 1;
  background-color: #fbfcfd;
  margin: 2px;
  top: 376px;
  margin-right: 12px;
  left: 489px;
  margin-top: 20px;
}

.role_permission_style2 {
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  margin: 2px;
  margin-right: 20px;
  padding: 3px;
}

[data-title] {
  outline: none;
  font-size: 30px;
  visibility: visible !important;
  position: relative;
  cursor: pointer;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  left: 20px;
  min-width: 40px;
  max-width: 170px;
  display: block;
  z-index: 100;
  align-items: center;
  background: gray;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 7px;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  visibility: visible !important;
}

[data-title]:hover::after {
  visibility: visible !important;
}

#del[data-title] {
  outline: none;
  font-size: 30px;
  visibility: visible !important;
  position: relative;
  cursor: pointer;
}

#del[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  min-width: 40px;
  max-width: 200px;
  display: block;
  z-index: 100;
  align-items: center;
  background: gray;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 7px;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  visibility: visible !important;
}

#del[data-title='Open project cannot be deleted']:hover::before {
  left: -75px;
  margin-bottom: 5px;
}

#del[data-title='Delete']:hover::before {
  left: -10px;
  margin-bottom: 5px;
}

#del[data-title='Edit']:hover::before {
  left: 5px;
  margin-bottom: 5px;
}

#remove[data-title] {
  outline: none;
  font-size: 30px;
  visibility: visible !important;
  position: relative;
  cursor: pointer;
}

#remove[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  min-width: 40px;
  max-width: 200px;
  display: block;
  z-index: 100;
  align-items: center;
  background: gray;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 7px;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  visibility: visible !important;
}

#remove[data-title='Remove']:hover::before {
  left: -20px;
  margin-bottom: 12px;
}

#remove[data-title='Edit']:hover::before {
  left: 0px;
  margin-bottom: 10px;
}

#remove1[data-title='Edit']:hover::before {
  left: -10px;
  margin-bottom: 10px;
}

#role[data-title] {
  outline: none;
  font-size: 30px;
  visibility: visible !important;
  position: relative;
  cursor: pointer;
}

#role[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  min-width: 40px;
  max-width: 250px;
  display: block;
  z-index: 100;
  align-items: center;
  background: gray;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 7px;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  visibility: visible !important;
}

#role[data-title='Default role cannot be deleted']:hover::before {
  left: -60px;
  margin-bottom: 13px;
}

#role[data-title='Default role cannot be edited']:hover::before {
  left: -60px;
  margin-bottom: 13px;
}

#role[data-title='Delete']:hover::before {
  left: -15px;
  margin-bottom: 13px;
}

#role[data-title='Edit']:hover::before {
  left: 5px;
  margin-bottom: 13px;
}

#role[data-title='Role from all projects cannot be edited']:hover::before {
  left: -90px;
  margin-bottom: 13px;
}

#role[data-title='Role from all projects cannot be deleted']:hover::before {
  left: -90px;
  margin-bottom: 13px;
}

#more[data-title='More']:hover::before {
  left: -10px;
  margin-bottom: 5px;
}

.duplicate-popup {
  width: 640px;
  height: 228px;
  margin-top: 220px;
}

.duplicate-pop {
  width: 640px;
}

.duplicate-p {
  width: 640px;
  height: 217px;
}

#info[data-title] {
  outline: none;
  font-size: 30px;
  visibility: visible !important;
  cursor: pointer;
}

#info[data-title]:hover::before {
  content: attr(data-title);
  bottom: 30px;
  left: 20px;
  bottom: -5px;
  min-width: 40px;
  max-width: 100px;
  display: block;
  z-index: 100;
  align-items: center;
  background: gray;
  display: inline-block;
  padding: 3px;
  border-radius: 7px;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  visibility: visible !important;
  justify-content: center;
  padding-left: 10px;
}

#info[data-title]:hover::after {
  visibility: visible !important;
}

.big-size-file {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  cursor: default;
}

#pprivilege:invalid {
  color: #727171;
  opacity: 0.6;
}

#projecttype:invalid {
  color: #727171;
  opacity: 0.6;
}

.project-row-user {
  height: 48px !important;
  padding: 0;
  border: none;
}

.project-row-user td {
  height: auto !important;
  padding-bottom: 9.6px;
}

.msg_align {
  margin-top: 17% !important;
}

.two {
  color: initial !important;
}

.two:checked {
  color: #1648c6 !important;
}

.alert-position-style {
  width: fit-content;
  align-self: center;
  position: absolute;
  z-index: 10000;

  margin-left: -104px;
}

#projecttype:focus {
  color: #727171;
  border-bottom: 2px solid #cecaca;
}

.HoverPhone:hover {
  color: #1648c6 !important;
}

.hoverMinus:hover {
  color: #c50303 !important;
}

.dial-code {
  float: right;
}

.project-config-header-style {
  height: 51px;
  padding-top: 15px;
}

.HoverPhone:hover {
  color: #1648c6 !important;
}

.hoverMinus:hover {
  color: #c50303 !important;
}

.dial-code {
  float: right;
}

.phoneNumberUserPage > input {
  width: 254px !important;
  border-radius: 0px;
}

.hoverItem:hover {
  color: #1648c6 !important;
}

.z-index-child-popup {
  z-index: 1300;
}

.emailErrorContent {
  margin-top: 4px;
}

.add-user-data {
  max-height: 38vh;
  overflow-y: auto;
  width: 950px;
  position: absolute;
}

.removePhone {
  position: absolute;
  right: 74px;
  top: 68%;
}

.labelColor {
  color: var(--lightGray);
}
